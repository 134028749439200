import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import EksoDataTable from "components/shared/ui/Table/EksoDataTable";
import { withStyles } from "@material-ui/core/styles";
import { formatDate } from "lib/dateUtils";
import changeCase from "change-case";
import UserActionMenu from "./UserActionMenu";

const styles = theme => ({
  serialCell: {
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
  },
  hideCellSmDown: {
    [theme.breakpoints.down("sm")]: {
      width: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      color: "transparent",
    },
  },
  hideCellMdDown: {
    [theme.breakpoints.down("md")]: {
      width: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      color: "transparent",
    },
  },
  nameCol: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      textOverflow: "clip",
      whiteSpace: "normal",
      wordBreak: "break-all",
    },
  },
  emailCol: {
    width: "25%",
    overflow: "hidden",
    textAlign: "right",
  },
  statusCol: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  actionCol: {
    width: "56px",
    paddingRight: "1px !important",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
    },
  },
});

class ManageUsersTable extends Component {
  columns = [
    {
      label: "FIRST NAME",
      prop: "userFirstName",
      className: this.props.classes.nameCol,
    },
    {
      label: "LAST NAME",
      prop: "userLastName",
      className: this.props.classes.nameCol,
    },
    {
      label: "EMAIL",
      prop: "email",
      className: classNames(
        this.props.classes.emailCol,
        this.props.classes.hideCellSmDown,
        this.props.classes.nameCol
      ),
      isNumeric: true,
    },
    {
      label: "LAST LOGIN",
      prop: "lastSignInAt",
      className: this.props.classes.hideCellMdDown,
      isNumeric: true,
      format: value => formatDate(value, "None Yet"),
    },
    {
      label: "CREATED ON",
      prop: "createdAt",
      className: this.props.classes.hideCellMdDown,
      isNumeric: true,
      format: value => formatDate(value),
    },
    {
      label: "ROLE",
      prop: "role",
      className: this.props.classes.hideCellSmDown,
      isNumeric: true,
      format: value => (value === "customer_admin" ? "Admin" : "Therapist"),
    },
    {
      label: "STATUS",
      prop: "status",
      isNumeric: true,
      format: value => changeCase.headerCase(value),
      className: this.props.classes.statusCol,
    },
    {
      label: "",
      prop: "",
      unsortable: true,
      className: this.props.classes.actionCol,
      format: (value, data) => {
        return <UserActionMenu user={data} />;
      },
    },
  ];

  render() {
    const { users, usersResourceMetadata, loadMore, onSortChange } = this.props;

    return (
      <EksoDataTable
        id="manageUsers"
        columns={this.columns}
        rows={users}
        resourceMetadata={usersResourceMetadata}
        loadMore={loadMore}
        onSortChange={onSortChange}
      />
    );
  }
}

ManageUsersTable.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array,
  usersResourceMetadata: PropTypes.object,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  loadMore: PropTypes.func,
};

export { ManageUsersTable as ManageUsersTableBase };
export default withStyles(styles)(ManageUsersTable);
