import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Text from "../Typography/Text";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    fontWeight: "bold",
    marginLeft: "2px",
    marginTop: 0,
    color: "#4A4A4A",
  },
});

const InnerPaperPanelTitle = ({ classes, title }) => {
  return (
    <Text fontSize={12} className={classes.root}>
      {title}
    </Text>
  );
};

InnerPaperPanelTitle.propTypes = {
  title: PropTypes.node,
  classes: PropTypes.object,
};

export default withStyles(styles)(InnerPaperPanelTitle);
