import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
//import * as toastActionCreators from "actions/toastActions";
import SupportModalDialog from "./SupportModalDialog";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { CONTACT_SUPPORT_MODAL_ID } from "constants/ModalIds";
import {
  submit as submitActionCreator,
  getFormSyncErrors,
  isPristine,
  stopSubmit as stopSubmitActionCreator,
  initialize as initializeActionCreator,
} from "redux-form";
import _ from "lodash";

import FieldData from "models/bluechip/FieldData";

const FORM_NAME = "contactSupportReduxForm";

class SupportModalContainer extends Component {
  state = {
    initialized: false,
  };

  componentDidUpdate(prevProps) {
    const wasCreating =
      prevProps.supportsResourceMetadata &&
      prevProps.supportsResourceMetadata.isCreatingItem;
    const {
      stopSubmit,
      supportsFieldData,
      fieldDataActions,
      currentUser,
      initializeForm,
    } = this.props;
    const wasOpen = prevProps.open;
    const {
      isCreateItemSuccess,
      createError,
    } = this.props.supportsResourceMetadata;

    // After update close the dialog, or show field errors if any
    if (wasCreating && isCreateItemSuccess) {
      this.handleClose();
    } else if (wasCreating && !!createError) {
      if (createError.validationMessages) {
        // Throw redux-form submission error
        stopSubmit(FORM_NAME, createError.validationMessages);
      }
    }

    // Retrieve necessary data to render when dialog is open
    if (!wasOpen && this.props.open) {
      // Question data
      if (_.isEmpty(supportsFieldData)) {
        fieldDataActions.find("supports", {
          errorToast: "Contact Support field data retrieval failed",
        });
      }

      // Device data
      this.props.fieldDataActions.find("ekso_serial_numbers", {
        errorToast: "Contact Support field data retrieval failed",
      });
    }

    if (
      !this.state.initialized &&
      supportsFieldData &&
      supportsFieldData.topics &&
      supportsFieldData.topics.length
    ) {
      initializeForm(FORM_NAME, {
        topic: `${supportsFieldData.topics[0].id}`,
        email: currentUser.attributes.email,
      });
      this.setState({ initialized: true });
    }
  }

  handleSubmit = form => {
    if (this.props.isSubmitDisabled) {
      return;
    }

    const values = {
      relationships: {
        topic: form.topic,
      },
      subject: form.subject,
      message: form.message,
      ekso_serial_num: form.eksoSerialNumber,
      to: form.to,
    };

    this.props.supportsActions.create(values, {
      successToast: "Message Sent",
      errorToast: "Failed to Send Message",
    });
  };

  handleClose = () => {
    this.props.modalActions.closeModal(CONTACT_SUPPORT_MODAL_ID);
  };

  handlePrimaryAction = () => {
    this.props.submit(FORM_NAME);
  };

  render() {
    const {
      supportsResourceMetadata = {},
      fieldDataResourceMetadata,
      eksoSerialNumbersFieldData,
      supportsFieldData,
      currentUser,
    } = this.props;

    const fieldDataMissing =
      _.isEmpty(eksoSerialNumbersFieldData) || _.isEmpty(supportsFieldData);
    const loading = fieldDataMissing && !fieldDataResourceMetadata.findError;
    const disabled = fieldDataMissing;
    return (
      <SupportModalDialog
        onSubmit={this.handleSubmit}
        onPrimaryAction={this.handlePrimaryAction}
        primaryActionLabel="Send"
        isSubmitDisabled={
          disabled || this.props.isSubmitDisabled || this.props.isPristine
        }
        loading={loading}
        disabled={disabled}
        onClose={this.handleClose}
        open={this.props.open}
        submitting={
          supportsResourceMetadata && supportsResourceMetadata.isCreatingItem
        }
        fieldData={supportsFieldData}
        eksoSerialNumbers={
          eksoSerialNumbersFieldData &&
          eksoSerialNumbersFieldData.eksoSerialNumbers
            ? eksoSerialNumbersFieldData.eksoSerialNumbers
            : []
        }
        currentUser={currentUser}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    open: state.modal[CONTACT_SUPPORT_MODAL_ID]
      ? state.modal[CONTACT_SUPPORT_MODAL_ID].open
      : false,
    supportsFieldData: state.resources.fieldData
      ? FieldData.query(state.resources)
          .where({ id: "supports" })
          .all()
          .toObjects()[0]
      : {},
    supportsResourceMetadata: state.jsonApiResources.supports
      ? state.jsonApiResources.supports
      : {},
    fieldDataResourceMetadata: state.jsonApiResources.field_data
      ? state.jsonApiResources.field_data
      : {},
    isSubmitDisabled: !_.isEmpty(getFormSyncErrors(FORM_NAME)(state)),
    isPristine: isPristine(FORM_NAME)(state),
    eksoSerialNumbersFieldData: state.resources.fieldData
      ? FieldData.query(state.resources)
          .where({ id: "ekso_serial_numbers" })
          .all()
          .toObjects()[0]
      : {},
  };
};

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  supportsActions: jsonAPIReduxActions(dispatch, "supports"),
  fieldDataActions: jsonAPIReduxActions(dispatch, "field_data"),
  submit: bindActionCreators(submitActionCreator, dispatch),
  stopSubmit: bindActionCreators(stopSubmitActionCreator, dispatch),
  initializeForm: bindActionCreators(initializeActionCreator, dispatch),
});

SupportModalContainer.propTypes = {
  currentUser: PropTypes.object,
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  supportsActions: PropTypes.object,
  submit: PropTypes.func,
  toastActions: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  stopSubmit: PropTypes.func,
  initializeForm: PropTypes.func,
  supportsResourceMetadata: PropTypes.object,
  supportsFieldData: PropTypes.object,
  fieldDataActions: PropTypes.object,
  fieldDataResourceMetadata: PropTypes.object,
  isPristine: PropTypes.bool,
  eksoSerialNumbersFieldData: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportModalContainer);
