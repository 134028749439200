import React from "react";
import PropTypes from "prop-types";

const styles = () => ({
  sitesLabel: {
    color: "rgba(52,73,94,0.87)",
    fontSize: "12px",
    lineHeight: "14px",
    opacity: 0.5,
    mixBlendMode: "normal",
    display: "inline-block",
  },
  hideButton: {
    minWidth: "56px",
    minHeight: "24px",
    paddingTop: "14px",
  },
  hideLabel: {
    color: "#3498DB",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    opacity: 0.5,
    mixBlendMode: "normal",
    textAlign: "right",
    display: "inline-block",
    marginTop: "-10px",
  },
  sitesContent: {
    maxHeight: "416px",
    overflowY: "scroll",
    height: "100%",
  },
  textAlignLeft: {
    textAlign: "left",
    paddingLeft: "0px",
    borderBottom: "1px solid #D8D8D8",
    height: "26px",
  },
  textAlignRight: {
    textAlign: "right",
    paddingRight: "18px !important",
    borderBottom: "1px solid #D8D8D8",
    height: "26px",
  },
  toolbarContent: {
    borderRight: "1px solid #D8D8D8",
    paddingLeft: "12px",
    alignContent: "start",
  },
  listText: {
    margin: "-28px",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  listItemText: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontSize: "14px",
  },
  listItem: {
    paddingLeft: "0px",
    margin: "0px 0px -12px -12px",
    paddingTop: "0px",
  },
  listRoot: {
    width: "100%",
    paddingTop: "1px",
  },
});

import {
  Grid,
  withStyles,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Checkbox,
  Button,
} from "@material-ui/core";

function SitesList({
  space,
  classes,
  showCompare,
  sites,
  selectedSiteID,
  sitesColors,
  onSiteClick,
  site
}) {
  return (
    <Grid
      container
      xs={12 - space}
      spacing={0}
      className={classes.toolbarContent}
    >
      <Grid item xs={8} className={classes.textAlignLeft}>
        <Typography className={classes.sitesLabel} variant="subheading">
          {site ? 'SELECT DEVICES' : 'SELECT SITES'}
        </Typography>
      </Grid>
      <Grid item xs={4} className={classes.textAlignRight}>
        <Button
          className={classes.hideButton}
          size="small"
          onClick={() => showCompare(false)}
        >
          <Typography variant="subheading" className={classes.hideLabel}>
            HIDE
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12} className={classes.sitesContent}>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className={classes.listRoot}
        >
          {sites.length
            ? sites.map(site => {
                const labelId = `checkbox-list-secondary-label-${site.id}`;
                return (
                  <React.Fragment key={site.id}>
                    <ListItem className={classes.listItem}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedSiteID.includes(site.id)}
                          style={{
                            color: sitesColors[site.id].color,
                          }}
                          inputProps={{ "aria-label": "primary checkbox" }}
                          onChange={event =>
                            onSiteClick(site, event.target.checked)
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        key={`list-item-${site.id}`}
                        primary={site.name}
                        classes={{
                          root: classes.listText,
                          primary: classes.listItemText,
                        }}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </React.Fragment>
                );
              })
            : null}
        </List>
      </Grid>
    </Grid>
  );
}

SitesList.propTypes = {
  space: PropTypes.number,
  classes: PropTypes.object,
  showCompare: PropTypes.function,
  sites: PropTypes.array,
  sitesColors: PropTypes.object,
  onSiteClick: PropTypes.func,
  selectedSiteID: PropTypes.array.isRequired,
};

export default withStyles(styles)(SitesList);
