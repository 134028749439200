import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import LearningCenterCategoryLayout from "./LearningCenterCategoryLayout";
import { withStyles } from "@material-ui/core/styles";
import LearningCenterFaqCategoryItem from "./LearningCenterFaqCategoryItem";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import VideoModal from "./VideoModal";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
  },
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
});

const LearningCenterPage = ({
  classes,
  trainingVideoItems,
  //outcomeMeasureItems,
  documentItems,
  categoryItems,
  onNavigate,
  loading,
  error,
  onPlayVideo,
}) => {
  let mainContent;
  if (loading) {
    mainContent = (
      <PaperPanel>
        <LoadingPanel />
      </PaperPanel>
    );
  } else if (error) {
    mainContent = (
      <PaperPanel>
        <ErrorPanel error={error} />
      </PaperPanel>
    );
  } else {
    mainContent = (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <LearningCenterCategoryLayout
            title="Training Videos"
            items={trainingVideoItems}
            viewAllPath="/dashboard/learning/videos"
            onNavigate={onNavigate}
            id="videos"
            onPlayVideo={onPlayVideo}
          />
        </Grid>
        {/* TODO "future" section
        <Grid item xs={12}>
          <LearningCenterCategoryLayout
            title="Outcome Measures"
            items={outcomeMeasureItems}
          />
        </Grid> */}
        <Grid item xs={12}>
          <LearningCenterCategoryLayout
            title="Documents"
            items={documentItems}
            viewAllPath="/dashboard/learning/documents"
            onNavigate={onNavigate}
            id="documents"
            onPlayVideo={onPlayVideo}
          />
        </Grid>
        <Grid item xs={12}>
          <LearningCenterCategoryLayout
            title="FAQ"
            items={categoryItems}
            ItemComponent={LearningCenterFaqCategoryItem}
            viewAllPath="/dashboard/learning/faq"
            onNavigate={path => onNavigate(path)}
            id="faq"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.main}>{mainContent}</div>
      <VideoModal />
    </div>
  );
};

LearningCenterPage.propTypes = {
  trainingVideoItems: PropTypes.array,
  //outcomeMeasureItems: PropTypes.array,
  documentItems: PropTypes.array,
  categoryItems: PropTypes.array,
  classes: PropTypes.object,
  onNavigate: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.object,
  onPlayVideo: PropTypes.func,
};

export default withStyles(styles)(LearningCenterPage);
