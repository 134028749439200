import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LineGraphBase from "components/shared/ui/Graph/LineGraphBase";
import moment from "moment";
import { calculateSuggestedMaxYFromData } from "lib/graphUtils";
import TimeLineGraphPager from "./TimeLineGraphPager";

// eslint-disable-next-line
const styles = theme => ({});

const getTimeSettingsForPeriod = (period, page) => {
  switch (period) {
    case "year": {
      return {
        format: "YYYY-MM",
        tooltipFormat: "MMMM YYYY",
        unit: "month",
        min: moment()
          .subtract(page, "years")
          .add(1, "month")
          .format("YYYY-MM"),
        max: moment()
          .subtract(page - 1, "years")
          .format("YYYY-MM"),
        stepSize: 1,
        displayFormats: {
          month: "MMM",
        },
      };
    }
    case "month": {
      return {
        format: "YYYY-MM-DD",
        tooltipFormat: "MMM DD, YYYY",
        unit: "day",
        min: moment()
          .subtract(page, "month")
          .format("YYYY-MM-DD"),
        max: moment()
          .subtract(page - 1, "month")
          .format("YYYY-MM-DD"),
        stepSize: 2,
        displayFormats: {
          month: "MMM DD",
        },
      };
    }
    case "week": {
      return {
        format: "YYYY DDDD",
        isoWeekday: true,
        tooltipFormat: "MMM DD, YYYY",
        unit: "day",
        stepSize: 1,
        min: moment()
          .subtract(page, "weeks")
          .add(1, "day")
          .format("YYYY DDDD"),
        max: moment()
          .subtract(page - 1, "weeks")
          .format("YYYY DDDD"),
        displayFormats: {
          day: "MMM DD",
        },
      };
    }
  }
};

const TimeLineGraphBase = ({
  id,
  data,
  period,
  unitLabel,
  tooltipLabelCallback,
  emptyMax,
  lineColor,
  pointColor,
  fill,
  fillColor,
  pointRadius,
  maxY,
  page,
  onPagerClick,
}) => {
  let suggestedMax = maxY;
  if (!maxY) {
    suggestedMax = calculateSuggestedMaxYFromData(data, emptyMax);
  }

  const time = getTimeSettingsForPeriod(period, page);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        label: tooltipLabelCallback
          ? tooltipLabelCallback
          : tooltipItem => tooltipItem.yLabel + ` ${unitLabel}`,
      },
    },
    scales: {
      xAxes: [
        {
          bounds: "ticks",
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            source: "auto",
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 8,
            fontFamily: "Roboto",
            padding: period == "month" ? 7 : 3,
          },
          type: "time",
          distribution: "linear",
          time,
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            min: 0,
            max: maxY,
            maxTicksLimit: 8,
            suggestedMax,
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 8,
            fontFamily: "Roboto",
            padding: 5,
          },
        },
      ],
    },
  };

  return (
    <div id={`${id}_graph`}>
      <TimeLineGraphPager
        onPagerClick={onPagerClick}
        page={page}
        period={period}
      />
      <LineGraphBase
        data={data}
        labels={[]}
        options={options}
        lineColor={lineColor}
        pointColor={pointColor}
        fill={fill}
        fillColor={fillColor}
        pointRadius={pointRadius}
      />
    </div>
  );
};

TimeLineGraphBase.defaultProps = {
  page: 1,
};

TimeLineGraphBase.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  classes: PropTypes.object,
  data: PropTypes.array,
  period: PropTypes.string,
  unitLabel: PropTypes.string,
  emptyMax: PropTypes.number,
  tooltipLabelCallback: PropTypes.func,
  pointRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  lineColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pointColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  maxY: PropTypes.number,
  page: PropTypes.number,
  onPagerClick: PropTypes.func,
};

export default withStyles(styles)(TimeLineGraphBase);
