import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Doughnut } from "react-chartjs-2";

const DoughnutChartBase = ({
  data,
  backgroundColor,
  labels,
  height,
  width,
  rotation,
  className,
}) => {
  let options = {
    cutoutPercentage: 85,
    maintainAspectRatio: false,
    legend: { display: false },
    rotation,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  if (!data || !data.length || _.max(data) === 0) {
    // Empty data render
    backgroundColor = ["#E8E8E8"];
    data = [100];
    labels = [""];
    options.tooltips = {
      enabled: false,
    };
  }

  const chartjsData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor,
        hoverBackgroundColor: backgroundColor,
      },
    ],
    options,
  };

  return (
    <div
      className={className}
      style={{ height: `${height}px`, width: `${width}px` }}
    >
      <Doughnut
        data={chartjsData}
        options={options}
        height={height}
        width={width}
      />
    </div>
  );
};

DoughnutChartBase.defaultProps = {
  height: 120,
  width: 120,
  rotation: Math.PI * -0.5,
};

DoughnutChartBase.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  backgroundColor: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  rotation: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default DoughnutChartBase;
