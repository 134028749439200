import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TableBody, TableCell, TableRow } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  emptyRow: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
  },
  cell: {
    paddingTop: "2px",
    paddingBottom: "2px",
  },
});

const MessageTableBody = ({ classes, colSpan, children }) => {
  return (
    <TableBody>
      <TableRow className={classes.emptyRow}>
        <TableCell className={classes.cell} colSpan={colSpan}>
          {children}
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

MessageTableBody.propTypes = {
  colSpan: PropTypes.number,
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(MessageTableBody);
