import React from "react";
import PropTypes from "prop-types";

import BaseText from "./BaseText";

const Text = ({ children, style, className, fontSize, fontFamily, testId }) => {
  return (
    <BaseText
      testId={testId}
      text={children}
      fontFamily={fontFamily}
      fontSize={fontSize}
      style={style}
      className={className}
    />
  );
};

Text.defaultProps = {
  fontSize: 14,
  fontFamily: "Roboto",
};

Text.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  fontSize: PropTypes.number,
  fontFamily: PropTypes.string,
  testId: PropTypes.string,
};

export default Text;
