import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import InsessResultsLayout from "./InsessResultsLayout";
import { INSESS_MODAL_ID } from "constants/ModalIds";

class InsessResultsContainer extends Component {
  onEdit = insessEvent => {
    this.props.modalActions.openModalWithData(INSESS_MODAL_ID, insessEvent);
  };

  render() {
    const { session, type } = this.props;

    return (
      <InsessResultsLayout session={session} type={type} onEdit={this.onEdit} />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
});

InsessResultsContainer.propTypes = {
  type: PropTypes.string,
  session: PropTypes.object,
  modalActions: PropTypes.object,
};

export default connect(
  null,
  mapDispatchToProps
)(InsessResultsContainer);
