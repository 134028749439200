import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TableCell, TableRow } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  root: {},
  row: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
    "&>td": {
      overflow: "hidden",
    },
  },
  cell: {
    "& .todayTime": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
  clickableRow: {
    cursor: "pointer",
  },
});

const DataTableRow = ({ classes, className, onClick, columns, rowData }) => {
  return (
    <TableRow
      key={rowData.id}
      onClick={
        onClick
          ? () => {
              onClick(rowData);
            }
          : null
      }
      className={classNames(classes.row, className, {
        [classes.clickableRow]: !!onClick,
      })}
      data-test="tableRow"
    >
      {columns.map(column => (
        <TableCell
          key={column.key ? column.key : column.prop}
          className={classNames(classes.cell, column.className)}
          numeric={column.isNumeric}
          data-test={column.key ? column.key : column.prop}
        >
          {column.format
            ? column.format(rowData[column.prop], rowData, column.prop)
            : rowData[column.prop]}
        </TableCell>
      ))}
    </TableRow>
  );
};

DataTableRow.propTypes = {
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  rowData: PropTypes.object,
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(DataTableRow);
