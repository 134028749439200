import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MessageTableBody from "./MessageTableBody";
import LoadingPanel from "../Panel/LoadingPanel";
import MessagePanel from "../Panel/MessagePanel";
import ErrorPanel from "../Panel/ErrorPanel";
import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  table: {
    tableLayout: "fixed",
    "& tbody:last-child tr:last-child td": {
      borderBottom: "0",
    },
  },
  invisibleHeaderRow: {
    height: "0 !important",
    padding: "0 !important",
    margin: "0 !important",
    border: "0 !important",
  },
  invisibleHeaderCell: {
    height: "0 !important",
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    margin: "0 !important",
    border: "0 !important",
  },
  emptyTablePanel: {},
  errorTablePanel: {},
  loadingTablePanel: {},
});

const TableContent = ({
  classes,
  columns,
  loading,
  children,
  error,
  emptyContent,
}) => {
  let emptyTableBody;
  if (!error && !loading && (!children || !React.Children.count(children))) {
    emptyTableBody = (
      <MessageTableBody colSpan={columns.length}>
        {emptyContent ? (
          emptyContent
        ) : (
          <MessagePanel
            className={classes.emptyTablePanel}
            iconName="directions_walk"
            headerText="No Results"
            description="Please refine your filters"
          />
        )}
      </MessageTableBody>
    );
  }

  let errorTableBody;
  if (error) {
    errorTableBody = (
      <MessageTableBody colSpan={columns.length}>
        <ErrorPanel className={classes.errorTablePanel} error={error} />
      </MessageTableBody>
    );
  }

  let loadingTableBody;
  if (loading) {
    loadingTableBody = (
      <MessageTableBody colSpan={columns.length}>
        <LoadingPanel className={classes.loadingTablePanel} />
      </MessageTableBody>
    );
  }

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.invisibleHeaderRow}>
          {columns.map(column => {
            return (
              <TableCell
                key={column.key ? column.key : column.prop}
                className={classNames(
                  classes.invisibleHeaderCell,
                  column.className
                )}
                numeric={column.isNumeric}
              />
            );
          })}
        </TableRow>
      </TableHead>
      {emptyTableBody}
      {error ? errorTableBody : children}
      {loadingTableBody}
    </Table>
  );
};

TableContent.propTypes = {
  error: PropTypes.object,
  emptyContent: PropTypes.node,
  classes: PropTypes.object,
  children: PropTypes.node,
  loading: PropTypes.bool,
  columns: PropTypes.array.isRequired,
};

export default withStyles(styles)(TableContent);
