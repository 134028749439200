import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    position: "relative",
    height: "auto",
    borderRight: "1px solid #E8E8E8",
    borderBottom: "1px solid #E8E8E8",
    padding: "16px",
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      height: "136px",
    },
  },
  borderBlocker: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      position: "absolute",
      left: "0",
      bottom: "-1px",
      backgroundColor: "white",
      height: "1px",
      width: "16px",
    },
  },
});

const LearningCenterItemLayout = ({ classes, children, id }) => {
  return (
    <Paper square elevation={0} className={classes.root} id={id}>
      {children}
      <div className={classNames(classes.borderBlocker, "borderBlocker")} />
    </Paper>
  );
};

LearningCenterItemLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  id: PropTypes.any,
};

export default withStyles(styles)(LearningCenterItemLayout);
