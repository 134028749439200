import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import AuthLayoutFooter from "./AuthLayoutFooter";
import AuthLayoutPanel from "./AuthLayoutPanel";
import Text from "components/shared/ui/Typography/Text";
import PanelHeader from "components/shared/ui/Typography/PanelHeader";

import BgImage from "images/ekso-bg.jpg";
import Logo from "components/shared/ui/Logo";

const styles = theme => ({
  body: {
    background: `url(${BgImage}) no-repeat center center fixed`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    margin: 0,
    height: "100%",
  },
  header: {
    margin: 0,
    marginBottom: "16px",
    color: "#4A4A4A",
  },
  description: {
    marginTop: 0,
    marginBottom: "16px",
  },
  loginPage: {
    paddingBottom: "60px",
  },
  logo: {
    width: "68px",
    height: "68px",
    display: "block",
    marginBottom: "48px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("xs")]: {
      marginTop: "10%",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "15%",
    },
  },
  innerGrid: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%",
      flexBasis: "95%",
    },
  },
  trademarkSymbol: {
    bottom: "4px",
    right: "4px",
    fontSize: "6px",
  },
});

class AuthLayoutComponent extends Component {
  state = {};

  componentDidMount() {
    document.body.className = this.props.classes.body;
  }

  render() {
    const {
      header,
      description,
      subContent,
      classes,
      renderFooter,
      lang,
    } = this.props;
    return (
      <div className={classes.loginPage}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid
            item
            xs={11}
            sm={7}
            md={6}
            lg={5}
            xl={4}
            className={classes.innerGrid}
          >
            <div>
              <Logo
                className={classes.logo}
                dimensions={68}
                color="#267CB3"
                classes={{ trademarkSymbol: classes.trademarkSymbol }}
              />
              <AuthLayoutPanel>
                <PanelHeader
                  testId="authLayoutHeader"
                  className={classes.header}
                >
                  {header}
                </PanelHeader>
                {description ? (
                  <Text className={classes.description}>{description}</Text>
                ) : null}
                {this.props.children}
              </AuthLayoutPanel>
              {subContent}
            </div>
          </Grid>
        </Grid>
        {renderFooter ? <AuthLayoutFooter lang={lang} /> : null}
      </div>
    );
  }
}

AuthLayoutComponent.defaultProps = {
  renderFooter: true,
};

AuthLayoutComponent.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  description: PropTypes.node,
  classes: PropTypes.object,
  subContent: PropTypes.node,
  renderFooter: PropTypes.bool,
  lang: PropTypes.string,
};

export default withStyles(styles)(AuthLayoutComponent);
