import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";

import numberFormatter from "lib/numberFormatter";
import EksoDataTable from "components/shared/ui/Table/EksoDataTable";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import Text from "components/shared/ui/Typography/Text";
import MessagePanel from "components/shared/ui/Panel/MessagePanel";
import { isWidthDown } from "@material-ui/core/withWidth";
import * as networkActionCreators from "actions/networkActions";

const styles = theme => ({
  table: {
    padding: "0px",
    borderRadius: "4px",
  },
  headerDiv: {
    padding: "0px",
  },
  tableDiv: {
    padding: "0px",
  },
  dateCol: {
    width: "350px",
    [theme.breakpoints.down("xs")]: {
      width: "72px",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentHeader: {
    width: "100%",
    padding: "0",
    margin: "0",
    boxShadow: "none",
    height: "52px",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
  },
  contentHeaderToolbar: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dateField: {
    marginRight: "4px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "48%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-8px",
      maxWidth: "inherit",
      marginTop: "2px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "30%",
    },
  },
  activityButton: {
    marginLeft: "8px",
    marginRight: "8px",
    padding: "8px",
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
  hideCellMdDown: {
    width: "0 !important",
    height: "0 !important",
    padding: "0 !important",
    margin: "0 !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "transparent",
  },
  hideCellXs: {
    [theme.breakpoints.down("xs")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  alignLeftSm: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      flexDirection: "row",
      paddingLeft: "8px",
    },
  },
  patientLink: {
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class UtilizationTable extends Component {
  state = {
    dateLabel: "This Month",
    sortObj: { column: "site_name", order: "DESC" },
  };

  handleSortChange = sortObj => {
    this.setState({ sortObj });
  };

  _sortedRows() {
    const { sortObj } = this.state;
    const { rows, resourceMetadata } = this.props;
    if (resourceMetadata && resourceMetadata.isFetchingItems) return [];
    return _.orderBy(
      rows || [],
      [_.camelCase(sortObj.column)],
      [sortObj.order.toLowerCase()]
    );
  }

  handleExportClick = () => {
    const { onExportClick, clientFilter, resourceMetadata } = this.props;
    const period = clientFilter.period;

    const mappedRows = this._sortedRows().map(row =>
      _.mapValues(
        _.mapKeys(row, (value, key) => _.snakeCase(key)),
        (value, key) => {
          if (key.includes("time")) {
            return numberFormatter.secondsToRoundedTime(value);
          }
          return value;
        }
      )
    );

    if (period != "allTime") {
      const page = resourceMetadata.filter && resourceMetadata.filter.page;
      const momentPeriod = period.replace("this", "").toLowerCase();
      let start;
      let end;
      if (page <= 1) {
        start = moment().startOf(momentPeriod);
        end = moment().endOf(momentPeriod);
      } else {
        start = moment()
          .subtract(page - 1, momentPeriod)
          .startOf(momentPeriod);
        end = moment()
          .subtract(page - 1, momentPeriod)
          .endOf(momentPeriod);
      }

      const startDate = start.format("YYYY-MM-DD");
      const endDate = end.format("YYYY-MM-DD");

      onExportClick(mappedRows, { startDate: startDate, endDate: endDate });
    } else {
      onExportClick(mappedRows);
    }
  };

  handleSiteClick = rowData => {
    if (!this.props.site) {
      this.props.networkActions.changeSite({
        id: rowData.id,
        name: rowData.siteName,
      });
    }
  };

  render() {
    const { resourceMetadata, loadMore, classes, site } = this.props;
    const { sortObj } = this.state;

    const columns = [
      {
        label: "SITE NAME",
        prop: "siteName",
        className: this.props.classes.dateCol,
      },
      {
        label: "DEVICE IDS",
        prop: "deviceIds",
        isNumeric: true,
        className: this.props.classes.alignLeftSm,
      },
      {
        label: "DEVICES",
        prop: "totalDevices",
        isNumeric: true,
        className: this.props.classes.alignLeftSm,
      },
      {
        label: "PATIENTS",
        prop: "totalPatients",
        isNumeric: true,
        className: this.props.classes.alignLeftSm,
      },
      {
        label: "SESSIONS",
        prop: "totalSessions",
        isNumeric: true,
        className: this.props.classes.alignLeftSm,
      },
      {
        label: "UP TIME",
        prop: "avgUpTime",
        format: numberFormatter.secondsToRoundedTime,
        isNumeric: true,
        className: this.props.classes.alignLeftSm,
      },
      {
        label: "WALKING TIME",
        prop: "avgWalkTime",
        format: numberFormatter.secondsToRoundedTime,
        isNumeric: true,
        className: this.props.classes.alignLeftSm,
      },
      {
        label: "STEP COUNT",
        prop: "avgSteps",
        isNumeric: true,
        format: numberFormatter.numberWithCommas,
        className: this.props.classes.alignLeftSm,
      },
    ];

    if (!resourceMetadata || !resourceMetadata.initiated) {
      return null;
    }

    let cols = columns;

    if (site) {
      cols[0] = {
        label: "DEVICE ID",
        prop: "customerNum",
        isNumeric: false,
        className: this.props.classes.dateCol,
      };
      cols[1] = {
        className: this.props.classes.hideCellMdDown,
        prop: "device",
      };
    }

    const title = (
      <Text fontSize={16} className={classes.title}>
        {site ? "Devices" : "Sites"}
      </Text>
    );

    const toolbar = (
      <div className={classes.toolbarContent}>
        <Button
          color="primary"
          className={classes.activityButton}
          onClick={this.handleExportClick}
        >
          EXPORT
        </Button>
      </div>
    );

    const emptyContent = (
      <MessagePanel
        className={classes.emptyTablePanel}
        headerText="No Information Recorded"
        description="Come back soon."
      />
    );

    let autoHeightMax = "calc(100vh - 416px)";
    if (isWidthDown("sm", this.props.width)) {
      autoHeightMax = "488px";
    }

    return (
      <div className={classes.root}>
        <ContentHeader
          title={title}
          toolbar={toolbar}
          classes={{
            root: classes.contentHeader,
            toolbar: classes.contentHeaderToolbar,
          }}
        />
        <EksoDataTable
          id="utilizationTable"
          columns={cols}
          rows={this._sortedRows()}
          resourceMetadata={resourceMetadata}
          loadMore={loadMore}
          sort={sortObj}
          onSortChange={this.handleSortChange}
          scrollbarsSettings={{
            autoHeight: true,
            autoHeightMax,
          }}
          classes={{
            root: classes.table,
            headerDiv: classes.headerDiv,
            tableDiv: classes.tableDiv,
          }}
          emptyContent={emptyContent}
          onRowClick={rowData => this.handleSiteClick(rowData)}
        />
      </div>
    );
  }
}

UtilizationTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array,
  resourceMetadata: PropTypes.object,
  onSortChange: PropTypes.func,
  loadMore: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onNavigate: PropTypes.func,
  customers: PropTypes.array,
  autoHeightMax: PropTypes.string,
  width: PropTypes.string,
  onDateChange: PropTypes.func,
  onCustomerChange: PropTypes.func,
  onExportClick: PropTypes.func,
  dateCurrentValue: PropTypes.string,
  customerCurrentValue: PropTypes.array,
  clientFilter: PropTypes.object,
  site: PropTypes.object,
  networkActions: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  networkActions: bindActionCreators(networkActionCreators, dispatch),
});

export { UtilizationTable as UtilizationTableBase };
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(UtilizationTable));
