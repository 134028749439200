import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/shared/ui/Button/ActionButton";
import ModalDialog from "components/shared/ui/Modal/ModalDialog";
import SupportReduxForm from "./SupportReduxForm";

const SupportModalDialog = ({
  open,
  onClose,
  onPrimaryAction,
  onSubmit,
  isSubmitDisabled,
  submitting,
  primaryActionLabel,
  fieldData,
  disabled,
  loading,
  eksoSerialNumbers,
  currentUser,
}) => {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      title="Contact Support"
      loading={loading}
      buttons={[
        <ActionButton
          key="sendContactSupportButton"
          variant="contained"
          color="primary"
          onClick={onPrimaryAction}
          disabled={isSubmitDisabled}
          loading={submitting}
          data-test="sendContactSupportButton"
        >
          {primaryActionLabel}
        </ActionButton>,
      ]}
      dialogOptions={{
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      }}
    >
      <SupportReduxForm
        onSubmit={onSubmit}
        fieldData={fieldData}
        eksoSerialNumbers={eksoSerialNumbers}
        disabled={disabled}
        initialValues={{ email: currentUser.attributes.email }}
      />
    </ModalDialog>
  );
};

SupportModalDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onPrimaryAction: PropTypes.func,
  primaryActionLabel: PropTypes.string,
  open: PropTypes.bool,
  classes: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  fieldData: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  eksoSerialNumbers: PropTypes.array,
  currentUser: PropTypes.object,
};

export default SupportModalDialog;
