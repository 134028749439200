import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

import Hidden from "@material-ui/core/Hidden";
import NavigationDrawer from "./NavigationDrawer";
import MobileContentHeader from "./MobileContentHeader";
import FilterDrawer from "./FilterDrawer";
import SupportModal from "./SupportModal";
import { Scrollbars } from "react-custom-scrollbars";

const drawerWidth = 198;
const mobileDrawerWidth = 280;
const filterDrawerWidth = 200;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#34495E",
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth + "px",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    height: "100%",
    width: mobileDrawerWidth,
    backgroundColor: "white",
    overflow: "hidden",
    color: "#4A4A4A",
    [theme.breakpoints.up("md")]: {
      color: "white",
      width: drawerWidth,
      backgroundColor: "#34495E",
      position: "fixed",
    },
  },
  navigationDrawerContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    // flexGrow: 1,
    backgroundColor: "#f3f3f3",
    padding: 0,
    position: "relative",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  "content-right": {
    [theme.breakpoints.up("md")]: {
      marginRight: -filterDrawerWidth,
    },
  },
  contentShift: {
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 200px)",
      transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  "contentShift-right": {
    [theme.breakpoints.up("md")]: {
      marginRight: 0,
    },
  },
});

class DashboardLayout extends React.Component {
  state = {
    mobileOpen: false,
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  render() {
    const { classes, isFilterOpen, mobileToolbarHeight } = this.props;

    const drawer = (
      <NavigationDrawer
        mobileOpen={this.state.mobileOpen}
        onDrawerToggle={this.handleDrawerToggle}
        className={classes.navigationDrawerContent}
      />
    );

    return (
      <div className={classes.root} data-test="dashboardLayout">
        <Hidden mdUp>
          <MobileContentHeader onDrawerToggle={this.handleDrawerToggle} />
          <Drawer
            variant="temporary"
            anchor="left"
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Scrollbars hideTracksWhenNotNeeded>{drawer}</Scrollbars>
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Scrollbars hideTracksWhenNotNeeded>{drawer}</Scrollbars>
          </Drawer>
        </Hidden>
        <div
          className={classNames(classes.content, classes["content-right"], {
            [classes.contentShift]: isFilterOpen,
            [classes["contentShift-right"]]: isFilterOpen,
          })}
        >
          <Hidden mdUp>
            <div
              className={classes.toolbar}
              style={{ minHeight: mobileToolbarHeight }}
            />
          </Hidden>
          {this.props.children}
        </div>

        <FilterDrawer />
        <SupportModal />
      </div>
    );
  }
}

DashboardLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isFilterOpen: PropTypes.bool,
  mobileToolbarHeight: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(DashboardLayout);
