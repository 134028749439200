import * as validationUtils from "validation/validationUtils";

export default function validate(values) {
  const errors = {};

  validationUtils.validateRequired(["email"], values, errors);

  validationUtils.validateEmail(["email"], values, errors);

  return errors;
}
