import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import LearningCenterItemLayout from "./LearningCenterItemLayout";
import ReactImageFallback from "react-image-fallback";
import FallbackThumbnail from "./FallbackThumbnail";
import Icon from "@material-ui/core/Icon";
import download from "downloadjs";
import Truncate from "react-truncate";
import Skeleton from "react-loading-skeleton";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  thumbnail: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    verticalAlign: "bottom",
  },
  title: {
    color: "rgba(74,74,74,1)",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "24px",
    marginBottom: "4px",

    [theme.breakpoints.down("sm")]: {
      marginBottom: "8px",
    },
  },
  description: {
    color: "rgba(155,155,155,1)",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
  },
  textBlock: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      verticalAlign: "top",
      flexGrow: 1,
    },
  },
  thumbnailContainer: {
    position: "relative",
    marginBottom: "12px",
    width: "100%",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px",
      marginRight: "16px",
      marginBottom: 0,
      flexGrow: 0,
      flexShrink: 0,
    },
  },
  download: {
    width: "100%",
    height: "100%",
    position: "absolute",
    opacity: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.4)",
      color: "white",
      cursor: "pointer",
      opacity: 1,
    },
  },
  downloadIcon: {
    fontSize: "36px",
  },
  info: {
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "20px",
    backgroundColor: "rgba(117,117,117,0.5)",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  metaInfo: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "20px",
    backgroundColor: "rgba(117,117,117,0.5)",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
  infoText: {
    color: "#FFFFFF",
    fontSize: "10px",
    lineHeight: "11px",
    fontFamily: "Roboto",
    fontWeight: 500,
    verticalAlign: "middle",
  },
  videoSkeleton: {
    paddingBottom: "calc(100% * 9/16)",
    position: "relative",
    "& > span": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "100px",
      paddingBottom: 0,
    },
  },
  hide: {
    display: "none",
  },
});

const formatDuration = duration => {
  // Remove leading 00 hours
  if (duration.startsWith("00:")) {
    return duration.substring(3);
  }

  return duration;
};

const WIDTH_ADJUST = 8;

class LearningCenterItem extends Component {
  state = {
    textWidth: 0,
    imageLoaded: false,
  };

  constructor(props) {
    super(props);
    this.textBlock = React.createRef();
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize = () => {
    if (this.textBlock.current && this.textBlock.current.offsetWidth) {
      this.setState({
        textWidth: this.textBlock.current.offsetWidth - WIDTH_ADJUST,
      });
    }
  };

  handleImageLoaded = () => {
    this.setState({ imageLoaded: true });
  };

  render() {
    const { classes, item, width, onPlayVideo } = this.props;

    let fileFormat;
    if (item.fileFormat) {
      fileFormat = item.fileFormat.toUpperCase();
    }
    const isVideo = !!item.videoDuration;
    return (
      <LearningCenterItemLayout classes={{ root: classes.root }} id={item.id}>
        <div className={classes.thumbnailContainer}>
          <div
            className={classNames(classes.videoSkeleton, {
              [classes.hide]: this.state.imageLoaded,
            })}
          >
            <Skeleton width="100%" height="100%" />
          </div>
          <ReactImageFallback
            src={item.fileThumbnail}
            onLoad={item.fileThumbnail ? this.handleImageLoaded : null}
            className={classNames(classes.thumbnail, {
              [classes.hide]: !this.state.imageLoaded,
            })}
            fallbackImage={
              <FallbackThumbnail
                onLoad={this.handleImageLoaded}
                item={fileFormat}
              />
            }
          />
          {!isVideo ? (
            <a
              className={classNames(classes.download, {
                [classes.hide]: !this.state.imageLoaded,
              })}
              onClick={() => download(item.fileUrl)}
              data-test="download"
            >
              <Icon className={classes.downloadIcon}>save_alt</Icon>
            </a>
          ) : (
            <a
              className={classNames(classes.download, {
                [classes.hide]: !this.state.imageLoaded,
              })}
              onClick={() => onPlayVideo(item)}
              data-test="play"
            >
              <Icon className={classes.downloadIcon}>play_arrow</Icon>
            </a>
          )}
          <div
            className={classNames(classes.info, {
              [classes.hide]: !this.state.imageLoaded,
            })}
          >
            <span className={classes.infoText} data-test="fileInfo">
              {isVideo ? formatDuration(item.videoDuration) : fileFormat}
            </span>
          </div>
          {!isVideo ? (
            <div
              className={classNames(classes.metaInfo, {
                [classes.hide]: !this.state.imageLoaded,
              })}
            >
              <span className={classes.infoText} data-test="fileSize">
                {item.fileSize}
              </span>
            </div>
          ) : null}
        </div>
        <div className={classes.textBlock} ref={this.textBlock}>
          <Typography
            variant="body1"
            className={classes.title}
            data-test="itemTitle"
            title={item.title}
          >
            <Truncate lines={2} width={this.state.textWidth}>
              {item.title}
            </Truncate>
          </Typography>
          {item.description ? (
            <Typography
              variant="body1"
              className={classes.description}
              title={item.description}
              data-test="itemDescription"
            >
              <Truncate
                lines={isWidthUp("md", width) ? 2 : 3}
                width={this.state.textWidth}
              >
                {item.description}
              </Truncate>
            </Typography>
          ) : null}
        </div>
      </LearningCenterItemLayout>
    );
  }
}

LearningCenterItem.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  width: PropTypes.string,
  onPlayVideo: PropTypes.func,
};

export default withWidth()(withStyles(styles)(LearningCenterItem));
