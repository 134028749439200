import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import { CONFIRM_MODAL_ID } from "constants/ModalIds";
import ConfirmationDialogComponent from "./ConfirmationDialogComponent";

class ConfirmationDialogContainer extends Component {
  render() {
    const { open, options, modalActions } = this.props;
    return (
      <ConfirmationDialogComponent
        title={options.title}
        message={options.message}
        onConfirm={options.onConfirm}
        confirmText={options.confirmText}
        onCancel={
          options.onCancel ? options.onCancel : modalActions.closeConfirmModal
        }
        cancelText={options.cancelText ? options.cancelText : "Cancel"}
        open={open}
      />
    );
  }
}

const mapStateToProps = state => ({
  open: state.modal[CONFIRM_MODAL_ID].open,
  options: state.modal[CONFIRM_MODAL_ID].options,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
});

ConfirmationDialogContainer.propTypes = {
  options: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.node,
    onConfirm: PropTypes.func,
    confirmText: PropTypes.string,
    onCancel: PropTypes.func,
    cancelText: PropTypes.string,
  }),
  open: PropTypes.bool,
  modalActions: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationDialogContainer);
