import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import InfiniteScrollWrapper from "./InfiniteScrollWrapper";
import TableContent from "./TableContent";
import TableHeader from "./TableHeader";
import { Scrollbars } from "react-custom-scrollbars";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "0 4px 8px 4px",
    overflowY: "hidden",
    transition: "max-height 0.5s linear", // note that we're transitioning transform, not height!
    maxHeight: "100vh",
    transformOrigin: "top", // keep the top of the element in the same place. this is optional.
  },
  headerDiv: {
    padding: "0px 4px",
  },
  scrollDiv: {
    height: "100%",
    width: "100%",
  },
  tableDiv: {
    padding: "0 4px",
  },
  spacer: {
    fontSize: "8px",
  },
  loading: {
    maxHeight: "200px",
  },
});

class DataTable extends Component {
  state = {
    loadTimes: 0,
  };

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      right: "4px",
      backgroundColor: "rgba(0, 0, 0, 0.2)",
      borderRadius: "inherit",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  componentDidUpdate(prevProps) {
    if (this.props.loading && !prevProps.loading) {
      this.setState({ loadTimes: this.state.loadTimes + 1 });
    }
  }

  render() {
    const {
      classes,
      columns,
      children,
      error,
      emptyContent,
      loading,
      sort,
      onSort,
      isInfiniteScroll,
      loadMore,
      hasMore,
      scrollbarsSettings,
      tableContentProps,
      isScrollable,
    } = this.props;

    const tableContent = (
      <TableContent
        columns={columns}
        error={error}
        emptyContent={emptyContent}
        loading={loading}
        {...tableContentProps}
      >
        {children}
      </TableContent>
    );

    let tableDiv;

    if (isInfiniteScroll && isScrollable) {
      tableDiv = (
        <Scrollbars
          hideTracksWhenNotNeeded
          className={classes.scrollDiv}
          renderThumbVertical={this.renderThumb}
          renderTrackHorizontal={() => <div />}
          renderThumbHorizontal={() => <div />}
          {...scrollbarsSettings}
          id="dataTableScroll"
        >
          <InfiniteScrollWrapper loadMore={loadMore} hasMore={hasMore}>
            <div className={classes.tableDiv}>{tableContent}</div>
          </InfiniteScrollWrapper>
        </Scrollbars>
      );
    } else if (isScrollable) {
      tableDiv = (
        <Scrollbars
          hideTracksWhenNotNeeded
          className={classes.scrollDiv}
          renderThumbVertical={this.renderThumb}
          {...scrollbarsSettings}
        >
          <div className={classes.tableDiv}>{tableContent}</div>
        </Scrollbars>
      );
    } else {
      tableDiv = <div className={classes.tableDiv}>{tableContent}</div>;
    }

    return (
      <div
        className={classNames(classes.root, {
          [classes.loading]: loading && this.state.loadTimes === 1,
        })}
        style={{ overflow: isScrollable ? "auto" : "visible" }}
      >
        <div className={classes.headerDiv}>
          <TableHeader columns={columns} sort={sort} onSort={onSort} />
        </div>
        {tableDiv}
        <div className={classes.spacer}>&nbsp;</div>
      </div>
    );
  }
}

DataTable.defaultProps = {
  isScrollable: true,
};

DataTable.propTypes = {
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object,
  columns: PropTypes.array,
  error: PropTypes.object,
  emptyContent: PropTypes.node,
  loading: PropTypes.bool,
  onSort: PropTypes.func,
  isInfiniteScroll: PropTypes.bool,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  scrollbarsSettings: PropTypes.object,
  tableContentProps: PropTypes.object,
  isScrollable: PropTypes.bool,
};

export { DataTable as DataTableBase };
export default withStyles(styles)(DataTable);
