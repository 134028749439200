import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import Text from "components/shared/ui/Typography/Text";
import numberFormatter from "lib/numberFormatter";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import MessagePanel from "components/shared/ui/Panel/MessagePanel";
import Skeleton from "react-loading-skeleton";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    minHeight: "272px",
  },
  contentHeader: {
    width: "100%",
    padding: "0",
    margin: "0",
    boxShadow: "none",
    minHeight: "52px",
    height: "52px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    margin: 0,
  },
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    display: "inline-block",
    marginRight: "8px",
  },
  panelContent: {
    boxSizing: "border-box",
    padding: "16px",
  },
  paramContent: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "560px",
  },
  field: {
    minWidth: "156px",
    marginTop: "16px",
    boxSizing: "border-box",
    paddingRight: "16px",
  },
  therapists: {
    marginTop: "16px",
    color: "rgba(52,73,94,0.87)",
    marginBottom: "24px",
    fontWeight: 400,
    lineHeight: "28px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "8px",
    },
  },
  howToText: {
    color: "rgba(0,0,0,0.87)",
    opacity: "0.5",
    textTransform: "uppercase",
    display: "inline-block",
    marginTop: "4px",
    marginBottom: 0,
    fontWeight: 700,
  },
  loadingPanel: {
    position: "relative",
  },
  messagePanel: {
    height: "272px",
    padding: "70px 0 0 0",
    margin: 0,
    boxSizing: "border-box",
  },
  totalsContent: {
    marginBottom: "16px",
  },
  averagesContent: {},
});

const AdminUtilizationLayout = ({
  classes,
  calcData = {},
  resourceMetadata,
}) => {
  const header = (
    <ContentHeader
      title={
        <Text fontSize={16} className={classes.title}>
          Utilization
        </Text>
      }
      classes={{
        root: classes.contentHeader,
      }}
    />
  );

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  if (resourceMetadata && resourceMetadata.fetchError) {
    return (
      <div className={classes.root}>
        {header}
        <ErrorPanel className={classes.messagePanel} />
      </div>
    );
  } else if (!loading && calcData.therapistsWithPatientsNumber === 0) {
    return (
      <div className={classes.root}>
        {header}
        <MessagePanel
          iconName="speaker_notes"
          headerText="No Utilization Recorded"
          description="Add new Therapists via the Users page."
          className={classes.messagePanel}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {header}
      <div className={classes.panelContent} data-test="adminUtilization">
        <Typography className={classes.header} variant="subheading">
          {loading ? <Skeleton width={80} /> : "Totals"}
        </Typography>
        <div
          className={classNames(classes.paramContent, classes.totalsContent)}
        >
          <LargeParamField
            classes={{ root: classes.field }}
            data={calcData ? calcData : {}}
            label="Therapists with Patients"
            name="therapistsWithPatientsNumber"
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            loading={loading}
          />
        </div>
        <Typography className={classes.header} variant="subheading">
          {loading ? <Skeleton width={80} /> : "Averages"}
        </Typography>
        <Text className={classes.howToText} fontSize={10}>
          {loading
            ? null
            : "(Averages are calculated for active Therapists which have Patients assigned)"}
        </Text>
        <div
          className={classNames(classes.paramContent, classes.averagesContent)}
        >
          <LargeParamField
            classes={{ root: classes.field }}
            data={calcData.averages ? calcData.averages : {}}
            label="Patients Per Therapist"
            name="patientsPerTherapist"
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            loading={loading}
          />
          <LargeParamField
            classes={{ root: classes.field }}
            data={calcData.averages ? calcData.averages : {}}
            label="Sessions Per Therapist"
            name="sessionsPerTherapist"
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            loading={loading}
          />
          <LargeParamField
            classes={{ root: classes.field }}
            data={calcData.averages ? calcData.averages : {}}
            label="Walk Time Per Therapist"
            name="sessionLengthPerTherapist"
            formatValue={numberFormatter.secondsToRoundedTime}
            loading={loading}
          />
          <LargeParamField
            classes={{ root: classes.field }}
            data={calcData.averages ? calcData.averages : {}}
            label="Steps Per Therapist"
            name="stepsPerTherapist"
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

AdminUtilizationLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
};

export default withStyles(styles)(AdminUtilizationLayout);
