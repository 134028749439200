import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/shared/ui/Button/ActionButton";
import ModalDialog from "components/shared/ui/Modal/ModalDialog";
import InsessReduxForm from "./InsessReduxForm";
import * as InSessDataTypes from "constants/InSessDataTypes";
import numberFormatter from "lib/numberFormatter";

const InsessModalDialog = ({
  open,
  onClose,
  onPrimaryAction,
  onSubmit,
  isSubmitDisabled,
  submitting,
  primaryActionLabel,
  insessEvent,
  type,
}) => {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      title="Update Ekso Outcome"
      buttons={[
        <ActionButton
          key="saveInsessEventButton"
          variant="contained"
          color="primary"
          onClick={onPrimaryAction}
          disabled={isSubmitDisabled}
          loading={submitting}
          data-test="saveInsessEventButton"
        >
          {primaryActionLabel}
        </ActionButton>,
      ]}
    >
      <InsessReduxForm
        initialValues={{
          id: insessEvent.id,
          insessEventResult:
            type === InSessDataTypes.TEN_METER_WALK
              ? numberFormatter.secondsToHMSPlain(
                  numberFormatter.divideNum(insessEvent.insessEventResult, 10)
                )
              : numberFormatter.divideNum(insessEvent.insessEventResult, 10),
          insessSpeedMode: `${insessEvent.insessSpeedMode}`,
          type,
        }}
        onSubmit={onSubmit}
        type={type}
      />
    </ModalDialog>
  );
};

InsessModalDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onPrimaryAction: PropTypes.func,
  primaryActionLabel: PropTypes.string,
  open: PropTypes.bool,
  classes: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  insessEvent: PropTypes.object,
  type: PropTypes.string,
};

export default InsessModalDialog;
