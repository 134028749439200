import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import validate from "validation/AdminSettings/updateSettingsValidation";
import ActionButton from "components/shared/ui/Button/ActionButton";
import LanguagePicker from "components/shared/ui/Localization/LanguagePicker";
import TimeZonePicker from "components/shared/ui/Localization/TimeZonePicker";
import FormHeader from "components/shared/ui/Page/FormHeader";

const styles = theme => ({
  submitButton: {},
  materialField: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "block",
  },
  input: {
    width: "100%",
  },
  buttonPanel: {
    textAlign: "right",
  },
});

const LocationForm = ({ handleSubmit, isSaving, classes, pristine }) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormHeader title="Location" />
      <Field
        name="timeZone"
        defaultValue="America/Los_Angeles"
        component={TimeZonePicker}
        label="Time Zone"
        className={classes.materialField}
        inputClassName={classes.input}
      />
      <Field
        name="language"
        component={LanguagePicker}
        label="Language"
        className={classes.materialField}
        inputClassName={classes.input}
      />
      <div className={classes.buttonPanel}>
        <ActionButton
          className={classes.submitButton}
          variant="contained"
          color="primary"
          disabled={pristine}
          loading={isSaving}
          onClick={handleSubmit}
          data-test="updateButton"
        >
          Update
        </ActionButton>
      </div>
    </form>
  );
};

LocationForm.propTypes = {
  isSaving: PropTypes.bool,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  pristine: PropTypes.bool,
};

export default reduxForm({
  form: "locationForm",
  validate,
})(withStyles(styles)(LocationForm));
