import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";
import PropTypes from "prop-types";
import Policy from "components/Policy";
import MenuItem from "@material-ui/core/MenuItem";

import Menu from "components/shared/ui/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { EDIT_SESSION_MODAL_ID } from "constants/ModalIds";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    padding: 0,
    width: "48px",
    float: "right",
  },
  iconButton: {
    width: "32px",
    margin: "0px 16px 0px 8px",
    height: "32px",
  },
  icon: {
    color: "#A7B0B9",
    fontSize: "24px",
    verticalAlign: "bottom",
  },
});

class SessionActionMenuComponent extends Component {
  _toggleMenu = () => {
    const menuId = `${this.props.session.id}_menu`;
    if (this.props.menuOpen) {
      this.props.menuActions.closeMenu(menuId);
    } else {
      this.props.menuActions.openMenu(menuId);
    }
  };

  _closeMenu = () => {
    const menuId = `${this.props.session.id}_menu`;
    this.props.menuActions.closeMenu(menuId);
  };

  handleToggle = event => {
    event.preventDefault();
    event.stopPropagation();

    this._toggleMenu();
  };

  handleClose = (event, buttonRef) => {
    event.preventDefault();
    event.stopPropagation();

    if (buttonRef.contains(event.target)) {
      return;
    }

    this._closeMenu();
  };

  handleExportSessionSummary = () => {
    this.props.onExportSessionSummary();
    this._closeMenu();
  };

  handleEditPatientID = () => {
    this.props.modalActions.openModalWithData(
      EDIT_SESSION_MODAL_ID,
      this.props.session
    );
  };

  handleExportSessionData = () => {
    this.props.onExportSessionData();
    this._closeMenu();
  };

  handleAddToMyPatients = () => {
    this.props.onAddToMyPatients();
    this._closeMenu();
  };

  handleRemoveFromMyPatients = () => {
    this.props.onRemoveFromMyPatients();
    this._closeMenu();
  };

  render() {
    const { classes, menuOpen, session } = this.props;
    const label = <MoreVertIcon className={classes.icon} />;

    return (
      <List className={classes.root}>
        <li>
          <Menu
            displayMenuOver={true}
            label={label}
            buttonClassName={classes.iconButton}
            onToggle={this.handleToggle}
            onClickAway={this.handleClose}
            open={menuOpen}
            isIconButton
            growPlacement="right top"
            dataTest="sessionActionMenu"
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => this.handleExportSessionSummary()}
              data-test="exportSession"
            >
              Export Session Summary
            </MenuItem>
            {session.hasInsessFeature && (
              <MenuItem
                className={classes.menuItem}
                onClick={() => this.handleExportSessionData()}
                data-test="exportSession"
              >
                Export Session Data
              </MenuItem>
            )}
            <Policy
              action="edit"
              object="session"
              context={{
                patient: this.props.session,
              }}
            >
              <MenuItem
                className={classes.menuItem}
                onClick={() => this.handleEditPatientID()}
                data-test="editSession"
              >
                Edit Session
              </MenuItem>
            </Policy>
            <Policy
              action="add"
              object="patient"
              context={{
                patient: this.props.session,
              }}
            >
              {session.mySession ? (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleRemoveFromMyPatients()}
                  data-test="removeFromMyPatients"
                >
                  Remove From My Sessions
                </MenuItem>
              ) : (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleAddToMyPatients()}
                  data-test="addToMySessions"
                >
                  Add to My Sessions
                </MenuItem>
              )}
            </Policy>
          </Menu>
        </li>
      </List>
    );
  }
}

SessionActionMenuComponent.propTypes = {
  session: PropTypes.object,
  onExportSessionSummary: PropTypes.func,
  onExportSessionData: PropTypes.func,
  classes: PropTypes.object,
  menuOpen: PropTypes.bool,
  menuActions: PropTypes.object,
  onAddToMyPatients: PropTypes.func,
  onRemoveFromMyPatients: PropTypes.func,
  modalActions: PropTypes.object,
};

export { SessionActionMenuComponent as SessionActionMenuComponentBase };
export default withStyles(styles)(SessionActionMenuComponent);
