import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MobileContentTitle from "components/shared/layouts/Dashboard/MobileContentHeader/MobileContentTitle";

const NotificationsMobileHeaderContent = ({ onDrawerToggle }) => {
  return (
    <Fragment>
      <MobileContentTitle
        title="Notifications"
        onDrawerToggle={onDrawerToggle}
      />
    </Fragment>
  );
};

NotificationsMobileHeaderContent.propTypes = {
  onDrawerToggle: PropTypes.func,
};

export default NotificationsMobileHeaderContent;
