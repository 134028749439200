import React from "react";
import PropTypes from "prop-types";
import ScatterGraphBase from "components/shared/ui/Graph/ScatterGraphBase";
import { calculateSuggestedMaxYFromData } from "lib/graphUtils";
import numberFormatter from "lib/numberFormatter";
import moment from "moment";
import * as InSessDataTypes from "constants/InSessDataTypes";

const MIN_TICKS = 5;

const EksoOutcomesGraph = ({ type, data }) => {
  let formatValueFunc = value => value;
  let yLabelString = "Distance (meters)";
  // type 4 is 10 meter walk
  if (type == InSessDataTypes.TEN_METER_WALK) {
    yLabelString = "Time (min)";
    formatValueFunc = value => (parseFloat(value / 10) / 60).toFixed(2);
  } else {
    formatValueFunc = value => (parseFloat(value) / 10).toFixed(2);
  }

  let fastestData = [];
  let selfSelectedData = [];
  if (data && data.length) {
    data.forEach((session, sessionIndex) => {
      session.results.forEach(result => {
        const point = {
          x: sessionIndex + 1,
          y: formatValueFunc(result.eventResult),
          origY: result.eventResult,
          date: moment(session.date).format("MM-DD-YYYY"),
        };

        if (result.speedMode) {
          fastestData.push(point);
        } else {
          selfSelectedData.push(point);
        }
      });
    });
  }

  const suggestedMax = calculateSuggestedMaxYFromData(
    [fastestData, selfSelectedData],
    100
  );

  let stepSize = null;

  let max = data.length;
  if (data.length < MIN_TICKS) {
    max = 5;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        fontSize: 10,
        fontFamily: "Roboto",
      },
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItems, data) => {
          const datasetIndex = tooltipItems[0].datasetIndex;
          const dataIndex = tooltipItems[0].index;
          const xLabel = tooltipItems[0].xLabel;
          const pointData = data.datasets[datasetIndex].data[dataIndex];
          return ["Session " + xLabel, pointData.date];
        },
        label: (tooltipItem, data) => {
          const datasetIndex = tooltipItem.datasetIndex;
          const dataIndex = tooltipItem.index;
          const pointData = data.datasets[datasetIndex].data[dataIndex];
          let plural = `${tooltipItem.yLabel}` !== "1.00" ? "s" : "";
          if (type == InSessDataTypes.TEN_METER_WALK) {
            var secNum = numberFormatter.divideNum(pointData.origY, 10);
            return numberFormatter.secondsToMinForTable(secNum);
          } else {
            return tooltipItem.yLabel + " meter" + plural;
          }
        },
      },
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Session",
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 10,
            fontFamily: "Roboto",
            padding: 0,
          },
          bounds: "ticks",
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            min: 1,
            max,
            stepSize,
            source: "auto",
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 8,
            fontFamily: "Roboto",
            padding: 5,
            maxTicksLimit: 15,
          },
          distribution: "linear",
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yLabelString,
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 10,
            fontFamily: "Roboto",
            padding: 0,
          },
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            precision: 0,
            min: 0,
            maxTicksLimit: 8,
            suggestedMax,
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 8,
            fontFamily: "Roboto",
            padding: 5,
          },
        },
      ],
    },
  };

  return (
    <ScatterGraphBase
      id={`EksoOutcomes_type${type}_graph`}
      data={[fastestData, selfSelectedData]}
      labels={[]}
      options={options}
      pointStyle={["rectRot", "circle"]}
      pointColor={["#E67E22", "#4A90E2"]}
      pointRadius={[4, 2]}
      legendLabel={["Fastest", "Self-Selected"]}
    />
  );
};

EksoOutcomesGraph.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  data: PropTypes.array,
};

export default EksoOutcomesGraph;
