import React from "react";
import PropTypes from "prop-types";
import GraphPanelLayout from "components/shared/ui/Graph/GraphPanelLayout";
import GraphPeriodFilter from "components/shared/ui/Graph/GraphPeriodFilter";
import AverageStepsGraph from "./AverageStepsGraph";
import numberFormatter from "lib/numberFormatter";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";

const AverageStepsLayout = ({
  onQuickFilter,
  calcData = {},
  resourceMetadata,
}) => {
  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : "year";

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  return (
    <GraphPanelLayout
      title="Steps per Patient"
      loading={loading}
      error={resourceMetadata ? resourceMetadata.fetchError : null}
      headerTools={
        <GraphPeriodFilter
          name="averageStepsPeriodFilter"
          onQuickFilter={onQuickFilter}
          defaultValue={period}
        />
      }
      graphContent={
        <AverageStepsGraph
          data={calcData.data}
          period={period}
          onPagerClick={onQuickFilter}
          page={
            resourceMetadata && resourceMetadata.filter
              ? resourceMetadata.filter.page
              : 1
          }
        />
      }
      paramContent={
        <div>
          <LargeParamField
            id="AverageSteps"
            data={calcData}
            label="Average"
            name="totalAvg"
            loading={loading}
            formatValue={numberFormatter.numberWithCommas}
          />
        </div>
      }
    />
  );
};

AverageStepsLayout.defaultValues = {
  calcData: {
    data: [],
  },
};

AverageStepsLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  onQuickFilter: PropTypes.func,
};

export default AverageStepsLayout;
