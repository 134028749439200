/* eslint-disable react/prop-types */
// #lizard forgives
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { withStyles } from "@material-ui/core/styles";
import * as MultiSelectFieldInnerComponents from "./MultiSelectFieldInnerComponents";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

import _ from "lodash";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    maxWidth: "calc(100% - 32px)",
    marginTop: "4px",
    paddingBottom: "4px",
  },
  chip: {
    margin: "1px",
    maxWidth: "100%",
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  chipDisabled: {
    opacity: 0.5,
  },
  chipLabel: {
    maxWidth: "100%",
    overflow: "hidden",
    marginRight: "10px",
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 1,
    bottom: 6,
    fontSize: "14px",
    opacity: 0.75,
  },
  menuPortal: {
    zIndex: 30000,
  },
  paper: {
    marginTop: 0,
    zIndex: 1350,
    position: "absolute",
    backgroundColor: "white",
    maxWidth: "100%",
    width: "100%",
    maxHeight: "300px",
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

const components = {
  DropdownIndicator: null,
  ...MultiSelectFieldInnerComponents,
};

class MultiSelectField extends React.Component {
  state = {
    values: MultiSelectField._translateValues(
      this.props.suggestions,
      this.props.input.value
    ),
    inputValue: "",
    maxSuggestions: this.props.maxSuggestions,
    options: this.props.suggestions.slice(0, this.props.maxSuggestions),
    isLoading: false,
  };

  static _translateValues(suggestions, values) {
    const hasSuggestions = suggestions && suggestions.length;

    if (hasSuggestions && values && values.length) {
      if (_.isString(values)) {
        values = [values];
      }
      // Translate raw values to their object equivalent values if fed in
      const translatedValue = values.map(value => {
        if (!_.isObject(value)) {
          return (
            _.find(suggestions, { value }) ||
            _.find(suggestions, { value: `${value}` })
          );
        }

        return value;
      });

      return translatedValue;
    }

    return [];
  }

  static getDerivedStateFromProps(props, state) {
    if (!_.isEqual(props.input.value, state.selectedValues)) {
      return {
        values: MultiSelectField._translateValues(
          props.suggestions,
          props.input.value
        ),
      };
    }

    return null;
  }

  onInputChange = inputValue => {
    this.getOptions(inputValue);
    this.setState({ inputValue, maxSuggestions: this.props.maxSuggestions });
  };

  getOptions = inputValue => {
    let exactMatch;

    let filteredSuggestions = this.props.suggestions.filter(suggestion => {
      const alreadySelected = !!_.find(this.state.selectedValues, suggestion);
      if (alreadySelected) {
        return false;
      }

      const stringSuggestion = new String(suggestion.label).toLowerCase();

      if (stringSuggestion === inputValue.toLowerCase()) {
        exactMatch = suggestion;
      }

      return stringSuggestion.includes(inputValue.toLowerCase());
    });

    if (exactMatch !== undefined) {
      // place exact match at beginning
      filteredSuggestions = _.without(filteredSuggestions, exactMatch);
      filteredSuggestions.unshift(exactMatch);
    }

    this.setState({
      isLoading: false,
      options: filteredSuggestions.slice(0, this.state.maxSuggestions),
    });
  };

  onChange = values => {
    this.props.input.onChange(values);
  };

  onMenuScrollToBottom = () => {
    this.setState({
      isLoading: true,
      maxSuggestions: this.state.maxSuggestions + 5,
    });
    setTimeout(() => {
      this.getOptions(this.state.inputValue);
    }, 500);
  };

  render() {
    const {
      classes,
      theme,
      input,
      label,
      meta,
      disabled,
      placeholder,
      suggestions,
      className,
    } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
      }),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
    };

    const hasSuggestions = suggestions && suggestions.length;

    return (
      <div className={classes.root}>
        <Select
          className={className}
          classes={classes}
          styles={selectStyles}
          meta={meta}
          isDisabled={disabled || !hasSuggestions}
          textFieldProps={{
            label,
            InputLabelProps: {
              shrink: true,
            },
          }}
          name={input.name}
          components={components}
          value={hasSuggestions && this.state.values}
          onChange={this.onChange}
          onFocus={() => this.onInputChange(this.state.inputValue)}
          onInputChange={this.onInputChange}
          placeholder={placeholder}
          isMulti
          isLoading={!disabled && (this.state.isLoading || !hasSuggestions)}
          options={this.state.options}
          onMenuScrollToBottom={this.onMenuScrollToBottom}
          menuPortalTarget={document.body}
        />
      </div>
    );
  }
}

MultiSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  onRetrieveSelections: PropTypes.func,
  maxSuggestions: PropTypes.number,
};

MultiSelectField.defaultProps = {
  maxSuggestions: 10,
};

export default withStyles(styles, { withTheme: true })(MultiSelectField);
