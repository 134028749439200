import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { calculateSuggestedMaxYFromData } from "lib/graphUtils";
import { Tooltip } from "react-tippy";
import _ from "lodash";
import { Line } from "react-chartjs-2";
import moment from "moment";
// Required import for zoom and pan
// eslint-disable-next-line
import * as zoom from "components/shared/ui/Graph/plugins/customChart.zoom";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
    "&:active:hover": {
      cursor: "grab",
    },
  },
  tooltip: {
    fontFamily: "Roboto",
    fontSize: "12px",
  },
});

function _getSessionIndexFromEvent(chart, event) {
  let sessionIndex;
  var scaler = chart.scales["x-axis-0"];
  var boundingRect = chart.canvas.getBoundingClientRect();
  var x = event.clientX - (boundingRect.left + scaler.left);
  var xval = (x / scaler.width) * (scaler.maxIndex - scaler.minIndex);
  sessionIndex = Math.round(xval) + scaler.minIndex;

  // Adjust for 1 based index
  return sessionIndex + 1;
}

const MIN_POINTS_SHOWN = {
  xs: 4,
  sm: 6,
  md: 10,
  lg: 15,
};
class SessionStatsGraph extends Component {
  state = {
    showSessionTooltip: false,
    sessionTooltipTitle: "View Session",
    firstLoad: true,
  };

  componentDidMount() {
    this.setState({ firstLoad: false });
  }

  _createGraphSessionData = minPointsShown => {
    const { sessions } = this.props;
    const sessionData = [];
    if (sessions && sessions.length) {
      sessions.forEach((session, index) => {
        sessionData.push({
          x: index + 1,
          y: session.sessTotStepCount,
          id: session.id,
          date: moment(session.date).format("MM-DD-YYYY"),
        });
      });

      while (sessionData.length < minPointsShown) {
        sessionData.push(null);
      }
    }

    return sessionData;
  };

  _onClickGraph = (event, chart, sessionData) => {
    const { onSessionChange, sessions } = this.props;
    var activePoint = chart.getElementsAtEvent(event)[0];
    let sessionIndex;

    if (activePoint) {
      // Use point to select session
      sessionIndex = activePoint._index + 1;
    } else {
      // Use clicked area to select session
      sessionIndex = _getSessionIndexFromEvent(chart, event);
    }

    // Change session to clicked session
    const selectedSessionData = _.find(sessionData, { x: sessionIndex });
    if (
      selectedSessionData &&
      selectedSessionData.id &&
      selectedSessionData.x > 0
    ) {
      const session = _.find(sessions, { id: selectedSessionData.id });
      onSessionChange(session, selectedSessionData.x);
    }
  };

  _onHoverGraph = (event, chart, sessionData) => {
    var activePoint = chart.getElementsAtEvent(event)[0];
    let sessionIndex;
    let showSessionTooltip = true;

    if (activePoint) {
      // Use point to select session
      sessionIndex = activePoint._index + 1;
      showSessionTooltip = false;
    } else {
      sessionIndex = _getSessionIndexFromEvent(chart, event);
    }

    // Determine if tooltip should be shown
    const selectedSessionData = _.find(sessionData, { x: sessionIndex });
    if (
      selectedSessionData &&
      selectedSessionData.id &&
      selectedSessionData.x > 0
    ) {
      this.setState({
        showSessionTooltip,
        sessionTooltipTitle: `View Session ${selectedSessionData.x}`,
      });
    } else {
      this.setState({ showSessionTooltip: false });
    }
  };

  render() {
    const { sessions, classes, width, currentSessionId } = this.props;
    let zoomPanEnabled = true;

    // Set the minimum number of points that must be rendered
    let minPointsShown = MIN_POINTS_SHOWN.lg;
    if (isWidthDown("xs", width)) {
      minPointsShown = MIN_POINTS_SHOWN.xs;
    } else if (isWidthDown("sm", width)) {
      minPointsShown = MIN_POINTS_SHOWN.sm;
    } else if (isWidthDown("md", width)) {
      minPointsShown = MIN_POINTS_SHOWN.md;
    }

    const sessionData = this._createGraphSessionData(minPointsShown);
    if (sessionData.length < minPointsShown) {
      zoomPanEnabled = false;
    }

    const suggestedMax = calculateSuggestedMaxYFromData(sessionData, 100);

    const labels = sessions.map((session, index) => `${index + 1}`);
    while (labels.length < minPointsShown) {
      labels.push("");
    }

    const zoomDefaults = {};
    if (zoomPanEnabled) {
      zoomDefaults.xMin = labels[labels.length - minPointsShown];
    }

    const self = this;
    const options = {
      pan: {
        // Boolean to enable panning
        enabled: zoomPanEnabled,
        mode: "x",
        speed: 5,
        threshold: 10,
      },
      zoom: {
        enabled: zoomPanEnabled,
        drag: false,
        mode: "x",
        limits: {
          max: 10,
          min: 3,
        },
        // Custom option:
        defaults: zoomDefaults,
      },
      onClick: function(event) {
        // in this function this was bound to chart
        self._onClickGraph(event, this, sessionData);
      },
      onHover: function(event) {
        // in this function this was bound to chart
        self._onHoverGraph(event, this, sessionData);
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      animation: false,
      tooltips: {
        enabled: !this.state.showSessionTooltip,
        displayColors: false,
        callbacks: {
          label: tooltipItem => tooltipItem.yLabel + " steps",
          title: (tooltipItems, data) => {
            const datasetIndex = tooltipItems[0].datasetIndex;
            const dataIndex = tooltipItems[0].index;
            const xLabel = tooltipItems[0].xLabel;
            const pointData = data.datasets[datasetIndex].data[dataIndex];
            return ["Session " + xLabel, pointData.date];
          },
        },
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Session",
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 10,
              fontFamily: "Roboto",
              padding: 0,
            },
            gridLines: {
              drawTicks: false,
            },
            ticks: {
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 10,
              fontFamily: "Roboto",
              padding: 8,
              autoSkipPadding: 4,
              maxRotation: 0,
              minRotation: 0,
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "Steps",
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 10,
              fontFamily: "Roboto",
              padding: 0,
            },
            gridLines: {
              drawTicks: false,
            },
            ticks: {
              min: 0,
              maxTicksLimit: 8,
              suggestedMax,
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 8,
              fontFamily: "Roboto",
              padding: 5,
              backdropPaddingX: -10,
            },
          },
        ],
      },
    };

    const pointBackgroundColors = Array(sessions.length).fill("#4A90E2");
    const pointRadii = Array(sessions.length).fill(2);

    if (currentSessionId) {
      const selectedIndex = _.findIndex(sessionData, { id: currentSessionId });
      pointBackgroundColors[selectedIndex] = "white";
      pointRadii[selectedIndex] = 6;
    }

    const chartjsData = {
      labels,
      options,
      datasets: [
        {
          label: "session",
          data: sessionData,
          backgroundColor: "rgba(1,154,232,0.1)",
          borderColor: "rgba(52,152,219,.2)",
          fill: true,
          lineTension: 0,
          pointRadius: pointRadii,
          pointHitRadius: 6,
          borderWidth: 2,
          spanGaps: true,
          pointBackgroundColor: pointBackgroundColors,
          pointBorderColor: "#4A90E2",
          pointHoverRadius: 6,
          pointHoverBorderWidth: 1,
          pointHoverBorderColor: "#4A90E2",
          pointHoverBackgroundColor: "#4A90E2",
        },
      ],
    };

    return (
      <Tooltip
        // options
        title={this.state.sessionTooltipTitle}
        position="bottom"
        followCursor
        open={this.state.showSessionTooltip}
        className={classes.tooltip}
      >
        <div
          data-test={"PatientSessionStats_graph"}
          className={classes.root}
          onMouseOut={() => this.setState({ showSessionTooltip: false })}
        >
          <div
            style={{
              position: "relative",
              height: "184px",
            }}
          >
            <Line data={chartjsData} options={options} />
          </div>
        </div>
      </Tooltip>
    );
  }
}

SessionStatsGraph.propTypes = {
  sessions: PropTypes.array,
  classes: PropTypes.object,
  currentSessionId: PropTypes.string,
  width: PropTypes.string,
  onSessionChange: PropTypes.func,
};

export { SessionStatsGraph as SessionStatsGraphBase };

export default withStyles(styles)(withWidth()(SessionStatsGraph));
