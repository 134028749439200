import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";
import numberFormatter from "lib/numberFormatter";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import MessagePanel from "components/shared/ui/Panel/MessagePanel";
import DemographicsDonutChart from "../../Overview/Demographics/DemographicsDonutChart";
import RightIconButton from "components/shared/ui/Button/RightIconButton";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import Skeleton from "react-loading-skeleton";
import { periodToString } from "lib/stringUtils";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    minHeight: "260px",
    maxHeight: "190px",
    [theme.breakpoints.down("sm")]: {
      minHeight: "275px",
    },
  },
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    display: "inline-block",
    marginRight: "8px",
    fontStyle: "normal",
  },
  panelContent: {
    boxSizing: "border-box",
    padding: "14px",
  },
  graphContent: {
    display: "flex",
    flexDirection: "column",
  },
  paramContent: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  field: {
    minWidth: "156px",
    marginTop: "14px",
    boxSizing: "border-box",
    paddingRight: "16px",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "28px",
    color: "rgba(52, 73, 94, 0.87)",
    width: "30%",
  },
  sitesField: {
    minWidth: "156px",
    marginTop: "14px",
    boxSizing: "border-box",
    paddingRight: "16px",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "28px",
    color: "rgba(52, 73, 94, 0.87)",
    width: "30%",
    cursor: "pointer",
  },
  fieldText: {
    fontSize: "36px !important",
    lineHeight: "28px !important",
  },
  loadingPanel: {
    position: "relative",
  },
  messagePanel: {
    height: "190px",
    padding: "25px 0 0 0",
    margin: 0,
    boxSizing: "border-box",
  },
  totalsContent: {
    marginBottom: "0px",
  },
  linkText: {
    color: "#3498DB",
    textTransform: "capitalize",
    float: "right",
    padding: "unset",
    marginTop: "50px",
  },
  statsGroupContainer: {
    display: "block",
    marginTop: "0px",
    marginBottom: "20px",
  },
});

const DemographicsLayout = ({
  classes,
  calcData = {},
  resourceMetadata,
  site,
  handleDemographicsOpenModal,
  onSitesClick,
  graph,
}) => {
  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  if (resourceMetadata && resourceMetadata.fetchError) {
    return (
      <div className={classes.root}>
        <ErrorPanel className={classes.messagePanel} />
      </div>
    );
  } else if (!loading && calcData.therapistsWithPatientsNumber === 0) {
    return (
      <div className={classes.root}>
        <MessagePanel
          iconName="speaker_notes"
          headerText="No Demographics Recorded"
          className={classes.messagePanel}
        />
      </div>
    );
  }

  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : "allTime";

  const sitesField = (
    <Grid item xs={4} md={3}>
      <LargeParamField
        classes={{ root: classes.sitesField, value: classes.fieldText }}
        data={calcData.averages ? calcData.averages : {}}
        onClick={onSitesClick}
        label="Sites"
        name="totalSites"
        formatValue={value =>
          numberFormatter.numberWithCommas(Math.round(value))
        }
        loading={loading}
      />
    </Grid>
  );

  const DemographicsData = (
    <div className={classNames(classes.paramContent, classes.averagesContent)}>
      <Typography className={classes.header} variant="subheading">
        {loading ? (
          <Skeleton width={80} />
        ) : site ? (
          "Site (" + `${periodToString(period)}` + ")"
        ) : (
          "Network (" + `${periodToString(period)}` + ")"
        )}
      </Typography>
      <Grid container className={classes.statsGroupContainer}>
        {site ? null : sitesField}
        <Grid item xs={4} md={3}>
          <LargeParamField
            classes={{ root: classes.field, value: classes.fieldText }}
            data={calcData.averages ? calcData.averages : {}}
            label="Devices"
            name="totalDevices"
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            loading={loading}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <LargeParamField
            classes={{ root: classes.field, value: classes.fieldText }}
            data={calcData.averages ? calcData.averages : {}}
            label="Therapists"
            name="totalTherapists"
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            loading={loading}
          />
        </Grid>
        <Grid item xs={4} md={3} />
      </Grid>
    </div>
  );

  const DemographicsGraph = (
    <div className={classNames(classes.graphContent)}>
      <Typography className={classes.header} variant="subheading">
        {loading ? <Skeleton width={80} /> : "Diagnosis"}
      </Typography>

      <DemographicsDonutChart
        data={calcData.averages ? calcData.averages.indicationTotals : []}
        period={period}
      />

      {site ? null : (
        <RightIconButton
          onClick={handleDemographicsOpenModal}
          label="View Diagnosis Detail"
          iconName="keyboard_arrow_right"
          dataTest="diagnosisDetailsButton"
          classes={{ button: classes.linkText }}
        />
      )}
    </div>
  );

  const Content = graph ? DemographicsGraph : DemographicsData;

  return (
    <div className={classes.root}>
      <div className={classes.panelContent} data-test="demographics">
        {loading ? <LoadingPanel /> : Content}
      </div>
    </div>
  );
};

DemographicsLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  site: PropTypes.object,
  handleDemographicsOpenModal: PropTypes.func,
  onSitesClick: PropTypes.func,
  graph: PropTypes.bool,
};

export default withStyles(styles)(DemographicsLayout);
