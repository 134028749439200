import React from "react";
import PropTypes from "prop-types";
import SelectField from "components/shared/ui/Field/SelectField";
import FlagIconLabel from "./FlagIconLabel";

class LanguagePicker extends React.Component {
  DEFAULT_MENU_ITEMS = [
    { value: "en", label: <FlagIconLabel text="English" code="us" /> },
    { value: "de-DE", label: <FlagIconLabel text="Deutch" code="de" /> },
    { value: "pl", label: <FlagIconLabel text="Polish" code="pl" /> },
    { value: "es-ES", label: <FlagIconLabel text="Español" code="es" /> },
    { value: "it-IT", label: <FlagIconLabel text="Italiano" code="it" /> },
  ];

  render() {
    const { value, input } = this.props;
    return (
      <SelectField
        {...this.props}
        input={
          input
            ? input
            : {
                name: "languageFilter",
                onChange: event =>
                  this.props.onChangeLocale(event.target.value),
                value,
              }
        }
        menuItems={this.DEFAULT_MENU_ITEMS}
      />
    );
  }
}

LanguagePicker.propTypes = {
  value: PropTypes.string,
  onChangeLocale: PropTypes.func,
  defaultValue: PropTypes.string,
  menuProps: PropTypes.object,
  input: PropTypes.object,
};

export default LanguagePicker;
