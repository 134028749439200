import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SessionStatsGraph from "./SessionStatsGraph";
import { Typography, Divider } from "@material-ui/core";
import { formatDate, formatTime } from "lib/dateUtils";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import SessionDetails from "components/pages/Activity/SessionDetails";
import InsessModal from "components/pages/Activity/SessionDetails/InsessModal";
import { BrowserView, MobileView } from "react-device-detect";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    paddingTop: "16px",
  },
  detailsPanel: {
    padding: "16px",
  },
  headerContent: {
    display: "flex",
    padding: "0 16px 16px 16px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    flex: 1,
  },
  headerDesc: {
    textAlign: "right",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    color: "rgba(0,0,0,0.435)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
      textAlign: "left",
    },
  },
  selectionTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "16px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  graphPanel: {
    paddingLeft: "4px",
    paddingRight: "16px",
  },
  date: {
    marginTop: "4px",
    color: "rgba(0,0,0,0.87)",
    display: "block",
    opacity: 0.5,
    fontSize: "12px",
    fontWeight: 500,
  },
  topDivider: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "16px",
      display: "block",
    },
  },
  bottomDivider: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "16px",
    },
  },
});

const SessionStatsLayout = ({
  classes,
  sessions,
  onSessionChange,
  resourceMetadata,
  currentSession,
  currentSessionId,
  sessionNum,
  patient,
}) => {
  let graphSessions = [];
  if (sessions && sessions.length) {
    graphSessions = sessions.slice().reverse();
  }

  const showGraph =
    resourceMetadata &&
    !resourceMetadata.isFetchingItems &&
    resourceMetadata.isFetchItemsSuccess;
  const showError = resourceMetadata && resourceMetadata.fetchError;
  const showLoading = !resourceMetadata || resourceMetadata.isFetchingItems;
  const sessionSelected =
    resourceMetadata && !resourceMetadata.isFetchingItems && currentSession;

  return (
    <div className={classes.root}>
      <div className={classes.headerContent}>
        <Typography className={classes.header} variant="subheading">
          Session Stats
        </Typography>
        {showGraph && sessions.length ? (
          <div className={classes.headerDesc}>
            Select a Session to view the details below.
            <BrowserView>
              <div>
                Use drag to pan the graph left or right, and mouse wheel to zoom
                in and out.
              </div>
            </BrowserView>
            <MobileView>
              <div>
                Drag to pan the graph left or right, and pinch to zoom in and
                out.
              </div>
            </MobileView>
          </div>
        ) : null}
      </div>

      <Divider className={classes.topDivider} />

      {showGraph ? (
        <div className={classes.graphPanel}>
          <SessionStatsGraph
            sessions={graphSessions}
            onSessionChange={onSessionChange}
            currentSessionId={currentSessionId}
          />
        </div>
      ) : null}

      {showLoading ? <LoadingPanel /> : null}

      {showError ? <ErrorPanel error={resourceMetadata.fetchError} /> : null}

      {sessionSelected ? <Divider className={classes.bottomDivider} /> : null}

      {sessionSelected ? (
        <div className={classes.detailsPanel}>
          <Typography
            data-test="sessionTitle"
            className={classes.selectionTitle}
            variant="subheading"
          >
            Session {sessionNum}{" "}
            <div className={classes.date}>
              {formatDate(currentSession.date)}{" "}
              {formatTime(currentSession.date)}{" "}
              {`(S/N: ${currentSession.eksoSerialNum})`}
            </div>
          </Typography>
          <SessionDetails
            session={currentSession}
            show={true}
            hideSubParams={true}
          />
        </div>
      ) : null}

      <InsessModal patient={patient} />
    </div>
  );
};

SessionStatsLayout.propTypes = {
  sessions: PropTypes.array,
  sessionNum: PropTypes.number,
  onSessionChange: PropTypes.func,
  resourceMetadata: PropTypes.object,
  currentSession: PropTypes.object,
  currentSessionId: PropTypes.string,
  classes: PropTypes.object,
  patient: PropTypes.object,
};

export default withStyles(styles)(SessionStatsLayout);
