import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as toastActionCreators from "actions/toastActions";
import ToastSnackbar from "./ToastSnackbar";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  snackbar: {
    marginBottom: "60px",
  },
  snackbarContent: {
    borderRadius: "0px",
  },
});

class ToastContainer extends Component {
  handleClose = () => {
    this.props.toastActions.dismissToast();
  };

  showNextInQueue = () => {
    this.props.toastActions.showQueuedToast();
  };

  render() {
    const { toastId, open, message, hideDuration } = this.props;
    return (
      <ToastSnackbar
        open={open}
        toastId={toastId}
        message={message}
        hideDuration={hideDuration}
        onClose={this.handleClose}
        onExited={this.showNextInQueue}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toastActions: bindActionCreators(toastActionCreators, dispatch),
});

const mapStateToProps = state => ({
  open: state.toast.open,
  toastId: state.toast.currentToastId,
  message: state.toast.currentMessage,
  hideDuration: state.toast.hideDuration,
});

ToastContainer.propTypes = {
  toastActions: PropTypes.object,
  toastId: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.node,
  hideDuration: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToastContainer);
