import React, { Component } from "react";
import PropTypes from "prop-types";
import Notification from "models/bluechip/Notification";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setContext } from "actions/pageContextActions";
import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import NotificationsPage from "./NotificationsPage";
import { translateDatePeriodFilter } from "lib/filterUtils";
import { markRead, updateUnreadTotal } from "actions/notificationActions";
import * as localizationActionCreators from "actions/localizationActions";

class NotificationsContainer extends Component {
  componentDidMount() {
    this.props.setContext({
      pageTitle: "Notifications",
      isFilterOpen: false,
      path: "/dashboard/notifications",
    });
  }

  componentDidUpdate(prevProps) {
    const { resourceMetadata } = this.props;

    // Force refilter when lang updates (to refresh/update table)
    if (prevProps.lang !== this.props.lang) {
      this.props.onQuickFilter({});
    }

    if (
      prevProps.resourceMetadata &&
      prevProps.resourceMetadata.meta &&
      resourceMetadata &&
      resourceMetadata.meta &&
      resourceMetadata.meta.total_unread !==
        prevProps.resourceMetadata.meta.total_unread
    ) {
      this.props.updateUnreadTotal(resourceMetadata.meta.total_unread);
    }
  }

  onRowOpen = notification => {
    this.props.markRead(notification);
  };

  render() {
    if (!this.props.resourceMetadata) {
      return null;
    }

    return (
      <NotificationsPage
        notifications={this.props.rows}
        notificationsResourceMetadata={this.props.resourceMetadata}
        loadMore={this.props.loadMore}
        onShow={this.props.onFind}
        onQuickFilter={this.onQuickFilter}
        onSortChange={this.props.onSortChange}
        onNavigate={this.onNavigate}
        onRowOpen={this.onRowOpen}
        unreadNotificationTotal={this.props.unreadNotificationTotal}
      />
    );
  }
}

const mapStateToProps = state => ({
  unreadNotificationTotal: state.notification.unreadTotal,
  lang: state.localization.lang,
});

const mapDispatchToProps = dispatch => ({
  setContext: bindActionCreators(setContext, dispatch),
  markRead: bindActionCreators(markRead, dispatch),
  updateUnreadTotal: bindActionCreators(updateUnreadTotal, dispatch),
  localizationActions: bindActionCreators(localizationActionCreators, dispatch),
});

NotificationsContainer.propTypes = {
  setContext: PropTypes.func,
  resourceMetadata: PropTypes.object,
  resourceActions: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onSubmitFilters: PropTypes.func,
  onFind: PropTypes.func,
  markRead: PropTypes.func,
  unreadNotificationTotal: PropTypes.number,
  localizationActions: PropTypes.object,
  lang: PropTypes.string,
  updateUnreadTotal: PropTypes.func,
};

const NotificationsContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsContainer);

export default EksoDataTableContainerWrapper(
  NotificationsContainerConnected,
  Notification,
  {
    resourceTypeName: "notifications",
    resourceName: "notifications",
    filterTranslations: {
      period: translateDatePeriodFilter,
    },
    startSort: { column: "created_at", order: "DESC" },
  }
);
