import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";

const DetailsPageStatusContent = ({ loading, error }) => {
  let statusContent = null;
  if (loading) {
    statusContent = (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <PaperPanel>
            <LoadingPanel />
          </PaperPanel>
        </Grid>
      </Grid>
    );
  } else if (error) {
    statusContent = (
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <PaperPanel>
            <ErrorPanel error={error} />
          </PaperPanel>
        </Grid>
      </Grid>
    );
  }

  return statusContent;
};

DetailsPageStatusContent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object,
};

export default DetailsPageStatusContent;
