import React, { Component } from "react";
import PropTypes from "prop-types";
import { setContext } from "actions/pageContextActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { connect } from "react-redux";
import AccountPage from "./AccountPage";
import { bindActionCreators } from "redux";
import { updateCurrentUser as updateCurrentUserActionCreator } from "actions/loginActions";
import * as localizationActionCreators from "actions/localizationActions";
import { translateLocaleToLang } from "lib/localization";
import {
  stopSubmit as stopSubmitActionCreator,
  reset as resetActionCreator,
} from "redux-form";
import _ from "lodash";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

const DETAILS_FORM = "accountDetailsForm";
const PASSWORD_FORM = "accountPasswordForm";

const labelMapping = {
  password: "New Password",
  currentPassword: "Current Password",
  email: "Email",
  userFirstName: "First Name",
  userLastName: "Last Name",
};

class AccountContainer extends Component {
  state = {
    userDetailsSubmitted: {},
    submittingForm: null,
  };

  componentDidMount() {
    this.props.setContext({
      pageTitle: "My Account",
      isFilterOpen: false,
      path: "/dashboard/account",
      isSearchOpen: false,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      resourceMetadata,
      updateCurrentUser,
      localizationActions,
      stopSubmit,
      resetForm,
      location,
    } = this.props;
    const wasUpdating =
      prevProps.resourceMetadata && prevProps.resourceMetadata.isUpdatingItem;

    if (
      wasUpdating &&
      resourceMetadata.isUpdateItemSuccess &&
      this.state.submittingForm === DETAILS_FORM
    ) {
      // Update current user and language to new details set by the user
      updateCurrentUser(this.state.userDetailsSubmitted);
      const lang = translateLocaleToLang(
        this.state.userDetailsSubmitted.language
      );
      localizationActions.saveLang(lang);
      // Immediately change url to reflect language change
      if (this.state.userDetailsSubmitted.language !== "en") {
        history.replace({
          pathname: location.pathname,
          search: `?lang=${lang}`,
        });
      } else {
        history.replace({ pathname: location.pathname });
      }
      this.setState({ userDetailsSubmitted: {}, submittingForm: null });
    } else if (
      wasUpdating &&
      resourceMetadata.isUpdateItemSuccess &&
      this.state.submittingForm === PASSWORD_FORM
    ) {
      resetForm(PASSWORD_FORM);
      this.setState({ submittingForm: null });
    } else if (wasUpdating && resourceMetadata.updateError) {
      if (resourceMetadata.updateError.validationMessages) {
        const validationMessages = _.mapValues(
          resourceMetadata.updateError.validationMessages,
          (message, key) => labelMapping[key] + " " + message
        );
        // Throw redux-form submission error
        stopSubmit(this.state.submittingForm, validationMessages);
      }
      this.setState({ userDetailsSubmitted: {}, submittingForm: null });
    }
  }

  handleSubmitDetails = formData => {
    const { currentUser, resourceActions } = this.props;
    const translatedFormData = {
      user_first_name: formData.userFirstName,
      user_last_name: formData.userLastName,
      language: formData.language,
    };

    resourceActions.update(
      {
        id: currentUser.id,
        ...translatedFormData,
      },
      {
        successToast: "Personal Details Updated",
        errorToast: "Personal Details Update Failed",
      }
    );

    this.setState({
      userDetailsSubmitted: translatedFormData,
      submittingForm: DETAILS_FORM,
    });
  };

  handleSubmitPassword = formData => {
    const { currentUser, resourceActions } = this.props;

    resourceActions.update(
      {
        id: currentUser.id,
        current_password: formData.currentPassword,
        password: formData.password,
      },
      {
        successToast: "Password Updated",
        errorToast: "Password Update Failed",
      }
    );

    this.setState({ submittingForm: PASSWORD_FORM });
  };

  render() {
    const { currentUser } = this.props;

    return (
      <AccountPage
        onSubmitDetails={this.handleSubmitDetails}
        onSubmitPassword={this.handleSubmitPassword}
        detailsSaving={this.state.submittingForm === DETAILS_FORM}
        passwordSaving={this.state.submittingForm === PASSWORD_FORM}
        initialValues={{
          accountDetailsForm: {
            userFirstName: currentUser.attributes.user_first_name,
            userLastName: currentUser.attributes.user_last_name,
            email: currentUser.attributes.email,
            language: currentUser.attributes.language
              ? currentUser.attributes.language
              : "en",
            eksoSerialNumbers:
              currentUser.attributes.ekso_serial_numbers &&
              currentUser.attributes.ekso_serial_numbers.length
                ? currentUser.attributes.ekso_serial_numbers.join(", ")
                : null,
          },
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  location: state.router.location,
  resourceMetadata: state.jsonApiResources.users
    ? state.jsonApiResources.users
    : {},
});

const mapDispatchToProps = dispatch => ({
  setContext: bindActionCreators(setContext, dispatch),
  updateCurrentUser: bindActionCreators(
    updateCurrentUserActionCreator,
    dispatch
  ),
  resourceActions: jsonAPIReduxActions(dispatch, "users"),
  localizationActions: bindActionCreators(localizationActionCreators, dispatch),
  stopSubmit: bindActionCreators(stopSubmitActionCreator, dispatch),
  resetForm: bindActionCreators(resetActionCreator, dispatch),
});

AccountContainer.propTypes = {
  resourceActions: PropTypes.object,
  resourceMetadata: PropTypes.object,
  currentUser: PropTypes.object,
  updateCurrentUser: PropTypes.func,
  stopSubmit: PropTypes.func,
  localizationActions: PropTypes.object,
  setContext: PropTypes.func,
  resetForm: PropTypes.func,
  location: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountContainer);
