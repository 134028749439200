import React from "react";
import PropTypes from "prop-types";
import { get, isFunction } from "lodash";
import { connect } from "react-redux";

import localize from "./localize";

const LocalizedText = ({ stringPath, defaultLang, lang, context }) => {
  const defaultText = get(localize, `${defaultLang}.${stringPath}`);
  const text = get(localize, `${lang}.${stringPath}`, defaultText);

  return <span>{isFunction(text) ? text(context) : text}</span>;
};

LocalizedText.propTypes = {
  stringPath: PropTypes.string.isRequired,
  defaultLang: PropTypes.string,
  context: PropTypes.object,
  lang: PropTypes.string,
};

LocalizedText.defaultProps = {
  defaultLang: "en",
};

const mapStateToProps = state => ({
  lang: state.localization.lang,
});

export { LocalizedText as LocalizedTextBase };
export default connect(mapStateToProps)(LocalizedText);
