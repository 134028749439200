import React from "react";
import PropTypes from "prop-types";
import DropdownLinkField from "components/shared/ui/Field/SelectField";

class CategoryQuickFilter extends React.Component {
  onChange = event => {
    const value = event.target.value;
    this.props.onQuickFilter({ category: value });
  };

  render() {
    const { value, menuItems } = this.props;
    return (
      <DropdownLinkField
        menuItems={menuItems}
        input={{
          name: "categoryFilter",
          onChange: this.onChange,
          value,
        }}
        placeholder="All Categories"
        renderPlaceholderMenuItem={false}
        meta={{}}
        labelLeft
        isLinkStyle
        className={this.props.className}
      />
    );
  }
}

CategoryQuickFilter.propTypes = {
  value: PropTypes.string,
  column: PropTypes.string,
  onQuickFilter: PropTypes.func,
  className: PropTypes.string,
  menuItems: PropTypes.array,
  defaultValue: PropTypes.string,
  menuProps: PropTypes.object,
};

export default CategoryQuickFilter;
