import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import SortIcon from "./SortIcon";
import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  headerTable: {
    tableLayout: "fixed",
  },
  headerRow: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
    height: "42px",
  },
  headerCell: {
    fontSize: "10px",
    fontWeight: 700,
    paddingBottom: 0,
    paddingTop: 0,
    fontFamily: "Roboto !important",
    height: "42px",
    textOverflow: "unset !important",
    color: "rgba(0,0,0,0.43)",
  },
  sortableHeaderCell: {
    cursor: "pointer",
    "&:hover": {
      color: "#3498DB",
    },
  },
  headerContent: {
    display: "inline-block",
    position: "relative",
    paddingTop: "8px",
    marginBottom: "-1px",
  },
  headerLabel: {
    display: "block",
    height: "34px",
    lineHeight: "8px",
  },
  sorted: {
    color: "#3498DB",
    opacity: 1,
    "&:after": {
      content: "close-quote",
      width: "100%",
      height: "100%",
      position: "absolute",
      bottom: "-0px",
      right: "-18px",
      paddingLeft: "18px",
      borderBottom: "3px solid #3498DB",
    },
  },
  unsortableIcon: {
    color: "transparent",
  },
});

function sortMatchesColumn(sort, column) {
  if (!sort || !sort.column) {
    return false;
  }

  const sortProp = column.sortProp ? column.sortProp : column.prop;
  return sort.column === sortProp;
}

const TableHeader = ({ classes, columns, sort, onSort }) => {
  return (
    <Table className={classes.headerTable}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          {columns.map(column => {
            return (
              <TableCell
                key={column.key ? column.key : column.prop}
                className={classNames(classes.headerCell, column.className, {
                  [classes.sortableHeaderCell]: !column.unsortable,
                })}
                onClick={column.unsortable ? null : () => onSort(column)}
                numeric={column.isNumeric}
                data-test={`columnHeader__${column.prop}`}
              >
                <div
                  className={classNames(classes.headerContent, {
                    [classes.sorted]:
                      !column.unsortable && sortMatchesColumn(sort, column),
                  })}
                >
                  <div className={classes.headerLabel}>
                    {column.label}
                    <SortIcon
                      className={classNames({
                        [classes.unsortableIcon]: column.unsortable,
                      })}
                      sortDirection={
                        sortMatchesColumn(sort, column) ? sort.order : ""
                      }
                    />
                  </div>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </Table>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  sort: PropTypes.object,
  onSort: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(TableHeader);
