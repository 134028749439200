import React from "react";
import PropTypes from "prop-types";
import DropdownLinkField from "components/shared/ui/Field/SelectField";
import { convertCamelCaseToUnderscoreCase } from "lib/stringUtils";

const DEFAULT_MENU_ITEMS = [
  { value: "DESC", label: "Newest First" },
  { value: "ASC", label: "Oldest First" },
];

class SortFilter extends React.Component {
  onChange = event => {
    const value = event.target.value;
    const column = convertCamelCaseToUnderscoreCase(this.props.column);

    const sortObj = {
      column,
      order: value,
    };
    this.props.onSortChange(sortObj);
  };

  render() {
    const { value } = this.props;
    return (
      <DropdownLinkField
        menuItems={DEFAULT_MENU_ITEMS}
        input={{
          name: "sortFilter",
          onChange: this.onChange,
          value,
        }}
        meta={{}}
        labelLeft
        isLinkStyle
        inputProps={{
          renderValue: value => (value === "DESC" ? "Newest" : "Oldest"),
        }}
        className={this.props.className}
      />
    );
  }
}

SortFilter.propTypes = {
  value: PropTypes.string,
  column: PropTypes.string,
  onSortChange: PropTypes.func,
  className: PropTypes.string,
  menuItems: PropTypes.array,
  defaultValue: PropTypes.string,
  menuProps: PropTypes.object,
};

export default SortFilter;
