import { BaseModel } from "@blue-chip/core";
import Indication from "./Indication";
import SettingOfUse from "./SettingOfUse";
import Device from "./Device";
import AssistiveDevice from "./AssistiveDevice";

export default class Patient extends BaseModel {
  static get hasMany() {
    return [Device, AssistiveDevice];
  }

  static get belongsTo() {
    return [Indication, SettingOfUse];
  }
}
