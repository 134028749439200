import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "components/shared/ui/Typography/Text";
import { withStyles } from "@material-ui/core/styles";
import { Icon } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  contentDiv: {
    display: "block",
    paddingTop: "92px",
    backgroundColor: "rgba(255,255,255,0.5)",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  icon: {
    fontSize: "36px",
    margin: "0 auto",
    color: "#8F9AA5",
    textAlign: "center",
    display: "block",
  },
  header: {
    padding: 0,
    marginTop: "8px",
    marginBottom: 0,
    color: "rgba(52,73,94,0.87)",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "14px",
    opacity: 0.6,
    textTransform: "uppercase",
  },
  description: {
    marginTop: "4px",
    marginBottom: 0,
    padding: 0,
    color: "rgba(52,73,94,0.87)",
    textAlign: "center",
    fontWeight: 400,
    opacity: 0.5,
  },
  children: {
    marginTop: "8px",
    textAlign: "center",
  },
});

const GraphPanelOverlay = ({
  classes,
  iconName,
  headerText,
  description,
  className,
  children,
}) => {
  return (
    <div
      className={classNames(classes.contentDiv, className)}
      data-test="overlayPanel"
    >
      <Icon className={classes.icon}>{iconName}</Icon>
      <Text fontSize={12} className={classes.header}>
        {headerText}
      </Text>
      {description ? (
        <Text fontSize={12} className={classes.description}>
          {description}
        </Text>
      ) : null}
      <div className={classes.children}>{children}</div>
    </div>
  );
};

GraphPanelOverlay.defaultProps = {
  iconName: "info",
};

GraphPanelOverlay.propTypes = {
  classes: PropTypes.object,
  iconName: PropTypes.string,
  headerText: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(GraphPanelOverlay);
