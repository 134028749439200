import * as types from "constants/ModalTypes";
import { RESET } from "constants/ClearTypes";

const initialState = {
  confirmModal: {
    open: false,
    options: {},
  },
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.OPEN: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          open: true,
          data: action.data,
        },
      };
    }

    case types.CLOSE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          open: false,
        },
      };
    }

    case types.OPEN_CONFIRM: {
      return {
        ...state,
        confirmModal: {
          open: true,
          options: action.options,
        },
      };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
