import _ from "lodash";

export function convertStringArray(value) {
  if (!value) {
    return [];
  }

  if (_.isString(value)) {
    return value.split(",");
  }

  return value;
}
