import React, { Component } from "react";
import PropTypes from "prop-types";
import Session from "models/bluechip/Session";
import { connect } from "react-redux";
import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import { translateDatePeriodFilter } from "lib/filterUtils";
import SessionsContentLayout from "./SessionsContentLayout";

class SessionsContentContainer extends Component {
  render() {
    if (!this.props.resourceMetadata) {
      return null;
    }

    return (
      <SessionsContentLayout
        sessions={this.props.rows}
        sessionsResourceMetadata={this.props.resourceMetadata}
        loadMore={this.props.loadMore}
        onShow={this.props.onFind}
        onQuickFilter={this.props.onQuickFilter}
        onSortChange={this.props.onSortChange}
        clientFilter={this.props.clientFilter}
        menusOpen={this.props.menusOpen}
      />
    );
  }
}

SessionsContentContainer.propTypes = {
  resourceMetadata: PropTypes.object,
  resourceActions: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onSubmitFilters: PropTypes.func,
  onFind: PropTypes.func,
  clientFilter: PropTypes.object,
  menusOpen: PropTypes.bool,
};

const mapStateToProps = state => ({
  menusOpen: state.menu.menusOpen,
});

const SessionsContentContainerConnected = connect(
  mapStateToProps,
  null
)(SessionsContentContainer);

export default EksoDataTableContainerWrapper(
  SessionsContentContainerConnected,
  Session,
  {
    resourceTypeName: "sessions",
    resourceName: "pulse_sessions",
    includes: ["insessEvents"],
    startSort: { column: "date", order: "DESC" },
    filterTranslations: {
      period: translateDatePeriodFilter,
    },
  }
);
