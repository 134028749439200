import React, { Component } from "react";
import PropTypes from "prop-types";
import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FaqsTable from "./FaqsTable";
import BasicPageHeader from "components/shared/ui/Page/BasicPageHeader";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import ContentHeaderVerticalDivider from "components/shared/ui/Page/ContentHeaderVerticalDivider";
import Text from "components/shared/ui/Typography/Text";
import SearchReduxForm from "components/shared/ui/Form/SearchReduxForm";
import { deriveTitle } from "lib/stringUtils";
import CategoryQuickFilter from "./CategoryQuickFilter";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F1F1F1",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 64px)",
    },
  },
  title: {
    margin: 0,
    fontWeight: 500,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchForm: {
    display: "flex",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  toolbarContent: {
    display: "flex",
    alignItems: "center",
  },
  quickFilter: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    display: "flex",
  },
  num: {
    fontWeight: 500,
  },
});

class FaqsPage extends Component {
  render() {
    const {
      faqsResourceMetadata,
      faqs,
      classes,
      currentSearch,
      currentCategory,
      categoryMenuItems,
      onNavigate,
      onQuickFilter,
      openFaqId,
    } = this.props;

    const title = (
      <Text fontSize={16} className={classes.title}>
        {currentSearch
          ? deriveTitle(faqsResourceMetadata, "FAQ", currentSearch)
          : "FAQ"}
      </Text>
    );

    const toolbar = (
      <div className={classes.toolbarContent}>
        <Hidden smDown>
          <ContentHeaderVerticalDivider />
          <SearchReduxForm
            formName="faqSearch"
            className={classes.searchForm}
          />
        </Hidden>
        <ContentHeaderVerticalDivider />
        <CategoryQuickFilter
          menuItems={categoryMenuItems}
          value={currentCategory}
          onQuickFilter={onQuickFilter}
          className={classes.quickFilter}
        />
      </div>
    );
    return (
      <div className={classes.root}>
        <BasicPageHeader
          onNavigate={onNavigate}
          backPath="/dashboard/learning"
          title="Learning Center"
        />
        <ContentHeader title={title} toolbar={toolbar} />
        <FaqsTable
          faqs={faqs}
          faqsResourceMetadata={faqsResourceMetadata}
          openFaqId={openFaqId}
          onQuickFilter={onQuickFilter}
        />
      </div>
    );
  }
}

FaqsPage.propTypes = {
  faqsResourceMetadata: PropTypes.object,
  faqs: PropTypes.array,
  classes: PropTypes.object,
  onQuickFilter: PropTypes.func,
  currentSearch: PropTypes.string,
  categoryMenuItems: PropTypes.array,
  currentCategory: PropTypes.string,
  onNavigate: PropTypes.func,
  openFaqId: PropTypes.string,
};

export default withStyles(styles)(FaqsPage);
