import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import UtilizationLayout from "./UtilizationLayout";

class UtilizationContentLayout extends Component {
  render() {
    const { resourceMetadata, calcData, site } = this.props;

    return ( 
      <UtilizationLayout
        resourceMetadata={resourceMetadata}
        calcData={calcData}
        site={site}
      /> 
    );
  }
};

UtilizationContentLayout.defaultProps = {};

UtilizationContentLayout.propTypes = {
  resourceMetadata: PropTypes.object,
  calcData: PropTypes.object,
};

const mapStateToProps = state => ({
  site: state.network.site,
});

export default connect(
  mapStateToProps,
  null
)(UtilizationContentLayout);
