import React from "react";
import PropTypes from "prop-types";
import TimeLineGraphBase from "components/shared/ui/Graph/TimeLineGraphBase";
import SessionGraphBase from "components/shared/ui/Graph/SessionGraphBase";
import _ from "lodash";
import moment from "moment";

const AverageSessionLengthGraph = ({ period, data, page, onPagerClick }) => {
  let convertedData = [];
  if (data && data.length) {
    convertedData = _.map(data, (item, index) => ({
      x: period == "last" ? index + 1 : item.period,
      y: item.avg ? Math.floor(item.avg / 60) : NaN,
      date: period == "last" ? moment(item.period).format("MM-DD-YYYY") : null,
    }));
  }

  if (period == "last") {
    return (
      <SessionGraphBase
        id="AverageSessionLength"
        data={[convertedData]}
        emptyMax={60}
        unitLabel={"min"}
      />
    );
  } else {
    return (
      <TimeLineGraphBase
        id="AverageSessionLength"
        data={[convertedData]}
        emptyMax={60}
        period={period}
        page={page}
        onPagerClick={onPagerClick}
        unitLabel={"min"}
      />
    );
  }
};

AverageSessionLengthGraph.propTypes = {
  classes: PropTypes.object,
  period: PropTypes.string,
  data: PropTypes.array,
  page: PropTypes.number,
  onPagerClick: PropTypes.func,
};

export default AverageSessionLengthGraph;
