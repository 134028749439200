import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import Text from "components/shared/ui/Typography/Text";
import ModalDialog from "components/shared/ui/Modal/ModalDialog";
import ActionButton from "components/shared/ui/Button/ActionButton";

const ConfirmationDialogComponent = ({
  title,
  message,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  open,
}) => {
  return (
    <ModalDialog
      open={open}
      closeable={false}
      title={title}
      buttons={[
        <Button
          key="cancelButton"
          color="primary"
          onClick={onCancel}
          data-test="cancelDialog"
        >
          {cancelText}
        </Button>,
        <ActionButton
          key="confirmButton"
          variant="contained"
          color="primary"
          onClick={onConfirm}
          data-test="confirmDialog"
        >
          {confirmText}
        </ActionButton>,
      ]}
    >
      <Text style={{ marginTop: "4px", marginBottom: "16px" }}>{message}</Text>
    </ModalDialog>
  );
};

ConfirmationDialogComponent.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  open: PropTypes.bool,
};

export default ConfirmationDialogComponent;
