import { OPEN, CLOSE } from "constants/MenuTypes";

export function openMenu(id) {
  return {
    type: OPEN,
    id,
  };
}

export function closeMenu(id) {
  return {
    type: CLOSE,
    id,
  };
}
