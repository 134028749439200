import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    paddingTop: "13px",
    paddingBottom: "13px",
    borderRadius: 0,
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
});

const CreateUserButton = ({ classes, onClick }) => {
  return (
    <Button
      onClick={onClick}
      color="primary"
      className={classes.root}
      data-test="createUserButton"
    >
      <AddIcon className={classes.icon} />
      Create User
    </Button>
  );
};

CreateUserButton.defaultProps = {
  isFilterOpen: false,
};

CreateUserButton.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(CreateUserButton);
