import React from "react";
import PropTypes from "prop-types";
import SelectField from "components/shared/ui/Field/SelectField";
import { TIME_ZONES } from "constants/TimeZones";
import moment from "moment-timezone";

const ITEM_HEIGHT = 36;
const selectMenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5,
    },
  },
};

class TimeZonePicker extends React.Component {
  DEFAULT_MENU_ITEMS = TIME_ZONES.map(tz => {
    return {
      label: tz.replace("_", " ") + " " + moment.tz(tz).format("Z"),
      value: tz,
    };
  });

  render() {
    const { value, input } = this.props;
    return (
      <SelectField
        {...this.props}
        menuProps={selectMenuProps}
        input={
          input
            ? input
            : {
                name: "timeZonePicker",
                onChange: event => this.props.onChange(event.target.value),
                value,
              }
        }
        menuItems={this.DEFAULT_MENU_ITEMS}
      />
    );
  }
}

TimeZonePicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  input: PropTypes.object,
};

export default TimeZonePicker;
