import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tabs, Tab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";
import DashboardIcon from "@material-ui/icons/Dashboard";

const styles = theme => ({
  root: {
    marginLeft: "56px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "16px",
      marginTop: "5px",
    },
  },
  tabRoot: {
    height: "48px",
    minHeight: "48px",
    minWidth: "88px",
    marginRight: "20px",
    marginTop: "4px",
    color: "#4A4A4A",
    "&$tabSelected": {
      color: "#3498DB !important",
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.down("sm")]: {
        opacity: 1,
        color: "#FFFFFF !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      color: "#FFFFFF",
      opacity: 0.5,
    },
  },
  wrapper: {
    flexDirection: "row",
  },
  label: {
    textTransform: "none",
    fontSize: "14px !important",
  },
  labelContainer: {
    paddingLeft: "10px",
    paddingRight: "4px",
  },
  tabSelected: {},
  summaryIcon: {
    fontSize: "16px",
  },
  sessionsIcon: {
    fontSize: "19px",
    transform: "scaleX(-1)",
  },
  unselectedIcon: {
    color: "#B2B2B2",
    [theme.breakpoints.down("sm")]: {
      opacity: 0.4,
      color: "white",
    },
  },
  indicator: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FFFFFF",
    },
  },
});

const PatientTabs = ({ classes, onTabChange, value }) => {
  const tabClasses = {
    root: classes.tabRoot,
    wrapper: classes.wrapper,
    label: classes.label,
    labelContainer: classes.labelContainer,
    selected: classes.tabSelected,
  };
  return (
    <Tabs
      value={value}
      indicatorColor="primary"
      textColor="primary"
      classes={{
        root: classes.root,
        indicator: classes.indicator,
      }}
      onChange={(event, value) => onTabChange(value)}
    >
      <Tab
        classes={tabClasses}
        label="Summary"
        value="summary"
        data-tab="summary"
        icon={
          <DashboardIcon
            className={classNames(classes.summaryIcon, {
              [classes.unselectedIcon]: value !== "summary",
            })}
          />
        }
      />
      <Tab
        classes={tabClasses}
        label="Sessions"
        value="sessions"
        data-tab="sessions"
        icon={
          <BarChartIcon
            className={classNames(classes.sessionsIcon, {
              [classes.unselectedIcon]: value !== "sessions",
            })}
          />
        }
      />
    </Tabs>
  );
};

PatientTabs.defaultProps = {
  value: "summary",
};

PatientTabs.propTypes = {
  onTabChange: PropTypes.func,
  patient: PropTypes.object,
  classes: PropTypes.object,
  value: PropTypes.string,
};

export default withStyles(styles)(PatientTabs);
