import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import StandardLineGraphBase from "components/shared/ui/Graph/StandardLineGraphBase";
import CalendarYearTimeLineGraphPager from "components/shared/ui/Graph/CalendarYearTimeLineGraphPager";
import moment from "moment";
import _ from "lodash";

import { calculateSuggestedMaxYFromData } from "lib/graphUtils";

// eslint-disable-next-line
const styles = theme => ({
  graphContainer: {
    marginTop: "16px",
  },
});

class NetworkUtilizationGraphBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      timeSettings: this._getTimeSettingsForPeriod(),
    };
  }

  handleTimePageChange = ({ page }) => {
    this.setState({
      page,
      timeSettings: {
        ...this.state.timeSettings,
        min: moment()
          .subtract(page - 1, "year")
          .startOf("year")
          .format("YYYY-MM"),
        max: moment()
          .subtract(page - 1, "years")
          .endOf("year")
          .format("YYYY-MM"),
      },
    });
  };

  _getTimeSettingsForPeriod = () => {
    const { period, page } = this.props;
    const normalizedPeriod = period.toLowerCase().replace("this", "");

    switch (normalizedPeriod) {
      case "year": {
        return {
          format: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "month",
          min: moment()
            .subtract(page - 1, "year")
            .startOf("year")
            .format("YYYY-MM"),
          max: moment()
            .subtract(page - 1, "years")
            .endOf("year")
            .format("YYYY-MM"),
          stepSize: 1,
          displayFormats: {
            month: "MMM",
          },
        };
      }
      case "month": {
        return {
          format: "YYYY-MM-DD",
          tooltipFormat: "MMM DD, YYYY",
          unit: "day",
          min: moment()
            .subtract(page - 1, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          max: moment()
            .subtract(page - 1, "month")
            .endOf("month")
            .format("YYYY-MM-DD"),
          stepSize: 2,
          displayFormats: {
            month: "MMM DD",
          },
        };
      }
      case "quarter": {
        return {
          format: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "month",
          min: moment()
            .subtract(page - 1, "quarter")
            .startOf("quarter")
            .format("YYYY-MM"),
          max: moment()
            .subtract(page - 1, "quarter")
            .endOf("quarter")
            .format("YYYY-MM"),
          stepSize: 1,
          displayFormats: {
            month: "MMM YYYY",
          },
        };
      }
      case "week": {
        return {
          format: "YYYY DDDD",
          isoWeekday: true,
          tooltipFormat: "MMM DD, YYYY",
          unit: "day",
          stepSize: 1,
          min: moment()
            .subtract(page - 1, "week")
            .startOf("week")
            .format("YYYY DDDD"),
          max: moment()
            .subtract(page - 1, "week")
            .endOf("week")
            .format("YYYY DDDD"),
          displayFormats: {
            day: "MMM DD",
          },
        };
      }
      default: {
        return {
          format: "YYYY-MM",
          tooltipFormat: "MMMM YYYY",
          unit: "month",
          min: moment()
            .subtract(page - 1, "year")
            .startOf("year")
            .format("YYYY-MM"),
          max: moment()
            .subtract(page - 1, "years")
            .endOf("year")
            .format("YYYY-MM"),
          stepSize: 1,
          displayFormats: {
            month: "MMM",
          },
        };
      }
    }
  };

  render() {
    const {
      id,
      data,
      period,
      unitLabel,
      tooltipLabelCallback,
      emptyMax,
      lineColor,
      pointColor,
      fill,
      fillColor,
      pointRadius,
      maxY,
      height,
      width,
      className,
      margin,
      classes,
      onGraphClick,
    } = this.props;

    const { timeSettings, page } = this.state;
    let suggestedMax = maxY;
    if (!maxY) {
      const parsedData = data.map(item => {
        if (_.isPlainObject(item)) {
          return item.data;
        }
        return item;
      });
      suggestedMax = calculateSuggestedMaxYFromData(parsedData, emptyMax);
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        displayColors: false,
        callbacks: {
          label: tooltipLabelCallback
            ? tooltipLabelCallback
            : tooltipItem => tooltipItem.yLabel + ` ${unitLabel}`,
        },
      },
      scales: {
        xAxes: [
          {
            bounds: "ticks",
            gridLines: {
              drawTicks: false,
            },
            ticks: {
              source: "auto",
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 8,
              fontFamily: "Roboto",
              padding: period == "month" ? 7 : 3,
            },
            type: "time",
            distribution: "linear",
            time: timeSettings,
          },
        ],
        yAxes: [
          {
            gridLines: {
              drawTicks: false,
            },
            ticks: {
              min: 0,
              max: maxY,
              maxTicksLimit: 8,
              suggestedMax,
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 8,
              fontFamily: "Roboto",
              padding: 5,
              callback: function(value) {
                if (unitLabel === "min" || unitLabel === "h") {
                  return `${value} ${unitLabel}`;
                }
                return value;
              },
            },
          },
        ],
      },
    };

    return (
      <div id={`${id}_graph`}>
        {period === "allTime" ? (
          <CalendarYearTimeLineGraphPager
            onPagerClick={this.handleTimePageChange}
            page={page}
          />
        ) : null}
        <div
          className={period === "allTime" ? {} : classes.graphContainer}
          onClick={onGraphClick}
        >
          <StandardLineGraphBase
            data={data}
            labels={[]}
            options={options}
            lineColor={lineColor}
            pointColor={pointColor}
            fill={fill}
            fillColor={fillColor}
            pointRadius={pointRadius}
            height={height}
            width={width}
            className={className}
            margin={margin}
            emptyMax={emptyMax}
          />
        </div>
      </div>
    );
  }
}

NetworkUtilizationGraphBase.defaultProps = {
  page: 1,
};

NetworkUtilizationGraphBase.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  classes: PropTypes.object,
  data: PropTypes.array,
  period: PropTypes.string,
  unitLabel: PropTypes.string,
  emptyMax: PropTypes.number,
  tooltipLabelCallback: PropTypes.func,
  pointRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  lineColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pointColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  maxY: PropTypes.number,
  page: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  onGraphClick: PropTypes.func,
};

export default withStyles(styles)(NetworkUtilizationGraphBase);
