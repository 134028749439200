import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import LeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    textAlign: "center",
    display: "inline-block",
  },
  label: {
    display: "inline-block",
    whiteSpace: "nowrap",
    overflow: "visible",
    verticalAlign: "middle",
    fontSize: "12px",
    color: "rgba(0,0,0,0.87)",
  },
  leftButton: {
    width: "24px",
    height: "24px",
  },
  rightButton: {
    width: "24px",
    height: "24px",
  },
});

const NetworkGraphPager = ({
  leftDisabled,
  rightDisabled,
  label,
  onLeftClick,
  onRightClick,
  classes,
  hideButtons,
  labelWidth,
  backPeriod,
  forwardPeriod,
}) => {
  return (
    <div className={classes.root}>
      {hideButtons ? null : (
        <IconButton
          aria-label="Back 1 Period"
          className={classes.leftButton}
          disabled={leftDisabled}
          onClick={onLeftClick}
          data-test={backPeriod}
        >
          <LeftIcon />
        </IconButton>
      )}
      <div className={classes.label} style={{ width: labelWidth }}>
        {label}
      </div>
      {hideButtons ? null : (
        <IconButton
          aria-label="Forward 1 Period"
          className={classes.rightButton}
          disabled={rightDisabled}
          onClick={onRightClick}
          data-test={forwardPeriod}
        >
          <RightIcon />
        </IconButton>
      )}
    </div>
  );
};

NetworkGraphPager.defaultProps = {
  labelWidth: "146px",
  backPeriod: "backPeriod",
  forwardPeriod: "forwardPeriod",
};

NetworkGraphPager.propTypes = {
  leftDisabled: PropTypes.bool,
  rightDisabled: PropTypes.bool,
  label: PropTypes.string,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  hideButtons: PropTypes.bool,
  classes: PropTypes.object,
  labelWidth: PropTypes.string,
  backPeriod: PropTypes.string,
  forwardPeriod: PropTypes.string,
};

export default withStyles(styles)(NetworkGraphPager);
