import React from "react";
import PropTypes from "prop-types";
import InnerPaperPanel from "components/shared/ui/Panel/InnerPaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import InnerPaperPanelTitle from "components/shared/ui/Panel/InnerPaperPanelTitle";
import numberFormatter from "lib/numberFormatter";

const PatientParamPanel = ({ session }) => {
  return (
    <InnerPaperPanel>
      <InnerPaperPanelTitle title="Patient Parameters" />
      <ParamTable>
        <ParamTableRow
          data={session}
          name="sessStepHeightNum"
          label="Step Height"
          formatValue={val => val + "in"}
        />
        <ParamTableRow
          data={session}
          name="sessStepLengthNum"
          label="Step Length"
          formatValue={val => val + "in"}
        />
        <ParamTableRow
          data={session}
          name="sessStepSwingTimeSec"
          label="Swing Time"
          formatValue={val => val + "s"}
        />
        <ParamTableRow
          data={session}
          name="sessAngKneeFlexionDeg"
          label="Knee Flex"
          formatValue={val => `${val}°`}
        />
        <ParamTableRow
          data={session}
          name="sessAngHipFlexionDeg"
          label="Hip Flex"
          formatValue={val => `${val}°`}
        />
        <ParamTableRow
          data={session}
          name="sessAutostepWtshiftLateralNum"
          label="Lateral Target"
          formatValue={val => numberFormatter.convertAutoStepWShift(val)}
        />
        <ParamTableRow
          data={session}
          name="sessAutostepWtshiftForwardNum"
          label="Forward Target"
          formatValue={val => numberFormatter.convertAutoStepWShift(val)}
        />
      </ParamTable>
    </InnerPaperPanel>
  );
};

PatientParamPanel.propTypes = {
  session: PropTypes.object.isRequired,
};

export default PatientParamPanel;
