import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  snackbar: {
    marginBottom: "60px",
  },
  snackbarContent: {
    borderRadius: "0px",
  },
});

class ToastSnackbar extends Component {
  render() {
    const {
      toastId,
      open,
      message,
      hideDuration,
      classes,
      onClose,
      onExited,
    } = this.props;
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        className={classes.snackbar}
        open={open}
        onClose={onClose}
        onExited={onExited}
        TransitionComponent={Fade}
        ContentProps={{
          "aria-describedby": toastId,
          className: classes.snackbarContent,
          "data-test": "toast",
        }}
        autoHideDuration={hideDuration}
        message={<span id={toastId}>{message}</span>}
        action={[
          <Button key="undo" color="primary" onClick={onClose}>
            DISMISS
          </Button>,
        ]}
      />
    );
  }
}

ToastSnackbar.propTypes = {
  toastId: PropTypes.string,
  open: PropTypes.bool,
  message: PropTypes.node,
  hideDuration: PropTypes.number,
  classes: PropTypes.object,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
};

export default withStyles(styles)(ToastSnackbar);
