import React, { Component } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { List, Icon, Badge } from "@material-ui/core";
import PropTypes from "prop-types";
import Policy from "components/Policy";

import Link from "./Link";
import Divider from "./NavigationDivider";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    padding: 0,
    flexGrow: 1,
  },
  insetDivider: {
    marginLeft: "20px",
    backgroundColor: "white",
    opacity: 0.16,
  },
  icon: {
    fontSize: "12px",
    opacity: 0.5,
    color: "inherit",
    margin: 0,
    paddingRight: "4px",
  },
  badge: {
    top: "-2px",
    right: "4px",
    height: "6px",
    width: "6px",
    backgroundColor: "#E74C3C",
  },
  badgeIcon: {
    margin: 0,
    color: "rgb(0, 0, 0)",
  },
  hideMdUp: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class NavigationLinkList extends Component {
  render() {
    const {
      classes,
      onNavigate,
      currentPath,
      onContactSupportClick,
      hasNotifications,
      currentUser,
    } = this.props;

    let alertIcon = (
      <Icon className={`${classes.icon} icon-icn-notification`} />
    );

    if (hasNotifications) {
      alertIcon = (
        <Badge
          className={classes.badgeIcon}
          badgeContent={""}
          classes={{ badge: classes.badge }}
        >
          {alertIcon}
        </Badge>
      );
    }

    return (
      <List className={classes.root}>
        <li>
          <Divider />
        </li>
        <Policy action="view" object="showOverview">
          <Link
            text="Overview"
            icon={<Icon className={`${classes.icon} icon-icn-tiles`} />}
            path="/dashboard/overview"
            currentPath={currentPath}
            onClick={() => onNavigate("/dashboard/overview")}
            dataTest="overviewLink"
          />
        </Policy>
        <Policy action="view" object="network">
          <Link
            text={
              currentUser.isA(["ekso_sales"]) ? "Sales Dashboard" : "Network"
            }
            icon={<Icon className={`${classes.icon} icon-icn-tiles`} />}
            path="/dashboard/network"
            currentPath={currentPath}
            onClick={() => onNavigate("/dashboard/network")}
            dataTest="overviewLink"
          />
        </Policy>
        <Policy action="view" object="patients">
          <li>
            <Divider inset />
          </li>
          <Link
            text="Patients"
            icon={<Icon className={`${classes.icon} icon-icn-group`} />}
            path="/dashboard/patients"
            currentPath={currentPath}
            onClick={() => onNavigate("/dashboard/patients")}
            dataTest="patients"
          />
        </Policy>
        <Policy action="view" object="activities">
          <li>
            <Divider inset />
          </li>
          <Link
            text="Activity"
            icon={<Icon className={`${classes.icon} icon-icn-list`} />}
            path="/dashboard/activity"
            currentPath={currentPath}
            onClick={() => onNavigate("/dashboard/activity")}
            dataTest="activityLink"
          />
        </Policy>
        {/* Future feature
        <li>
          <Divider inset />
        </li>
        <Link
          text="Outcomes"
          icon={<Icon className={`${classes.icon} icon-icn-notes`} />}
          path="/dashboard/outcomes"
          currentPath={currentPath}
          onClick={() => onNavigate("/dashboard/outcomes")}
          dataTest="outcomesLink"
        />
        */}
        <li>
          <Divider inset />
        </li>
        <Link
          text="Learning Center"
          icon={<Icon className={`${classes.icon} icon-icn-lifesaver`} />}
          path="/dashboard/learning"
          currentPath={currentPath}
          onClick={() => onNavigate("/dashboard/learning")}
          dataTest="learningCenterLink"
        />
        <Policy action="view" object="users">
          <li>
            <Divider inset />
          </li>
          <Link
            text="Users"
            icon={<Icon className={`${classes.icon} icon-icn-user`} />}
            path="/dashboard/users"
            currentPath={currentPath}
            onClick={() => onNavigate("/dashboard/users")}
            dataTest="manageUsers"
          />
        </Policy>
        <div className={classes.hideMdUp}>
          <li>
            <Divider inset />
          </li>
          <Link
            text="Notifications"
            icon={alertIcon}
            path="/dashboard/notifications"
            currentPath={currentPath}
            onClick={() => onNavigate("/dashboard/notifications")}
          />
          <li>
            <Divider inset />
          </li>
          <Link
            text="Help"
            icon={<Icon className={`${classes.icon} icon-icn-help`} />}
            currentPath={currentPath}
            onClick={onContactSupportClick}
          />
        </div>
        <li>
          <Divider />
        </li>
      </List>
    );
  }
}

NavigationLinkList.propTypes = {
  classes: PropTypes.object,
  onNavigate: PropTypes.func,
  currentPath: PropTypes.string,
  onContactSupportClick: PropTypes.func,
  hasNotifications: PropTypes.bool,
  currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(NavigationLinkList));
