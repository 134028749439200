import React from "react";
import PropTypes from "prop-types";
import CheckboxField from "components/shared/ui/Field/CheckboxField";

class MyPatientsQuickFilter extends React.Component {
  onChange = value => {
    this.props.onQuickFilter({
      my_patients: value,
    });
  };

  render() {
    const { className, value, label } = this.props;
    const translatedValue = value === undefined ? false : value;
    return (
      <CheckboxField
        className={className}
        input={{
          onChange: this.onChange,
          value: translatedValue,
          name: "myPatientsFilter",
        }}
        meta={{}}
        label={label}
      />
    );
  }
}

MyPatientsQuickFilter.defaultProps = {
  label: "My Sessions",
};

MyPatientsQuickFilter.propTypes = {
  onQuickFilter: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  label: PropTypes.string,
};

export default MyPatientsQuickFilter;
