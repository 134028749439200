import * as types from "constants/MenuTypes";
import { RESET } from "constants/ClearTypes";

const initialState = {
  menusOpen: false,
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.OPEN: {
      return {
        [action.id]: true,
        menusOpen: true,
      };
    }

    case types.CLOSE: {
      return {
        [action.id]: false,
        menusOpen: false,
      };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
