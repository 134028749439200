import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  SESSION_EXPIRATION,
  LOGOUT_INACTIVITY,
  UPDATE_CURRENT_USER,
} from "constants/LoginTypes";
import { push } from "connected-react-router";
import { navigate } from "./navigateActions";
import { createResource, deleteResource } from "lib/api";
import { clearStore } from "./clearActions";
import { clearNetwork } from "./networkActions";
import { showToast } from "./toastActions";
import { createBrowserHistory } from "history";
import policyRulesEvaluator from "lib/policyRulesEvaluator";

export function loginUserSuccess(response) {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: {
      response: response,
    },
  };
}

export function loginUserFailure(error) {
  return {
    type: LOGIN_USER_FAILURE,
    payload: {
      status: error.status,
      statusText: error.message,
    },
  };
}

export function loginUserRequest() {
  return {
    type: LOGIN_USER_REQUEST,
  };
}

export function logout() {
  return {
    type: LOGOUT_USER,
  };
}

export function logoutDueInactivity() {
  return {
    type: LOGOUT_INACTIVITY,
  };
}

export function sessionExpired() {
  return {
    type: SESSION_EXPIRATION,
  };
}

export function updateCurrentUser(attributes) {
  return {
    type: UPDATE_CURRENT_USER,
    attributes,
  };
}

export function logoutAndRedirect() {
  return async (dispatch, getState) => {
    try {
      await deleteResource("sessions");
    } finally {
      dispatch(logout());
      dispatch(navigate("/"));
      dispatch(clearStore());
      dispatch(clearNetwork());

      const currentMessage = getState().auth.statusText;
      dispatch(showToast(currentMessage));
    }
  };
}

export function logoutInactivity() {
  return async (dispatch, getState) => {
    try {
      await deleteResource("sessions");
    } finally {
      const location = createBrowserHistory().location;

      dispatch(logoutDueInactivity());
      dispatch(
        navigate(
          `/?timeout=true&next=${encodeURIComponent(
            location.pathname + location.search
          )}`
        )
      );
      dispatch(clearStore());

      const currentMessage = getState().auth.statusText;
      dispatch(showToast(currentMessage));
    }
  };
}

export function redirectToRoute(redirect) {
  return dispatch => {
    dispatch(push(redirect));
  };
}

export function expiresCurrentUser() {
  return (dispatch, getState) => {
    const location = createBrowserHistory().location;

    dispatch(sessionExpired());
    dispatch(
      navigate(
        `/?timeout=true&next=${encodeURIComponent(
          location.pathname + location.search
        )}`
      )
    );
    dispatch(clearStore());

    // Show message for action taken
    const currentMessage = getState().auth.statusText;
    dispatch(showToast(currentMessage));
  };
}

export function loginUser(email, password, query = {}) {
  return async (dispatch, getState) => {
    try {
      dispatch(loginUserRequest());
      let response = await createResource(
        "sessions",
        {
          email: email,
          password: password,
        },
        false
      );

      dispatch(loginUserSuccess(response));

      const lastUserId = getState().auth.lastUserId;
      const currentUserId = getState().auth.currentUser.id;
      const is_overviewPage = policyRulesEvaluator(
        "view",
        "showOverview",
        getState().auth.currentUser
      );

      if (query.next && query.timeout && lastUserId === currentUserId) {
        dispatch(push(decodeURIComponent(query.next)));
      } else if (query.next && !query.timeout && !lastUserId) {
        dispatch(push(decodeURIComponent(query.next)));
      } else {
        dispatch(
          navigate(`/dashboard/${is_overviewPage ? "overview" : "network"}`)
        );
      }
    } catch (e) {
      dispatch(loginUserFailure(e));
    }

    // Show message for action taken
    const currentMessage = getState().auth.statusText;
    dispatch(showToast(currentMessage));
  };
}
