import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import _ from "lodash";

// eslint-disable-next-line
const styles = theme => ({
  actionButton: {
    position: "relative",
  },
  buttonProgress: {
    color: "inherit",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  invisible: {
    opacity: 0,
  },
});

class ActionButton extends Component {
  state = {
    loading: false,
  };

  handleClick = async event => {
    this.setState({ loading: true });
    await this.props.onClick(event);
    this.setState({ loading: false });
  };

  render() {
    const { classes, children } = this.props;
    const disabled =
      this.props.loading || this.props.disabled || this.state.loading;
    const loading = this.props.loading || this.state.loading;
    // Passes through all props it does not override to material-ui Button
    const buttonProps = _.omit(this.props, ["loading"]);
    return (
      <Button
        {...buttonProps}
        classes={_.omit(classes, [
          "actionButton",
          "buttonProgress",
          "invisible",
        ])}
        className={classNames(this.props.className, classes.actionButton)}
        onClick={disabled ? null : this.handleClick}
        disabled={disabled}
      >
        <span
          className={classNames({
            [classes.invisible]: loading,
          })}
        >
          {children}
        </span>
        {loading ? (
          <CircularProgress size={24} className={classes.buttonProgress} />
        ) : null}
      </Button>
    );
  }
}

ActionButton.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(ActionButton);
