import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    marginTop: "-2px",
    marginBottom: "-2px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
});

const ParamTable = ({ classes, children, style }) => {
  return (
    <table className={classes.root} style={style}>
      <tbody>{children}</tbody>
    </table>
  );
};

ParamTable.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  style: PropTypes.object,
};

export default withStyles(styles)(ParamTable);
