import React from "react";
import PropTypes from "prop-types";
import { Grid, Divider, withStyles } from "@material-ui/core";
import _ from "lodash";

import AverageSessionsLayout from "./AverageSessionsLayout";
import numberFormatter from "lib/numberFormatter";
import {
  getDataWithOptimalUnit,
  convertDataToUnit,
} from "lib/graphPointsAdjuster";
import NetworkTimeLinePager from "components/shared/ui/Graph/NetworkTimeLinePager";
import DateQuickFilter from "components/shared/ui/Page/DateQuickFilter";
import EksoIconButton from "components/shared/ui/Button/EksoIconButton";

// eslint-disable-next-line
const styles = theme => ({
  main: {
    padding: "0px",
    paddingTop: "6px",
    width: "100%",
    boxSizing: "border-box",
  },
  grid: {
    width: "100%",
    height: "100%",
  },
  graphPanel: {
    height: "296px",
  },
  graphContent: {
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
  },
  graphGrid: {
    width: "296px",
  },
  graphContainer: {
    paddingTop: "0px",
    minWidth: "1024px",
    maxWidth: "calc(100% + 4px)",
    [theme.breakpoints.down("sm")]: {
      minWidth: "1242px",
    },
  },
  scrollDiv: {
    height: "300px !important",
    width: "100%",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  textAlignRight: {
    textAlign: "right",
  },
  buttonHide: {
    color: "#979797",
  },
  buttonShow: {
    color: "#0099E1",
  },
});

const GraphContent = ({
  classes,
  calcData = {},
  resourceMetadata,
  page,
  modalData,
  showCompare,
  menuItems,
  quickFilter,
  period,
  onPagerClick,
  compare,
  sites,
  site_id
}) => {
  const getGraph = (
    modalData,
    calcData,
    resourceMetadata,
    page,
    compare,
    showCompare,
    sites,
    site_id,
    period
  ) => {
    let result = null;
    let { fieldValue } = modalData;
    let data = calcData.data;
    let sitesData = calcData.sites;
    let convertedData,
      sitesConvertedData = {};
    let commondProps = {
      page,
      compare,
      showCompare,
      sites,
      site_id,
      period
    };

    switch (fieldValue) {
      case "totalSessions": {
        convertedData = _.map(data, item => ({
          x: item.period,
          y: item.totalSessions ? item.totalSessions : 0,
        }));
        _.forEach(sitesData, (value, key) => {
          sitesConvertedData[key] = _.map(value, item => ({
            x: item.period,
            y: item.totalSessions ? item.totalSessions : 0,
          }));
        });
        result = (
          <AverageSessionsLayout
            key="avgSessions"
            fieldValue="totalSessions"
            calcData={calcData}
            convertedData={convertedData}
            resourceMetadata={resourceMetadata}
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            sitesConvertedData={sitesConvertedData}
            {...commondProps}
          />
        );
        break;
      }
      case "avgUpTime": {
        const { points, bestUnit } = getDataWithOptimalUnit(
          data || [],
          "avgUpTime"
        );
        convertedData = points;
        _.forEach(sitesData, (value, key) => {
          sitesConvertedData[key] = convertDataToUnit(
            value,
            "avgUpTime",
            bestUnit
          );
        });
        result = (
          <AverageSessionsLayout
            key="avgSessionUpTime"
            fieldValue="avgUpTime"
            calcData={calcData}
            convertedData={convertedData}
            resourceMetadata={resourceMetadata}
            formatValue={numberFormatter.secondsToRoundedTime}
            unitLabel={bestUnit}
            sitesConvertedData={sitesConvertedData}
            {...commondProps}
          />
        );
        break;
      }
      case "avgWalkTime": {
        const { points, bestUnit } = getDataWithOptimalUnit(
          data || [],
          "avgWalkTime"
        );
        convertedData = points;
        _.forEach(sitesData, (value, key) => {
          sitesConvertedData[key] = convertDataToUnit(
            value,
            "avgWalkTime",
            bestUnit
          );
        });
        result = (
          <AverageSessionsLayout
            key="avgSessionWalkTime"
            fieldValue="avgWalkTime"
            calcData={calcData}
            convertedData={convertedData}
            resourceMetadata={resourceMetadata}
            formatValue={numberFormatter.secondsToRoundedTime}
            unitLabel={bestUnit}
            sitesConvertedData={sitesConvertedData}
            {...commondProps}
          />
        );
        break;
      }
      case "avgSteps": {
        convertedData = _.map(data, item => ({
          x: item.period,
          y: item.avgSteps ? item.avgSteps : 0,
        }));
        _.forEach(sitesData, (value, key) => {
          sitesConvertedData[key] = _.map(value, item => ({
            x: item.period,
            y: item.avgSteps ? item.avgSteps : 0,
          }));
        });
        result = (
          <AverageSessionsLayout
            key="avgStepCount"
            fieldValue="avgSteps"
            label="Steps"
            calcData={calcData}
            convertedData={convertedData}
            resourceMetadata={resourceMetadata}
            formatValue={value =>
              numberFormatter.numberWithCommas(Math.round(value))
            }
            unitLabel="steps"
            sitesConvertedData={sitesConvertedData}
            {...commondProps}
          />
        );
        break;
      }
    }

    return result;
  };

  const toolbar = (
    <div className={classes.toolbarContent}>
      <Grid container spacing={8}>
        <Grid item xs={4} className={classes.textAlignLeft}>
          <DateQuickFilter
            menuItems={menuItems}
            onQuickFilter={quickFilter}
            value={period}
          />
        </Grid>
        <Grid item xs={4} className={classes.textAlignCenter}>
          <NetworkTimeLinePager
            period={period}
            onPagerClick={onPagerClick}
            page={page}
          />
        </Grid>
        <Grid item xs={4} className={classes.textAlignRight}>
          <EksoIconButton
            onClick={() => showCompare(true)}
            label="Compare"
            iconName="dns"
            dataTest="compareButtons"
            classes={{
              button: compare
                ? compare.show
                  ? classes.buttonHide
                  : classes.buttonShow
                : classes.buttonShow,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div className={classes.main}>
      {toolbar}
      <Divider key="modal-graph-divider" />
      {getGraph(
            modalData,
            calcData,
            resourceMetadata,
            page,
            compare,
            showCompare,
            sites,
            site_id,
            period
          )}
    </div>
  );
};

GraphContent.defaultProps = {};

GraphContent.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  page: PropTypes.number,
  openModal: PropTypes.func,
  compare: PropTypes.func,
  period: PropTypes.string,
  menuItems: PropTypes.array,
  quickFilter: PropTypes.func,
  sites: PropTypes.array,
  showCompare: PropTypes.func,
  onPagerClick: PropTypes.func,
  modalData: PropTypes.object,
  site_id: PropTypes.number,
};

export default withStyles(styles)(GraphContent);
