import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import { Scrollbars } from "react-custom-scrollbars";
import PatientAverageSessionLengthContainer from "./PatientAverageSessionLengthContainer";
import EksoOutcomes from "./EksoOutcomes";
import LoadingGraphPanel from "components/shared/ui/Graph/LoadingGraphPanel";
import MinimumAssistanceContainer from "./MinimumAssistanceContainer";
import SwingAssistanceContainer from "./SwingAssistanceContainer";
import SessionStats from "../SessionStats";

import PatientParamsPanel from "./PatientParamsPanel";

const styles = theme => ({
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
  grid: {},
  graphPanel: {
    height: "296px",
  },
  utilPanel: {
    minHeight: "272px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  graphContent: {
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
  },
  graphGrid: {
    width: "296px",
  },
  graphContainer: {
    minWidth: "1242px",
    maxWidth: "calc(100% + 4px)",
  },
  scrollDiv: {
    height: "300px !important",
    width: "100%",
  },
});

const SummaryContent = ({
  classes,
  patient,
  onAddDetails,
  currentUser,
  loading,
}) => {
  const graphSiblingResourceNames = [
    "stats/sessions_length",
    "stats/minimum_assistance",
    "stats/swing_assistance",
  ];

  return (
    <div>
      <Grid
        className={classes.grid}
        container
        spacing={8}
        data-test="summaryContent"
      >
        <PatientParamsPanel
          patient={patient ? patient : {}}
          onAddDetails={onAddDetails}
          currentUser={currentUser}
          loading={loading}
        />
        <Grid item xs={12} className={classes.graphContent}>
          <Scrollbars className={classes.scrollDiv}>
            <Grid container spacing={8} className={classes.graphContainer}>
              <Grid item xs={3}>
                {loading ? (
                  <LoadingGraphPanel />
                ) : (
                  <PatientAverageSessionLengthContainer
                    requiredFilter={{ patient_id: patient.id }}
                    last40Option={true}
                    siblingResourceNames={graphSiblingResourceNames}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                {loading ? (
                  <LoadingGraphPanel />
                ) : (
                  <MinimumAssistanceContainer
                    requiredFilter={{ patient_id: patient.id }}
                    siblingResourceNames={graphSiblingResourceNames}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                {loading ? (
                  <LoadingGraphPanel />
                ) : (
                  <SwingAssistanceContainer
                    requiredFilter={{ patient_id: patient.id }}
                    siblingResourceNames={graphSiblingResourceNames}
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                {loading ? (
                  <LoadingGraphPanel hasParams={false} />
                ) : (
                  <EksoOutcomes
                    requiredFilter={{ patient_id: patient.id }}
                    isMissingFeature={!patient.hasInsessFeature}
                  />
                )}
              </Grid>
            </Grid>
          </Scrollbars>
        </Grid>
        <Grid item xs={12}>
          <PaperPanel>
            {!patient ? null : (
              <SessionStats
                patient={patient}
                resourceName={`patients/${patient.id}/pulse_sessions`}
              />
            )}
          </PaperPanel>
        </Grid>
      </Grid>
    </div>
  );
};

SummaryContent.propTypes = {
  patient: PropTypes.object,
  classes: PropTypes.object,
  onAddDetails: PropTypes.func,
  currentUser: PropTypes.object,
  loading: PropTypes.bool,
};

export default withStyles(styles)(SummaryContent);
