import * as types from "constants/VideoPlaybackTypes";
import { RESET } from "constants/ClearTypes";

const initialState = {
  isPlaying: false,
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.PLAYBACK_START: {
      return {
        ...state,
        isPlaying: true,
      };
    }

    case types.PLAYBACK_STOP: {
      return {
        ...state,
        isPlaying: false,
      };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
