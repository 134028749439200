import * as types from "constants/LocalizationTypes";

const initialState = {
  lang: "en",
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.SAVE_LANG: {
      const { lang } = action;
      return {
        lang,
      };
    }

    default:
      return state;
  }
}
