import * as types from "constants/NetworkTypes";

const initialState = {
  name: "",
  site: null,
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.CHANGE_NETWORK: {
      return {
        ...action.network,
      };
    }

    case types.CHANGE_NETWORK_SITE: {
      return {
        ...state,
        site: {
          ...action.site,
        },
      };
    }

    case types.COMPARE_SHOW: {
      return {
        ...state,
        ...action.compare,
      };
    }

    case types.DRAW_SITES: {
      return {
        ...state,
        drawSites: action.drawSites,
      };
    }

    case types.CLEAR_NETWORK: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
