import React from "react";
import PropTypes from "prop-types";
import InnerPaperPanelDivider from "components/shared/ui/Panel/InnerPaperPanelDivider";
import InnerPaperPanel from "components/shared/ui/Panel/InnerPaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import PanelSubHeader from "components/shared/ui/Panel/PanelSubHeader";
import { withStyles } from "@material-ui/core/styles";
import * as InSessDataTypes from "constants/InSessDataTypes";
import InsessResults from "./InsessResults";

const styles = theme => ({
  labelCol: {
    width: "186px",
    [theme.breakpoints.down("sm")]: {
      width: "inherit",
    },
  },
});

const PregaitPerformance = ({ classes, session }) => {
  return (
    <div>
      <PanelSubHeader title="PREGAIT PERFORMANCE" />
      <InnerPaperPanel>
        <ParamTable>
          <ParamTableRow
            classes={{
              labelCol: classes.labelCol,
            }}
            name="sessStepHeightNum"
            label="Total Squats"
          >
            <InsessResults
              session={session}
              onEdit={() => {}}
              type={InSessDataTypes.SQUATS}
            />
          </ParamTableRow>
        </ParamTable>
      </InnerPaperPanel>
    </div>
  );
};

PregaitPerformance.propTypes = {
  session: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(PregaitPerformance);
