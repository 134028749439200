import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TimeLineGraphBase from "components/shared/ui/Graph/TimeLineGraphBase";

const AverageSessionsGraph = ({ period, data, page, onPagerClick }) => {
  let convertedData = [];
  if (data && data.length) {
    convertedData = _.map(data, item => ({
      x: item.period,
      y: item.avg ? item.avg : NaN,
    }));
  }

  return (
    <TimeLineGraphBase
      id="AverageSessions"
      data={[convertedData]}
      emptyMax={10}
      period={period}
      page={page}
      onPagerClick={onPagerClick}
      unitLabel={"sessions"}
      tooltipLabelCallback={tooltipItem => {
        if (`${tooltipItem.yLabel}` !== "1") {
          return tooltipItem.yLabel + " sessions";
        } else {
          return tooltipItem.yLabel + " session";
        }
      }}
    />
  );
};

AverageSessionsGraph.propTypes = {
  period: PropTypes.string,
  data: PropTypes.array,
  page: PropTypes.number,
  onPagerClick: PropTypes.func,
};

export default AverageSessionsGraph;
