import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "components/shared/ui/Typography/Text";
import { withStyles } from "@material-ui/core/styles";
import { Icon, Grid } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  contentDiv: {
    display: "block",
    margin: "45px auto",
  },
  icon: {
    fontSize: "48px",
    margin: "0 auto",
    color: "#3498DB",
    textAlign: "center",
    display: "block",
  },
  header: {
    padding: 0,
    marginTop: "24px",
    marginBottom: 0,
    color: "#4A4A4A",
    textAlign: "center",
    fontWeight: 200,
  },
  description: {
    marginTop: "16px",
    marginBottom: 0,
    padding: 0,
    color: "#9B9B9B",
    textAlign: "center",
    fontWeight: 400,
  },
  children: {
    marginTop: "8px",
    textAlign: "center",
  },
});

const MessagePanel = ({
  classes,
  iconName,
  headerText,
  description,
  className,
  children,
}) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <div
          className={classNames(classes.contentDiv, className)}
          data-test="messagePanel"
        >
          <Icon className={classes.icon}>{iconName}</Icon>
          <Text fontSize={24} className={classes.header}>
            {headerText}
          </Text>
          {description ? (
            <Text fontSize={16} className={classes.description}>
              {description}
            </Text>
          ) : null}
          <div className={classes.children}>{children}</div>
        </div>
      </Grid>
    </Grid>
  );
};

MessagePanel.defaultProps = {
  iconName: "directions_walk",
};

MessagePanel.propTypes = {
  classes: PropTypes.object,
  iconName: PropTypes.string,
  headerText: PropTypes.node,
  description: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(MessagePanel);
