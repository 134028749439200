import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { navigate } from "actions/navigateActions";
import * as modalActionCreators from "actions/modalActions";
import * as toastActionCreators from "actions/toastActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import * as modalIds from "constants/ModalIds";
import { api } from "lib/api";

// #lizard forgives
function UserActionWrapper(WrappedActionComponent) {
  class BaseUserActionContainer extends Component {
    handleNavigate = path => {
      this.props.navigate(path);
    };

    handleDeleteClick = () => {
      const { modalActions, user } = this.props;
      modalActions.openConfirmModal({
        title: `Delete ${user.userFirstName} ${user.userLastName}`,
        message: (
          <Fragment>
            Are you sure you want to delete{" "}
            <b>
              {user.userFirstName} {user.userLastName}
            </b>
            ?
          </Fragment>
        ),
        confirmText: "Delete",
        onConfirm: this.processDelete,
      });
    };

    processDelete = async () => {
      const { usersActions, modalActions, user, navigate } = this.props;
      // Correct type is required to delete from blue-chip, and MUST be plural
      user.type = "users";
      navigate("/dashboard/users");
      usersActions.delete(user, {
        successToast: `${user.userFirstName} ${user.userLastName} Deleted`,
        errorToast: "Deletion Failed",
        removeResource: true,
      });
      modalActions.closeConfirmModal();
    };

    handleDeactivateClick = () => {
      const { modalActions, user } = this.props;
      modalActions.openConfirmModal({
        title: `Deactivate ${user.userFirstName} ${user.userLastName}`,
        message: (
          <Fragment>
            Are you sure you want to deactivate{" "}
            <b>
              {user.userFirstName} {user.userLastName}
            </b>
            ?
          </Fragment>
        ),
        confirmText: "Deactivate",
        onConfirm: this.processDeactivate,
      });
    };

    processDeactivate = () => {
      const { usersActions, modalActions, user } = this.props;
      usersActions.update(
        {
          id: user.id,
          status: "deactive",
        },
        {
          successToast: `${user.userFirstName} ${
            user.userLastName
          } Deactivated`,
          errorToast: "Deactivation Failed",
        }
      );
      modalActions.closeConfirmModal();
    };

    handleActivateClick = () => {
      const { usersActions, user } = this.props;

      usersActions.update(
        {
          id: user.id,
          status: "active",
        },
        {
          successToast: `${user.userFirstName} ${user.userLastName} Activated`,
          errorToast: "Activation Failed",
        }
      );
    };

    handleResendInvite = async () => {
      const { user, toastActions } = this.props;
      try {
        await api(`users/${user.id}/resend_invitation`, "post");
        toastActions.showToast("Invitation Resent");
      } catch (error) {
        toastActions.showToast(
          `Invitation sending failed${
            error.message ? ": " + error.message : ""
          }`
        );
      }
    };

    handleEditClick = () => {
      this.props.modalActions.openModalWithData(
        modalIds.USER_MODAL_ID,
        this.props.user
      );
    };

    render() {
      const { user } = this.props;
      let onStatusUpdate = this.handleDeactivateClick;
      let onStatusUpdateLinkText = "Deactivate";
      if (user && user.status === "deactive") {
        onStatusUpdate = this.handleActivateClick;
        onStatusUpdateLinkText = "Activate";
      } else if (user && user.status === "pending") {
        onStatusUpdate = this.handleResendInvite;
        onStatusUpdateLinkText = "Resend Invitation";
      }

      return (
        <WrappedActionComponent
          {...this.state}
          {...this.props}
          onNavigate={this.handleNavigate}
          onDelete={this.handleDeleteClick}
          onEdit={this.handleEditClick}
          user={this.props.user}
          currentUser={this.props.currentUser}
          onStatusUpdate={onStatusUpdate}
          onStatusUpdateLinkText={onStatusUpdateLinkText}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    currentUser: state.auth.currentUser,
  });

  const mapDispatchToProps = dispatch => ({
    navigate: bindActionCreators(navigate, dispatch),
    modalActions: bindActionCreators(modalActionCreators, dispatch),
    toastActions: bindActionCreators(toastActionCreators, dispatch),
    usersActions: jsonAPIReduxActions(dispatch, "users"),
  });

  BaseUserActionContainer.propTypes = {
    currentUser: PropTypes.object,
    user: PropTypes.object,
    navigate: PropTypes.func,
    modalActions: PropTypes.object,
    usersActions: PropTypes.object,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseUserActionContainer);
}

export default UserActionWrapper;
