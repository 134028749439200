import React from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";
import _ from "lodash";
// Required import for zoom and pan
// eslint-disable-next-line
import * as zoom from "components/shared/ui/Graph/plugins/customChart.zoom";

const createDatasets = (
  data,
  { lineColor, pointColor, fillColor, pointRadius, fill }
) => {
  let dataArrays = data;
  return dataArrays.map((dataArray, index) => {
    if (_.isPlainObject(dataArray)) {
      return {
        ...createDataset({
          dataArray: dataArray.data,
          index,
          fillColor,
          lineColor,
          pointRadius,
          pointColor,
          fill,
        }),
        key: dataArray.key,
      };
    }
    return createDataset({
      dataArray,
      index,
      fillColor,
      lineColor,
      pointRadius,
      pointColor,
      fill,
    });
  });
};

const createDataset = ({
  dataArray,
  index,
  fillColor,
  lineColor,
  pointRadius,
  pointColor,
  fill,
}) => ({
  label: "" + index,
  data: dataArray,
  backgroundColor: Array.isArray(fillColor) ? fillColor[index] : fillColor,
  borderColor: Array.isArray(lineColor) ? lineColor[index] : lineColor,
  fill: Array.isArray(fill) ? fill[index] : fill,
  lineTension: 0,
  pointRadius: Array.isArray(pointRadius) ? pointRadius[index] : pointRadius,
  pointHitRadius: 6,
  borderWidth: 2,
  spanGaps: true,
  pointBackgroundColor: Array.isArray(pointColor)
    ? pointColor[index]
    : pointColor,
  pointBorderColor: Array.isArray(pointColor) ? pointColor[index] : pointColor,
});

const StandardLineGraphBase = ({
  data,
  lineColor,
  pointColor,
  fillColor,
  pointRadius,
  fill,
  labels,
  height,
  width,
  className,
  options,
  margin,
}) => {
  const chartjsData = {
    labels,
    options,
    datasets: createDatasets(data, {
      lineColor,
      pointColor,
      fillColor,
      pointRadius,
      fill,
    }),
  };

  return (
    <div
      className={className}
      style={{
        position: "relative",
        height,
        width,
        margin,
      }}
    >
      <Line
        datasetKeyProvider={ds => {
          return ds.key;
        }}
        data={chartjsData}
        options={options}
      />
    </div>
  );
};

StandardLineGraphBase.defaultProps = {
  className: "chart-container",
  height: "166px",
  width: "100%",
  margin: "0px",
  pointColor: "#4A90E2",
  lineColor: "#4A90E2",
  fillColor: "rgba(1,154,232,0.1)",
  pointRadius: 2,
  fill: "origin",
};

StandardLineGraphBase.propTypes = {
  data: PropTypes.array.isRequired,
  labels: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  pointRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  lineColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pointColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.object,
  margin: PropTypes.string,
};

export default StandardLineGraphBase;
