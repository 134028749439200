import React from "react";
import PropTypes from "prop-types";
import FlagIcon from "components/shared/ui/Localization/FlagIcon";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  root: {},
  icon: {
    marginRight: "8px",
    backgroundColor: "white",
    border: "1px solid rgba(0, 0, 0, 0.40)",
    height: "14px",
    width: "19px",
  },
  labelText: {},
});

const FlagIconLabel = ({ text, code, classes }) => {
  return (
    <div className={classes.root}>
      <FlagIcon code={code} className={classes.icon} />
      <span className={classes.labelText}>{text}</span>
    </div>
  );
};

FlagIconLabel.propTypes = {
  classes: PropTypes.object,
  code: PropTypes.string,
  text: PropTypes.string,
};

export default withStyles(styles)(FlagIconLabel);
