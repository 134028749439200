import * as types from "constants/LoginTypes";
// import * as accountTypes from './constants/Dashboard/MyAccountTypes';
import User from "models/User";

const initialState = {
  currentUser: null,
  token: null,
  isAuthenticated: false,
  isAuthenticating: false,
  statusText: null,
  isUpdating: false,
  updateSuccess: null,
  inactivity: false,
  loginError: false,
  lastUserId: null,
  network: null,
};

function storeUser(user) {
  localStorage.setItem("currentUser", JSON.stringify(user));
}

function removeUser() {
  localStorage.removeItem("currentUser");
}

function getNetwork(state) {
  return Array.isArray(state.included) ?
    state.included.map(incl => {
      if (incl.type == "networks") {
        if(Array.isArray(incl.relationships.sites.data) && incl.relationships.sites.data.length == 0){
          return {
            id: incl.id,
            name: incl.attributes.name,
            sites: []
          }
        } else {
          return {
            id: incl.id,
            name: incl.attributes.name,
            sites: state.included.map(incl => {
              if (incl.type == "sites" && incl.attributes.network_name){
                return {
                  id: incl.id,
                  name: incl.attributes.name
                };
              }
            })
          }
        }
      }
      if (incl.type == "sites" && incl.relationships.network.data){
        return {
          id: incl.relationships.network.data.id,
          name: incl.attributes.network_name,
          sites: state.included.map(incl => {
            if (incl.type == "sites" && incl.attributes.network_name){
              return {
                id: incl.id,
                name: incl.attributes.name
              };
            }
          })
        }
      }
    }) : {};
}

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case types.LOGIN_USER_REQUEST: {
      return {
        ...state,
        isAuthenticating: true,
        statusText: null,
      };
    }

    case types.LOGIN_USER_SUCCESS: {
      let user = new User(payload.response);
      let network = getNetwork(user);
      storeUser(payload.response);
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: true,
        currentUser: user,
        loginError: false,
        statusText: "You have been successfully logged in.",
        inactivity: false,
        network,
      };
    }

    case types.LOGIN_USER_FAILURE: {
      removeUser();
      return {
        ...state,
        isAuthenticating: false,
        isAuthenticated: false,
        token: null,
        inactivity: false,
        loginError: true,
        statusText: `Authentication Error: ${payload.statusText}`,
      };
    }

    case types.LOGOUT_USER: {
      removeUser();
      return {
        ...state,
        isAuthenticated: false,
        currentUser: null,
        statusText: "You have been successfully logged out.",
      };
    }

    case types.SESSION_EXPIRATION: {
      removeUser();
      return {
        ...state,
        lastUserId: state.currentUser.id,
        isAuthenticated: false,
        currentUser: null,
        statusText: "Your session has expired.",
      };
    }

    case types.LOGOUT_INACTIVITY: {
      removeUser();
      return {
        ...state,
        lastUserId: state.currentUser.id,
        isAuthenticated: false,
        currentUser: null,
        inactivity: true,
        statusText: "Your session expired due to inactivity.",
      };
    }

    case types.UPDATE_CURRENT_USER: {
      const userData = {
        data: {
          id: state.currentUser.id,
          type: "users",
          attributes: {
            ...state.currentUser.attributes,
            ...action.attributes,
          },
          relationships: state.currentUser.apiAttrs.relationships,
        },
      };
      let user = new User(userData);
      storeUser(userData);

      return {
        ...state,
        currentUser: user,
      };
    }

    /*     case accountTypes.UPDATE_PROFILE_REQUEST: {
      return {
        ...state,
        isUpdating: true,
        updateSuccess: false,
        error: null,
        statusText: null
      };
    }

    case accountTypes.UPDATE_PROFILE_SUCCESS: {
      let user;
      if (payload.response) {
        storeUser(payload.response);
        user = new User(payload.response);
      } else {
        user = state.currentUser;
      }

      return {
        ...state,
        isUpdating: false,
        updateSuccess: true,
        currentUser: user,
        statusText: 'Profile Updated.'
      };
    }

    case accountTypes.UPDATE_PROFILE_FAILURE: {
      return {
        ...state,
        isUpdating: false,
        updateSuccess: false,
        statusText: payload.statusText,
        error: payload.error
      };
    } */

    default:
      return state;
  }
}
