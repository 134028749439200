import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Icon, Button } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

import NetworkContentHeader from "components/shared/ui/Page/NetworkContentHeader";
import Text from "components/shared/ui/Typography/Text";
import DateQuickFilter from "components/shared/ui/Page/DateQuickFilter";
import * as networkActionCreators from "actions/networkActions";
import NetworkTimeLinePager from "components/shared/ui/Graph/NetworkTimeLinePager";
import NetworkModal from "./NetworkModal";
import NetworkCompareModal from "./NetworkCompareModal";
import PanelContent from "./PanelContent";
import { formatDateForApi, getPageFromPeriods } from "lib/dateUtils";
import { translateDatePeriodFilterToCalendar } from "lib/filterUtils";
import { NETWORK_MODAL_ID, NETWORK_COMPARE_MODAL_ID } from "constants/ModalIds";
import {
  STATS_UTILIZATION,
  STATS_DEMOGRAPHICS,
  STATS_UTILIZATION_GRAPH,
  STATS_UTILIZATION_TABLE,
  STATS_UTILIZATION_MODAL,
} from "constants/NetworkTypes";
import AverageSessions from "./AverageSessions";
import UtilizationTable from "./UtilizationTable";
import DemographicsModal from "./DemographicsModal";
import AuthLayout from "components/shared/layouts/AuthLayout";
import { logoutAndRedirect } from "actions/loginActions";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
    height: "73px",
  },
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
  mainTable: {
    padding: "8px",
    paddingTop: "0px",
    width: "100%",
    boxSizing: "border-box",
  },
  title: {
    top: "16px",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4A4A4A",
  },
  titleChange: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#979797",
    cursor: "pointer",
  },
  siteText: {
    paddingLeft: "16px",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4A4A4A",
  },
  spacer: {
    color: "#D8D8D8",
    verticalAlign: "middle",
    width: "1px",
    height: "24px",
    display: "inline-block",
    backgroundColor: "#D8D8D8",
    marginLeft: "24px",
    marginRight: "16px",
  },
  graphContainer: {
    paddingTop: "4px",
  },
  toolbarContent: {
    display: "inline-block",
  },
  networkButton: {
    paddingLeft: "8px",
    paddingRight: "8px",
    padding: "8px",
    minWidth: "0px",
    minHeight: "0px",

    background: "#2C6AFA",
    borderRadius: "3px",
    width: "134px",
    height: "28px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#2c6afa70",
    },
  },
  networkButtonIcon: {
    marginRight: "16px",
    color: "#FFFFFF",
  },
  fiveDaysSwitch: {
    color: "rgb(145 145 145)",
    fontSize: "13px",
    fontWeight: "bold",
  },
});

const menuItems = [
  { value: "allTime", label: "All Time" },
  { value: "thisWeek", label: "Week" },
  { value: "thisMonth", label: "Month" },
  { value: "thisQuarter", label: "Quarter" },
  { value: "thisYear", label: "Year" },
];

class NetworkOverviewPage extends Component {
  state = {
    clientFilter: {
      period: "allTime",
    },
    page: 1,
    modalData: {},
    useFiveDaysAverages: true,
  };

  componentDidUpdate(prevProps) {
    let { clientFilter, page } = this.state;
    let { site } = this.props;

    if (this.props.site !== prevProps.site) {
      let filter = {
        site: site,
        ...translateDatePeriodFilterToCalendar(clientFilter.period),
        period: clientFilter.period,
        page,
      };

      this.props.getUtilization.get({ filter });
      this.props.getDemographics.get({ filter });
      this.props.getUtilizationGraph.get({
        filter,
        use_five_days_averages: this.state.useFiveDaysAverages,
      });
      this.props.getUtilizationTable.get({ filter });
    }
  }

  handleNetworkModalClick = () => {
    this.props.modalActions.openModal(NETWORK_MODAL_ID);
  };

  handleNetworkClick = network => {
    this.props.networkActions.changeNetwork(network);
  };

  onQuickFilter = quickFilter => {
    let { site } = this.props;
    let { page } = this.state;
    page = getPageFromPeriods(
      this.state.clientFilter.period,
      quickFilter.period,
      page
    );

    let filter = {
      site,
      ...translateDatePeriodFilterToCalendar(quickFilter.period),
      period: quickFilter.period,
      page,
    };

    this.props.getUtilization.get({ filter });
    this.props.getDemographics.get({ filter });
    this.props.getUtilizationGraph.get({
      filter,
      use_five_days_averages: this.state.useFiveDaysAverages,
    });
    this.props.getUtilizationTable.get({ filter });
    this.setState({ clientFilter: quickFilter, page });
  };

  onPagerClick = dateFilter => {
    let { site } = this.props;
    let filter = {
      site,
      ...translateDatePeriodFilterToCalendar(dateFilter.period),
      ...dateFilter,
    };
    this.setState({ clientFilter: filter, page: dateFilter.page });
    this.props.getUtilization.get({ filter });
    this.props.getDemographics.get({ filter });
    this.props.getUtilizationGraph.get({
      filter,
      use_five_days_averages: this.state.useFiveDaysAverages,
    });
    this.props.getUtilizationTable.get({ filter });
    this.props.getUtilizationModal.get({ filter });
  };

  handleModalNetworkClick = data => {
    this.setState({ modalData: data });
    this.props.modalActions.openModal(NETWORK_COMPARE_MODAL_ID);
  };

  handleLogout = event => {
    event.preventDefault();
    this.props.logoutAndRedirect();
  };

  handleSevenDaysSwitch = event => {
    let { site } = this.props;
    let { clientFilter, page } = this.state;

    let filter = {
      site,
      ...translateDatePeriodFilterToCalendar(clientFilter.period),
      period: clientFilter.period,
      page,
    };

    this.props.getUtilizationGraph.get({
      filter,
      use_five_days_averages: !event.target.checked,
    });

    this.setState({ useFiveDaysAverages: !event.target.checked });
  };

  render() {
    const { classes, site, currentUser, network } = this.props;

    const titleChange = `${site ? classes.titleChange : classes.title}`;
    const periodValue = this.state.clientFilter.period
      ? this.state.clientFilter.period
      : "thisYear";

    const siteTitle = (
      <span className={classes.siteText}>
        <ArrowForwardIosIcon
          key="icon-site"
          style={{ fontSize: 12, paddingRight: "16px" }}
        />
        {site ? site.name : null}
      </span>
    );

    const networkTitle = () => {
      if (currentUser.isA(["ekso_sales"])) {
        return "Sales Dashboard";
      } else {
        return network ? network.name : null;
      }
    };

    const title = (
      <Text fontSize={16} className={classes.title}>
        <span
          className={titleChange}
          onClick={() => this.handleNetworkClick(network)}
        >
          {networkTitle()}
        </span>
        {site ? siteTitle : null}
      </Text>
    );

    const toolbar = (
      <div className={classes.toolbarContent}>
        <span className={classes.fiveDaysSwitch}>
          {this.state.useFiveDaysAverages
            ? "Using 5 Day Averages"
            : "Using 7 Day Averages"}
        </span>
        <Switch
          checked={!this.state.useFiveDaysAverages}
          onChange={this.handleSevenDaysSwitch}
          name="sevenDaysCheck"
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <NetworkTimeLinePager
          period={periodValue}
          page={this.state.page}
          onPagerClick={this.onPagerClick}
        />
        <DateQuickFilter
          menuItems={menuItems}
          onQuickFilter={this.onQuickFilter}
          value={periodValue}
        />
        <span className={classes.spacer} />
        <Button
          data-test="selectNetwork"
          onClick={this.handleNetworkModalClick}
          label="Network"
          className={classes.networkButton}
        >
          <Icon className={classes.networkButtonIcon}>language</Icon>
          Network
        </Button>
      </div>
    );

    const notFoundNetworkOrSites = (
      <AuthLayout
        header="Network or Sites Not Found"
        description="Don't have a site or a network assigned, please contact support."
        renderFooter={false}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "16px" }}
          onClick={this.handleLogout}
        >
          Log Out
        </Button>
      </AuthLayout>
    );

    const showContent = (
      <div className={classes.root} data-test="NetworkOverviewPage">
        <NetworkContentHeader title={title} toolbar={toolbar} />
        <div className={classes.main}>
          <PanelContent data-test="NetworkOverviewPanelContent" />
          <AverageSessions
            site={site}
            page={this.state.page}
            openModal={value => this.handleModalNetworkClick(value)}
          />
        </div>
        <div className={classes.mainTable}>
          <UtilizationTable
            data-test="NetworkOverviewUtilizationTable"
            site={site}
            clientFilter={this.state.clientFilter}
          />
        </div>
        <NetworkModal />
        <NetworkCompareModal
          sites={
            network
              ? network.sites
                ? network.sites.filter(s => s != null)
                : []
              : []
          }
          modalData={this.state.modalData}
          menuItems={menuItems}
          clientFilter={this.state.clientFilter}
          site_id={site ? site.id : null}
          globalPeriod={periodValue}
          globalPage={this.state.page}
        />
        <DemographicsModal />
      </div>
    );

    if (network) {
      return showContent;
    }
    return notFoundNetworkOrSites;
  }
}

NetworkOverviewPage.propTypes = {
  classes: PropTypes.object,
  modalActions: PropTypes.object,
  network: PropTypes.object,
  networkActions: PropTypes.object,
  site: PropTypes.object,
  last_sign: PropTypes.last_sign_in_at,
  clientFilter: PropTypes.object,
  onQuickFilter: PropTypes.func,
  getUtilization: PropTypes.object,
  getDemographics: PropTypes.object,
  getUtilizationGraph: PropTypes.object,
  getUtilizationTable: PropTypes.object,
  onPagerClick: PropTypes.func,
  getUtilizationModal: PropTypes.object,
  logoutAndRedirect: PropTypes.func,
  useFiveDaysAverages: PropTypes.bool,
  currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
  site: state.network.site,
  currentUser: state.auth.currentUser,
  network: state.auth.network,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  getUtilization: jsonAPIReduxActions(dispatch, STATS_UTILIZATION),
  getDemographics: jsonAPIReduxActions(dispatch, STATS_DEMOGRAPHICS),
  networkActions: bindActionCreators(networkActionCreators, dispatch),
  getUtilizationGraph: jsonAPIReduxActions(dispatch, STATS_UTILIZATION_GRAPH),
  getUtilizationTable: jsonAPIReduxActions(dispatch, STATS_UTILIZATION_TABLE),
  getUtilizationModal: jsonAPIReduxActions(dispatch, STATS_UTILIZATION_MODAL),
  logoutAndRedirect: bindActionCreators(logoutAndRedirect, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NetworkOverviewPage));
