import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";

import { withStyles } from "@material-ui/core/styles";
import { getDataWithOptimalUnit } from "lib/graphPointsAdjuster";
import numberFormatter from "lib/numberFormatter";
import AverageSessionsLayout from "./AverageSessionsLayout";
import { periodToString } from "lib/stringUtils";

// eslint-disable-next-line
const styles = theme => ({
  main: {
    padding: "0px",
    paddingTop: "6px",
    width: "100%",
    boxSizing: "border-box",
  },
  grid: {},
  graphPanel: {
    height: "296px",
  },
  graphContent: {
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
  },
  graphGrid: {
    width: "296px",
  },
  graphContainer: {
    paddingTop: "0px",
    minWidth: "1024px",
    maxWidth: "calc(100% + 4px)",
    [theme.breakpoints.down("sm")]: {
      minWidth: "1242px",
    },
  },
  scrollDiv: {
    height: "300px !important",
    width: "100%",
  },
  pointer: {
    cursor: "pointer",
  },
});

const UtilizationGraphContent = ({
  classes,
  calcData = {},
  resourceMetadata,
  page,
  openModal,
}) => {
  let sessionsData = [],
    sessionUpTimeDataOptimal = { points: [], bestUnit: "h" },
    sessionWalkTimeDataOptimal = { points: [], bestUnit: "h" },
    sessionStepsData = [];

  let data = calcData.data;

  if (data && data.length) {
    sessionsData = _.map(data, item => ({
      x: item.period,
      y: item.totalSessions ? item.totalSessions : 0,
    }));

    sessionUpTimeDataOptimal = getDataWithOptimalUnit(data, "avgUpTime");
    sessionWalkTimeDataOptimal = getDataWithOptimalUnit(data, "avgWalkTime");

    sessionStepsData = _.map(data, item => ({
      x: item.period,
      y: item.avgSteps ? item.avgSteps : 0,
    }));
  }

  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : "allTime";

  let periodUnit = periodToString(period, true).toLowerCase();

  return (
    <div className={classes.main}>
      <Grid className={classes.grid} container spacing={8}>
        <Grid item xs={12} className={classes.graphContent}>
          <Scrollbars
            className={classes.scrollDiv}
            renderTrackVertical={() => <div />}
            renderThumbVertical={() => <div />}
          >
            <Grid container spacing={8} className={classes.graphContainer} data-test="AverageSessionsContent">
              <Grid item xs={3}>
                <AverageSessionsLayout
                  key="avgSessions"
                  title="Sessions"
                  fieldValue="totalSessions"
                  calcData={calcData}
                  convertedData={sessionsData}
                  resourceMetadata={resourceMetadata}
                  formatValue={value =>
                    numberFormatter.numberWithCommas(Math.round(value))
                  }
                  unitLabel="Sessions"
                  page={page}
                  onGraphClick={() =>
                    openModal({
                      title: "Avg. Sessions",
                      fieldValue: "totalSessions",
                    })
                  }
                  periodUnit={periodUnit}
                />
              </Grid>
              <Grid item xs={3}>
                <AverageSessionsLayout
                  key="avgSessionUpTime"
                  title="Total Up Time"
                  fieldValue="avgUpTime"
                  calcData={calcData}
                  convertedData={sessionUpTimeDataOptimal.points}
                  resourceMetadata={resourceMetadata}
                  formatValue={numberFormatter.secondsToRoundedTime}
                  unitLabel={sessionUpTimeDataOptimal.bestUnit}
                  page={page}
                  onGraphClick={() =>
                    openModal({
                      title: "Avg. Total Up Time",
                      fieldValue: "avgUpTime",
                    })
                  }
                  periodUnit={periodUnit}
                />
              </Grid>
              <Grid item xs={3}>
                <AverageSessionsLayout
                  key="avgSessionWalkTime"
                  title="Total Walk Time"
                  fieldValue="avgWalkTime"
                  calcData={calcData}
                  convertedData={sessionWalkTimeDataOptimal.points}
                  resourceMetadata={resourceMetadata}
                  formatValue={numberFormatter.secondsToRoundedTime}
                  unitLabel={sessionWalkTimeDataOptimal.bestUnit}
                  page={page}
                  onGraphClick={() =>
                    openModal({
                      title: "Avg. Total Walk Time",
                      fieldValue: "avgWalkTime",
                    })
                  }
                  periodUnit={periodUnit}
                />
              </Grid>
              <Grid item xs={3}>
                <AverageSessionsLayout
                  key="avgStepCount"
                  title="Step Count"
                  fieldValue="avgSteps"
                  label="Steps"
                  calcData={calcData}
                  convertedData={sessionStepsData}
                  resourceMetadata={resourceMetadata}
                  formatValue={value =>
                    numberFormatter.numberWithCommas(Math.round(value))
                  }
                  unitLabel="steps"
                  page={page}
                  onGraphClick={() =>
                    openModal({
                      title: "Step Count",
                      fieldValue: "avgSteps",
                    })
                  }
                  periodUnit={periodUnit}
                />
              </Grid>
            </Grid>
          </Scrollbars>
        </Grid>
      </Grid>
    </div>
  );
};

UtilizationGraphContent.defaultProps = {};

UtilizationGraphContent.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  site: PropTypes.object,
  page: PropTypes.number,
  openModal: PropTypes.func,
};

export default withStyles(styles)(UtilizationGraphContent);
