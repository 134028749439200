import React from "react";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";

const TimeInput = props => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
      showMask
      placeholderChar={"\u2000"}
    />
  );
};

TimeInput.propTypes = {
  inputRef: PropTypes.any,
};

export default TimeInput;
