import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import InnerPaperPanel from "components/shared/ui/Panel/InnerPaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import InnerPaperPanelTitle from "components/shared/ui/Panel/InnerPaperPanelTitle";
import PanelSubHeader from "components/shared/ui/Panel/PanelSubHeader";
import numberFormatter from "lib/numberFormatter";
import InnerPaperPanelDivider from "components/shared/ui/Panel/InnerPaperPanelDivider";

const WalkingStatGaitPerformance = ({ insessEvent }) => {
  return (
    <div>
      <PanelSubHeader title="GAIT PERFORMANCE" />
      <Grid container spacing={16}>
        <Grid item xs={12} style={{ display: "flex" }}>
          <InnerPaperPanel>
            <InnerPaperPanelTitle title="Gait Scores" />

            <Grid container spacing={8}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <ParamTable>
                  <ParamTableRow
                    data={insessEvent}
                    name="insessLtLast5SwingAssistLevel"
                    label="Left Swing Assist"
                    formatValue={val => numberFormatter.convertGaitScore(val)}
                  />
                  <ParamTableRow
                    data={insessEvent}
                    name="insessLtLast5MinAssistLevel"
                    label="Left Min Assist"
                    formatValue={val => numberFormatter.convertGaitScore(val)}
                  />
                </ParamTable>
              </Grid>
              <Grid item xs={12} md={6}>
                <ParamTable>
                  <ParamTableRow
                    data={insessEvent}
                    name="insessRtLast5SwingAssistLevel"
                    label="Right Swing Assist"
                    formatValue={val => numberFormatter.convertGaitScore(val)}
                  />
                  <ParamTableRow
                    data={insessEvent}
                    name="insessRtLast5MinAssistLevel"
                    label="Right Min Assist"
                    formatValue={val => numberFormatter.convertGaitScore(val)}
                  />
                </ParamTable>
              </Grid>
            </Grid>

            <InnerPaperPanelDivider />

            <InnerPaperPanelTitle title="Metrics" />
            <Grid container spacing={8}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <ParamTable>
                  <ParamTableRow
                    data={insessEvent}
                    name="insessLtSwingTimeSec"
                    label="Left Swing Time"
                    formatValue={val => numberFormatter.divideNum(val, 10)}
                  />
                  <ParamTableRow
                    data={insessEvent}
                    name="insessLtStepLenInch"
                    label="Left Step Length"
                    paddingBottom={8}
                    formatValue={val => numberFormatter.divideNum(val, 10, 0)}
                  />
                </ParamTable>
              </Grid>
              <Grid item xs={12} md={6}>
                <ParamTable>
                  <ParamTableRow
                    data={insessEvent}
                    name="insessRtSwingTimeSec"
                    label="Right Swing Time"
                    formatValue={val => numberFormatter.divideNum(val, 10)}
                  />
                  <ParamTableRow
                    data={insessEvent}
                    name="insessRtStepLenInch"
                    label="Right Step Length"
                    paddingBottom={8}
                    formatValue={val => numberFormatter.divideNum(val, 10, 0)}
                  />
                </ParamTable>
              </Grid>
            </Grid>
          </InnerPaperPanel>
        </Grid>
      </Grid>
    </div>
  );
};

WalkingStatGaitPerformance.propTypes = {
  insessEvent: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default WalkingStatGaitPerformance;
