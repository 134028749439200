import {
  CHANGE_NETWORK_SITE,
  CHANGE_NETWORK,
  COMPARE_SHOW,
  DRAW_SITES,
  CLEAR_NETWORK,
} from "constants/NetworkTypes";

export function changeSite(site) {
  return {
    type: CHANGE_NETWORK_SITE,
    site,
  };
}

export function changeNetwork(network) {
  return {
    type: CHANGE_NETWORK,
    network,
  };
}

export function compareShow(compare) {
  return {
    type: COMPARE_SHOW,
    compare,
  };
}

export function drawSites(drawSites) {
  return {
    type: DRAW_SITES,
    drawSites,
  };
}

export function clearNetwork() {
  return {
    type: CLEAR_NETWORK,
  };
}
