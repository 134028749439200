import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import qs from "qs";
import LearningCenterContainer from "./LearningCenterContainer";
import Faqs from "./Faqs";
import TrainingVideos from "./TrainingVideos";
import Documents from "./Documents";

class LearningCenterRoutes extends Component {
  render() {
    const { match, locale } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={() => {
            // Main Index page
            return <LearningCenterContainer locale={locale} />;
          }}
        />
        <Route
          exact
          path={`${match.url}/faq`}
          render={props => {
            // Faqs Page
            return (
              <Faqs
                additionalRequestProps={{
                  index: "all",
                }}
                queryParams={qs.parse(props.location.search, {
                  ignoreQueryPrefix: true,
                })}
              />
            );
          }}
        />
        <Route
          exact
          path={`${match.url}/videos`}
          render={() => {
            // Training Videos page
            return <TrainingVideos locale={locale} />;
          }}
        />
        <Route
          exact
          path={`${match.url}/documents`}
          render={() => {
            // Documents page
            return <Documents locale={locale} />;
          }}
        />
      </Switch>
    );
  }
}

LearningCenterRoutes.propTypes = {
  match: PropTypes.object,
  locale: PropTypes.string,
};

export default LearningCenterRoutes;
