import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import User from "models/bluechip/User";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { setContext } from "actions/pageContextActions";
import { bindActionCreators } from "redux";
import UserDetailsLayout from "./UserDetailsLayout";

class UserDetailsContainer extends Component {
  componentDidMount() {
    this.props.setContext({
      pageTitle: "",
      pageSubTitle: "",
      isFilterOpen: false,
      mobileToolbarHeight: "80px",
      loaded: false,
    });
    this.props.usersActions.find(this.props.id);
  }

  componentDidUpdate() {
    const { user, usersResourceMetadata, pageContext } = this.props;
    if (user && !pageContext.loaded) {
      this.props.setContext({
        pageTitle: `${user.userFirstName} ${user.userLastName}`,
        pageSubTitle: `${
          user.role === "customer_admin" ? "Admin" : "Therapist"
        }`,
        isFilterOpen: false,
        mobileToolbarHeight: "80px",
        path: `/dashboard/users/${user.id}`,
        loaded: true,
      });
    } else if (
      usersResourceMetadata &&
      usersResourceMetadata.findError &&
      !pageContext.loaded
    ) {
      this.props.setContext({
        mobileToolbarHeight: "80px",
        error: true,
        loaded: true,
      });
    }
  }

  render() {
    const { usersResourceMetadata } = this.props;

    // Redirect on 404 error for base user data
    if (
      usersResourceMetadata &&
      usersResourceMetadata.findError &&
      usersResourceMetadata.findError.status === 404
    ) {
      return <Redirect to={{ state: { notFoundError: true } }} />;
    }

    return (
      <UserDetailsLayout
        usersResourceMetadata={usersResourceMetadata}
        id={this.props.id}
        user={this.props.user}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  user: state.resources.users
    ? User.query(state.resources)
        .where({ id: ownProps.id })
        .all()
        .toObjects()[0]
    : null,
  pageContext: state.pageContext,
  usersResourceMetadata: state.jsonApiResources["users"],
});

const mapDispatchToProps = dispatch => ({
  usersActions: jsonAPIReduxActions(dispatch, "users"),
  setContext: bindActionCreators(setContext, dispatch),
});

UserDetailsContainer.propTypes = {
  id: PropTypes.string,
  usersActions: PropTypes.object,
  user: PropTypes.object,
  pageContext: PropTypes.object,
  setContext: PropTypes.func,
  usersResourceMetadata: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailsContainer);
