import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  icon: {
    color: "white",
  },
});

const CreateUserMobileButton = ({ classes, onClick, className }) => {
  return (
    <IconButton onClick={onClick} className={className}>
      <PersonAddIcon className={classes.icon} />
    </IconButton>
  );
};

CreateUserMobileButton.defaultProps = {
  isFilterOpen: false,
};

CreateUserMobileButton.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(CreateUserMobileButton);
