import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { navigate } from "actions/navigateActions";
import { formValueSelector } from "redux-form";

import ResetPasswordPage from "./ResetPasswordPage";
import * as toastActionCreators from "actions/toastActions";
import * as actionCreators from "actions/resetPasswordActions";

class ResetPasswordContainer extends Component {
  onRequestReset = formData =>
    this.props.actions.requestPasswordReset(formData.email);

  onCancel = () => {
    this.props.navigate("/");
  };

  render() {
    const { disabled, isRequestingResetPassword } = this.props;

    return (
      <ResetPasswordPage
        disabled={disabled}
        isSubmitting={isRequestingResetPassword}
        onRequestReset={this.onRequestReset}
        onCancel={this.onCancel}
      />
    );
  }
}

const selector = formValueSelector("resetPasswordForm");
const mapStateToProps = state => ({
  disabled: state.form.resetPasswordForm && !selector(state, "email"),
  error: state.resetPassword.error,
  isRequestingResetPassword: state.resetPassword.isRequestingResetPassword,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
  toastActions: bindActionCreators(toastActionCreators, dispatch),
  navigate: bindActionCreators(navigate, dispatch),
});

ResetPasswordContainer.propTypes = {
  actions: PropTypes.object,
  toastActions: PropTypes.object,
  navigate: PropTypes.func,
  error: PropTypes.object,
  isRequestingResetPassword: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);
