import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";
import PropTypes from "prop-types";
import Policy from "components/Policy";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

import Menu from "components/shared/ui/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    padding: 0,
    width: "48px",
    float: "right",
  },
  iconButton: {
    width: "32px",
    margin: "0px 16px 0px 8px",
    height: "32px",
  },
  icon: {
    color: "#A7B0B9",
    fontSize: "24px",
    verticalAlign: "bottom",
  },
});

class UserActionMenuComponent extends Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event, buttonRef) => {
    if (buttonRef.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleViewDetails = () => {
    this.props.onNavigate(`/dashboard/users/${this.props.user.id}`);
    this.setState({ open: false });
  };

  handleEdit = () => {
    this.props.onEdit();
    this.setState({ open: false });
  };

  handleDelete = () => {
    this.props.onDelete();
    this.setState({ open: false });
  };

  handleStatusUpdate = () => {
    this.props.onStatusUpdate();
    this.setState({ open: false });
  };

  render() {
    const { classes, onStatusUpdateLinkText } = this.props;
    const label = <MoreVertIcon className={classes.icon} />;

    return (
      <List className={classes.root}>
        <li>
          <Menu
            displayMenuOver={true}
            label={label}
            buttonClassName={classes.iconButton}
            onToggle={this.handleToggle}
            onClickAway={this.handleClose}
            open={this.state.open}
            isIconButton
            growPlacement="right top"
            dataTest="userActionMenu"
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => this.handleViewDetails()}
              data-test="viewUserDetails"
            >
              View Details
            </MenuItem>
            <Policy
              action="edit"
              object="user"
              context={{
                user: this.props.user,
              }}
            >
              <Divider />
              <MenuItem
                className={classes.menuItem}
                onClick={() => this.handleEdit()}
                data-test="editUser"
              >
                Edit
              </MenuItem>
              <Policy
                action="delete"
                object="user"
                context={{
                  user: this.props.user,
                }}
              >
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleDelete()}
                  data-test="deleteUser"
                >
                  Delete
                </MenuItem>
                <Divider />
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => this.handleStatusUpdate()}
                >
                  {onStatusUpdateLinkText}
                </MenuItem>
              </Policy>
            </Policy>
          </Menu>
        </li>
      </List>
    );
  }
}

UserActionMenuComponent.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
  onNavigate: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onStatusUpdate: PropTypes.func,
  onStatusUpdateLinkText: PropTypes.string,
};

export { UserActionMenuComponent as UserActionMenuComponentBase };
export default withStyles(styles)(UserActionMenuComponent);
