import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import NetworkGraphPager from "./NetworkGraphPager";

const periodsProperties = {
  allTime: {
    hideButtons: true,
    labelWidth: "50px",
  },
  thisYear: {
    hideButtons: false,
    leftDisabled: 10,
    labelWidth: "112px",
    labelDateFormat: "MMM YYYY",
  },
  thisMonth: {
    hideButtons: false,
    leftDisabled: 12 * 10,
    labelWidth: "150px",
    labelDateFormat: "MMM DD YYYY",
  },
  thisWeek: {
    hideButtons: false,
    leftDisabled: 52 * 10,
    labelWidth: "150px",
    labelDateFormat: "MMM DD YYYY",
  },
  thisQuarter: {
    hideButtons: false,
    leftDisabled: 3 * 10,
    labelWidth: "150px",
    labelDateFormat: "MMM YYYY",
  },
};

const _calculatePagerProps = (period, page, onPagerClick) => {
  const result = {};
  result.rightDisabled = page === 1;

  let startLabel, start_date;
  let endLabel, end_date;
  const momentPeriod = period.replace("this", "").toLowerCase();
  const start = moment()
    .subtract(page - 1, momentPeriod)
    .startOf(momentPeriod);
  const end = moment()
    .subtract(page - 1, momentPeriod)
    .endOf(momentPeriod);

  const periodProperties = periodsProperties[period];

  result.hideButtons = periodProperties.hideButtons;
  result.label = "All Time";
  result.labelWidth = periodProperties.labelWidth;

  if (period !== "allTime") {
    result.leftDisabled = page === periodProperties.leftDisabled;
    startLabel = start.format(periodProperties.labelDateFormat);
    endLabel = end.format(periodProperties.labelDateFormat);
    start_date = start.format("YYYY-MM-DD");
    end_date = end.format("YYYY-MM-DD");
    result.label = `${startLabel} - ${endLabel}`;
  }

  result.onLeftClick = () =>
    onPagerClick({ period, page: page + 1, start_date, end_date });
  result.onRightClick = () =>
    onPagerClick({ period, page: page - 1, start_date, end_date });

  return result;
};

const NetworkTimeLinePager = ({ onPagerClick, page, period, backPeriod, forwardPeriod }) => {
  const pagerProps = _calculatePagerProps(period, page, onPagerClick);
  return <NetworkGraphPager {...pagerProps} backPeriod={backPeriod} forwardPeriod={forwardPeriod}/>;
};

NetworkTimeLinePager.propTypes = {
  onPagerClick: PropTypes.func,
  page: PropTypes.number,
  period: PropTypes.string,
  backPeriod: PropTypes.string,
  forwardPeriod: PropTypes.string,
};

export default NetworkTimeLinePager;
