import { UPDATE_UNREAD_TOTAL } from "constants/NotificationTypes";
import { Actions } from "@blue-chip/core";
import reduxAdapter from "@blue-chip/redux-adapter";
import { getResource, patchResource } from "lib/api";
import { showToast } from "./toastActions";

let resourceActions;

export function updateUnreadTotal(unreadTotal) {
  return {
    type: UPDATE_UNREAD_TOTAL,
    unreadTotal,
  };
}

export function retrieveUnreadNotificationTotal() {
  return async dispatch => {
    try {
      let response = await getResource("notifications", "unread");
      dispatch(updateUnreadTotal(response.total_unread));
    } catch (e) {
      dispatch(showToast("Failed to retrieve Notification unread total"));
    }
  };
}

export function markRead(notification) {
  return async (dispatch, getState) => {
    if (notification.read) {
      return;
    }

    try {
      // Update read status on client immediately
      dispatch(updateUnreadTotal(getState().notification.unreadTotal - 1));
      notification.read = true;
      _updateNotificationResource(dispatch, {
        id: notification.id,
        type: "notifications",
        attributes: notification,
      });

      // Fire and forget
      await patchResource("notifications", notification.id, { read: true });
    } catch (e) {
      dispatch(showToast("Failed to update Notification status"));
    }
  };
}

function _updateNotificationResource(dispatch, notification) {
  if (!resourceActions) {
    resourceActions = Actions.config({
      adapter: reduxAdapter,
      mutator: dispatch,
    });
  }

  resourceActions.updateResource(notification);
}
