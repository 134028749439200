import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/shared/ui/Button/ActionButton";
import ModalDialog from "components/shared/ui/Modal/ModalDialog";
import PatientReduxForm from "./PatientReduxForm";

const PatientModalDialog = ({
  open,
  onClose,
  onPrimaryAction,
  onSubmit,
  isSubmitDisabled,
  submitting,
  primaryActionLabel,
  initialValues,
  fieldData,
  disabled,
  loading,
  patient,
}) => {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      title={`Add Details for Patient #${patient.pilotNum}`}
      loading={loading}
      buttons={[
        <ActionButton
          key="savePatientButton"
          variant="contained"
          color="primary"
          onClick={onPrimaryAction}
          disabled={isSubmitDisabled}
          loading={submitting}
          data-test="savePatientButton"
        >
          {primaryActionLabel}
        </ActionButton>,
      ]}
    >
      <PatientReduxForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        fieldData={fieldData}
        disabled={disabled}
      />
    </ModalDialog>
  );
};

PatientModalDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onPrimaryAction: PropTypes.func,
  primaryActionLabel: PropTypes.string,
  open: PropTypes.bool,
  classes: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  patient: PropTypes.object,
  initialValues: PropTypes.object,
  fieldData: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default PatientModalDialog;
