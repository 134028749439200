import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import SelectField from "components/shared/ui/Field/SelectField";
import MaterialField from "components/shared/ui/Field/MaterialField";
import validate from "validation/Dashboard/sendContactSupportValidation";
import _ from "lodash";

const styles = theme => ({
  submitButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  clearButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  materialField: {
    marginBottom: theme.spacing.unit * 2,
    display: "block",
    width: "100%",
  },
  selectField: {
    display: "inline-block",
    marginRight: "16px",
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
  },
  disabledClearButton: {
    color: "inherit !important",
    backgroundColor: "white !important",
  },
  input: {
    width: "100%",
  },
  label: {
    lineHeight: "14px",
  },
});

const ITEM_HEIGHT = 36;
const selectMenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5,
    },
  },
};

const TO_OPTIONS = [
  {
    label: "EKSO Representative",
    value: "ekso_rep",
  },
  {
    label: "Customer Admin",
    value: "customer_admin",
  },
];

function mapMenuItems(fieldData, name, hasBlank) {
  if (!fieldData || !fieldData[name]) {
    return [];
  }

  const menuItems = fieldData[name].map(item => {
    if (!_.isPlainObject(item)) {
      return {
        label: `${item}`,
        value: `${item}`,
      };
    }

    return {
      label: item.name,
      value: `${item.id}`,
    };
  });

  if (hasBlank) {
    menuItems.unshift({ label: "N/A", value: "N/A" });
  }

  return menuItems;
}

const SupportReduxForm = ({
  handleSubmit,
  classes,
  fieldData,
  disabled,
  eksoSerialNumbers,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        component={MaterialField}
        label="Email"
        readOnly={true}
        className={classes.materialField}
        inputClassName={classes.input}
      />

      <Field
        name="to"
        component={SelectField}
        label="To"
        className={classNames(classes.materialField, classes.selectField)}
        inputClassName={classes.input}
        renderPlaceholderMenuItem={false}
        menuProps={selectMenuProps}
        menuItems={TO_OPTIONS}
        labelClassName={classes.label}
        inputProps={{
          autoFocus: true,
        }}
      />

      <Field
        name="topic"
        component={SelectField}
        label="Topic"
        className={classNames(classes.materialField, classes.selectField)}
        inputClassName={classes.input}
        renderPlaceholderMenuItem={false}
        disabled={disabled}
        menuProps={selectMenuProps}
        menuItems={mapMenuItems(fieldData, "topics", false)}
        labelClassName={classes.label}
        inputProps={{
          autoFocus: true,
        }}
      />
      <Field
        name="subject"
        component={MaterialField}
        label="Subject"
        disabled={disabled}
        className={classes.materialField}
        inputClassName={classes.input}
        labelClassName={classes.label}
        inputProps={{
          maxLength: "70",
        }}
      />
      <Field
        name="message"
        component={MaterialField}
        disabled={disabled}
        multiline={true}
        rowsMax="6"
        label="Message"
        className={classes.materialField}
        inputClassName={classes.input}
        labelClassName={classes.label}
      />
      <Field
        name="eksoSerialNumber"
        component={SelectField}
        label="Ekso Serial Number"
        className={classes.materialField}
        placeholder="Select"
        renderPlaceholderMenuItem={false}
        disabled={disabled}
        menuProps={selectMenuProps}
        menuItems={mapMenuItems(
          { eksoSerialNumbers },
          "eksoSerialNumbers",
          true
        )}
      />
    </form>
  );
};

SupportReduxForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  fieldData: PropTypes.object,
  disabled: PropTypes.bool,
  eksoSerialNumbers: PropTypes.array,
};

export default reduxForm({
  form: "contactSupportReduxForm",
  validate,
})(withStyles(styles)(SupportReduxForm));
