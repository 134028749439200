import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";
import numberFormatter from "lib/numberFormatter";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import classNames from "classnames";
import * as InSessDataTypes from "constants/InSessDataTypes";
import ReactCSSTransitionReplace from "react-css-transition-replace";

const styles = theme => ({
  root: {
    margin: 0,
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "row-reverse",
      flexWrap: "wrap",
      textAlign: "right",
    },
    flexDirection: "row",
    flexWrap: "wrap",
  },
  item: {},
  result: {
    fontFamily: "Noto Sans",
    fontSize: "12px",
    color: "#1D2426",
    fontWeight: 700,
  },
  fastest: {
    color: "rgb(230, 126, 34)",
  },
  transitionContainer: {
    minWidth: "75px",
  },
  editableResult: {
    padding: "2px 2px 2px 4px",
    minHeight: "12px",
    marginRight: "2px",
    minWidth: "0",
    textAlign: "left",
    textTransform: "none",
    "&:hover svg": {
      opacity: 1,
    },
  },
  editIcon: {
    fontSize: "14px",
    verticalAlign: "text-top",
    color: "#3498DB",
    opacity: ".65",
    padding: "0 2px",
  },
});

const InsessResults = ({ classes, session, type, onEdit }) => {
  let insessEventResults = [];
  if (session && session.insessEvents) {
    insessEventResults = _.filter(session.insessEvents, {
      insessDataType: parseInt(type),
    });
  }

  if (!insessEventResults.length) {
    return <div className={classes.root}>-</div>;
  }

  let editable = false;
  let formatValue = val => val;

  if (
    type === InSessDataTypes.TWO_MIN_WALK ||
    type === InSessDataTypes.SIX_MIN_WALK
  ) {
    formatValue = val => `${numberFormatter.divideNum(val, 10)}m`;
    editable = true;
  } else if (type === InSessDataTypes.TEN_METER_WALK) {
    formatValue = val => {
      const reformatedValue = numberFormatter.divideNum(val, 10);
      return numberFormatter.secondsToHMSPlain(reformatedValue);
    };
    editable = true;
  }

  return (
    <div className={classes.root}>
      {insessEventResults.map((insessEvent, index) => {
        const value = insessEvent.insessEventResult
          ? formatValue(insessEvent.insessEventResult)
          : "-";

        return (
          <ReactCSSTransitionReplace
            transitionName="cross-fade"
            key={insessEvent.id}
            className={classes.transitionContainer}
            transitionEnterTimeout={1000}
            transitionLeaveTimeout={1000}
          >
            <div
              key={`${insessEvent.id}_${insessEvent.insessEventResult}`}
              className={classes.item}
            >
              {editable ? (
                <Button
                  id={`insess_${insessEvent.id}`}
                  data-value={`${value}`}
                  onClick={() => onEdit(insessEvent)}
                  className={classNames(
                    classes.result,
                    classes.editableResult,
                    {
                      [classes.fastest]: insessEvent.insessSpeedMode == 1,
                    }
                  )}
                >
                  {value}
                  <EditIcon className={classes.editIcon} />
                </Button>
              ) : (
                <div className={classes.result}>
                  {value}
                  {index == insessEventResults.length - 1 ? null : ", "}
                </div>
              )}
            </div>
          </ReactCSSTransitionReplace>
        );
      })}
    </div>
  );
};

InsessResults.propTypes = {
  session: PropTypes.object.isRequired,
  classes: PropTypes.object,
  type: PropTypes.string,
  onEdit: PropTypes.func,
};

export default withStyles(styles)(InsessResults);
