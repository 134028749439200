import React from "react";
import moment from "moment";

export function formatDate(date, emptyString) {
  if (!date) {
    return emptyString ? emptyString : "No Date Found";
  }

  if (moment(date).isSame(moment(), "day")) {
    return (
      <span>
        Today
        <span className="todayTime">{`, ${moment(date).format(
          "h:mm A"
        )}`}</span>
      </span>
    );
  }

  return moment(date).format("MM-DD-YYYY");
}

export function formatDateFull(date, emptyString) {
  if (!date) {
    return emptyString ? emptyString : "No Date Found";
  }

  return moment(date).format("MMM DD, YYYY H:mm");
}

export function formatDateForApi(date) {
  if (!date) {
    return null;
  }

  return moment(date).format("YYYY-MM-DD");
}

export function formatTime(date) {
  if (!date) {
    return "Invalid Time";
  }

  return moment(date).format("h:mm A");
}

export function parseDate(dateString) {
  if (!dateString) {
    return null;
  }

  return moment(dateString, "YYYY-MM-DD").toISOString();
}

export function parseTime(timeString) {
  if (!timeString) {
    return null;
  }

  return moment(timeString, "h:mm A").toISOString();
}

export function getDateFromPeriodAndPage(period, page) {
  const normalizedPeriod = period.toLowerCase().replace("this", "");
  switch (normalizedPeriod) {
    case "year":
      return moment().subtract(page - 1, "years").endOf("year");
    case "month":
      return moment().subtract(page - 1, "month").endOf("month");
    case "quarter":
      return moment().subtract(page - 1, "quarter").endOf("quarter");
    case "week":
      return moment().subtract(page - 1, "week").endOf("week");
    default:
      return moment().subtract(page - 1, "years").endOf("years");
  }
}

export function getPageFromPeriods (fromPeriod, toPeriod, currentPage) {
  const normalizedToPeriod = toPeriod.toLowerCase().replace("this", "");
  let fromDate = getDateFromPeriodAndPage(fromPeriod, currentPage);
  let current = moment();
  let newPage = 1;

  switch (normalizedToPeriod) {
    case "year": {
      current = current.endOf("years");
      return current > fromDate ? current.diff(fromDate, "years") + 1 : newPage;
    }
    case "month": {
      current = current.endOf("month");
      return current > fromDate ? current.diff(fromDate, "month") + 1 : newPage;
    }
    case "quarter": {
      current = current.endOf("quarter");
      return current > fromDate ? current.diff(fromDate, "quarter") + 2 : newPage;
    }
    case "week": {
      current = current.endOf("week");
      return current > fromDate ? current.diff(fromDate, "week") + 2 : newPage;
    }
    default: {
      return newPage;
    }
  }
};
