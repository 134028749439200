import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "lib/dateUtils";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  date: {
    fontFamily: "Roboto",
    color: "color: rgba(0,0,0,0.87)",
    opacity: 0.5,
    fontSize: "10px",
    fontWeight: 500,
    marginBottom: "8px",
    "& .todayTime": {
      display: "inline !important",
    },
  },
  title: {
    fontFamily: "Roboto",
    color: "color: rgba(0,0,0,0.87)",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: 400,
    margin: 0,
  },
});

const MobileMessageHeader = ({ classes, messageData }) => {
  return (
    <div className={classes.root}>
      <Typography variant="body1" className={classes.date}>
        {formatDate(messageData.createdAt)}
      </Typography>
      <Typography variant="subheading" className={classes.title}>
        {messageData.title}
      </Typography>
    </div>
  );
};

MobileMessageHeader.propTypes = {
  messageData: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(MobileMessageHeader);
