import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Overview from "components/pages/Overview";
import NetworkOverview from "components/pages/NetworkOverview";
import Patients from "components/pages/Patients";
import Activity from "components/pages/Activity";
import Outcomes from "components/pages/Outcomes";
import LearningCenter from "components/pages/LearningCenter";
import ManageUsers from "components/pages/ManageUsers";
import Notifications from "components/pages/Notifications";
import Account from "components/pages/Account";
import AdminSettings from "components/pages/AdminSettings";
import DashboardLayout from "./DashboardLayout";
import { translateLangToLocale } from "lib/localization";
import { convertStringArray } from "lib/commonUtils";
import policyRulesEvaluator from "lib/policyRulesEvaluator";

class DashboardContainer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      match,
      isFilterOpen,
      mobileToolbarHeight,
      user,
      showMyPatientsData,
      currentCustomerNum,
    } = this.props;

    const is_overviewPage = policyRulesEvaluator("view", "showOverview", user);
    return (
      <DashboardLayout
        isFilterOpen={isFilterOpen}
        mobileToolbarHeight={mobileToolbarHeight}
      >
        <Switch>
          <Route
            exact
            path={match.url}
            render={() => <Redirect to={`${match.url}/overview`} />}
          />
          <Route
            key="overview"
            exact
            path={`${match.url}/overview`}
            component={Overview}
          />
          <Route
            key="network"
            exact
            path={`${match.url}/network`}
            component={NetworkOverview}
          />
          <Route
            path={`${match.url}/patients`}
            render={props => (
              <Patients
                {...props}
                user={user}
                startFilter={{
                  my_patients: showMyPatientsData,
                  customer_num: convertStringArray(currentCustomerNum),
                }}
              />
            )}
          />
          <Route
            exact
            path={`${match.url}/activity`}
            render={props => (
              <Activity
                {...props}
                startFilter={{
                  my_patients: showMyPatientsData,
                  customer_num: convertStringArray(currentCustomerNum),
                }}
              />
            )}
          />
          <Route exact path={`${match.url}/outcomes`} component={Outcomes} />
          <Route exact path={`${match.url}/account`} component={Account} />
          <Route
            exact
            path={`${match.url}/settings`}
            component={AdminSettings}
          />
          <Route
            path={`${match.url}/learning`}
            render={props => (
              <LearningCenter
                {...props}
                locale={translateLangToLocale(this.props.lang)}
              />
            )}
          />
          <Route path={`${match.url}/users`} component={ManageUsers} />
          <Route
            exact
            path={`${match.url}/notifications`}
            render={props => (
              <Notifications
                {...props}
                additionalRequestProps={{
                  locale: translateLangToLocale(this.props.lang),
                }}
              />
            )}
          />
          <Redirect to={{ state: { notFoundError: true } }} />
        </Switch>
      </DashboardLayout>
    );
  }
}

DashboardContainer.propTypes = {
  match: PropTypes.object,
  isFilterOpen: PropTypes.bool,
  mobileToolbarHeight: PropTypes.string,
  user: PropTypes.object,
  lang: PropTypes.string,
  showMyPatientsData: PropTypes.bool,
  currentCustomerNum: PropTypes.any,
};

const mapStateToProps = state => ({
  isFilterOpen: state.pageContext.isFilterOpen,
  mobileToolbarHeight: state.pageContext.mobileToolbarHeight,
  user: state.auth.currentUser,
  lang: state.localization.lang,
  showMyPatientsData: state.globalFilter.my_patients,
  currentCustomerNum: state.globalFilter.customer_num,
});

export default connect(mapStateToProps)(DashboardContainer);
