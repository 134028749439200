import EksoOutcomesLayout from "./EksoOutcomesLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";
import * as InSessDataTypes from "constants/InSessDataTypes";

export default EksoGraphContainerWrapper(EksoOutcomesLayout, Stats, {
  resourceTypeName: "stats",
  resourceName: "stats/insess_event",
  id: "insess_event",
  startFilter: {
    type: InSessDataTypes.TWO_MIN_WALK,
  },
});
