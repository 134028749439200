import _ from "lodash";

//Email Regex
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateRequired(fieldNames, values, errors) {
  _.each(fieldNames, fieldName => {
    if (!values[fieldName]) {
      errors[fieldName] = "Required";
    }
  });
}

export function validateEmail(fieldNames, values, errors) {
  _.each(fieldNames, fieldName => {
    if (
      values[fieldName] &&
      !emailRegex.test(String(values[fieldName].trim()).toLowerCase())
    ) {
      errors[fieldName] = "Must be a valid email address";
    }
  });
}

export function validateMax(fieldNames, maxValue, values, errors) {
  _.each(fieldNames, fieldName => {
    if (values[fieldName] && values[fieldName].length > maxValue) {
      errors[fieldName] = `Should be ${maxValue} characters max`;
    }
  });
}
