import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import DetailsPageStatusContent from "components/shared/ui/Page/DetailsPageStatusContent";
import SummaryContent from "./SummaryContent";
import SessionsContent from "./SessionsContent";
import HeaderContainer from "./HeaderContainer";
import PatientModal from "../PatientModal";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
  },
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
});

const PatientDetailsLayout = ({
  classes,
  tab,
  patient,
  patientsResourceMetadata,
  initialMount,
}) => {
  const loading =
    !patientsResourceMetadata ||
    patientsResourceMetadata.isFindingItem ||
    initialMount;
  const error = patientsResourceMetadata && patientsResourceMetadata.findError;
  let statusContent = <DetailsPageStatusContent error={error} />;

  return (
    <div className={classes.root}>
      <HeaderContainer patient={patient} tab={tab} error={!!error} />
      <div className={classes.main}>
        {statusContent}
        <TransitionGroup appear className="patientDetail-content">
          {!error && tab === "summary" ? (
            <CSSTransition
              key="patientSummary"
              timeout={600}
              exit={false}
              classNames="fade"
            >
              <SummaryContent loading={loading} patient={patient} />
            </CSSTransition>
          ) : null}
          {!loading && !error && tab === "sessions" ? (
            <CSSTransition
              key="patientSessions"
              timeout={600}
              exit={false}
              classNames="fade"
            >
              <SessionsContent
                patient={patient}
                requiredFilter={{
                  pilot_num: patient.pilotNum,
                  customer_num: patient.customer,
                }}
              />
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
      <PatientModal />
    </div>
  );
};

PatientDetailsLayout.propTypes = {
  classes: PropTypes.object,
  patient: PropTypes.object,
  patientsResourceMetadata: PropTypes.object,
  id: PropTypes.string,
  tab: PropTypes.string,
  initialMount: PropTypes.bool,
};

export default withStyles(styles)(PatientDetailsLayout);
