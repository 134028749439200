import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Grid } from "@material-ui/core";
import * as selectSiteAction from "actions/networkActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import NetworkUtilizationGraphBase from "components/shared/ui/Graph/NetworkUtilizationGraphBase";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import SitesList from "./SitesList";

class AverageSessionsGraph extends Component {
  constructor(props) {
    super(props);
    const colors = [
      "#f44336",
      "#607D8B",
      "#E91E63",
      "#9E9E9E",
      "#9C27B0",
      "#795548",
      "#673AB7",
      "#FF5722",
      "#3F51B5",
      "#FF9800",
      "#2196F3",
      "#FFC107",
      "#03A9F4",
      "#FFEB3B",
      "#00BCD4",
      "#CDDC39",
      "#009688",
      "#8BC34A"
    ];

    const sitesColors = props.sites.reduce((acc, site, index) => {
      if (props.sites.length > colors.length) {
        return {
          ...acc,
          [site.id]: {
            color: colors[_.random(colors.length - 1)],
          },
        };
      }

      return {
        ...acc,
        [site.id]: {
          color: colors[index],
        },
      };
    }, {});

    const newDrawSites = props.drawSites.map(drawSite => {
      return {
        ...drawSite,
        data: props.sitesConvertedData[drawSite.siteId]
      }
    });

    const newData = [
      {
        data: [...props.convertedData],
        key: 0,
      },
      ...newDrawSites.map(drawSite => ({
        data: drawSite.data,
        key: drawSite.siteId,
      })),
    ];

    this.state = {
      data: newData,
      drawSites: newDrawSites,
      sitesColors,
    };
  }

  _addSiteToGraph(site) {
    const { sitesConvertedData } = this.props;
    const { drawSites, data, sitesColors } = this.state;
    const color = sitesColors[site.id].color;

    const siteData = [...sitesConvertedData[site.id]];
    const newDrawSites = [
      ...drawSites,
      {
        siteId: site.id,
        color,
        data: siteData,
        key: site.id,
      },
    ];

    const newData = [
      ...data,
      {
        key: site.id,
        data: siteData,
      },
    ];
    this.props.siteAction.drawSites(newDrawSites);
    this.setState({
      data: newData,
      drawSites: newDrawSites,
    });
  }

  _removeSiteFromGraph(site) {
    const { convertedData, sitesConvertedData } = this.props;
    const { drawSites } = this.state;
    const newDrawSites = _.reduce(
      drawSites,
      (acc, drawSite) => {
        if (drawSite.siteId !== site.id) {
          return [
            ...acc,
            {
              siteId: drawSite.siteId,
              color: drawSite.color,
              data: [...sitesConvertedData[drawSite.siteId]],
            },
          ];
        }

        return acc;
      },
      []
    );

    const newData = [
      {
        data: [...convertedData],
        key: 0,
      },
      ...newDrawSites.map(drawSite => ({
        data: drawSite.data,
        key: drawSite.siteId,
      })),
    ];

    this.setState({ data: newData, drawSites: newDrawSites });
    this.props.siteAction.drawSites(newDrawSites);
  }

  handleSiteClick = (site, checked) => {
    if (checked) {
      this._addSiteToGraph(site);
    } else {
      this._removeSiteFromGraph(site);
    }
  };

  _getFillColors = () => {
    const { drawSites } = this.state;
    let lineColor = ["#4A90E2"];
    if (!_.isEmpty(drawSites)) {
      _.map(drawSites, item => {
        lineColor.push(item.color);
      });
    }
    return lineColor;
  };

  _renderSites() {
    const { compare, showCompare, sites, site_id } = this.props;
    const { sitesColors, drawSites } = this.state;
    let space = compare ? (compare.show ? 9 : 12) : 12;

    return compare && compare.show ? (
      <SitesList
        space={space}
        showCompare={showCompare}
        sites={sites}
        sitesColors={sitesColors}
        onSiteClick={this.handleSiteClick}
        selectedSiteID={drawSites.map(drawSite => drawSite.siteId)}
        site={site_id}
      />
    ) : null;
  }

  _renderGraph() {
    const { sites, period, page, unitLabel } = this.props;
    const { data } = this.state;
    const fill = _.fill(new Array(_.size(sites) + 1), false);
    fill[0] = true;

    return (
      <NetworkUtilizationGraphBase
        id="AverageSessions"
        data={data}
        emptyMax={1}
        period={period}
        page={page}
        unitLabel={unitLabel}
        tooltipLabelCallback={tooltipItem => {
          let point;
          const dataset = data[tooltipItem.datasetIndex];
          if (Array.isArray(dataset)) {
            point = dataset[tooltipItem.index];
          } else {
            point = dataset.data[tooltipItem.index];
          }

          if (point.label) {
            return point.label;
          }

          return `${tooltipItem.yLabel} ${unitLabel}`;
        }}
        height="410px"
        fillColor={["rgba(1,154,232,0.1)"]}
        fill={fill}
        lineColor={this._getFillColors(this.state.drawSites)}
        pointColor={this._getFillColors(this.state.drawSites)}
      />
    );
  }

  render() {
    const { compare, loading } = this.props;
    const space = compare ? (compare.show ? 9 : 12) : 12;

    return (
      <div>
        <Grid container spacing={8}>
          {this._renderSites()}
          <Grid item xs={space || 12}>
            {loading ? <LoadingPanel /> : this._renderGraph()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

AverageSessionsGraph.propTypes = {
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  sites: PropTypes.array,
  convertedData: PropTypes.object,
  period: PropTypes.string,
  unitLabel: PropTypes.string,
  page: PropTypes.number,
  compare: PropTypes.object,
  classes: PropTypes.object,
  showCompare: PropTypes.func,
  sitesConvertedData: PropTypes.object,
  loading: PropTypes.bool,
  site: PropTypes.array,
  drawSites: PropTypes.array
};

AverageSessionsGraph.defaultProps = {
  unitLabel: "Sessions",
  drawSites: [],
};

const mapStateToProps = state => ({
  drawSites: state.network.drawSites
});

const mapDispatchToProps = dispatch => ({
  siteAction: bindActionCreators(selectSiteAction, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AverageSessionsGraph);
