import EksoDataNetworkTableContainerWrapper from "components/shared/ui/Table/EksoDataNetworkTableContainerWrapper";
import Stats from "models/bluechip/Stats";
import DemographicsTableLayout from "./DemographicsTableLayout";
import { STATS_DEMOGRAPHICS_TABLE } from "constants/NetworkTypes";

export default EksoDataNetworkTableContainerWrapper(DemographicsTableLayout, Stats, {
  resourceTypeName: "table",
  resourceName: STATS_DEMOGRAPHICS_TABLE,
  startSort: { column: "site_name", order: "DESC" },
  id: "network_demographics_table",
  startFilter: {
    period: "allTime"
  },
});
