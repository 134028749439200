import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withStyles, Grid, Divider } from "@material-ui/core";
import { DEMOGRAPHICS_MODAL_ID } from "constants/ModalIds";
import * as modalActionCreators from "actions/modalActions";
import * as networkActionCreators from "actions/networkActions";
import NetworkTimeLinePager from "components/shared/ui/Graph/NetworkTimeLinePager";
import DateQuickFilter from "components/shared/ui/Page/DateQuickFilter";
import DemographicsTable from "../DemographicsTable";
import { translateDatePeriodFilter } from "lib/filterUtils";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { STATS_DEMOGRAPHICS_TABLE } from "constants/NetworkTypes";

const styles = () => ({
  main: {
    padding: "0px",
    margin: "0 0 0 -8px",
    width: "100%",
    boxSizing: "border-box",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  textAlignLeft: {
    textAlign: "left",
  },
});

const menuItems = [
  { value: "allTime", label: "All Time" },
  { value: "thisWeek", label: "Week" },
  { value: "thisMonth", label: "Month" },
  { value: "thisQuarter", label: "Quarter" },
  { value: "thisYear", label: "Year" },
];

class DiagnosticsList extends Component {
  state = {
    clientFilter: {
      period: "allTime",
    },
    page: 1,
  };

  dateQuickFilter = quickFilter => {
    let filter = {
      ...translateDatePeriodFilter(quickFilter.period),
      period: quickFilter.period,
    };
    this.props.getDemographicsTable.get({ filter });
    this.setState({ clientFilter: quickFilter, page: 1 });
  };

  onTimePagerClick = dateFilter => {
    let filter = {
      ...translateDatePeriodFilter(dateFilter.period),
      ...dateFilter,
    };
    this.props.getDemographicsTable.get({ filter });
    this.setState({ clientFilter: filter, page: dateFilter.page });
  };

  render() {
    const { classes } = this.props;

    const periodValue = this.state.clientFilter.period
      ? this.state.clientFilter.period
      : "thisYear";

    const toolbar = (
      <div className={classes.toolbarContent}>
        <Grid container spacing={8}>
          <Grid item xs={4} className={classes.textAlignLeft}>
            <DateQuickFilter
              menuItems={menuItems}
              onQuickFilter={this.dateQuickFilter}
              value={periodValue}
              id="modalQuickDateFilter"
            />
          </Grid>
          <Grid item xs={4} className={classes.textAlignCenter}>
            <NetworkTimeLinePager
              backPeriod="modalBackPeriod"
              forwardPeriod="modalForwardPeriod"
              period={periodValue}
              page={this.state.page}
              onPagerClick={this.onTimePagerClick}
            />
          </Grid>
        </Grid>
      </div>
    );

    return (
      <div className={classes.main}>
        {toolbar}
        <Divider />
        <DemographicsTable data-test="NetworkOverviewDemographicsTable" />
      </div>
    );
  }
}

DiagnosticsList.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  modalActions: PropTypes.object,
  networkActions: PropTypes.object,
  site: PropTypes.object,
  network: PropTypes.object,
  getDemographicsTable: PropTypes.object,
};

const mapStateToProps = state => ({
  open: state.modal[DEMOGRAPHICS_MODAL_ID]
    ? state.modal[DEMOGRAPHICS_MODAL_ID].open
    : false,
  network: state.auth.network,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  networkActions: bindActionCreators(networkActionCreators, dispatch),
  getDemographicsTable: jsonAPIReduxActions(dispatch, STATS_DEMOGRAPHICS_TABLE),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DiagnosticsList));
