import * as validationUtils from "validation/validationUtils";

export default function validate(values) {
  const errors = {};

  validationUtils.validateRequired(
    ["password", "password_confirmation"],
    values,
    errors
  );

  if (values.password !== values.password_confirmation) {
    errors.password_confirmation = "Confirm Password must match New Password";
  }

  return errors;
}
