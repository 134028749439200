import React from "react";
import PropTypes from "prop-types";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import GraphPanelLayout from "./GraphPanelLayout";

const LoadingGraphPanel = ({ hasParams }) => {
  return (
    <GraphPanelLayout
      loading={true}
      paramContent={
        hasParams ? (
          <div>
            <LargeParamField name="load" data={{}} loading={true} />
          </div>
        ) : null
      }
    />
  );
};

LoadingGraphPanel.defaultProps = {
  hasParams: true,
};

LoadingGraphPanel.propTypes = {
  hasParams: PropTypes.bool,
};

export default LoadingGraphPanel;
