import React from "react";
import PropTypes from "prop-types";
import numberFormatter from "lib/numberFormatter";
import { Grid } from "@material-ui/core";
import InnerPaperPanel from "components/shared/ui/Panel/InnerPaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import PanelSubHeader from "components/shared/ui/Panel/PanelSubHeader";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  root: {
    marginTop: "14px",
  },
});

const GaitStats = ({ classes, session }) => {
  return (
    <div className={classes.root}>
      <PanelSubHeader title="GAIT STATS" />
      <InnerPaperPanel>
        <Grid container spacing={8}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ParamTable>
              <ParamTableRow
                data={session}
                name="sessTotUprightTimeSec"
                label="Up Time"
                formatValue={numberFormatter.secondsToHMSPlain}
              />
              <ParamTableRow
                data={session}
                name="sessTotWalkTimeSec"
                label="Walk Time"
                formatValue={numberFormatter.secondsToHMSPlain}
              />
            </ParamTable>
          </Grid>
          <Grid item xs={12} md={6}>
            <ParamTable>
              <ParamTableRow
                classes={{
                  label: classes.leftParamLabels,
                }}
                data={session}
                name="sessTotStepCount"
                label="# Of Steps"
              />
              <ParamTableRow
                classes={{
                  label: classes.leftParamLabels,
                }}
                data={session}
                name="stepsPerMin"
                label="# Steps/Min"
                formatValue={(val, data) =>
                  numberFormatter.stepsPerMin(
                    data,
                    "sessTotStepCount",
                    "sessTotWalkTimeSec"
                  )
                }
              />
            </ParamTable>
          </Grid>
        </Grid>
      </InnerPaperPanel>
    </div>
  );
};

GaitStats.propTypes = {
  session: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(GaitStats);
