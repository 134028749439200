export const CHANGE_NETWORK_SITE = "CHANGE_NETWORK_SITE";
export const CHANGE_NETWORK = "CHANGE_NETWORK";
export const STATS_UTILIZATION = "stats/network_utilization";
export const STATS_DEMOGRAPHICS = "stats/network_demographics";
export const STATS_UTILIZATION_GRAPH = "stats/network_utilization_graph";
export const STATS_SITES_UTILIZATION_GRAPH =
  "stats/network_sites_utilization_graph";
export const STATS_UTILIZATION_TABLE = "stats/network_utilization_table";
export const STATS_MODAL_UTILIZATION_TABLE =
  "stats/network_modal_utilization_graph";
export const STATS_UTILIZATION_MODAL_GRAPH =
  "stats/network_utilization_modal_graph";
export const STATS_UTILIZATION_MODAL = "stats/network_utilization_modal";
export const STATS_DEMOGRAPHICS_TABLE = "stats/network_demographics_table";

export const COMPARE_SHOW = "COMPARE_SHOW";
export const DRAW_SITES = "DRAW_SITES";
export const CLEAR_NETWORK = "CLEAR_NETWORK";
