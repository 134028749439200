import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { navigate } from "actions/navigateActions";
import MobileContentHeader from "./MobileContentHeader";
import { toggleFilter as toggleFilterActionCreator } from "actions/pageContextActions";
import * as modalActionCreators from "actions/modalActions";

class MobileContentHeaderContainer extends Component {
  render() {
    const {
      pageContext,
      onDrawerToggle,
      toggleFilter,
      modalActions,
      onNavigate,
    } = this.props;
    return (
      <MobileContentHeader
        pageContext={pageContext}
        toggleFilter={toggleFilter}
        onDrawerToggle={onDrawerToggle}
        modalActions={modalActions}
        onNavigate={onNavigate}
      />
    );
  }
}

const mapStateToProps = state => ({
  pageContext: state.pageContext,
});

const mapDispatchToProps = dispatch => ({
  toggleFilter: bindActionCreators(toggleFilterActionCreator, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  onNavigate: bindActionCreators(navigate, dispatch),
});

MobileContentHeaderContainer.propTypes = {
  pageContext: PropTypes.object,
  onDrawerToggle: PropTypes.func,
  toggleFilter: PropTypes.func,
  modalActions: PropTypes.object,
  onNavigate: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileContentHeaderContainer);
