import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "components/shared/ui/Modal/ModalDialog";
import NetworkList from "./NetworkList";

const NetworkModalDialog = ({
  open,
  onClose,
}) => {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      title="Network"
    >
      <NetworkList />
    </ModalDialog>
  );
};

NetworkModalDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default NetworkModalDialog;
