import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DropdownLinkField from "components/shared/ui/Field/SelectField";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    marginTop: "-8px",
  },
});

const DEFAULT_MENU_ITEMS = [
  { value: "year", label: "Year" },
  { value: "month", label: "Month" },
  { value: "week", label: "Week" },
];

class GraphPeriodFilter extends Component {
  state = {
    value: this.props.defaultValue ? this.props.defaultValue : "year",
  };

  onChange = event => {
    const value = event.target.value;
    this.setState({ value });
    let filter = { period: value };
    if (value == "all") {
      filter = { period: null };
    }
    filter.page = 1;
    this.props.onQuickFilter(filter);
  };

  render() {
    const { classes, defaultValue, name, menuItems } = this.props;
    return (
      <DropdownLinkField
        menuItems={menuItems || DEFAULT_MENU_ITEMS}
        input={{
          name,
          onChange: this.onChange,
          value: this.state.value,
        }}
        meta={{}}
        labelLeft
        className={classes.root}
        isLinkStyle={false}
        isMiniStyle
        defaultValue={defaultValue}
        menuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
      />
    );
  }
}

GraphPeriodFilter.propTypes = {
  name: PropTypes.string.isRequired,
  onQuickFilter: PropTypes.func,
  defaultValue: PropTypes.string,
  classes: PropTypes.object,
  menuItems: PropTypes.array,
};

export default withStyles(styles)(GraphPeriodFilter);
