import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ClearIcon from "@material-ui/icons/Clear";

import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      color: "white",
      "&:hover": {
        [theme.breakpoints.down("sm")]: {
          backgroundColor: "rgba(255, 255, 255, .05)",
        },
      },
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "13px",
      paddingBottom: "13px",
      borderRadius: 0,
    },
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
    [theme.breakpoints.down("sm")]: {
      opacity: 0.5,
      marginRight: theme.spacing.unit,
    },
  },
});

const FilterButton = ({ toggleFilter, classes, isFilterOpen }) => {
  return (
    <Button
      onClick={toggleFilter}
      color="primary"
      className={classes.root}
      data-test="filterButton"
    >
      {isFilterOpen ? (
        <ClearIcon className={classes.icon} />
      ) : (
        <FilterListIcon className={classes.icon} />
      )}
      {isFilterOpen ? "Close Filters" : "Filters"}
    </Button>
  );
};

FilterButton.defaultProps = {
  isFilterOpen: false,
};

FilterButton.propTypes = {
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(styles)(FilterButton);
