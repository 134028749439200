import React from "react";
import PropTypes from "prop-types";
import NetworkModalDialog from "components/shared/ui/Modal/NetworkModalDialog";
import DiagnosticsList from "./DiagnosticsList";

const DemographicsModalDialog = ({
  open,
  onClose,
}) => {
  return (
    <NetworkModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      title="All Diagnostics"
    >
      <DiagnosticsList />
    </NetworkModalDialog>
  );
};

DemographicsModalDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default DemographicsModalDialog;
