import React, { Component } from "react";
import PropTypes from "prop-types";
import OverviewPage from "./OverviewPage";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setContext } from "actions/pageContextActions";

class OverviewContainer extends Component {
  componentDidMount() {
    this.props.setContext({
      pageTitle: "Overview",
      isFilterOpen: false,
      path: "/dashboard/overview",
      isSearchOpen: false,
    });
  }

  render() {
    return <OverviewPage key="NetworkOverviewPage" />;
  }
}

OverviewContainer.propTypes = {
  setContext: PropTypes.func,
};

const mapDispatchToProps = dispatch => ({
  setContext: bindActionCreators(setContext, dispatch),
});

export default connect(
  null,
  mapDispatchToProps
)(OverviewContainer);
