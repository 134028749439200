import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { navigate } from "actions/navigateActions";
import qs from "qs";

import LoginPage from "./LoginPage";
import * as toastActionCreators from "actions/toastActions";
import * as actionCreators from "actions/loginActions";

class LoginContainer extends Component {
  onLogin = formData => {
    // Parse the "search" (query) params in location
    let query = {};
    if (this.props.location && this.props.location.search) {
      query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }

    return this.props.actions.loginUser(
      formData.email,
      formData.password,
      query
    );
  };

  onForgotPassword = () => {
    this.props.navigate("/resetPassword");
  };

  render() {
    const { isAuthenticating, disabled, loginError } = this.props;

    return (
      <LoginPage
        onLogin={this.onLogin}
        onForgotPassword={this.onForgotPassword}
        isSubmitting={isAuthenticating}
        disabled={disabled}
        hasErrors={loginError}
      />
    );
  }
}

const selector = formValueSelector("loginForm");
const mapStateToProps = state => {
  const { email, password } = selector(state, "email", "password");

  return {
    loginError: state.auth.loginError,
    isAuthenticating: state.auth.isAuthenticating,
    location: state.router.location,
    loginForm: state.form.loginForm,
    disabled: !email || !password,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
  toastActions: bindActionCreators(toastActionCreators, dispatch),
  navigate: bindActionCreators(navigate, dispatch),
});

LoginContainer.propTypes = {
  actions: PropTypes.object,
  toastActions: PropTypes.object,
  isAuthenticating: PropTypes.bool,
  navigate: PropTypes.func,
  loginError: PropTypes.bool,
  location: PropTypes.object,
  disabled: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);
