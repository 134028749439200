import React from "react";
import PropTypes from "prop-types";
import Text from "../Typography/Text";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    display: "inline-block",
    marginRight: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  label: {
    fontSize: "10px",
    fontWeight: 700,
    color: "#1D2426",
    opacity: 0.5,
    margin: 0,
    lineHeight: "11px",
    marginBottom: theme.spacing.unit / 2,
  },
  value: {
    fontSize: "14px",
    color: "#1D2426",
    margin: 0,
    lineHeight: "16px",
  },
});

const ParamField = ({
  data,
  name,
  label,
  formatValue,
  uppercaseLabel,
  classes,
}) => {
  let value = "";
  let formattedLabel = label ? label : "";
  if (formatValue) {
    value = formatValue(data[name], data, name);
  } else if (data[name]) {
    value = `${data[name]}`;
  }

  if (uppercaseLabel) {
    formattedLabel = formattedLabel.toUpperCase();
  }

  return (
    <div className={classes.root}>
      <label htmlFor={name}>
        <Text className={classes.label} fontSize={10}>
          {formattedLabel}
        </Text>
      </label>
      <div id={name}>
        <Text className={classes.value} fontSize={14}>
          {value}
        </Text>
      </div>
    </div>
  );
};

ParamField.defaultProps = {
  uppercaseLabel: true,
  paddingBottom: 0,
};

ParamField.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  formatValue: PropTypes.func,
  uppercaseLabel: PropTypes.bool,
  className: PropTypes.string,
};

export default withStyles(styles)(ParamField);
