import React from "react";
import PropTypes from "prop-types";
import Text from "components/shared/ui/Typography/Text";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import Policy from "components/Policy";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddDetailsMobileButton from "./AddDetailsMobileButton";
import ExportDetailsMobileButton from "./ExportDetailsMobileButton";
import PatientTabs from "../PatientTabs";

const styles = theme => ({
  title: {
    fontSize: "20px",
    fontWeight: 400,
    color: "white",
    paddingTop: "12px",
  },
  titleContent: {
    paddingLeft: "8px",
    paddingTop: "4px",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
  },
  toolbar: {
    display: "flex",
    width: "100%",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    height: "120px",
    flexGrow: 1,
    marginLeft: "-8px",
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: 400,
    margin: "8px 0 0 0",
    whiteSpace: "nowrap",
    width: "160px",
  },
  buttonLabel: {
    position: "absolute",
    top: "8px",
  },
  icon: {
    fontSize: "40px",
    fontWeight: 200,
    color: "white",
  },
  button: {
    height: "42px !important",
    width: "42px !important",
    whiteSpace: "nowrap",
  },
  buttons: {
    marginTop: "8px",
    whiteSpace: "nowrap",
  },
  backButton: {
    "&:hover": {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "rgba(255, 255, 255, .05)",
        borderRadius: 0,
      },
    },
  },
});

const PatientDetailsMobileHeaderLayout = ({
  onNavigate,
  pageContext,
  classes,
  onTabChange,
  onAddDetails,
  onExportSummary,
  patient,
  tab,
}) => {
  return (
    <div
      className={classes.root}
      style={{
        height: pageContext.mobileToolbarHeight,
      }}
    >
      <div className={classes.toolbar}>
        <Button
          className={classes.backButton}
          classes={{ label: classes.buttonLabel }}
          onClick={() => onNavigate("/dashboard/patients")}
        >
          <ChevronLeftIcon className={classes.icon} />
        </Button>
        <div className={classes.titleContent}>
          <Typography variant="title" className={classes.title}>
            {!pageContext.error ? pageContext.pageTitle : "Error"}
          </Typography>
          <Text size="14" className={classes.subTitle}>
            {pageContext.pageSubTitle}
          </Text>
        </div>
        {!pageContext.error ? (
          <div className={classes.buttons}>
            <Policy action="add" object="patient">
              <AddDetailsMobileButton
                className={classes.button}
                onClick={onAddDetails}
              />
            </Policy>
            <ExportDetailsMobileButton
              className={classes.button}
              onClick={onExportSummary}
            />
          </div>
        ) : null}
      </div>
      {!pageContext.error ? (
        <PatientTabs onTabChange={onTabChange} patient={patient} value={tab} />
      ) : null}
    </div>
  );
};

PatientDetailsMobileHeaderLayout.propTypes = {
  onNavigate: PropTypes.func,
  classes: PropTypes.object,
  pageContext: PropTypes.object,
  onTabChange: PropTypes.func,
  onExportSummary: PropTypes.func,
  onAddDetails: PropTypes.func,
  tab: PropTypes.string,
  patient: PropTypes.object,
};

export default withStyles(styles)(PatientDetailsMobileHeaderLayout);
