import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import GraphPanelOverlay from "components/shared/ui/Graph/GraphPanelOverlay";
import { Tooltip } from "react-tippy";
import _ from "lodash";
import { Line } from "react-chartjs-2";
// Required import for zoom and pan
// eslint-disable-next-line
import * as zoom from "components/shared/ui/Graph/plugins/customChart.zoom";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
    "&:active:hover": {
      cursor: "grab",
    },
  },
  tooltip: {
    fontFamily: "Roboto",
    fontSize: "12px",
  },
  graphContent: {
    position: "relative",
  },
  overlayContentDiv: {
    paddingTop: "56px",
  },
});

const STEP_SIZE = 5;

function _roundNearestStep(val) {
  return Math.round(val / STEP_SIZE) * STEP_SIZE;
}

function _getStepFromEvent(chart, event) {
  let step;
  var scaler = chart.scales["x-axis-0"];
  var boundingRect = chart.canvas.getBoundingClientRect();
  var x = event.clientX - (boundingRect.left + scaler.left);
  var xval = (x / scaler.width) * (scaler.max - scaler.min);
  step = _roundNearestStep(xval + scaler.min);

  return step;
}

const MIN_POINTS_SHOWN = {
  xs: 10,
  sm: 15,
};
class WalkingStatsGraph extends Component {
  state = {
    showStepTooltip: false,
    stepTooltipTitle: "View Step",
    firstLoad: true,
  };

  componentDidMount() {
    this.setState({ firstLoad: false });
  }

  _getCurrentStepDataFromEvent(event, chart, stepData) {
    const activePoint = chart.getElementsAtEvent(event)[0];
    let selectedStepData;

    if (activePoint) {
      // Use point to select step
      selectedStepData =
        activePoint._chart.data.datasets[activePoint._datasetIndex].data[
          activePoint._index
        ];
    } else {
      const step = _getStepFromEvent(chart, event);
      selectedStepData = _.find(stepData, { x: step });
    }

    return selectedStepData;
  }

  _onClickGraph = (event, chart, stepData) => {
    const { onStepChange } = this.props;
    const selectedStepData = this._getCurrentStepDataFromEvent(
      event,
      chart,
      stepData
    );

    if (selectedStepData) {
      onStepChange(selectedStepData);
    }
  };

  _onHoverGraph = (event, chart, stepData) => {
    let showStepTooltip = true;
    const selectedStepData = this._getCurrentStepDataFromEvent(
      event,
      chart,
      stepData
    );

    // Determine if tooltip should be shown
    if (selectedStepData) {
      const activePoint = chart.getElementsAtEvent(event)[0];

      // Don't show if currently on a point
      if (activePoint) {
        showStepTooltip = false;
      }

      this.setState({
        showStepTooltip,
        stepTooltipTitle: `View Step ${selectedStepData.x}`,
      });
    } else {
      // Don't show on no match
      this.setState({ showStepTooltip: false });
    }
  };

  render() {
    const {
      graphData,
      classes,
      selectedStepData,
      width,
      isMissingFeature,
    } = this.props;
    let zoomPanEnabled = true;
    const STEP_MAX = 65535;
    const leftData = _.uniqBy(graphData.left, "x").filter(
      data => data.y !== STEP_MAX
    );

    const rightData = _.uniqBy(graphData.right, "x").filter(
      data => data.y !== STEP_MAX
    );

    const labels = graphData.left.map(item => item.x);

    let xMinValue = STEP_SIZE;
    if (labels.length) {
      xMinValue = labels[0];
    }

    let xMaxValue = 100;
    if (labels.length) {
      xMaxValue = labels[labels.length - 1];
    }

    // Set the minimum number of points that must be rendered
    let minPointsShown = null;
    if (isWidthDown("xs", width)) {
      minPointsShown = MIN_POINTS_SHOWN.xs;
    } else if (isWidthDown("sm", width)) {
      minPointsShown = MIN_POINTS_SHOWN.sm;
    } else {
      // Show all points for larger screen sizes by default
      minPointsShown = labels.length;
    }

    if (labels.length < 10) {
      zoomPanEnabled = false;
    }

    const zoomDefaults = {};

    if (minPointsShown && zoomPanEnabled) {
      zoomDefaults.xMin = labels[labels.length - minPointsShown];
    }

    const self = this;
    const options = {
      pan: {
        // Boolean to enable panning
        enabled: zoomPanEnabled,
        mode: "x",
        speed: 5,
        threshold: 10,
        rangeMin: {
          x: parseInt(xMinValue),
        },
        rangeMax: {
          x: parseInt(xMaxValue),
        },
      },
      zoom: {
        enabled: zoomPanEnabled,
        drag: false,
        mode: "x",
        // Custom option:
        defaults: zoomDefaults,
        rangeMin: {
          x: parseInt(xMinValue),
        },
        rangeMax: {
          x: parseInt(xMaxValue),
        },
      },
      onClick: function(event) {
        // in this function "this" was bound to chart
        self._onClickGraph(event, this, leftData);
      },
      onHover: function(event) {
        // in this function "this" was bound to chart
        self._onHoverGraph(event, this, leftData);
      },
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        labels: {
          fontFamily: "Roboto",
          fontColor: "rgba(0,0,0,0.50)",
          fontSize: 10,
          filter: legendItem => legendItem.text !== "selected",
        },
      },
      animation: false,
      tooltips: {
        enabled: !this.state.showStepTooltip,
        displayColors: false,
        callbacks: {
          label: (tooltipItem, data) => {
            let datasetName = "Walking Statistics";
            if (
              leftData[tooltipItem.index].y !== rightData[tooltipItem.index].y
            ) {
              datasetName = data.datasets[tooltipItem.datasetIndex].label;
            }
            return datasetName + " Min Assist: " + tooltipItem.yLabel;
          },
          title: tooltipItem => {
            return "Step " + tooltipItem[0].xLabel;
          },
        },
      },
      scales: {
        xAxes: [
          {
            type: "linear",
            scaleLabel: {
              display: true,
              labelString: "Steps",
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 10,
              fontFamily: "Roboto",
              padding: 0,
            },
            gridLines: {
              drawTicks: false,
            },
            ticks: {
              stepSize: STEP_SIZE,
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 10,
              fontFamily: "Roboto",
              padding: 8,
              autoSkipPadding: 4,
              maxRotation: 0,
              minRotation: 0,
              suggestedMax: 50,
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            scaleLabel: {
              display: true,
              labelString: "Min Assist",
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 10,
              fontFamily: "Roboto",
              padding: 0,
            },
            gridLines: {
              drawTicks: false,
            },
            ticks: {
              suggestedMax: leftData.length
                ? _.maxBy(leftData.concat(rightData), "y").y + 10
                : 100,
              fontColor: "rgba(0,0,0,0.50)",
              fontSize: 8,
              fontFamily: "Roboto",
              padding: 6,
              backdropPaddingX: -10,
            },
          },
        ],
      },
    };

    const leftPointBackgroundColors = Array(leftData.length).fill("#E67E22");
    const rightPointBackgroundColors = Array(leftData.length).fill("#4A90E2");
    const pointRadii = Array(leftData.length).fill(0);

    if (selectedStepData) {
      const selectedIndex = _.findIndex(leftData, { x: selectedStepData.x });
      leftPointBackgroundColors[selectedIndex] = "white";
      rightPointBackgroundColors[selectedIndex] = "white";
      pointRadii[selectedIndex] = 6;
    }

    const chartjsData = {
      options,
      datasets: [
        {
          label: "Left",
          data: leftData,
          backgroundColor: "#E67E22",
          borderColor: "#E67E22",
          fill: false,
          lineTension: 0.1,
          pointRadius: pointRadii,
          pointHitRadius: 6,
          borderWidth: 2,
          spanGaps: true,
          pointBackgroundColor: leftPointBackgroundColors,
          pointBorderColor: "#E67E22",
          pointHoverRadius: 6,
          pointHoverBorderWidth: 1,
          pointHoverBorderColor: "#E67E22",
          pointHoverBackgroundColor: "#E67E22",
        },
        {
          label: "Right",
          data: rightData,
          backgroundColor: "#4A90E2",
          borderColor: "#4A90E2",
          fill: false,
          lineTension: 0.1,
          pointRadius: pointRadii,
          pointHitRadius: 6,
          borderWidth: 2,
          spanGaps: true,
          pointBackgroundColor: rightPointBackgroundColors,
          pointBorderColor: "#4A90E2",
          pointHoverRadius: 6,
          pointHoverBorderWidth: 1,
          pointHoverBorderColor: "#4A90E2",
          pointHoverBackgroundColor: "#4A90E2",
        },
      ],
    };

    let overlay = null;
    if (isMissingFeature) {
      overlay = (
        <GraphPanelOverlay
          classes={{ contentDiv: classes.overlayContentDiv }}
          headerText="Feature available with upgrade"
        />
      );
    }

    return (
      <Tooltip
        // options
        title={this.state.stepTooltipTitle}
        position="bottom"
        followCursor
        open={this.state.showStepTooltip}
        className={classes.tooltip}
      >
        <div className={classes.graphContent}>
          <div
            data-test={"WalkingStats_graph"}
            className={classes.root}
            onMouseOut={() => this.setState({ showStepTooltip: false })}
          >
            <div
              style={{
                position: "relative",
                height: "184px",
              }}
            >
              <Line data={chartjsData} options={options} />
            </div>
          </div>
          {overlay}
        </div>
      </Tooltip>
    );
  }
}

WalkingStatsGraph.propTypes = {
  graphData: PropTypes.object,
  classes: PropTypes.object,
  selectedStepData: PropTypes.object,
  width: PropTypes.string,
  onStepChange: PropTypes.func,
  isMissingFeature: PropTypes.bool,
};

export { WalkingStatsGraph as WalkingStatsGraphBase };

export default withStyles(styles)(withWidth()(WalkingStatsGraph));
