import React from "react";
import PropTypes from "prop-types";
import GraphPanelLayout from "components/shared/ui/Graph/GraphPanelLayout";
import AverageSessionsGraph from "./AverageSessionsGraph";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  label: {
    paddingTop: "8px",
    marginBottom: "8px",
  },
  pointer: {
    cursor: "pointer",
  },
});

const AverageSessionsLayout = ({
  calcData = {},
  resourceMetadata,
  title,
  fieldValue,
  formatValue,
  label,
  convertedData,
  classes,
  unitLabel,
  page,
  onGraphClick,
  periodUnit,
}) => {
  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : "allTime";

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  return (
    <GraphPanelLayout
      title={title}
      loading={loading}
      error={resourceMetadata ? resourceMetadata.fetchError : null}
      graphContent={
        <div className={classes.pointer}>
          <AverageSessionsGraph
            convertedData={convertedData}
            period={period}
            unitLabel={unitLabel}
            page={page}
            onGraphClick={onGraphClick}
          />
        </div>
      }
      paramContent={
        <div>
          {periodUnit != "year" ? (
            <LargeParamField
              id={fieldValue}
              data={calcData.totalAvg ? calcData.totalAvg : {}}
              label={label ? label : "Average"}
              name={fieldValue}
              formatValue={formatValue}
              loading={loading}
              classes={{ label: classes.label }}
              period={periodUnit}
            />
          ) : (
            ""
          )}
        </div>
      }
    />
  );
};

AverageSessionsLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  title: PropTypes.string,
  fieldValue: PropTypes.string,
  label: PropTypes.string,
  formatValue: PropTypes.func,
  convertedData: PropTypes.array,
  unitLabel: PropTypes.string,
  page: PropTypes.number,
  onGraphClick: PropTypes.func,
  periodUnit: PropTypes.string,
};

export default withStyles(styles)(AverageSessionsLayout);
