import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MenuIcon from "@material-ui/icons/Menu";
import { IconButton, Typography, withStyles } from "@material-ui/core";

// eslint-disable-next-line
const styles = theme => ({
  title: {
    fontSize: "20px",
    fontWeight: 500,
    marginLeft: "12px",
    flexGrow: 1,
  },
});

const MobileContentTitle = ({
  onDrawerToggle,
  title,
  secondTitle,
  classes,
}) => {
  return (
    <Fragment>
      <IconButton
        color="inherit"
        aria-label="Open Navigation"
        onClick={onDrawerToggle}
      >
        <MenuIcon />
      </IconButton>
      {secondTitle ? (
        <Typography
          variant="title"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {title} | {secondTitle}
        </Typography>
      ) : (
        <Typography
          variant="title"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {title}
        </Typography>
      )}
    </Fragment>
  );
};

MobileContentTitle.propTypes = {
  onDrawerToggle: PropTypes.func,
  title: PropTypes.node,
  secondTitle: PropTypes.node,
  classes: PropTypes.object,
};

export default withStyles(styles)(MobileContentTitle);
