import React, { Component } from "react";
import PropTypes from "prop-types";
import Session from "models/bluechip/Session";
import SessionExporter from "models/bluechip/SessionExporter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { setContext, toggleFilter } from "actions/pageContextActions";
import { updateGlobalFilter } from "actions/globalFilterActions";
import * as toastActionCreators from "actions/toastActions";

import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import { destroy } from "redux-form";
import { translateDatePeriodFilter } from "lib/filterUtils";
import ActivityPage from "./ActivityPage";
import { deriveTitle } from "lib/stringUtils";
import { api } from "lib/api";
import _ from "lodash";
import qs from "qs";
import moment from "moment";

class ActivityContainer extends Component {
  state = {
    hasCustomFilter: false,
  };

  componentDidMount() {
    this.props.setContext({
      pageTitle: "Sessions",
      onFilter: this.onSubmitFilters,
      filterName: "pulse_sessions",
      isFilterOpen: false,
      path: "/dashboard/activity",
    });

    const params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (params.filter) {
      this._updateGlobalFilter(params.filter);
    }

    if (params.export_id) {
      this._downloadSessionsExport(params.export_id);
    }
  }

  _updateGlobalFilter = filter => {
    const globalFilter = {};

    if ("my_patients" in filter) {
      globalFilter.my_patients =
        new String(filter.my_patients).toLowerCase() === "true";
    }

    globalFilter.customer_num = filter.customer_num;

    this.props.updateGlobalFilter(globalFilter);
  };

  _downloadSessionsExport(export_id) {
    const { sessionExportActions } = this.props;
    sessionExportActions.find(export_id);
  }

  componentDidUpdate(prevProps) {
    const { resourceMetadata } = this.props;
    if (
      resourceMetadata &&
      (!prevProps.resourceMetadata ||
        !_.isEqual(prevProps.resourceMetadata.meta, resourceMetadata.meta))
    ) {
      this.props.setContext({
        pageTitle: deriveTitle(resourceMetadata, "Session"),
        secondTitle: deriveTitle(resourceMetadata, "Step", null, "total_steps"),
      });
    }
    this._didReceiveSessionExport(prevProps);
  }

  _didReceiveSessionExport(prevProps) {
    const params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!params.export_id) return;

    const { sessionExports } = this.props;
    const sessionExport = sessionExports.find(se => params.export_id === se.id);
    const prevSessionExport = prevProps.sessionExports.find(
      se => params.export_id === se.id
    );

    if (!prevSessionExport && sessionExport) {
      window.location.href = sessionExport.url;
    }
  }

  componentWillUnmount() {
    this.props.destroyForm("sessionFilterForm");
  }

  onQuickFilter = quickFilter => {
    this.props.onQuickFilter(quickFilter);
    this._updateGlobalFilter(quickFilter);

    this.setState({ hasCustomFilter: false });
  };

  onSubmitFilters = filter => {
    this.props.onSubmitFilters(filter);
    this._updateGlobalFilter(filter);

    if (!_.isEmpty(filter)) {
      this.setState({ hasCustomFilter: true });
    } else {
      this.setState({ hasCustomFilter: false });
    }
  };

  _validateExportDateRange(clientFilter, adminExport) {
    const { toastActions } = this.props;
    if (!adminExport) {
      if (!clientFilter.start_date || !clientFilter.end_date) {
        toastActions.showToast("Please select date range (Up to 5 years)");
        return false;
      }

      const mStartDate = moment(clientFilter.start_date);
      const mEndDate = moment(clientFilter.end_date);
      if (
        mEndDate.diff(mStartDate, "months") >
        moment.duration(60, "months").asMonths()
      ) {
        toastActions.showToast("Plase select a maximum of 5 years");
        return false;
      }
    }

    return true;
  }

  onExportSummarySubmit = async (adminExport = false) => {
    const { toastActions, clientFilter } = this.props;

    if (!this._validateExportDateRange(clientFilter, adminExport)) return;

    let newWindow = window.open("/generatingExport.html", "_blank");
    try {
      toastActions.showToast("Generating sessions export file...");
      let response = await api("sessions_exports", "post", {
        type: "sessions_exports",
        data: {
          data: {
            attributes: {
              admin_export: adminExport,
              filter: clientFilter,
              order: {
                column: "date",
                order: "DESC",
              },
            },
          },
        },
      });
      const attributes = response.data.attributes;
      toastActions.dismissToast();
      if (attributes.later_export) {
        newWindow.close();
        toastActions.showToast(
          "Export is being processed. You will receive a confirmation email shortly"
        );
      } else {
        newWindow.location.href = attributes.url;
      }
    } catch (error) {
      newWindow.close();
      toastActions.showToast(
        `Error while generating summary report${
          error.message ? ": " + error.message : ""
        }`
      );
    }
  };

  render() {
    if (!this.props.resourceMetadata) {
      return null;
    }

    return (
      <ActivityPage
        sessions={this.props.rows}
        sessionsResourceMetadata={this.props.resourceMetadata}
        loadMore={this.props.loadMore}
        onShow={this.props.onFind}
        toggleFilter={this.props.toggleFilter}
        isFilterOpen={this.props.isFilterOpen}
        onQuickFilter={this.onQuickFilter}
        hasCustomFilter={this.state.hasCustomFilter}
        onSortChange={this.props.onSortChange}
        onExportSummarySubmit={this.onExportSummarySubmit}
        clientFilter={this.props.clientFilter}
        menusOpen={this.props.menusOpen}
        currentUser={this.props.currentUser}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFilterOpen: state.pageContext.isFilterOpen,
  menusOpen: state.menu.menusOpen,
  currentUser: state.auth.currentUser,
  sessionExports: SessionExporter.query(state.resources).toObjects(),
});

const mapDispatchToProps = dispatch => ({
  setContext: bindActionCreators(setContext, dispatch),
  toggleFilter: bindActionCreators(toggleFilter, dispatch),
  updateGlobalFilter: bindActionCreators(updateGlobalFilter, dispatch),
  destroyForm: bindActionCreators(destroy, dispatch),
  toastActions: bindActionCreators(toastActionCreators, dispatch),
  sessionExportActions: jsonAPIReduxActions(dispatch, "sessions_exports"),
});

ActivityContainer.propTypes = {
  setContext: PropTypes.func,
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  resourceMetadata: PropTypes.object,
  resourceActions: PropTypes.object,
  toastActions: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onSubmitFilters: PropTypes.func,
  onFind: PropTypes.func,
  destroyForm: PropTypes.func,
  clientFilter: PropTypes.object,
  menusOpen: PropTypes.bool,
  updateGlobalFilter: PropTypes.func,
  location: PropTypes.object,
  currentUser: PropTypes.object,
  sessionExportActions: PropTypes.object,
  sessionExports: PropTypes.array,
};

const ActivityContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivityContainer);

export default EksoDataTableContainerWrapper(
  ActivityContainerConnected,
  Session,
  {
    resourceTypeName: "sessions",
    resourceName: "pulse_sessions",
    startSort: { column: "date", order: "DESC" },
    includes: ["insessEvents"],
    filterTranslations: {
      period: translateDatePeriodFilter,
    },
  }
);
