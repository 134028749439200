import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Text from "../Typography/Text";
import { withStyles } from "@material-ui/core/styles";
import Skeleton from "react-loading-skeleton";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    display: "inline-block",
  },
  labelValue: {
    margin: "0",
  },
  label: {
    fontWeight: 700,
    color: "rgba(52,73,94,0.435)",
    margin: 0,
    lineHeight: "14px",
    marginBottom: "8px",
  },
  value: {
    color: "rgba(52,73,94,0.87)",
    margin: 0,
    fontWeight: 400,
    display: "inline-block",
  },
  delta: {
    color: "rgba(52,73,94,0.44)",
    lineHeight: "19px",
    fontWeight: 500,
    display: "inline",
    marginLeft: "16px",
  },
  negativeDelta: {
    color: "#D0021B",
  },
  positiveDelta: {
    color: "rgba(46,204,113,0.87)",
  },
  titleLabel: {},
  colorLabel: {},
});

const LargeParamField = ({
  id,
  data,
  name,
  label,
  formatValue,
  uppercaseLabel,
  classes,
  delta,
  loading,
  onClick,
  period,
}) => {
  let value = "";
  let formattedLabel = label ? label : "";
  const derivedId = id ? id : name;
  let deltaString = "";

  if (!loading && data) {
    if (formatValue) {
      value = formatValue(data[name], data, name);
    } else if (data[name] !== undefined) {
      value = `${data[name]}`;
    } else {
      value = data.count;
    }

    if (uppercaseLabel) {
      formattedLabel = formattedLabel.toUpperCase();
    }

    if (delta) {
      deltaString = delta >= 0 ? `+${delta}` : `${delta}`;
    }

    if (period) {
      value += ` / ${period}`;
    }
  }

  return (
    <div
      className={classNames(classes.root, classes.value)}
      data-test={`${derivedId}_largeParam`}
      onClick={onClick}
    >
      <label htmlFor={derivedId}>
        <Text
          className={classNames(classes.label, classes.titleLabel)}
          fontSize={12}
        >
          {loading ? <Skeleton width={72} /> : formattedLabel}
        </Text>
      </label>
      <div id={derivedId}>
        <Text
          fontSize={24}
          className={classNames(classes.labelValue, classes.colorLabel)}
        >
          {loading ? <Skeleton width={60} /> : value}
        </Text>
        {delta !== undefined ? (
          <Text
            className={classNames(classes.delta, {
              [classes.positiveDelta]: delta >= 0,
              [classes.negativeDelta]: delta < 0,
            })}
            fontSize={16}
          >
            {loading ? <Skeleton width={60} /> : deltaString}
          </Text>
        ) : null}
      </div>
    </div>
  );
};

LargeParamField.defaultProps = {
  uppercaseLabel: true,
};

LargeParamField.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  data: PropTypes.object.isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  formatValue: PropTypes.func,
  uppercaseLabel: PropTypes.bool,
  className: PropTypes.string,
  delta: PropTypes.number,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  period: PropTypes.string,
};

export default withStyles(styles)(LargeParamField);
