import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import MaterialField from "components/shared/ui/Field/MaterialField";
import ActionButton from "components/shared/ui/Button/ActionButton";
import validate from "validation/Login/updatePasswordValidation";

const styles = theme => ({
  secondaryButton: {
    marginRight: "16px",
  },
  buttonBar: {
    marginTop: theme.spacing.unit,
    textAlign: "right",
  },
  materialField: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "inline-block",
  },
  input: {
    width: "100%",
  },
});

class UpdatePasswordForm extends Component {
  render() {
    const { onCancel, classes, disabled, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="password"
          component={MaterialField}
          label="Password"
          className={classes.materialField}
          inputClassName={classes.input}
          inputProps={{
            type: "password",
            autoComplete: "new-password",
          }}
        />
        <Field
          name="password_confirmation"
          component={MaterialField}
          label="Confirm Password"
          className={classes.materialField}
          inputClassName={classes.input}
          inputProps={{
            type: "password",
            autoComplete: "new-password",
          }}
        />
        <div className={classes.buttonBar}>
          <Button
            color="primary"
            onClick={onCancel}
            className={classes.secondaryButton}
            data-test="cancelButton"
          >
            Cancel
          </Button>
          <ActionButton
            variant="contained"
            disabled={disabled}
            loading={this.props.isSubmitting}
            onClick={handleSubmit}
            color="primary"
            type="submit"
            style={styles.primaryButton}
            data-test="sendButton"
          >
            Update Password
          </ActionButton>
        </div>
      </form>
    );
  }
}

UpdatePasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  onCancel: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default reduxForm({
  form: "updatePasswordForm",
  validate,
})(withStyles(styles)(UpdatePasswordForm));
