import React, { Component } from "react";
import PropTypes from "prop-types";
import Faq from "models/bluechip/Faq";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setContext } from "actions/pageContextActions";
import { push } from "connected-react-router";
import { formValueSelector, destroy, initialize } from "redux-form";
import * as modalActionCreators from "actions/modalActions";
import FaqsPage from "./FaqsPage";
import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import _ from "lodash";

class FaqsContainer extends Component {
  state = {
    search: null,
    category: null,
  };

  componentDidMount() {
    this.props.setContext({
      pageTitle: "FAQ",
      isFilterOpen: false,
      path: "/dashboard/learning/faq",
      onSearch: this.onSearch,
      isSearchOpen: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.resourceMetadata && this.props.resourceMetadata.initiated) {
      this.props.initializeForm("faqSearch", {
        search: this.props.resourceMetadata.filter.search,
      });
    }
  }

  onSearch = formData => {
    let filter = formData;
    if (!formData.search) {
      filter = { search: null };
    }

    this.onQuickFilter(filter);
  };

  onQuickFilter = formData => {
    // Not re-calling to the server, handling client-side
    let filter = formData;

    const { clientFilter, addQueryParams } = this.props;

    filter = _.extend({}, clientFilter, filter);
    addQueryParams({ filter });
  };

  componentWillUnmount() {
    this.props.destroyForm("faqSearch");
  }

  _applyFilter = faqs => {
    if (!faqs || !faqs.length) {
      return faqs;
    }

    const { search, category } = this.props.clientFilter;

    let filteredFaqs = faqs;

    if (search) {
      filteredFaqs = _.filter(filteredFaqs, item => {
        return (
          item.question.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          item.answer.toLowerCase().indexOf(search.toLowerCase()) > -1
        );
      });
    }

    if (category) {
      filteredFaqs = _.filter(filteredFaqs, item => {
        return (
          (item.faqCategory && item.faqCategory.id === category) ||
          (!item.faqCategory && category === "NOT_SET")
        );
      });
    }

    return filteredFaqs;
  };

  render() {
    if (
      !this.props.resourceMetadata ||
      !this.props.resourceMetadata.initiated
    ) {
      return null;
    }

    const categoryMenuItems = _.uniqBy(this.props.rows, "faqCategory.id").map(
      faq => ({
        value: faq.faqCategory ? faq.faqCategory.id : "NOT_SET",
        label: faq.faqCategory ? faq.faqCategory.title : "No Category",
      })
    );
    categoryMenuItems.unshift({ value: "", label: "All Categories" });

    const filteredFaqs = this._applyFilter(this.props.rows);

    return (
      <FaqsPage
        faqs={filteredFaqs}
        faqsResourceMetadata={this.props.resourceMetadata}
        onQuickFilter={this.onQuickFilter}
        onSearch={this.onSearch}
        currentSearch={this.props.clientFilter.search}
        currentCategory={this.props.clientFilter.category}
        openFaqId={this.props.clientFilter.id}
        categoryMenuItems={categoryMenuItems}
        onNavigate={this.props.push}
      />
    );
  }
}

const selector = formValueSelector("faqSearch");

const mapStateToProps = state => ({
  currentSearch: selector(state, "search"),
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  setContext: bindActionCreators(setContext, dispatch),
  destroyForm: bindActionCreators(destroy, dispatch),
  initializeForm: bindActionCreators(initialize, dispatch),
  push: bindActionCreators(push, dispatch),
});

FaqsContainer.propTypes = {
  setContext: PropTypes.func,
  currentSearch: PropTypes.string,
  resourceMetadata: PropTypes.object,
  resourceActions: PropTypes.object,
  rows: PropTypes.array,
  clientFilter: PropTypes.object,
  onQuickFilter: PropTypes.func,
  destroyForm: PropTypes.func,
  initializeForm: PropTypes.func,
  addQueryParams: PropTypes.func,
  push: PropTypes.func,
};

const FaqsContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqsContainer);

export default EksoDataTableContainerWrapper(FaqsContainerConnected, Faq, {
  resourceTypeName: "faqs",
  resourceName: "faq_questions",
  includes: ["faqCategory"],
});
