import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import { List, Hidden } from "@material-ui/core";
import PropTypes from "prop-types";
import Policy from "components/Policy";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import NavigationDivider from "./NavigationDivider";
import Menu from "components/shared/ui/Menu";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { currentUserToken } from "utils/login";

const styles = theme => ({
  root: {
    padding: 0,
    backgroundColor: "#34495E",
  },
  button: {
    height: "48px",
    fontSize: "12px",
    width: "100%",
    color: "white",
    backgroundColor: "#34495E",
    borderRadius: 0,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      height: "40px",
      justifyContent: "flex-start",
      paddingBottom: "20px",
      textAlign: "left !important",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, .05)",
    },
  },
  icon: {
    color: "white",
    opacity: 0.5,
    fontSize: "20px",
    verticalAlign: "bottom",
    marginLeft: "29px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "8px",
    },
  },
  menuItem: {
    fontSize: "14px",
    padding: "12px 16px",
  },
});

class UserMenu extends Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event, buttonRef) => {
    if (buttonRef.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  handleMenuClick = path => {
    if (path) {
      this.props.onNavigate(path);
    }
    this.setState({ open: false });
  };

  adminClick = e => {
    e.preventDefault();
    e.currentTarget.parentNode.submit();
  };

  render() {
    const token = currentUserToken();
    const { user, classes, width, onLogout } = this.props;
    const label = (
      <span>
        {user.fullName()} <ExpandMore className={classes.icon} />
      </span>
    );
    let displayMenuOver = false;
    if (isWidthDown("sm", width)) {
      displayMenuOver = true;
    }

    return (
      <List className={classes.root}>
        <Hidden smDown>
          <li>
            <NavigationDivider />
          </li>
        </Hidden>
        <li>
          <Menu
            displayMenuOver={displayMenuOver}
            label={label}
            buttonClassName={classes.button}
            onToggle={this.handleToggle}
            onClickAway={this.handleClose}
            open={this.state.open}
            dataTest="userMenu"
          >
            <MenuItem
              className={classes.menuItem}
              data-test="myAccountButton"
              onClick={() => this.handleMenuClick("/dashboard/account")}
            >
              My Account
            </MenuItem>
            <Policy action="view" object="settings">
              <MenuItem
                className={classes.menuItem}
                data-test="settingsButton"
                onClick={() => this.handleMenuClick("/dashboard/settings")}
              >
                Settings
              </MenuItem>
            </Policy>
            <Policy action="view" object="adminMenu">
              <form
                action={`${process.env.ADMIN_URL}/sso`}
                method="post"
                target="_blank"
              >
                <input type="hidden" name="token" value={token} />
                <MenuItem
                  className={classes.menuItem}
                  component="a"
                  onClick={e => this.adminClick(e)}
                >
                  Admin Site
                </MenuItem>
              </form>
            </Policy>
            <Divider />
            <MenuItem
              className={classes.menuItem}
              onClick={onLogout}
              data-test="logoutButton"
            >
              Log Out
            </MenuItem>
          </Menu>
        </li>
      </List>
    );
  }
}

UserMenu.propTypes = {
  user: PropTypes.object,
  width: PropTypes.string.isRequired,
  classes: PropTypes.object,
  onLogout: PropTypes.func,
  onNavigate: PropTypes.func,
};

export { UserMenu as UserMenuBase };
export default withWidth()(withStyles(styles)(UserMenu));
