import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Hidden } from "@material-ui/core";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import OverviewMobileTabs from "../OverviewMobileTabs";
import MyUtilizationLayout from "./MyUtilizationLayout";
import RecentSessions from "../RecentSessions";

const TherapistContentLayout = ({ resourceMetadata, calcData }) => {
  const utilization = (
    <MyUtilizationLayout
      resourceMetadata={resourceMetadata}
      calcData={calcData}
    />
  );
  const recentSessions = <RecentSessions key="recentSessions" />;

  return (
    <Fragment>
      <Hidden smDown>
        <Grid item xs={12} md={6}>
          <PaperPanel>{recentSessions}</PaperPanel>
        </Grid>
        <Grid item xs={12} md={6}>
          <PaperPanel>{utilization}</PaperPanel>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <OverviewMobileTabs
          recentSessionsTable={recentSessions}
          utilization={utilization}
          utilizationTabLabel="My Utilization"
        />
      </Hidden>
    </Fragment>
  );
};

TherapistContentLayout.defaultProps = {};

TherapistContentLayout.propTypes = {
  resourceMetadata: PropTypes.object,
  calcData: PropTypes.object,
};

export default TherapistContentLayout;
