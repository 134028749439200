import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleFilter } from "actions/pageContextActions";
import FilterDrawerLayout from "./FilterDrawerLayout";
import SessionFilterForm from "components/pages/Activity/SessionFilterForm";
import PatientFilterForm from "components/pages/Patients/PatientFilterForm";

const filterForms = {
  pulse_sessions: SessionFilterForm,
  patients: PatientFilterForm,
};

class FilterDrawerContainer extends Component {
  renderFilterForm = () => {
    const { filterName, onFilter } = this.props.pageContext;
    if (!filterName) {
      return null;
    }

    const FilterForm = filterForms[filterName];
    return <FilterForm onSubmit={onFilter} />;
  };

  render() {
    const { toggleFilter, pageContext } = this.props;

    return (
      <FilterDrawerLayout
        toggleFilter={toggleFilter}
        isFilterOpen={pageContext.isFilterOpen}
      >
        {this.renderFilterForm()}
      </FilterDrawerLayout>
    );
  }
}

const mapStateToProps = state => ({
  pageContext: state.pageContext,
});

const mapDispatchToProps = dispatch => ({
  toggleFilter: bindActionCreators(toggleFilter, dispatch),
});

FilterDrawerContainer.propTypes = {
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  onFilter: PropTypes.func,
  pageContext: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterDrawerContainer);
