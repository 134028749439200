import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
  },
  title: {
    color: "#4A4A4A",
    fontWeight: 700,
    fontSize: "16px",
    paddingLeft: "12px",
  },
  clickable: {
    cursor: "pointer",
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "12px",
  },
  iconButton: {
    margin: "0 0 0 8px",
    width: "32px",
    height: "32px",
  },
  button: {
    paddingLeft: "8px",
    paddingRight: "8px",
    padding: "8px",
    minWidth: "0px",
    minHeight: "0px",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "4px",
    [theme.breakpoints.down("sm")]: {
      flexGrow: 0,
      display: "none",
    },
  },
  invisible: {
    display: "none",
  },
  invisibleOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class BasicPageHeader extends Component {
  render() {
    const { classes, onNavigate, backPath, title } = this.props;
    let titleContent = (
      <span
        className={classNames(classes.title, {
          [classes.clickable]: !!backPath,
        })}
        onClick={() => (backPath ? onNavigate(backPath) : null)}
      >
        {title}
      </span>
    );

    return (
      <AppBar
        position="static"
        className={classNames(classes.root, classes.invisibleOnMobile)}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="title" color="inherit" className={classes.grow}>
            {backPath ? (
              <IconButton
                color="primary"
                className={classes.iconButton}
                onClick={() => onNavigate(backPath)}
              >
                <ChevronLeftIcon />
              </IconButton>
            ) : null}
            {titleContent}
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }
}

BasicPageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  onNavigate: PropTypes.func,
  backPath: PropTypes.string,
  title: PropTypes.node,
};

export default withStyles(styles)(BasicPageHeader);
