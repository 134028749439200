import React, { Component } from "react";
import PropTypes from "prop-types";
import WalkingStatsLayout from "./WalkingStatsLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";

class WalkingStatsContainer extends Component {
  state = {
    selectedStepData: null,
  };

  componentDidMount() {
    const { calcData } = this.props;
    if (
      this.state.selectedStepData === null &&
      calcData &&
      calcData.data &&
      calcData.data.left
    ) {
      const selectedStepData =
        calcData.data.left[calcData.data.left.length - 1];
      this.setState({ selectedStepData });
    }
  }

  componentDidUpdate(prevProps) {
    const { calcData, resourceMetadata } = this.props;
    const wasLoading =
      prevProps.resourceMetadata && prevProps.resourceMetadata.isFetchingItems;

    if (
      this.state.selectedStepData === null &&
      wasLoading &&
      resourceMetadata.isFetchItemsSuccess &&
      calcData &&
      calcData.data &&
      calcData.data.left
    ) {
      const selectedStepData =
        calcData.data.left[calcData.data.left.length - 1];
      this.setState({ selectedStepData });
    }
  }

  onStepChange = selectedStepData => {
    // Retrieve point information
    this.setState({ selectedStepData });
  };

  render() {
    const { resourceMetadata, calcData } = this.props;
    return (
      <WalkingStatsLayout
        loadingPoint={false}
        isMissingFeature={calcData ? !calcData.hasInsessFeature : false}
        resourceMetadata={resourceMetadata}
        graphData={calcData ? calcData.data : null}
        onStepChange={this.onStepChange}
        selectedStepData={this.state.selectedStepData}
      />
    );
  }
}

WalkingStatsContainer.propTypes = {
  resourceMetadata: PropTypes.object,
  calcData: PropTypes.object,
  session: PropTypes.object,
};

export default EksoGraphContainerWrapper(WalkingStatsContainer, Stats, {
  resourceTypeName: "stats",
  resourceName: "stats/insess_event",
  skipMountClear: true,
});
