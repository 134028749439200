import React from "react";
import AssistanceLayout from "./Assistance/AssistanceLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";

export default EksoGraphContainerWrapper(
  props => (
    <AssistanceLayout
      id="swingAssistance"
      title={"Swing Assistance"}
      unit="Swing Assist"
      {...props}
    />
  ),
  Stats,
  {
    resourceTypeName: "stats",
    resourceName: "stats/swing_assistance",
    id: "swing_assistance",
    startFilter: {
      period: "last",
      page: 1,
    },
  }
);
