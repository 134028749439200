import React from "react";
import PropTypes from "prop-types";
import CheckboxSelectField from "components/shared/ui/Field/CheckboxSelectField";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    display: "flex",
  },
});

class IndicationCheckboxSelect extends React.Component {
  onChange = value => {
    let filterValue = value;
    if (value && value.length === this.props.indications.length) {
      filterValue = null;
    } else if (value.length === 0) {
      filterValue = ["NO_MATCH"];
    }

    this.props.onQuickFilter({
      indication_ids: filterValue,
    });
  };

  render() {
    const { classes, indications, value } = this.props;
    const isNoMatch = value && value.length && value.includes("NO_MATCH");
    let translatedValue = isNoMatch ? [] : value;
    translatedValue = translatedValue === "" ? null : translatedValue;

    return (
      <CheckboxSelectField
        className={classes.root}
        allItemsLabel="All Indications"
        menuItems={indications.map(item => {
          return {
            label: item.name,
            value: `${item.id}`,
          };
        })}
        input={{
          value: translatedValue,
          onChange: this.onChange,
          name: "indicationQuickFilter",
        }}
        meta={{}}
        isLinkStyle
        labelLeft
      />
    );
  }
}

IndicationCheckboxSelect.propTypes = {
  onQuickFilter: PropTypes.func,
  className: PropTypes.string,
  indications: PropTypes.array,
  value: PropTypes.array,
  classes: PropTypes.object,
};

export default withStyles(styles)(IndicationCheckboxSelect);
