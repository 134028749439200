import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setContext } from "actions/pageContextActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import * as modalActionCreators from "actions/modalActions";
import LearningCenterPage from "./LearningCenterPage";
import Faq from "models/bluechip/Faq";
import LearningResourceDocument from "models/bluechip/LearningResourceDocument";
import LearningResourceVideo from "models/bluechip/LearningResourceVideo";
import { VIDEO_MODAL_ID } from "constants/ModalIds";
import _ from "lodash";

class LearningCenterContainer extends Component {
  componentDidMount() {
    this.props.setContext({
      pageTitle: "Learning Center",
      isFilterOpen: false,
      path: "/dashboard/learning",
      isSearchOpen: false,
    });

    this.props.resourceActions.clear([
      "learningResourceDocuments",
      "learningResourceVideos",
    ]);
    this.props.resourceActions.init({
      firstLoad: true,
    });

    this.props.faqsResourceActions.clear(["faqs"]);
    this.props.faqsResourceActions.init({
      firstLoad: true,
    });
  }

  componentDidUpdate() {
    // Learning resource initial request
    if (
      !this.props.resourceMetadata.isFetchingItems &&
      this.props.resourceMetadata.firstLoad
    ) {
      this.props.resourceActions.get({});
    }

    // Faqs initial request
    if (
      !this.props.faqsResourceMetadata.isFetchingItems &&
      this.props.faqsResourceMetadata.firstLoad
    ) {
      this.props.faqsResourceActions.get({});
    }
  }

  _createCategoryItems() {
    const { faqItems } = this.props;
    if (!faqItems || !faqItems.length) {
      return [];
    }
    const categoryItems = [];
    faqItems.forEach(faqItem => {
      if (!faqItem.faqCategory) {
        faqItem.faqCategory = {
          id: "NOT_SET",
          title: "No Category",
        };
      }

      const categoryItem = _.find(categoryItems, {
        faqCategory: faqItem.faqCategory,
      });
      if (!categoryItem) {
        categoryItems.push({
          id: faqItem.faqCategory.id,
          type: "faqCategory",
          faqCategory: faqItem.faqCategory,
          questions: [{ id: faqItem.id, question: faqItem.question }],
        });
      } else {
        categoryItem.questions.push({
          id: faqItem.id,
          question: faqItem.question,
        });
      }
    });

    return categoryItems;
  }

  onPlayVideo = videoData => {
    this.props.modalActions.openModalWithData(VIDEO_MODAL_ID, videoData);
  };

  render() {
    const {
      push,
      trainingVideoItems,
      documentItems,
      resourceMetadata,
      faqsResourceMetadata,
    } = this.props;
    const categoryItems = this._createCategoryItems();
    return (
      <LearningCenterPage
        onNavigate={push}
        trainingVideoItems={trainingVideoItems}
        categoryItems={categoryItems}
        documentItems={documentItems}
        onPlayVideo={this.onPlayVideo}
        loading={
          resourceMetadata.isFetchingItems ||
          faqsResourceMetadata.isFetchingItems
        }
        error={resourceMetadata.fetchError || faqsResourceMetadata.fetchError}
      />
    );
  }
}

const mapStateToProps = state => ({
  resourceMetadata: state.jsonApiResources.learning_resources
    ? state.jsonApiResources.learning_resources
    : {},
  faqsResourceMetadata: state.jsonApiResources.faq_questions
    ? state.jsonApiResources.faq_questions
    : {},
  documentItems: state.resources.learningResourceDocuments
    ? LearningResourceDocument.query(state.resources)
        .all()
        .toObjects()
    : [],
  faqItems: state.resources.faqs
    ? Faq.query(state.resources)
        .includes(["faqCategory"])
        .all()
        .toObjects()
    : [],
  trainingVideoItems: state.resources.learningResourceVideos
    ? LearningResourceVideo.query(state.resources)
        .all()
        .toObjects()
    : [],
});

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  resourceActions: jsonAPIReduxActions(dispatch, "learning_resources"),
  faqsResourceActions: jsonAPIReduxActions(dispatch, "faq_questions"),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  setContext: bindActionCreators(setContext, dispatch),
});

LearningCenterContainer.propTypes = {
  push: PropTypes.func,
  resourceActions: PropTypes.object,
  resourceMetadata: PropTypes.object,
  trainingVideoItems: PropTypes.array,
  documentItems: PropTypes.array,
  faqItems: PropTypes.array,
  faqsResourceActions: PropTypes.object,
  faqsResourceMetadata: PropTypes.object,
  modalActions: PropTypes.object,
  setContext: PropTypes.func,
  lang: PropTypes.string,
  locale: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningCenterContainer);
