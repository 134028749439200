import React from "react";
import PropTypes from "prop-types";
import GraphPanelLayout from "components/shared/ui/Graph/GraphPanelLayout";
import EksoOutcomesGraph from "./EksoOutcomesGraph";
import InSessDataTypeFilter from "./InSessDataTypeFilter";
import * as InSessDataTypes from "constants/InSessDataTypes";

const EksoOutcomesLayout = ({
  onQuickFilter,
  calcData = {},
  resourceMetadata,
  isMissingFeature,
}) => {
  const type =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.type
      : InSessDataTypes.TWO_MIN_WALK;

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  return (
    <GraphPanelLayout
      title="Ekso Outcomes"
      headerTools={
        !isMissingFeature ? (
          <InSessDataTypeFilter
            name="insessDataTypeFilter"
            onQuickFilter={onQuickFilter}
            defaultValue={type}
          />
        ) : null
      }
      showOverlay={isMissingFeature}
      overlayTitle="Feature Available with Upgrade"
      loading={loading && !isMissingFeature}
      error={
        resourceMetadata && !isMissingFeature
          ? resourceMetadata.fetchError
          : null
      }
      graphContent={
        <EksoOutcomesGraph
          data={calcData.data && !isMissingFeature ? calcData.data : []}
          type={type}
        />
      }
      paramContent={null}
    />
  );
};

EksoOutcomesLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  onQuickFilter: PropTypes.func,
  isMissingFeature: PropTypes.bool,
};

export default EksoOutcomesLayout;
