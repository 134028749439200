//import * as validationUtils from "validation/validationUtils";
import moment from "moment";

export default function validate(values) {
  const errors = {};

  if (
    values.startDate &&
    values.endDate &&
    moment(values.startDate, "MM-DD-YYYY").isAfter(
      moment(values.endDate, "MM-DD-YYYY")
    )
  ) {
    errors.startDate = "Must be before Date To";
    errors.endDate = "Must be after Date From";
  }
  return errors;
}
