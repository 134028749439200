import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { withRouter } from "react-router-dom";
import qs from "qs";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// #lizard forgives
function EksoDataNetworkTableContainerWrapperWrapper(
  WrappedEksoDataTable,
  BlueChipResourceModel,
  options
) {
  class EksoDataNetworkTableContainerWrapper extends Component {
    // This state stores the pre-api filters (untranslated)
    state = {
      queryParams: {
        filter: {},
        order: {},
      },
    };

    componentDidMount() {
      const { location } = this.props;
      const params = qs.parse(location.search, { ignoreQueryPrefix: true });
      // Default to startFilter/startSort if not set on query param already
      const {
        filter = this.props.startFilter,
        order = this.props.startSort,
      } = params;
      this.props.resourceActions.clear([options.resourceTypeName]);
      this.props.resourceActions.init({
        filter,
        order,
        firstLoad: true,
      });

      delete params.filter;
      delete params.order;

      this.setState({
        queryParams: {
          filter,
          order,
        },
      });
    }

    addQueryParams = params => {
      let combinedParams = {
        ...(this.props.lang !== "en" && { lang: this.props.lang }),
        filter: params.filter
          ? params.filter
          : _.omitBy(this.state.queryParams.filter, _.isNil),
        order: params.order ? params.order : this.state.queryParams.order,
      };

      const search = qs.stringify(combinedParams, { arrayFormat: "brackets" });
      this._replaceQueryParams(search);
      this.setState({ queryParams: combinedParams });
    };

    _replaceQueryParams = search => {
      const { location } = this.props;
      history.replace({ pathname: location.pathname, search });
    };

    componentDidUpdate() {
      const noRowsLoaded = !this.props.rows || !this.props.rows.length;
      if (
        !!this.props.resourceMetadata &&
        noRowsLoaded &&
        !this.props.resourceMetadata.isFetchingItems &&
        this.props.resourceMetadata.firstLoad
      ) {
        const filter = _.extend(
          {},
          this.props.resourceMetadata.filter,
          this.props.requiredFilter
        );

        if (!this._isValidSearch(filter)) {
          return;
        }

        this.loadMore();
      }
    }

    _translateFilter(filter) {
      let translatedFilter = _.extend({}, filter);
      if (options.filterTranslations) {
        Object.keys(filter).forEach(key => {
          if (options.filterTranslations[key]) {
            const newFilterValue = options.filterTranslations[key](filter[key]);
            delete translatedFilter[key];
            translatedFilter = _.extend(translatedFilter, newFilterValue);
          }
        });
      }

      return translatedFilter;
    }

    _isValidSearch(filter) {
      let valid = true;
      if (this.props.mustExistToSearchFilterNames) {
        for (let filterName of this.props.mustExistToSearchFilterNames) {
          valid =
            valid &&
            _.has(filter, filterName) &&
            !_.isEmpty(filter[filterName]);
        }
      }

      return valid;
    }

    loadMore = (
      scrollNum,
      additionalRequestProps = this.props.additionalRequestProps
    ) => {
      let resourceMetadata = this.props.resourceMetadata;

      if (
        !!resourceMetadata.nextPage &&
        !resourceMetadata.isFetchingItems &&
        resourceMetadata.nextPage !== this.props.resourceMetadata.currentPage
      ) {
        this.addQueryParams({});

        const filter = _.extend(
          {},
          resourceMetadata.filter,
          this.props.requiredFilter
        );

        if (!this._isValidSearch(filter)) {
          return this.props.resourceActions.clear([options.resourceTypeName]);
        }

        this.props.resourceActions.get(
          {
            page: { number: resourceMetadata.nextPage },
            filter,
            order: resourceMetadata.order,
            ...additionalRequestProps,
          },
          {
            clearResourceTypes:
              this.props.resourceMetadata.currentPage == 0
                ? [options.resourceTypeName]
                : [],
            translateFilterForApi: this._translateFilter,
          }
        );
      }
    };

    onSortChange = (
      sortObj,
      additionalRequestProps = this.props.additionalRequestProps
    ) => {
      let resourceMetadata = this.props.resourceMetadata;
      this.addQueryParams({
        order: sortObj,
      });
      // Translate for api call
      let filter = _.extend(
        {},
        resourceMetadata.filter,
        this.props.requiredFilter
      );
      filter = this._translateFilter(filter);

      if (!this._isValidSearch(filter)) {
        return this.props.resourceActions.clear([options.resourceTypeName]);
      }

      return this.props.resourceActions.get(
        {
          page: { number: 1 },
          filter: _.extend({}, filter, this.props.requiredFilter),
          order: sortObj,
          ...additionalRequestProps,
        },
        {
          clearResourceTypes: [options.resourceTypeName],
          translateFilterForApi: this._translateFilter,
        }
      );
    };

    onQuickFilter = (
      quickFilter,
      additionalRequestProps = this.props.additionalRequestProps
    ) => {
      let filter = _.extend({}, this.state.queryParams.filter, quickFilter);
      filter = _.pickBy(filter, val => !_.isNil(val) && val !== "");
      this.addQueryParams({ filter });
      // Translate for api call
      filter = _.extend({}, filter, this.props.requiredFilter);

      if (!this._isValidSearch(filter)) {
        this.props.resourceActions.init({
          filter,
          order: this.props.resourceMetadata.order,
        });
        return this.props.resourceActions.clear([options.resourceTypeName]);
      }

      return this.props.resourceActions.get(
        {
          page: { number: 1 },
          filter,
          order: this.props.resourceMetadata.order,
          ...additionalRequestProps,
        },
        {
          clearResourceTypes: [options.resourceTypeName],
          translateFilterForApi: this._translateFilter,
        }
      );
    };

    onSubmitFilters = (
      newFilter,
      combine,
      additionalRequestProps = this.props.additionalRequestProps
    ) => {
      let filter = _.extend(
        {},
        combine ? this.state.queryParams.filter : {},
        newFilter
      );
      filter = _.pickBy(filter, val => !_.isNil(val) && val !== "");
      this.addQueryParams({ filter });
      // Translate for api call
      filter = _.extend({}, filter, this.props.requiredFilter);

      if (!this._isValidSearch(filter)) {
        this.props.resourceActions.init({
          filter,
          order: this.props.resourceMetadata.order,
        });
        return this.props.resourceActions.clear([options.resourceTypeName]);
      }

      return this.props.resourceActions.get(
        {
          page: { number: 1 },
          filter,
          order: this.props.resourceMetadata.order,
          ...additionalRequestProps,
        },
        {
          clearResourceTypes: [options.resourceTypeName],
          translateFilterForApi: this._translateFilter,
        }
      );
    };

    clear = () => {
      let filter = {};
      const order = {};

      const search = qs.stringify({}, { arrayFormat: "brackets" });
      this._replaceQueryParams(search);
      this.setState({
        queryParams: {
          filter,
          order,
        },
      });

      this.props.resourceActions.get(
        {
          page: { number: 1 },
          filter,
          order,
        },
        {
          clearResourceTypes: [options.resourceTypeName],
          translateFilterForApi: this._translateFilter,
        }
      );
    };

    onFind = id => {
      this.props.resourceActions.find(id);
    };

    render() {
      return (
        <WrappedEksoDataTable
          {...this.props}
          {...this.state}
          rows={this.props.rows}
          resourceMetadata={this.props.resourceMetadata}
          resourceActions={this.props.resourceActions}
          loadMore={this.loadMore}
          clear={this.clear}
          onSortChange={this.onSortChange}
          onQuickFilter={this.onQuickFilter}
          onSubmitFilters={this.onSubmitFilters}
          onFind={this.onFind}
          addQueryParams={this.addQueryParams}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => {
    let bluechipQuery = BlueChipResourceModel.query(state.resources);
    const resourceMetadata =
      state.jsonApiResources[
        ownProps.resourceName ? ownProps.resourceName : options.resourceName
      ];
    if (ownProps.applyResourceFilter) {
      bluechipQuery = ownProps.applyResourceFilter(
        bluechipQuery,
        resourceMetadata && resourceMetadata.filter
          ? resourceMetadata.filter
          : {}
      );
    }

    return {
      rows: bluechipQuery
        .includes(options.includes ? options.includes : [])
        .where({ id: ownProps.id ? ownProps.id : options.id })
        .all()
        .toObjects()[0],
      resourceMetadata,
      requiredFilter: ownProps.requiredFilter ? ownProps.requiredFilter : {},
      startFilter: ownProps.startFilter
        ? ownProps.startFilter
        : options.startFilter
          ? options.startFilter
          : {},
      startSort: ownProps.startSort
        ? ownProps.startSort
        : options.startSort
          ? options.startSort
          : {},
      additionalRequestProps: ownProps.additionalRequestProps
        ? ownProps.additionalRequestProps
        : options.additionalRequestProps
          ? options.additionalRequestProps
          : {},
      lang: state.localization.lang,
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    resourceActions: jsonAPIReduxActions(
      dispatch,
      ownProps.resourceName ? ownProps.resourceName : options.resourceName
    ),
  });

  EksoDataNetworkTableContainerWrapper.propTypes = {
    resourceMetadata: PropTypes.object,
    rows: PropTypes.array,
    resourceActions: PropTypes.object,
    requiredFilter: PropTypes.object,
    location: PropTypes.object,
    startFilter: PropTypes.object,
    startSort: PropTypes.object,
    mustExistToRetrieveFilters: PropTypes.array,
    lang: PropTypes.string,
    additionalRequestProps: PropTypes.object,
    mustExistToSearchFilterNames: PropTypes.array,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(EksoDataNetworkTableContainerWrapper));
}

export default EksoDataNetworkTableContainerWrapperWrapper;
