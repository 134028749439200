import GraphContent from "./GraphContent";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";
import { STATS_SITES_UTILIZATION_GRAPH } from "constants/NetworkTypes";

export default EksoGraphContainerWrapper(GraphContent, Stats, {
  resourceTypeName: "stats",
  resourceName: STATS_SITES_UTILIZATION_GRAPH,
  id: "network_modal_graph",
  startFilter: {
    period: "allTime",
    modal: true,
    page: 1,
  },
});
