import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import LogoIcon from "./LogoIcon";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    position: "relative",
  },
  logo: {},
  trademarkSymbol: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
});

const Logo = ({ classes, className, dimensions, color, displayTrademark }) => {
  return (
    <div className={classNames(classes.root, className)}>
      <svg
        width={dimensions + "px"}
        height={dimensions + "px"}
        viewBox="0 0 669 668"
      >
        <LogoIcon color={color} />
      </svg>
      {displayTrademark ? (
        <span
          className={classes.trademarkSymbol}
          style={{
            color: color,
          }}
        >
          ®
        </span>
      ) : null}
    </div>
  );
};

Logo.propTypes = {
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  dimensions: PropTypes.number.isRequired,
  displayTrademark: PropTypes.bool,
};

Logo.defaultProps = {
  color: "white",
  dimensions: 50,
  displayTrademark: true,
};

export default withStyles(styles)(Logo);
