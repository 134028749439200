import React, { Component } from "react";
import AuthLayout from "components/shared/layouts/AuthLayout";
import { Button } from "@material-ui/core";

class UnauthorizedPage extends Component {
  render() {
    return (
      <AuthLayout
        header="403 Unauthorized"
        description="You are not authorized to view this page."
        renderFooter={false}
      >
        <Button
          data-test="goBackButton"
          variant="contained"
          color="primary"
          style={{ marginTop: "16px" }}
          onClick={() => (window.location = "/dashboard/overview")}
        >
          Go back to Dashboard
        </Button>
      </AuthLayout>
    );
  }
}

export default UnauthorizedPage;
