import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import InnerPaperPanelDivider from "components/shared/ui/Panel/InnerPaperPanelDivider";
import InnerPaperPanel from "components/shared/ui/Panel/InnerPaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import InnerPaperPanelTitle from "components/shared/ui/Panel/InnerPaperPanelTitle";
import PanelSubHeader from "components/shared/ui/Panel/PanelSubHeader";
import * as InSessDataTypes from "constants/InSessDataTypes";
import InsessResults from "./InsessResults";

const GaitPerformance = ({ session }) => {
  return (
    <div>
      <PanelSubHeader title="GAIT PERFORMANCE" />
      <InnerPaperPanel>
        <InnerPaperPanelTitle title="Gait Scores" />
        <Grid container spacing={8}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <ParamTable>
              <ParamTableRow
                data={session}
                name="sessLtLast30AssistLevel"
                label="Left Swing Assist"
              />
              <ParamTableRow
                data={session}
                name="sessLtLast30MaxFwdAssistLevel"
                label="Left Min Assist"
              />
            </ParamTable>
          </Grid>
          <Grid item xs={12} md={6}>
            <ParamTable>
              <ParamTableRow
                data={session}
                name="sessRtLast30AssistLevel"
                label="Right Swing Assist"
              />
              <ParamTableRow
                data={session}
                name="sessRtLast30MaxFwdAssistLevel"
                label="Right Min Assist"
              />
            </ParamTable>
          </Grid>
        </Grid>
        <InnerPaperPanelDivider />
        <InnerPaperPanelTitle title="Ekso Outcomes" />
        <Grid container spacing={8}>
          <Grid item xs={12} md={8}>
            <ParamTable>
              <ParamTableRow name="2MinWalkResults" label="2 Min Walk">
                <InsessResults
                  session={session}
                  onEdit={() => {}}
                  type={InSessDataTypes.TWO_MIN_WALK}
                />
              </ParamTableRow>
              <ParamTableRow name="6MinWalkResults" label="6 Min Walk">
                <InsessResults
                  session={session}
                  onEdit={() => {}}
                  type={InSessDataTypes.SIX_MIN_WALK}
                />
              </ParamTableRow>
              <ParamTableRow name="10MeterWalkResults" label="10 Meter Walk">
                <InsessResults
                  session={session}
                  onEdit={() => {}}
                  type={InSessDataTypes.TEN_METER_WALK}
                />
              </ParamTableRow>
            </ParamTable>
          </Grid>
        </Grid>
      </InnerPaperPanel>
    </div>
  );
};

GaitPerformance.propTypes = {
  session: PropTypes.object.isRequired,
  classes: PropTypes.object,
};

export default GaitPerformance;
