import React from "react";
import PropTypes from "prop-types";

import { Paper } from "@material-ui/core";

const PaperPanel = ({ style, children, className, dataTest, id }) => {
  return (
    <Paper
      style={style}
      className={className}
      elevation={1}
      data-test={dataTest}
      id={id}
    >
      {children}
    </Paper>
  );
};

PaperPanel.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  dataTest: PropTypes.string,
  id: PropTypes.string,
};

export default PaperPanel;
