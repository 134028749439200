import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Tabs, Tab } from "@material-ui/core";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    margin: "4px",
    width: "100%",
  },
  tabs: {
    borderBottom: "1px solid rgba(0,0,0,0.12)",
  },
  tab: {
    maxWidth: "none",
    color: "rgba(52,152,219,0.5)",
  },
  hidden: {
    display: "none",
  },
});

class NetworkOverviewMobileTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const {
      classes,
      demographics,
      utilization,
      utilizationTabLabel,
    } = this.props;

    return (
      <PaperPanel className={classes.root}>
        <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          fullWidth
          className={classes.tabs}
        >
          <Tab label="Demographics" className={classes.tab} />
          <Tab label={utilizationTabLabel} className={classes.tab} />
        </Tabs>
        <div
          className={classNames({
            [classes.hidden]: this.state.value !== 0,
          })}
        >
          {demographics}
        </div>
        <div
          className={classNames({
            [classes.hidden]: this.state.value !== 1,
          })}
        >
          {utilization}
        </div>
      </PaperPanel>
    );
  }
}

NetworkOverviewMobileTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  demographics: PropTypes.node,
  utilization: PropTypes.node,
  utilizationTabLabel: PropTypes.string,
};

export default withStyles(styles, { withTheme: true })(NetworkOverviewMobileTabs);
