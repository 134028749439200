import React, { Component } from "react";
import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import PropTypes from "prop-types";
import { navigate } from "actions/navigateActions";
import FieldData from "models/bluechip/FieldData";
import { bindActionCreators } from "redux";
import Session from "models/bluechip/Session";
import { connect } from "react-redux";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { translateDatePeriodFilter } from "lib/filterUtils";
import RecentSessionsTable from "./RecentSessionsTable";

class RecentSessionsContainer extends Component {
  state = {
    dateCurrentValue: null,
    customerCurrentValue: null,
  };

  componentDidMount() {
    // Retrieve and populate suggestion data
    this.props.filterFormsActions.find("customers");
  }

  handleNavigate = path => {
    this.props.navigate(path);
  };

  render() {
    return (
      <RecentSessionsTable
        clientFilter={this.props.clientFilter}
        onNavigate={this.handleNavigate}
        rows={this.props.rows}
        resourceMetadata={this.props.resourceMetadata}
        customerCurrentValue={this.state.customerCurrentValue}
        dateCurrentValue={this.state.dateCurrentValue}
        onDateChange={value => this.setState({ dateCurrentValue: value })}
        onCustomerChange={value =>
          this.setState({ customerCurrentValue: value })
        }
        loadMore={this.props.loadMore}
        onQuickFilter={this.props.onQuickFilter}
        onSortChange={this.props.onSortChange}
        customers={
          this.props.filterFieldData && this.props.filterFieldData.customers
            ? this.props.filterFieldData.customers
            : []
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  filterFieldData: state.resources.fieldData
    ? FieldData.query(state.resources)
        .where({ id: "customers" })
        .all()
        .toObjects()[0]
    : {},
});

const mapDispatchToProps = dispatch => ({
  navigate: bindActionCreators(navigate, dispatch),
  filterFormsActions: jsonAPIReduxActions(dispatch, "field_data"),
});

RecentSessionsContainer.propTypes = {
  navigate: PropTypes.func,
  resourceMetadata: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  onQuickFilter: PropTypes.func,
  filterFieldData: PropTypes.object,
  filterFormsActions: PropTypes.object,
  autoHeightMax: PropTypes.string,
  clientFilter: PropTypes.object,
};

const RecentSessionsContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecentSessionsContainer);

export default EksoDataTableContainerWrapper(
  RecentSessionsContainerConnected,
  Session,
  {
    resourceTypeName: "sessions",
    resourceName: "pulse_sessions",
    startSort: { column: "date", order: "DESC" },
    startFilter: {
      period: "thisMonth",
    },
    filterTranslations: {
      period: translateDatePeriodFilter,
    },
  }
);
