import React, { Component } from "react";
import PropTypes from "prop-types";
import { setContext } from "actions/pageContextActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { connect } from "react-redux";
import AdminSettingsPage from "./AdminSettingsPage";
import { bindActionCreators } from "redux";
import Customer from "models/bluechip/Customer";
import { updateCurrentUser as updateCurrentUserActionCreator } from "actions/loginActions";
import * as localizationActionCreators from "actions/localizationActions";
import { translateLocaleToLang } from "lib/localization";
import { stopSubmit as stopSubmitActionCreator } from "redux-form";
import _ from "lodash";
import { createBrowserHistory } from "history";
import FieldData from "models/bluechip/FieldData";

const history = createBrowserHistory();

const labelMapping = {
  timeZone: "Time Zone",
  language: "Language",
};

class AdminSettingsContainer extends Component {
  state = {
    formSubmitted: {},
  };

  componentDidMount() {
    this.props.setContext({
      pageTitle: "Settings",
      isFilterOpen: false,
      path: "/dashboard/settings",
      isSearchOpen: false,
    });

    if (!this.props.customer) {
      this.props.resourceActions.clear(["customers"]);
      this.props.resourceActions.init({
        firstLoad: true,
      });
    }

    // Retrieve and populate suggestion data
    this.props.filterFormsActions.find("ekso_serial_numbers");
  }

  componentDidUpdate(prevProps) {
    const {
      resourceMetadata,
      stopSubmit,
      customer,
      resourceActions,
      updateCurrentUser,
      localizationActions,
    } = this.props;
    const wasUpdating =
      prevProps.resourceMetadata && prevProps.resourceMetadata.isUpdatingItem;

    if (
      !!resourceMetadata &&
      !customer &&
      !resourceMetadata.isFetchingItems &&
      resourceMetadata.firstLoad
    ) {
      resourceActions.get({});
    }

    if (wasUpdating && resourceMetadata.isUpdateItemSuccess) {
      // Update current user and language to new setting set by the user
      updateCurrentUser({
        language: this.state.formSubmitted.language,
      });
      const lang = translateLocaleToLang(this.state.formSubmitted.language);
      localizationActions.saveLang(lang);
      // Immediately change url to reflect language change
      if (this.state.formSubmitted.language !== "en") {
        history.replace({
          pathname: location.pathname,
          search: `?lang=${lang}`,
        });
      } else {
        history.replace({ pathname: location.pathname });
      }
      this.setState({ formSubmitted: {} });
    } else if (wasUpdating && resourceMetadata.updateError) {
      if (resourceMetadata.updateError.validationMessages) {
        const validationMessages = _.mapValues(
          resourceMetadata.updateError.validationMessages,
          (message, key) => labelMapping[key] + " " + message
        );
        // Throw redux-form submission error
        stopSubmit(this.state.submittingForm, validationMessages);
      }
    }
  }

  handleSubmitLocation = formData => {
    const { resourceActions } = this.props;
    const translatedFormData = {
      time_zone: formData.timeZone,
      language: formData.language,
    };

    resourceActions.update(
      {
        id: this.props.customer.id,
        ...translatedFormData,
      },
      {
        successToast: "Location Settings Updated",
        errorToast: "Location Settings Update Failed",
      }
    );

    this.setState({
      formSubmitted: translatedFormData,
    });
  };

  render() {
    const { resourceMetadata, filterFieldData } = this.props;

    return (
      <AdminSettingsPage
        onSubmitLocation={this.handleSubmitLocation}
        isSaving={resourceMetadata.isUpdatingItem}
        customer={this.props.customer}
        eksoSerialNumbers={
          filterFieldData && filterFieldData.eksoSerialNumbers
            ? filterFieldData.eksoSerialNumbers
            : []
        }
        loading={!resourceMetadata || resourceMetadata.isFetchingItems}
      />
    );
  }
}

const mapStateToProps = state => ({
  customer: state.resources.customers
    ? Customer.query(state.resources)
        .all()
        .toObjects()[0]
    : null,
  resourceMetadata: state.jsonApiResources.customers
    ? state.jsonApiResources.customers
    : {},
  filterFieldData: state.resources.fieldData
    ? FieldData.query(state.resources)
        .where({ id: "ekso_serial_numbers" })
        .all()
        .toObjects()[0]
    : {},
});

const mapDispatchToProps = dispatch => ({
  setContext: bindActionCreators(setContext, dispatch),
  resourceActions: jsonAPIReduxActions(dispatch, "customers"),
  stopSubmit: bindActionCreators(stopSubmitActionCreator, dispatch),
  localizationActions: bindActionCreators(localizationActionCreators, dispatch),
  updateCurrentUser: bindActionCreators(
    updateCurrentUserActionCreator,
    dispatch
  ),
  filterFormsActions: jsonAPIReduxActions(dispatch, "field_data"),
});

AdminSettingsContainer.propTypes = {
  resourceActions: PropTypes.object,
  resourceMetadata: PropTypes.object,
  currentUser: PropTypes.object,
  updateCurrentUser: PropTypes.func,
  stopSubmit: PropTypes.func,
  localizationActions: PropTypes.object,
  setContext: PropTypes.func,
  resetForm: PropTypes.func,
  location: PropTypes.object,
  customer: PropTypes.object,
  filterFieldData: PropTypes.object,
  filterFormsActions: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSettingsContainer);
