import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Policy from "components/Policy";
import policyRules from "lib/policyRules";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "8px 8px 0 8px",
      width: "unset",
      borderRadius: "2px",
    },
  },
  title: {
    color: "#4A4A4A",
    fontWeight: 500,
    fontSize: "16px",
  },
  subTitle: {
    color: "#4A4A4A",
    fontWeight: 400,
    fontSize: "16px",
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px",
    },
  },
  iconButton: {
    margin: "0px 16px 0px 8px",
    width: "32px",
    height: "32px",
  },
  button: {
    paddingLeft: "8px",
    paddingRight: "8px",
    padding: "8px",
    minWidth: "0px",
    minHeight: "0px",
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      flexGrow: 0,
      display: "none",
    },
  },
  invisible: {
    display: "none",
  },
  invisibleOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class Header extends Component {
  render() {
    const {
      classes,
      user,
      onEdit,
      onStatusUpdate,
      onDelete,
      onNavigate,
      onStatusUpdateLinkText,
      error,
      currentUser,
    } = this.props;
    let title = null;
    let subTitle = null;
    if (user) {
      title = (
        <span className={classes.title}>
          {user.userFirstName} {user.userLastName}
        </span>
      );
      subTitle = (
        <span className={classes.subTitle}>{` - ${
          user.role === "customer_admin" ? "Admin" : "Therapist"
        }`}</span>
      );
    } else if (error) {
      title = <span className={classes.title}>Error</span>;
    }

    return (
      <AppBar
        position="static"
        className={classNames(classes.root, {
          [classes.invisibleOnMobile]:
            error || !policyRules.user.edit(currentUser, { user }),
        })}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="title" color="inherit" className={classes.grow}>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={() => onNavigate("/dashboard/users")}
            >
              <ChevronLeftIcon />
            </IconButton>
            {title}
            {subTitle}
          </Typography>
          <div className={classes.grow} />
          <Policy action="edit" object="user" context={{ user }}>
            <div
              className={classNames({
                [classes.invisible]: error,
              })}
            >
              <Button
                className={classes.button}
                onClick={onEdit}
                color="primary"
              >
                Edit
              </Button>
              <Policy
                action="delete"
                object="user"
                context={{
                  user: this.props.user,
                }}
              >
                <Button
                  className={classes.button}
                  onClick={onDelete}
                  color="primary"
                >
                  Delete
                </Button>
                <Button
                  className={classes.button}
                  onClick={onStatusUpdate}
                  color="primary"
                >
                  {onStatusUpdateLinkText}
                </Button>
              </Policy>
            </div>
          </Policy>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onNavigate: PropTypes.func,
  onDelete: PropTypes.func,
  onStatusUpdate: PropTypes.func,
  onStatusUpdateLinkText: PropTypes.string,
  user: PropTypes.object,
  currentUser: PropTypes.object,
  error: PropTypes.bool,
};

export default withStyles(styles)(Header);
