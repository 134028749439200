import React from "react";
import PropTypes from "prop-types";
import DropdownLinkField from "components/shared/ui/Field/SelectField";
import _ from "lodash";

const DEFAULT_MENU_ITEMS = [
  { value: "allTime", label: "All Time" },
  { value: "thisYear", label: "Year" },
  { value: "thisMonth", label: "Month" },
  { value: "thisWeek", label: "Week" },
  { value: "today", label: "Today" },
];

class QuickDateFilter extends React.Component {
  state = {
    value: this.props.defaultValue ? this.props.defaultValue : "allTime",
    showCustomPlaceholder: false,
  };

  onChange = event => {
    const value = event.target.value;
    this.setState({ value, showCustomPlaceholder: false });

    const filter = {
      period: value,
    };

    if (this.props.onQuickFilter) {
      this.props.onQuickFilter(filter);
    }

    if (this.props.onChange) {
      this.props.onChange(_.find(this.props.menuItems, { value }));
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hasCustomFilter && !prevState.showCustomPlaceholder) {
      return { value: "", showCustomPlaceholder: true };
    }

    if (nextProps.value && nextProps.value !== prevState.value) {
      return { value: nextProps.value };
    }

    return null;
  }

  render() {
    const { menuItems, isLinkStyle, isMiniStyle, menuProps, id } = this.props;
    return (
      <DropdownLinkField
        menuItems={menuItems || DEFAULT_MENU_ITEMS}
        placeholder={this.state.showCustomPlaceholder ? "Custom" : null}
        input={{
          name: id,
          onChange: this.onChange,
          value: this.state.value,
        }}
        meta={{}}
        labelLeft
        isLinkStyle={isLinkStyle}
        isMiniStyle={isMiniStyle}
        menuProps={menuProps}
        className={this.props.className}
      />
    );
  }
}

QuickDateFilter.defaultProps = {
  isLinkStyle: true,
  id: "quickDateFilter",
};

QuickDateFilter.propTypes = {
  hasCustomFilter: PropTypes.bool,
  onQuickFilter: PropTypes.func,
  className: PropTypes.string,
  menuItems: PropTypes.array,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  isLinkStyle: PropTypes.bool,
  isMiniStyle: PropTypes.bool,
  menuProps: PropTypes.object,
  id: PropTypes.string,
};

export default QuickDateFilter;
