/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import DataTable from "components/shared/ui/Table";
import FaqItem from "./FaqItem";
import {
  convertCamelCaseToUnderscoreCase,
  convertUnderscoreCaseToCamelCase,
} from "lib/stringUtils";
import FaqHeader from "./FaqHeader";

const styles = theme => ({
  headerDiv: {
    display: "none",
  },
  questionCell: {
    padding: "16px 16px 0 16px",
    paddingRight: "16px !important",
  },
});

class FaqsTable extends Component {
  columns = [
    {
      label: "question",
      prop: "question",
      className: this.props.classes.questionCell,
      format: (value, data) => <FaqHeader faq={data} />,
    },
  ];

  handleRowClick = rowData => {
    this.props.onQuickFilter({
      id: this.props.openFaqId !== rowData.id ? rowData.id : null,
    });
  };

  convertSortPropName = columnConfig => {
    // blue-chip auto camelCases property names, must undo for api call
    return convertCamelCaseToUnderscoreCase(
      columnConfig.sortProp ? columnConfig.sortProp : columnConfig.prop
    );
  };

  onSort = columnConfig => {
    const { faqsResourceMetadata } = this.props;
    const sort =
      faqsResourceMetadata && faqsResourceMetadata.order
        ? faqsResourceMetadata.order
        : {};

    // Must convert to underscoreCase for api call
    const column = this.convertSortPropName(columnConfig);

    let order = "ASC";
    if (sort.column === column) {
      order = sort.order === "DESC" ? "ASC" : "DESC";
    }

    const sortObj = {
      column,
      order,
    };
    this.props.onSortChange(sortObj);
  };

  render() {
    const { faqs, faqsResourceMetadata, classes } = this.props;

    const sort =
      faqsResourceMetadata && faqsResourceMetadata.order
        ? faqsResourceMetadata.order
        : {};

    return (
      <DataTable
        columns={this.columns}
        loading={faqsResourceMetadata.isFetchingItems}
        sort={{
          column: convertUnderscoreCaseToCamelCase(sort.column),
          order: sort.order,
        }}
        error={faqsResourceMetadata.fetchError}
        onSort={this.onSort}
        isInfiniteScroll={false}
        isScrollable={false}
        classes={{
          headerDiv: classes.headerDiv,
        }}
      >
        {faqs.map(faq => {
          return (
            <FaqItem
              key={faq.id}
              columns={this.columns}
              faq={faq}
              onRowClick={this.handleRowClick}
              isActive={this.props.openFaqId === faq.id}
            />
          );
        })}
      </DataTable>
    );
  }
}

FaqsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  faqs: PropTypes.array,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
};

export { FaqsTable as FaqsTableBase };
export default withWidth()(withStyles(styles)(FaqsTable));
