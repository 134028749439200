// eslint-disable-next-line
import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import qs from "qs";
import * as localizationActionCreators from "actions/localizationActions";
import { translateLocaleToLang } from "lib/localization";

class LocalizationWrapper extends Component {
  componentDidMount() {
    if (this.props.location) {
      this.storeLang(this.props.location.search);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      this.storeLang(nextProps.location.search);
    }
  }

  storeLang = search => {
    const { currentUser } = this.props;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    if (query.lang) {
      this.props.localizationActions.saveLang(query.lang);
    } else if (currentUser && currentUser.attributes.language) {
      this.props.localizationActions.saveLang(
        translateLocaleToLang(currentUser.attributes.language)
      );
    }
  };

  render() {
    return this.props.children;
  }
}

LocalizationWrapper.propTypes = {
  localizationActions: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.object,
};

const mapDispatchToProps = dispatch => ({
  localizationActions: bindActionCreators(localizationActionCreators, dispatch),
});

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  location: state.router.location,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalizationWrapper);
