import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import OverviewMobileHeaderContent from "components/pages/Overview/OverviewMobileHeaderContent";
import PatientsMobileHeaderContent from "components/pages/Patients/PatientsMobileHeaderContent";
import PatientDetailsMobileHeader from "components/pages/Patients/PatientDetails/PatientDetailsMobileHeader";
import ActivityMobileHeaderContent from "components/pages/Activity/ActivityMobileHeaderContent";
import ManageUsersMobileHeaderContent from "components/pages/ManageUsers/ManageUsersMobileHeaderContent";
import UserDetailsMobileHeaderContent from "components/pages/ManageUsers/UserDetails/UserDetailsMobileHeaderContent";
import NotificationsMobileHeaderContent from "components/pages/Notifications/NotificationsMobileHeaderContent";
import LearningCenterMobileHeaderContent from "components/pages/LearningCenter/LearningCenterMobileHeaderContent";
import LearningCenterChildPageMobileHeaderContent from "components/pages/LearningCenter/childPage/LearningCenterChildPageMobileHeaderContent";
import AccountMobileHeaderContent from "components/pages/Account/AccountMobileHeaderContent";
import AdminSettingsMobileHeaderContent from "components/pages/AdminSettings/AdminSettingsMobileHeaderContent";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    backgroundColor: "#34495E",
  },
  toolbar: {
    paddingLeft: theme.spacing.unit,
  },
});

class MobileContentHeader extends Component {
  render() {
    const {
      classes,
      onDrawerToggle,
      onNavigate,
      toggleFilter,
      pageContext,
      modalActions,
    } = this.props;
    return (
      <AppBar className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <Switch>
            <Route
              exact
              path="/dashboard/overview"
              render={() => (
                <OverviewMobileHeaderContent onDrawerToggle={onDrawerToggle} />
              )}
            />
            <Route
              exact
              path="/dashboard/activity"
              render={() => (
                <ActivityMobileHeaderContent
                  pageContext={pageContext}
                  onDrawerToggle={onDrawerToggle}
                  toggleFilter={toggleFilter}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/patients"
              render={() => (
                <PatientsMobileHeaderContent
                  pageContext={pageContext}
                  onDrawerToggle={onDrawerToggle}
                  toggleFilter={toggleFilter}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/patients/:id/:tab"
              render={props => (
                <PatientDetailsMobileHeader
                  pageContext={pageContext}
                  patient={pageContext ? pageContext.data : null}
                  id={props.match.params.id}
                  tab={props.match.params.tab}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/users"
              render={() => (
                <ManageUsersMobileHeaderContent
                  onDrawerToggle={onDrawerToggle}
                  pageContext={pageContext}
                  modalActions={modalActions}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/users/:id"
              render={() => (
                <UserDetailsMobileHeaderContent
                  onNavigate={onNavigate}
                  pageContext={pageContext}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/notifications"
              render={() => (
                <NotificationsMobileHeaderContent
                  onDrawerToggle={onDrawerToggle}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/learning"
              render={() => (
                <LearningCenterMobileHeaderContent
                  onDrawerToggle={onDrawerToggle}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/learning/faq"
              render={() => (
                <LearningCenterChildPageMobileHeaderContent
                  onNavigate={onNavigate}
                  pageContext={pageContext}
                  hasSearch
                />
              )}
            />
            <Route
              exact
              path="/dashboard/learning/videos"
              render={() => (
                <LearningCenterChildPageMobileHeaderContent
                  onNavigate={onNavigate}
                  pageContext={pageContext}
                  hasSearch={false}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/learning/documents"
              render={() => (
                <LearningCenterChildPageMobileHeaderContent
                  onNavigate={onNavigate}
                  pageContext={pageContext}
                  hasSearch={false}
                />
              )}
            />
            <Route
              exact
              path="/dashboard/account"
              render={() => (
                <AccountMobileHeaderContent onDrawerToggle={onDrawerToggle} />
              )}
            />
            <Route
              exact
              path="/dashboard/settings"
              render={() => (
                <AdminSettingsMobileHeaderContent
                  onDrawerToggle={onDrawerToggle}
                />
              )}
            />
          </Switch>
        </Toolbar>
      </AppBar>
    );
  }
}

MobileContentHeader.propTypes = {
  onDrawerToggle: PropTypes.func,
  className: PropTypes.string,
  pageContext: PropTypes.object,
  classes: PropTypes.object,
  toggleFilter: PropTypes.func,
  match: PropTypes.object,
  modalActions: PropTypes.object,
  onNavigate: PropTypes.func,
};

export { MobileContentHeader as MobileContentHeaderBase };
export default withStyles(styles)(MobileContentHeader);
