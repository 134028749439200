import React, { Component } from "react";
import PropTypes from "prop-types";
import User from "models/bluechip/User";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setContext } from "actions/pageContextActions";
import { formValueSelector, destroy, initialize } from "redux-form";
import * as modalActionCreators from "actions/modalActions";
import { deriveTitle } from "lib/stringUtils";
import ManageUsersPage from "./ManageUsersPage";
import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import _ from "lodash";

class ManageUsersContainer extends Component {
  componentDidMount() {
    this.props.setContext({
      pageTitle: "Users",
      isFilterOpen: false,
      path: "/dashboard/users",
      onSearch: this.onSearch,
      isSearchOpen: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.resourceMetadata && this.props.resourceMetadata.initiated) {
      this.props.initializeForm("manageUsersSearch", {
        search: this.props.resourceMetadata.filter.search,
      });
    }

    const { resourceMetadata } = this.props;
    if (
      resourceMetadata &&
      (!prevProps.resourceMetadata ||
        !_.isEqual(prevProps.resourceMetadata.meta, resourceMetadata.meta))
    ) {
      this.props.setContext({
        pageTitle: deriveTitle(resourceMetadata, "User"),
      });
    }
  }

  onSearch = formData => {
    let filter = formData;
    if (!formData.search) {
      filter = { search: null };
    }

    this.props.onQuickFilter(filter);
  };

  componentWillUnmount() {
    this.props.destroyForm("manageUsersSearch");
  }

  render() {
    if (
      !this.props.resourceMetadata ||
      !this.props.resourceMetadata.initiated
    ) {
      return null;
    }

    return (
      <ManageUsersPage
        users={this.props.rows}
        usersResourceMetadata={this.props.resourceMetadata}
        loadMore={this.props.loadMore}
        onQuickFilter={this.props.onQuickFilter}
        onSortChange={this.props.onSortChange}
        currentSearch={this.props.resourceMetadata.filter.search}
        modalActions={this.props.modalActions}
      />
    );
  }
}

const selector = formValueSelector("manageUsersSearch");

const mapStateToProps = state => ({
  currentSearch: selector(state, "search"),
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  setContext: bindActionCreators(setContext, dispatch),
  destroyForm: bindActionCreators(destroy, dispatch),
  initializeForm: bindActionCreators(initialize, dispatch),
});

ManageUsersContainer.propTypes = {
  setContext: PropTypes.func,
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  currentSearch: PropTypes.string,
  modalActions: PropTypes.object,
  resourceMetadata: PropTypes.object,
  resourceActions: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  onQuickFilter: PropTypes.func,
  destroyForm: PropTypes.func,
  initializeForm: PropTypes.func,
};

const ManageUsersContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsersContainer);

export default EksoDataTableContainerWrapper(
  ManageUsersContainerConnected,
  User,
  {
    resourceTypeName: "users",
    resourceName: "users",
    filterTranslations: {
      search: value => ({ name_or_email: value }),
    },
  }
);
