import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AscIcon from "@material-ui/icons/ExpandLess";
import DescIcon from "@material-ui/icons/ExpandMore";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    position: "relative",
    padding: "8px 0px",
    display: "inline-block",
    //transform: scale(1.3, .7),
  },
  asc: {
    position: "absolute",
    top: theme.spacing.unit / 2,
    left: theme.spacing.unit,
    fontSize: "11px",
  },
  desc: {
    position: "absolute",
    bottom: -theme.spacing.unit / 2,
    left: theme.spacing.unit,
    fontSize: "11px",
  },
  sorted: {
    color: "#3498DB",
  },
});

const SortIcon = ({ className, classes, sortDirection }) => {
  //
  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.sorted]: !!sortDirection,
        },
        className
      )}
    >
      {!sortDirection || sortDirection.toUpperCase() === "ASC" ? (
        <AscIcon className={classes.asc} />
      ) : null}
      {!sortDirection || sortDirection.toUpperCase() === "DESC" ? (
        <DescIcon className={classes.desc} />
      ) : null}
    </div>
  );
};

SortIcon.propTypes = {
  sortDirection: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(SortIcon);
