import AverageSessionsLayout from "./AverageSessionsLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";

export default EksoGraphContainerWrapper(AverageSessionsLayout, Stats, {
  resourceTypeName: "stats",
  resourceName: "stats/sessions_count",
  id: "sessions_count",
  startFilter: {
    period: "year",
    page: 1,
  },
});
