import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { navigate, replace } from "actions/navigateActions";
import * as menuActionCreators from "actions/menuActions";
import * as toastActionCreators from "actions/toastActions";
import * as modalActionCreators from "actions/modalActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { api } from "lib/api";

// #lizard forgives
function SessionActionWrapper(WrappedActionComponent) {
  class BaseSessionActionContainer extends Component {
    handleExportSessionSummary = async () => {
      const { session, toastActions } = this.props;
      let newWindow = window.open("/generatingPdf.html", "_blank");
      try {
        toastActions.showToast("Generating summary report...");

        let response = await api(
          `pulse_sessions/${session.id}/summary_export`,
          "get"
        );

        toastActions.dismissToast();
        newWindow.location.href = response.url;
      } catch (error) {
        newWindow.close();
        toastActions.showToast(
          `Error while generating summary report${
            error.message ? ": " + error.message : ""
          }`
        );
      }
    };

    handleExportSessionData = async () => {
      const { session, toastActions } = this.props;
      let newWindow = window.open("/generatingExport.html", "_blank");
      try {
        toastActions.showToast("Generating session data report...");

        let response = await api(
          `pulse_sessions/${session.id}/insess_export`,
          "get"
        );

        toastActions.dismissToast();
        newWindow.location.href = response.url;
      } catch (error) {
        newWindow.close();
        toastActions.showToast(
          `Error while generating summary report${
            error.message ? ": " + error.message : ""
          }`
        );
      }
    };

    handleRemoveFromMyPatients = () => {
      const { modalActions } = this.props;
      modalActions.openConfirmModal({
        title: "Remove Session",
        message: (
          <Fragment>
            {"Are you sure you want to remove Session from My Sessions?"}
          </Fragment>
        ),
        confirmText: "Remove",
        onConfirm: this.processRemoveFromMyPatients,
      });
    };

    processRemoveFromMyPatients = () => {
      const {
        session,
        patientActions,
        modalActions,
        sessionsResourceMetadata,
      } = this.props;
      patientActions.delete(
        { id: session.id, type: "pulse_sessions" },
        {
          successToast: "Session was removed from My Sessions",
          errorToast: "Remove From My Sessions Failed",
          removeResource:
            sessionsResourceMetadata.filter.my_patients == "true" ||
            sessionsResourceMetadata.filter.my_patients == true,
          forceUpdate:
            sessionsResourceMetadata.filter.my_patients == "false" ||
            sessionsResourceMetadata.filter.my_patients == false,
        }
      );
      modalActions.closeConfirmModal();
    };

    handleAddToMyPatients = () => {
      const { session, patientActions } = this.props;
      patientActions.create(
        { session_id: session.id },
        {
          successToast: "Session was added to My Sessions",
          errorToast: "Add To My Sessions Failed",
        }
      );
    };

    render() {
      return (
        <WrappedActionComponent
          {...this.state}
          {...this.props}
          onExportSessionSummary={this.handleExportSessionSummary}
          onExportSessionData={this.handleExportSessionData}
          onAddToMyPatients={this.handleAddToMyPatients}
          onRemoveFromMyPatients={this.handleRemoveFromMyPatients}
          session={this.props.session}
          menuOpen={this.props.menuOpen}
          menuActions={this.props.menuActions}
          tab={this.props.tab}
          currentUser={this.props.currentUser}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    sessionsResourceMetadata: state.jsonApiResources.pulse_sessions,
    menuOpen: ownProps.session
      ? state.menu[`${ownProps.session.id}_menu`]
      : false,
    currentUser: state.auth.currentUser,
  });

  const mapDispatchToProps = dispatch => ({
    modalActions: bindActionCreators(modalActionCreators, dispatch),
    menuActions: bindActionCreators(menuActionCreators, dispatch),
    navigate: bindActionCreators(navigate, dispatch),
    replace: bindActionCreators(replace, dispatch),
    patientActions: jsonAPIReduxActions(dispatch, "therapist_sessions"),
    toastActions: bindActionCreators(toastActionCreators, dispatch),
  });

  BaseSessionActionContainer.propTypes = {
    session: PropTypes.object,
    navigate: PropTypes.func,
    replace: PropTypes.func,
    modalActions: PropTypes.object,
    menuActions: PropTypes.object,
    menuOpen: PropTypes.bool,
    patientActions: PropTypes.object,
    sessionsResourceMetadata: PropTypes.object,
    tab: PropTypes.string,
    currentUser: PropTypes.object,
    toastActions: PropTypes.object,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseSessionActionContainer);
}

export default SessionActionWrapper;
