import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Text from "components/shared/ui/Typography/Text";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    fontWeight: 700,
    margin: "24px 16px",
    color: "#4A4A4A",
  },
});

const FilterHeader = ({ classes, title }) => {
  return (
    <Text fontSize={16} className={classes.root}>
      {title}
    </Text>
  );
};

FilterHeader.propTypes = {
  title: PropTypes.node,
  classes: PropTypes.object,
};

export default withStyles(styles)(FilterHeader);
