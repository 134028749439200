import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import MaterialField from "components/shared/ui/Field/MaterialField";
import validate from "validation/Account/changePasswordValidation";
import ActionButton from "components/shared/ui/Button/ActionButton";
import FormHeader from "components/shared/ui/Page/FormHeader";

const styles = theme => ({
  submitButton: {},
  materialField: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "block",
  },
  input: {
    width: "100%",
  },
  buttonPanel: {
    textAlign: "right",
  },
});

const AccountPasswordForm = ({
  handleSubmit,
  isSaving,
  classes,
  pristine,
  invalid,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormHeader title="Change Password" />
      <Field
        name="currentPassword"
        component={MaterialField}
        label="Current Password"
        className={classes.materialField}
        inputClassName={classes.input}
        inputProps={{
          type: "password",
        }}
      />
      <Field
        name="password"
        component={MaterialField}
        label="New Password"
        className={classes.materialField}
        inputClassName={classes.input}
        inputProps={{
          type: "password",
        }}
      />
      <Field
        name="passwordConfirm"
        component={MaterialField}
        label="Confirm New Password"
        className={classes.materialField}
        inputClassName={classes.input}
        inputProps={{
          type: "password",
        }}
      />
      <div className={classes.buttonPanel}>
        <ActionButton
          className={classes.submitButton}
          variant="contained"
          color="primary"
          loading={isSaving}
          onClick={handleSubmit}
          data-test="changePasswordButton"
          disabled={pristine || invalid}
        >
          Change Password
        </ActionButton>
      </div>
    </form>
  );
};

AccountPasswordForm.propTypes = {
  isSaving: PropTypes.bool,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
};

export default reduxForm({
  form: "accountPasswordForm",
  validate,
})(withStyles(styles)(AccountPasswordForm));
