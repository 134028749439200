import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InsessEvent from "models/bluechip/InsessEvent";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import WalkingStatDetailsLayout from "./WalkingStatDetailsLayout";

class WalkingStatDetailsContainer extends Component {
  state = {
    initialMount: true,
  };

  componentDidMount() {
    const { id, insessEventsResourceMetadata } = this.props;
    if (
      !insessEventsResourceMetadata ||
      !insessEventsResourceMetadata.foundIds.includes(id)
    ) {
      this.props.insessEventsActions.find(id);
    }
    this.setState({ initialMount: false });
  }

  componentDidUpdate(prevProps) {
    const { id, insessEventsResourceMetadata } = this.props;
    if (
      prevProps.id != id &&
      !insessEventsResourceMetadata.foundIds.includes(id)
    ) {
      this.props.insessEventsActions.find(id);
    }
  }

  render() {
    const { insessEventsResourceMetadata, insessEvent } = this.props;

    return (
      <WalkingStatDetailsLayout
        resourceMetadata={insessEventsResourceMetadata}
        insessEvent={insessEvent}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  insessEvent: state.resources.insessEvents
    ? InsessEvent.query(state.resources)
        .where({ id: ownProps.id })
        .all()
        .toObjects()[0]
    : null,
  insessEventsResourceMetadata: state.jsonApiResources["insess_events"],
});

const mapDispatchToProps = dispatch => ({
  insessEventsActions: jsonAPIReduxActions(dispatch, "insess_events"),
});

WalkingStatDetailsContainer.propTypes = {
  id: PropTypes.string,
  insessEventsActions: PropTypes.object,
  insessEvent: PropTypes.object,
  pageContext: PropTypes.object,
  setContext: PropTypes.func,
  insessEventsResourceMetadata: PropTypes.object,
  tab: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalkingStatDetailsContainer);
