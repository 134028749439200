import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";
import DemographicsContentLayout from "./DemographicsContentLayout";
import { STATS_DEMOGRAPHICS } from "constants/NetworkTypes";

export default EksoGraphContainerWrapper(DemographicsContentLayout, Stats, {
  resourceTypeName: "stats",
  resourceName: STATS_DEMOGRAPHICS,
  id: "network_demographics",
  startFilter: {
    period: "allTime",
  },
});
