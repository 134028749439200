import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "material-ui-pickers";

const DatePickerField = ({
  input,
  label,
  inputProps,
  placeholder,
  disabled,
  className,
  meta,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <DatePicker
        clearable
        keyboard
        format="MM-DD-YYYY"
        mask={value =>
          value
            ? [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
            : []
        }
        InputLabelProps={{
          shrink: true,
        }}
        autoOk
        disableFuture
        {...inputProps}
        disabled={disabled}
        className={className}
        name={name}
        label={label}
        placeholder={placeholder}
        value={input.value ? input.value : null}
        onChange={input.onChange}
        onBlur={input.onBlur}
        error={meta.touched && meta.invalid}
        helperText={meta.touched && meta.error}
      />
    </div>
  );
};

DatePickerField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
};

export default DatePickerField;
