import React from "react";
import PropTypes from "prop-types";
import CheckboxSelectField from "components/shared/ui/Field/CheckboxSelectField";

class CustomerCheckboxSelect extends React.Component {
  onChange = value => {
    let filterValue = value;
    if (value && value.length === this.props.customers.length) {
      filterValue = null;
    } else if (value.length === 0) {
      filterValue = ["NO_MATCH"];
    } else {
      filterValue = filterValue.join(",");
    }

    this.props.onQuickFilter({
      customer_num: filterValue,
    });
  };

  render() {
    const { className, customers, value } = this.props;
    const isNoMatch = value && value.length && value.includes("NO_MATCH");
    let translatedValue = isNoMatch ? [] : value;
    translatedValue = translatedValue === "" ? null : translatedValue;

    return (
      <CheckboxSelectField
        className={className}
        allItemsLabel="All Customers"
        menuWidth="250px"
        menuItems={customers.map(item => {
          return {
            label: item.customerNum + " - " + item.customerDisplayName,
            value: item.customerNum,
          };
        })}
        input={{
          value: translatedValue,
          onChange: this.onChange,
        }}
        meta={{}}
        isLinkStyle
        labelLeft
      />
    );
  }
}

CustomerCheckboxSelect.propTypes = {
  onQuickFilter: PropTypes.func,
  className: PropTypes.string,
  customers: PropTypes.array,
  value: PropTypes.array,
};

export default CustomerCheckboxSelect;
