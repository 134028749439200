import * as types from "constants/NotificationTypes";
import { RESET } from "constants/ClearTypes";

const initialState = {
  unreadTotal: 0,
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.UPDATE_UNREAD_TOTAL: {
      return {
        ...state,
        unreadTotal: action.unreadTotal,
      };
    }

    case RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
