import React, { Component } from "react";
import PropTypes from "prop-types";
import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Policy from "components/Policy";
import PatientsTable from "./PatientsTable";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import ContentHeaderVerticalDivider from "components/shared/ui/Page/ContentHeaderVerticalDivider";
import FilterButton from "components/shared/ui/Page/FilterButton";
import Text from "components/shared/ui/Typography/Text";
import MyPatientsQuickFilter from "./MyPatientsQuickFilter";
import IndicationCheckboxSelect from "./IndicationCheckboxSelect";
import { deriveTitle } from "lib/stringUtils";
import PatientModal from "./PatientModal";
import { convertStringArray } from "lib/commonUtils";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 64px)",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
  },
  titleText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleFilterText: {
    fontSize: "14px",
    opacity: 0.5,
    fontWeight: 500,
  },
  titleSeparator: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolbarContent: {
    display: "flex",
    alignItems: "center",
  },
  quickFilter: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    display: "flex",
  },
  checkboxQuickFilter: {
    marginRight: 0,
    marginLeft: theme.spacing.unit * 2,
    display: "flex",
  },
  num: {
    fontWeight: 500,
  },
});

class PatientsPage extends Component {
  render() {
    const {
      patientsResourceMetadata,
      patients,
      classes,
      loadMore,
      onShow,
      onQuickFilter,
      toggleFilter,
      isFilterOpen,
      onSortChange,
      onNavigate,
    } = this.props;

    const noCustomerIdSelected =
      !patientsResourceMetadata ||
      !patientsResourceMetadata.filter ||
      !patientsResourceMetadata.filter.customer_num ||
      !patientsResourceMetadata.filter.customer_num.length;

    const title = (
      <Text fontSize={16} className={classes.title}>
        <span className={classes.titleText}>
          {deriveTitle(patientsResourceMetadata, "Patient")}
        </span>
        <span className={classes.titleFilterText}>
          {noCustomerIdSelected ? null : (
            <span className={classes.titleSeparator}>{" - "}</span>
          )}
          {noCustomerIdSelected
            ? ""
            : `Customer #${convertStringArray(
                patientsResourceMetadata.filter.customer_num
              ).join(", #")}`}
        </span>
      </Text>
    );

    const toolbar = (
      <div className={classes.toolbarContent}>
        <Policy action="edit" object="patient">
          <ContentHeaderVerticalDivider />
          <MyPatientsQuickFilter
            className={classes.checkboxQuickFilter}
            onQuickFilter={onQuickFilter}
            value={
              patientsResourceMetadata &&
              patientsResourceMetadata.filter &&
              patientsResourceMetadata.filter.my_patients
            }
          />
        </Policy>
        <ContentHeaderVerticalDivider />
        <IndicationCheckboxSelect
          className={classes.quickFilter}
          onQuickFilter={onQuickFilter}
          value={
            patientsResourceMetadata &&
            patientsResourceMetadata.filter &&
            patientsResourceMetadata.filter.indication_ids
          }
        />
        <Hidden smDown>
          <ContentHeaderVerticalDivider />
          <FilterButton
            toggleFilter={toggleFilter}
            isFilterOpen={isFilterOpen}
          />
        </Hidden>
      </div>
    );
    return (
      <div className={classes.root} data-test="patientsPage">
        <ContentHeader title={title} toolbar={toolbar} />
        <PatientsTable
          patients={patients}
          patientsResourceMetadata={patientsResourceMetadata}
          loadMore={loadMore}
          onShow={onShow}
          onSortChange={onSortChange}
          onNavigate={onNavigate}
          menusOpen={this.props.menusOpen}
          currentUser={this.props.currentUser}
          noCustomerIdSelected={noCustomerIdSelected}
          toggleFilter={toggleFilter}
        />
        <PatientModal />
      </div>
    );
  }
}

PatientsPage.propTypes = {
  patientsResourceMetadata: PropTypes.object,
  patients: PropTypes.array,
  classes: PropTypes.object,
  loadMore: PropTypes.func,
  onShow: PropTypes.func,
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  hasCustomFilter: PropTypes.bool,
  onQuickFilter: PropTypes.func,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  onNavigate: PropTypes.func,
  menusOpen: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default withStyles(styles)(PatientsPage);
