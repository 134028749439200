import {
  SHOW_TOAST,
  DISMISS_TOAST,
  SHOW_QUEUED_TOAST,
} from "constants/ToastTypes";

export function showToast(message, hideDuration = 6000) {
  return {
    type: SHOW_TOAST,
    message,
    hideDuration,
  };
}

export function dismissToast() {
  return {
    type: DISMISS_TOAST,
  };
}

export function showQueuedToast() {
  return {
    type: SHOW_QUEUED_TOAST,
  };
}
