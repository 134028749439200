import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";

import SessionDetailsLayout from "components/pages/Activity/SessionDetails";

class SessionDetailsContainer extends Component {
  componentDidMount() {
    const { resourceActions, session, show } = this.props;
    if (!show) {
      resourceActions.find(session.id);
    }
  }

  render() {
    const { session, show } = this.props;
    return <SessionDetailsLayout session={session} show={show} />;
  }
}

SessionDetailsContainer.propTypes = {
  session: PropTypes.object,
  resourceActions: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  resourceActions: jsonAPIReduxActions(dispatch, "pulse_sessions"),
});

export default connect(
  null,
  mapDispatchToProps
)(SessionDetailsContainer);
