import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MessagePanel from "./MessagePanel";

const styles = () => ({
  errorIcon: {
    color: "red",
  },
});

const ErrorPanel = ({ error, classes, className }) => {
  let errorMessage =
    error && error.message ? error.message : "An unknown error occurred";
  errorMessage =
    error && error.status ? `${error.status} ${errorMessage}` : errorMessage;
  return (
    <MessagePanel
      iconName="error_outline"
      headerText="Retrieval Error"
      description={errorMessage}
      className={className}
      classes={{
        icon: classes.errorIcon,
      }}
    />
  );
};

ErrorPanel.propTypes = {
  error: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(ErrorPanel);
