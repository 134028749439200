/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import "react-tippy/dist/tippy.css";
import "video-react/dist/video-react.css";

import LocalizationWrapper from "components/localization/LocalizationWrapper";
import Toast from "components/shared/ui/Toast";
import ConfirmationDialog from "components/shared/ui/Modal/ConfirmationDialog";

import MuiPickersUtilsProvider from "material-ui-pickers/utils/MuiPickersUtilsProvider";
// pick utils
import MomentUtils from "material-ui-pickers/utils/moment-utils";

import Routes from "./Routes";
import ErrorBoundary from "./shared/ErrorBoundary";

const muiTheme = createMuiTheme({
  palette: {
    primary: blue,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        touchAction: "none",
      },
    },
    MuiButton: {
      root: {
        fontWeight: 500,
        borderRadius: "2px",
      },
      disabled: {
        backgroundColor: "#9B9B9B !important",
        opacity: 0.5,
        color: "white !important",
      },
      containedPrimary: {
        backgroundColor: "#3498DB",
        "&:hover": {
          backgroundColor: "#1F6CA7",
        },
      },
      flatPrimary: {
        "&:hover": {
          backgroundColor: "#F0F0F0",
        },
      },
      contained: {
        minWidth: "76px",
      },
      textPrimary: {
        color: "#3498DB",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
      },
      rounded: {
        borderRadius: "2px",
      },
      elevation2: {
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      },
      elevation8: {
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5)",
      },
    },
    MuiTableCell: {
      root: {
        padding: "4px 16px",
        "&:last-child": {
          paddingRight: "30px",
        },
      },
      body: {
        fontSize: "14px",
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: "14px",
        padding: "12px 16px",
        minWidth: "120px",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: "#0071ED !important",
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "rgba(216,216,216, 0.6)",
      },
    },
    MuiTab: {
      root: {
        fontWeight: 500,
      },
      textColorPrimary: {
        color: "#3498DB",
      },
      selected: {
        opacity: 1,
      },
    },
    TabIndicator: {
      colorPrimary: {
        color: "#3498DB",
      },
    },
    MuiInput: {
      root: {
        fontSize: "14px",
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: "#3498DB",
      },
    },
    MuiPickersModal: {
      dialog: {
        overflowY: "auto",
        overflowX: "hidden",
      },
    },
  },
});

class App extends React.Component {
  render() {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={muiTheme}>
          <LocalizationWrapper>
            <ErrorBoundary>
              <Routes />
              <Toast />
            </ErrorBoundary>
            <ConfirmationDialog />
          </LocalizationWrapper>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
