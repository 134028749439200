// Login Actions
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const SESSION_EXPIRATION = "SESSION_EXPIRATION";
export const LOGOUT_INACTIVITY = "LOGOUT_INACTIVITY";
export const LOGOUT_USER = "LOGOUT_USER";
export const FETCH_PROTECTED_DATA_REQUEST = "FETCH_PROTECTED_DATA_REQUEST";
export const RECEIVE_PROTECTED_DATA = "RECEIVE_PROTECTED_DATA";
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
