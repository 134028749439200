/* eslint-disable import/default */
import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import { onSessionExpired } from "./lib/api";
import { loginUserSuccess, expiresCurrentUser } from "actions/loginActions";
import rules from "lib/policyRules";
import { configPolicyRules } from "lib/policyRulesEvaluator";
import configureStore, { history } from "./store/configureStore";
import Root from "./components/Root";
import "./styles/app.scss"; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
require("./images/favicon.ico"); // Tell webpack to load favicon.ico

const store = configureStore();

let currentUser = localStorage.getItem("currentUser");
configPolicyRules(rules);

if (currentUser !== null) {
  store.dispatch(loginUserSuccess(JSON.parse(currentUser)));
}

onSessionExpired(() => {
  store.dispatch(expiresCurrentUser());
});

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./components/Root", () => {
    const NewRoot = require("./components/Root").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("app")
    );
  });
}
