import React, { Component } from "react";
import PropTypes from "prop-types";
import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ManageUsersTable from "./ManageUsersTable";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import ContentHeaderVerticalDivider from "components/shared/ui/Page/ContentHeaderVerticalDivider";
import CreateUserButton from "./CreateUserButton";
import UserTypeQuickFilter from "./UserTypeQuickFilter";
import Text from "components/shared/ui/Typography/Text";
import SearchReduxForm from "components/shared/ui/Form/SearchReduxForm";
import UserModal from "./UserModal";
import { USER_MODAL_ID } from "constants/ModalIds";
import Policy from "components/Policy";
import { deriveTitle } from "lib/stringUtils";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 64px)",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  searchForm: {
    display: "flex",
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },
  toolbarContent: {
    display: "flex",
    alignItems: "center",
  },
  quickFilter: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    display: "flex",
  },
  num: {
    fontWeight: 500,
  },
});

class ManageUsersPage extends Component {
  render() {
    const {
      usersResourceMetadata,
      users,
      classes,
      loadMore,
      onSortChange,
      currentSearch,
      modalActions,
    } = this.props;

    const title = (
      <Text fontSize={16} className={classes.title}>
        {deriveTitle(usersResourceMetadata, "User", currentSearch)}
      </Text>
    );
    const toolbar = (
      <div className={classes.toolbarContent}>
        <Hidden smDown>
          <ContentHeaderVerticalDivider />
          <SearchReduxForm
            formName="manageUsersSearch"
            className={classes.searchForm}
          />
        </Hidden>
        <ContentHeaderVerticalDivider />
        <UserTypeQuickFilter
          filter={usersResourceMetadata.filter}
          onQuickFilter={this.props.onQuickFilter}
          className={classes.quickFilter}
        />
        <Policy action="create" object="user">
          <Hidden smDown>
            <ContentHeaderVerticalDivider />
            <CreateUserButton
              onClick={() => modalActions.openModal(USER_MODAL_ID)}
            />
          </Hidden>
        </Policy>
      </div>
    );
    return (
      <div className={classes.root}>
        <ContentHeader title={title} toolbar={toolbar} />
        <ManageUsersTable
          users={users}
          usersResourceMetadata={usersResourceMetadata}
          loadMore={loadMore}
          onSortChange={onSortChange}
        />
        <UserModal />
      </div>
    );
  }
}

ManageUsersPage.propTypes = {
  usersResourceMetadata: PropTypes.object,
  users: PropTypes.array,
  classes: PropTypes.object,
  loadMore: PropTypes.func,
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  hasCustomFilter: PropTypes.bool,
  onQuickFilter: PropTypes.func,
  onSortChange: PropTypes.func,
  currentSearch: PropTypes.string,
  modalActions: PropTypes.object,
};

export default withStyles(styles)(ManageUsersPage);
