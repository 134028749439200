import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import FieldData from "models/bluechip/FieldData";
import { connect } from "react-redux";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import PatientFilterReduxForm from "./PatientFilterReduxForm";
import { initialize, formValueSelector } from "redux-form";
import { bindActionCreators } from "redux";
import { convertStringArray } from "lib/commonUtils";
import policyRules from "lib/policyRules";

class PatientFilterFormContainer extends Component {
  componentDidMount() {
    const { isEksoUser, customersFieldData } = this.props;
    // Retrieve and populate suggestion data
    if (
      isEksoUser &&
      !this.getFilterData(customersFieldData, "customers").length
    ) {
      this.props.filterFormsActions.find("customers");
    }
    this._updateConditionalFieldData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isEksoUser &&
      prevProps.currentCustomerNum !== this.props.currentCustomerNum
    ) {
      this._updateConditionalFieldData();
    }
  }

  _deleteFieldData = () => {
    const { pilotNumbersFieldData, eksoSerialNumbersFieldData } = this.props;

    if (this.getFilterData(pilotNumbersFieldData, "pilotNumbers").length) {
      this.props.filterFormsActions.removeResource(
        "pilot_numbers",
        "fieldData"
      );
    }

    if (
      this.getFilterData(eksoSerialNumbersFieldData, "eksoSerialNumbers").length
    ) {
      this.props.filterFormsActions.removeResource(
        "ekso_serial_numbers",
        "fieldData"
      );
    }
  };

  _updateConditionalFieldData = () => {
    const { isEksoUser, currentCustomerNum } = this.props;

    if (isEksoUser && !_.isEmpty(currentCustomerNum)) {
      const findParams = {
        customer_num: currentCustomerNum.map(
          item => (item.value ? item.value : item)
        ),
      };

      this._deleteFieldData();

      this.props.filterFormsActions.find("pilot_numbers", { findParams });
      this.props.filterFormsActions.find("ekso_serial_numbers", { findParams });
    } else if (!isEksoUser) {
      this.props.filterFormsActions.find("pilot_numbers");
      this.props.filterFormsActions.find("ekso_serial_numbers");
    }
  };

  onSubmit = formData => {
    // Translate filter to expected format for the api call
    let filter = {
      customer_num: formData.customerNum
        ? formData.customerNum.map(item => (item.value ? item.value : item))
        : null,
      ekso_serial_num: formData.eksoSerialNumber
        ? formData.eksoSerialNumber.map(
            item => (item.value ? item.value : item)
          )
        : null,
      patient_id: formData.pilotNum
        ? formData.pilotNum.map(item => (item.value ? item.value : item))
        : null,
    };

    return this.props.onSubmit(filter);
  };

  onClear = () => {
    this.props.initializeForm("patientFilterForm", {});
    this.props.onSubmit({
      customer_num: null,
      ekso_serial_num: null,
      patient_id: null,
    });
  };

  getFilterData = (filterFieldData, fieldName) => {
    return filterFieldData && filterFieldData[fieldName]
      ? filterFieldData[fieldName]
      : [];
  };

  render() {
    const {
      isEksoUser,
      customersFieldData,
      pilotNumbersFieldData,
      eksoSerialNumbersFieldData,
      currentCustomerNum,
    } = this.props;

    const hasCustomer = isEksoUser ? !_.isEmpty(currentCustomerNum) : true;
    return (
      <PatientFilterReduxForm
        onSubmit={this.onSubmit}
        onClear={this.onClear}
        customers={this.getFilterData(customersFieldData, "customers")}
        patients={this.getFilterData(pilotNumbersFieldData, "pilotNumbers")}
        eksoSerialNumbers={this.getFilterData(
          eksoSerialNumbersFieldData,
          "eksoSerialNumbers"
        )}
        initialValues={this.props.initialValues}
        customerNumSelected={hasCustomer}
      />
    );
  }
}

const selector = formValueSelector("patientFilterForm");
const mapStateToProps = state => {
  const customerNum = selector(state, "customerNum");
  return {
    customersFieldData: state.resources.fieldData
      ? FieldData.query(state.resources)
          .where({ id: "customers" })
          .all()
          .toObjects()[0]
      : {},
    eksoSerialNumbersFieldData: state.resources.fieldData
      ? FieldData.query(state.resources)
          .where({ id: "ekso_serial_numbers" })
          .all()
          .toObjects()[0]
      : {},
    pilotNumbersFieldData: state.resources.fieldData
      ? FieldData.query(state.resources)
          .where({ id: "pilot_numbers" })
          .all()
          .toObjects()[0]
      : {},
    initialValues: state.jsonApiResources.patients
      ? {
          customerNum: convertStringArray(
            state.jsonApiResources.patients.filter.customer_num
          ),
          eksoSerialNumber:
            state.jsonApiResources.patients.filter.ekso_serial_num || [],
          pilotNum: state.jsonApiResources.patients.filter.patient_id || [],
        }
      : {},
    isEksoUser: policyRules.filter.viewCustomerNum(state.auth.currentUser),
    currentCustomerNum: customerNum,
  };
};

const mapDispatchToProps = dispatch => ({
  filterFormsActions: jsonAPIReduxActions(dispatch, "field_data"),
  initializeForm: bindActionCreators(initialize, dispatch),
});

PatientFilterFormContainer.propTypes = {
  filterFieldData: PropTypes.object,
  filterFormsActions: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func,
  initialValues: PropTypes.object,
  customersFieldData: PropTypes.object,
  pilotNumbersFieldData: PropTypes.object,
  eksoSerialNumbersFieldData: PropTypes.object,
  isEksoUser: PropTypes.bool,
  currentCustomerNum: PropTypes.array,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientFilterFormContainer);
