import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const styles = theme => ({
  root: {
    border: "1px solid rgba(151,151,151,0.24)",
    padding: theme.spacing.unit * 2,
    borderRadius: "3px",
    flex: 1,
  },
});

const PaperPanel = ({ children, classes }) => {
  return (
    <Paper className={classes.root} elevation={0}>
      {children}
    </Paper>
  );
};

PaperPanel.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(PaperPanel);
