import React from "react";
import PropTypes from "prop-types";

const BaseText = ({
  text,
  color,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  textTransform,
  fontStyle,
  style,
  className,
  testId,
}) => {
  return (
    <p
      {...(testId ? { "data-test": testId } : {})}
      style={{
        ...styles,
        color,
        fontFamily,
        fontSize,
        fontWeight,
        lineHeight,
        textTransform,
        fontStyle,
        ...style,
      }}
      className={className}
    >
      {text}
    </p>
  );
};

BaseText.propTypes = {
  text: PropTypes.node,
  color: PropTypes.string,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  lineHeight: PropTypes.string,
  textTransform: PropTypes.string,
  fontStyle: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  testId: PropTypes.string,
};

const styles = {
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: "Roboto",
  fontSize: 14,
};

export default BaseText;
