import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Checkbox, FormHelperText } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Text from "components/shared/ui/Typography/Text";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  formControl: {},
  label: {
    color: "#4A4A4A",
  },
  description: {
    color: "#9B9B9B",
    lineHeight: "24px",
    width: "312px",
    marginTop: "-12px",
    marginLeft: "34px",
    fontWeight: 300,
  },
});

const convertToBool = val => {
  return String(val).toLowerCase() === "true";
};

const CheckboxField = ({
  input,
  label,
  disabled,
  meta,
  className,
  inputProps,
  classes,
  description,
}) => {
  const currentValue = convertToBool(input.value);
  return (
    <div className={className} data-name={input.name}>
      <FormControlLabel
        {...inputProps}
        disabled={disabled}
        control={
          <Checkbox
            checked={currentValue}
            color="primary"
            onChange={() => input.onChange(!currentValue)}
            onKeyDown={event =>
              event.keyCode == 32 ? input.onChange(!currentValue) : null
            }
            value={input.name}
          />
        }
        classes={{
          label: classes.label,
        }}
        label={label}
      />
      {meta.touched && meta.error ? (
        <FormHelperText error id={`${input.name}_helperText`}>
          {meta.error}
        </FormHelperText>
      ) : null}
      {description ? (
        <Text className={classes.description} fontSize={14}>
          {description}
        </Text>
      ) : null}
    </div>
  );
};

CheckboxField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  classes: PropTypes.object,
  description: PropTypes.string,
};

export default withStyles(styles)(CheckboxField);
