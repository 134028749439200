import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import Logo from "components/shared/ui/Logo";

const styles = theme => ({
  root: {
    minHeight: "98px",
    backgroundColor: "#34495E",
    [theme.breakpoints.down("sm")]: {
      minHeight: "72px",
    },
  },
  logo: {
    height: "49px",
    width: "49px",
    paddingTop: "16px",
    marginLeft: "16px",
    [theme.breakpoints.up("md")]: {
      paddingTop: "0px",
      marginTop: "24px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
});

class Header extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Logo className={classes.logo} displayTrademark={false} />
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(Header);
