import React from "react";
import PropTypes from "prop-types";
import InnerPaperPanel from "components/shared/ui/Panel/InnerPaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import InnerPaperPanelTitle from "components/shared/ui/Panel/InnerPaperPanelTitle";
import PanelSubHeader from "components/shared/ui/Panel/PanelSubHeader";
import { Grid } from "@material-ui/core";
import numberFormatter from "lib/numberFormatter";

const WalkingStatGeneralParams = ({ insessEvent }) => {
  return (
    <div>
      <PanelSubHeader title="PARAMETERS" />
      <Grid container spacing={16}>
        <Grid item xs={12} md={6} style={{ display: "flex" }}>
          <InnerPaperPanel>
            <InnerPaperPanelTitle title="Patient Parameters" />
            <ParamTable>
              <ParamTableRow
                data={insessEvent}
                name="insessStepHeightNum"
                label="Step Height"
                formatValue={val => numberFormatter.divideNum(val, 10) + "in"}
              />
              <ParamTableRow
                data={insessEvent}
                name="insessStepLengthNum"
                label="Step Length"
                formatValue={val => numberFormatter.divideNum(val, 10) + "in"}
              />
              <ParamTableRow
                data={insessEvent}
                name="insessStepSwingTimeSec"
                label="Swing Time"
                formatValue={val => numberFormatter.divideNum(val, 10) + "s"}
              />
              <ParamTableRow
                data={insessEvent}
                name="insessAutostepWtshiftLateralNum"
                label="Lateral Target"
                formatValue={val =>
                  numberFormatter.convertAutoStepWShift(val, 100)
                }
              />
              <ParamTableRow
                data={insessEvent}
                name="insessAutostepWtshiftForwardNum"
                label="Forward Target"
                formatValue={val =>
                  numberFormatter.convertAutoStepWShift(val, 100)
                }
              />
              <ParamTableRow
                data={insessEvent}
                name="insessSwingCompleteDelay"
                label="Swing Complete"
              />
            </ParamTable>
          </InnerPaperPanel>
        </Grid>
        <Grid item xs={12} md={6}>
          <InnerPaperPanel>
            <InnerPaperPanelTitle title="Ekso Parameters" />
            <ParamTable>
              <ParamTableRow
                data={insessEvent}
                name="leftAssistLcdSetting"
                label="Left Assist"
              />

              <ParamTableRow
                data={insessEvent}
                name="rightAssistLcdSetting"
                label="Right Assist"
              />

              <ParamTableRow
                data={insessEvent}
                name="methodWalkNum"
                label="Walk Mode"
              />
            </ParamTable>
          </InnerPaperPanel>
        </Grid>
      </Grid>
    </div>
  );
};

WalkingStatGeneralParams.propTypes = {
  insessEvent: PropTypes.object.isRequired,
};

export default WalkingStatGeneralParams;
