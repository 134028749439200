import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { formatDate } from "lib/dateUtils";
import numberFormatter from "lib/numberFormatter";
import EksoDataTable from "components/shared/ui/Table/EksoDataTable";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import Text from "components/shared/ui/Typography/Text";
import DateQuickFilter from "components/shared/ui/Page/DateQuickFilter";
import MessagePanel from "components/shared/ui/Panel/MessagePanel";
import CustomerCheckboxSelect from "./CustomerCheckboxSelect";
import { Link } from "react-router-dom";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

import _ from "lodash";

const menuItems = [
  { value: "thisMonth", label: "Month" },
  { value: "thisWeek", label: "Week" },
  { value: "today", label: "Today" },
];

const styles = theme => ({
  table: {
    padding: "0px",
    borderRadius: "4px",
  },
  headerDiv: {
    padding: "0px",
  },
  tableDiv: {
    padding: "0px",
  },
  dateCol: {
    [theme.breakpoints.down("xs")]: {
      width: "72px",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentHeader: {
    width: "100%",
    padding: "0",
    margin: "0",
    boxShadow: "none",
    height: "52px",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
  },
  contentHeaderToolbar: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  customerField: {
    maxWidth: "160px",
    display: "inline-flex",

    [theme.breakpoints.down("md")]: {
      maxWidth: "28%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "132px",
      marginTop: "2px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "30%",
    },
  },
  dateField: {
    marginRight: "4px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "28%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-8px",
      maxWidth: "inherit",
      marginTop: "2px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "30%",
    },
  },
  activityButton: {
    marginLeft: "8px",
    marginRight: "8px",
    padding: "8px",
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
  hideCellMdDown: {
    [theme.breakpoints.down("md")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  hideCellXs: {
    [theme.breakpoints.down("xs")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  alignLeftSm: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      flexDirection: "row",
      paddingLeft: "8px",
    },
  },
  patientLink: {
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class RecentSessionsTable extends Component {
  state = {
    dateLabel: "This Month",
  };

  columns = [
    {
      label: "DATE",
      prop: "date",
      format: value => formatDate(value),
      className: this.props.classes.dateCol,
    },
    {
      label: "PATIENT ID",
      prop: "pilotNum",
      isNumeric: true,
      className: this.props.classes.alignLeftSm,
      format: (val, data) => (
        <Link
          className={this.props.classes.patientLink}
          to={`/dashboard/patients/${data.patientId}/summary`}
        >
          {val}
        </Link>
      ),
    },
    {
      label: "UPRIGHT TIME",
      prop: "sessTotUprightTimeSec",
      format: numberFormatter.secondsToMinForTable,
      isNumeric: true,
      className: classNames(
        this.props.classes.alignLeftSm,
        this.props.classes.hideCellMdDown
      ),
    },
    {
      label: "WALKING TIME",
      prop: "sessTotWalkTimeSec",
      format: numberFormatter.secondsToMinForTable,
      isNumeric: true,
      className: classNames(
        this.props.classes.alignLeftSm,
        this.props.classes.walkingTime,
        this.props.classes.hideCellXs
      ),
    },
    {
      label: "STEPS",
      prop: "sessTotStepCount",
      isNumeric: true,
      format: numberFormatter.numberWithCommas,
      className: classNames(
        this.props.classes.alignLeftSm,
        this.props.classes.steps
      ),
    },
  ];

  render() {
    const {
      rows,
      resourceMetadata,
      loadMore,
      onSortChange,
      classes,
      customers,
      clientFilter,
    } = this.props;

    if (!resourceMetadata || !resourceMetadata.initiated) {
      return null;
    }

    const title = (
      <Text fontSize={16} className={classes.title}>
        Recent Sessions
      </Text>
    );

    const periodValue = clientFilter.period ? clientFilter.period : "thisMonth";

    const customerNumFilter =
      resourceMetadata.filter && resourceMetadata.filter.customer_num;
    let customerNumValue = [];

    if (customerNumFilter && _.isArray(customerNumFilter)) {
      customerNumValue = customerNumFilter;
    } else if (customerNumFilter) {
      customerNumValue = resourceMetadata.filter.customer_num.split(",");
    } else if (customers) {
      customerNumValue = customers.map(item => item.customerNum);
    }

    const toolbar = (
      <div className={classes.toolbarContent}>
        <DateQuickFilter
          onQuickFilter={this.props.onQuickFilter}
          className={classes.dateField}
          menuItems={menuItems}
          value={periodValue}
        />
        <CustomerCheckboxSelect
          customers={customers}
          className={classes.customerField}
          onQuickFilter={this.props.onQuickFilter}
          value={customerNumValue}
        />
        <Button
          color="primary"
          className={classes.activityButton}
          onClick={() => this.props.onNavigate("/dashboard/activity")}
        >
          SEE ACTIVITY
        </Button>
      </div>
    );

    const emptyContent = (
      <MessagePanel
        className={classes.emptyTablePanel}
        headerText={`No Sessions Recorded for ${
          _.find(menuItems, { value: periodValue }).label
        }`}
        description="Come back soon."
      />
    );

    let autoHeightMax = "calc(100vh - 416px)";
    if (isWidthDown("sm", this.props.width)) {
      autoHeightMax = "488px";
    }

    return (
      <div className={classes.root}>
        <ContentHeader
          title={title}
          toolbar={toolbar}
          classes={{
            root: classes.contentHeader,
            toolbar: classes.contentHeaderToolbar,
          }}
        />
        <EksoDataTable
          id="recentSessions"
          columns={this.columns}
          rows={rows}
          resourceMetadata={resourceMetadata}
          loadMore={loadMore}
          onSortChange={onSortChange}
          scrollbarsSettings={{
            autoHeight: true,
            autoHeightMax,
          }}
          classes={{
            root: classes.table,
            headerDiv: classes.headerDiv,
            tableDiv: classes.tableDiv,
          }}
          emptyContent={emptyContent}
        />
      </div>
    );
  }
}

RecentSessionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array,
  resourceMetadata: PropTypes.object,
  onSortChange: PropTypes.func,
  loadMore: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onNavigate: PropTypes.func,
  customers: PropTypes.array,
  autoHeightMax: PropTypes.string,
  width: PropTypes.string,
  onDateChange: PropTypes.func,
  onCustomerChange: PropTypes.func,
  dateCurrentValue: PropTypes.string,
  customerCurrentValue: PropTypes.array,
  clientFilter: PropTypes.object,
};

export { RecentSessionsTable as RecentSessionsTableBase };
export default withWidth()(withStyles(styles)(RecentSessionsTable));
