import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { navigate, replace } from "actions/navigateActions";
import * as modalActionCreators from "actions/modalActions";
import * as toastActionCreators from "actions/toastActions";
import * as menuActionCreators from "actions/menuActions";
import { changeTab } from "actions/pageContextActions";
import * as modalIds from "constants/ModalIds";
import { api } from "lib/api";

// #lizard forgives
function PatientActionWrapper(WrappedActionComponent) {
  class BasePatientActionContainer extends Component {
    handleNavigate = path => {
      this.props.navigate(path);
    };

    handleTabChange = value => {
      const { patient } = this.props;
      this.props.replace(`/dashboard/patients/${patient.id}/${value}`);
      this.props.changeTab(value);
    };

    handleExportSummary = async () => {
      const { patient, toastActions } = this.props;
      let newWindow = window.open("/generatingPdf.html", "_blank");
      try {
        toastActions.showToast("Generating summary report...");

        let response = await api(
          `patients/${patient.id}/summary_export`,
          "get"
        );

        toastActions.dismissToast();
        newWindow.location.href = response.url;
      } catch (error) {
        newWindow.close();
        toastActions.showToast(
          `Error while generating summary report${
            error.message ? ": " + error.message : ""
          }`
        );
      }
    };

    handleAddDetails = () => {
      this.props.modalActions.openModalWithData(
        modalIds.PATIENT_MODAL_ID,
        this.props.patient
      );
    };

    render() {
      return (
        <WrappedActionComponent
          {...this.state}
          {...this.props}
          onAddToMyPatients={this.handleAddToMyPatients}
          onRemoveFromMyPatients={this.handleRemoveFromMyPatients}
          onExportSummary={this.handleExportSummary}
          onAddDetails={this.handleAddDetails}
          patient={this.props.patient}
          menuOpen={this.props.menuOpen}
          menuActions={this.props.menuActions}
          onNavigate={this.handleNavigate}
          onTabChange={this.handleTabChange}
          tab={this.props.tab}
          currentUser={this.props.currentUser}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    patientsResourceMetadata: state.jsonApiResources.patients,
    menuOpen: ownProps.patient
      ? state.menu[`${ownProps.patient.id}_menu`]
      : false,
    currentUser: state.auth.currentUser,
    tab: state.pageContext.tab ? state.pageContext.tab : ownProps.tab,
  });

  const mapDispatchToProps = dispatch => ({
    modalActions: bindActionCreators(modalActionCreators, dispatch),
    toastActions: bindActionCreators(toastActionCreators, dispatch),
    menuActions: bindActionCreators(menuActionCreators, dispatch),
    navigate: bindActionCreators(navigate, dispatch),
    replace: bindActionCreators(replace, dispatch),
    changeTab: bindActionCreators(changeTab, dispatch),
  });

  BasePatientActionContainer.propTypes = {
    patient: PropTypes.object,
    navigate: PropTypes.func,
    replace: PropTypes.func,
    modalActions: PropTypes.object,
    patientActions: PropTypes.object,
    patientsResourceMetadata: PropTypes.object,
    menuActions: PropTypes.object,
    menuOpen: PropTypes.bool,
    tab: PropTypes.string,
    currentUser: PropTypes.object,
    changeTab: PropTypes.func,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(BasePatientActionContainer);
}

export default PatientActionWrapper;
