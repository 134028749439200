import * as constants from "constants/ResetPasswordTypes";
import { navigate } from "./navigateActions";
import { push } from "connected-react-router";
import { createResource, updateResource } from "lib/api";
import { showToast } from "./toastActions";
import { SubmissionError } from "redux-form";

export function requestPasswordRequest() {
  return {
    type: constants.REQUEST_PASSWORD_RESET_REQUEST,
  };
}

export function requestPasswordSuccess() {
  return {
    type: constants.REQUEST_PASSWORD_RESET_REQUEST_SUCCESS,
    payload: {},
  };
}

export function requestPasswordFailure(error) {
  return {
    type: constants.REQUEST_PASSWORD_RESET_REQUEST_FAILURE,
    payload: {
      status: error.status,
      statusText: error.message,
      error: error,
    },
  };
}

export function updatePasswordRequest() {
  return {
    type: constants.PASSWORD_RESET_REQUEST,
  };
}

export function updatePasswordSuccess() {
  return {
    type: constants.PASSWORD_RESET_REQUEST_SUCCESS,
    payload: {},
  };
}

export function updatePasswordFailure(error) {
  return {
    type: constants.PASSWORD_RESET_REQUEST_FAILURE,
    payload: {
      status: error.status,
      statusText: error.message,
      error: error,
    },
  };
}

export function requestPasswordReset(email, redirect = "/") {
  return async (dispatch, getState) => {
    try {
      dispatch(requestPasswordRequest());
      await createResource("passwords", {
        email: email,
      });
      dispatch(requestPasswordSuccess());
      dispatch(push(redirect));

      // Show message for action taken
      const currentMessage = getState().resetPassword.statusText;
      dispatch(showToast(currentMessage));
    } catch (error) {
      dispatch(requestPasswordFailure(error));
      dispatch(showToast("An error occurred sending the request."));
      if (error.validationMessages) {
        throw new SubmissionError(error.validationMessages);
      }
    }
  };
}

export function requestPasswordUpdate(resetPasswordAttributes) {
  return async (dispatch, getState) => {
    try {
      dispatch(updatePasswordRequest());
      await updateResource("passwords", resetPasswordAttributes);
      dispatch(updatePasswordSuccess());
      dispatch(navigate("/"));

      // Show message for action taken
      const currentMessage = getState().resetPassword.statusText;
      dispatch(showToast(currentMessage));
    } catch (error) {
      dispatch(updatePasswordFailure(error));
      if (
        error.validationMessages &&
        (error.validationMessages.resetPasswordToken ||
          error.validationMessages.invitationToken)
      ) {
        dispatch(
          showToast(
            "An error occurred: Your password token may be missing, invalid, or expired."
          )
        );
      } else if (error.validationMessages) {
        dispatch(
          showToast(
            "An error occurred while resetting your password, please correct any errors."
          )
        );
        throw new SubmissionError(error.validationMessages);
      } else {
        dispatch(showToast("An error occurred while resetting your password."));
      }
    }
  };
}
