/* eslint-disable react/prop-types */
import React from "react";

import LocalizedLink from "./LocalizedLink";

export default {
  en: {
    landing: {
      title: "Hi, Welcome to Pulse",
      description:
        "A secure online portal that provides tools to help you manage your Ekso and retrieve device data.",
      policy({ policyUrl }) {
        return (
          <span>
            We recently updated our{" "}
            <LocalizedLink href={policyUrl}>privacy policy</LocalizedLink>, by
            logging in you are accepting that you understand how your data is
            used, and are ok with receiving news or notifications from Ekso
            Bionics. This data is for informational purposes only and should not
            be used for patient diagnostics
          </span>
        );
      },
    },
  },
  de: {
    landing: {
      title: "Hi, Welcome to Pulse",
      description:
        "Ein sicheres Online-Portal, das Tools zur Verfügung stellt, mit denen Sie Ihre Ekso verwalten und Gerätedaten abrufen können.",
      policy({ policyUrl }) {
        return (
          <span>
            Wir haben vor kurzem unsere{" "}
            <LocalizedLink href={policyUrl}>
              Datenschutzerklärung aktualisiert
            </LocalizedLink>
            , indem Sie sich damit einverstanden erklären, dass Sie verstehen,
            wie Ihre Daten verwendet werden, und dass Sie Nachrichten oder
            Benachrichtigungen von Ekso Bionics erhalten.
          </span>
        );
      },
    },
  },
};
