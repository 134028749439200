import React from "react";
import PropTypes from "prop-types";
import InnerPaperPanel from "components/shared/ui/Panel/InnerPaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import InnerPaperPanelTitle from "components/shared/ui/Panel/InnerPaperPanelTitle";

const GTParamPanel = ({ session }) => {
  return (
    <InnerPaperPanel>
      <InnerPaperPanelTitle title="Ekso Parameters" />
      <ParamTable>
        <ParamTableRow
          data={session}
          name="injuryMethod"
          label="Injury Method"
          paddingBottom={8}
        />
        <ParamTableRow
          data={session}
          name="kneeLtSupportSetting"
          label="Left Stance"
        />
        <ParamTableRow
          data={session}
          name="leftAssistLcdSetting"
          label="Left Assist"
          paddingBottom={8}
        />

        <ParamTableRow
          data={session}
          name="kneeRtSupportSetting"
          label="Right Stance"
        />
        <ParamTableRow
          data={session}
          name="rightAssistLcdSetting"
          label="Right Assist"
          paddingBottom={8}
        />

        <ParamTableRow data={session} name="methodWalkNum" label="Walk Mode" />
      </ParamTable>
    </InnerPaperPanel>
  );
};

GTParamPanel.propTypes = {
  session: PropTypes.object.isRequired,
};

export default GTParamPanel;
