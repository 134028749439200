import React from "react";
import PropTypes from "prop-types";
import ParamTableRow from "components/shared/ui/Parameters/ParamTableRow";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  label: {
    fontSize: "14px !important",
    opacity: 1,
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto !important",
    fontWeight: 400,
    textAlign: "right",
    marginRight: "16px",
  },
  value: {
    fontSize: "14px !important",
    color: "#9B9B9B",
    fontFamily: "Roboto !important",
    fontWeight: 400,
    textAlign: "left",
    wordBreak: "break-all",
  },
  labelCol: {
    width: "50%",
    verticalAlign: "top",
  },
  valueCol: {
    width: "50%",
  },
});

const UserDetailRow = props => {
  // Pass through to ParamTableRow, for styling purposes only
  return (
    <ParamTableRow
      uppercase={false}
      classes={{ ...props.classes }}
      paddingBottom={8}
      {...props}
    />
  );
};

UserDetailRow.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(UserDetailRow);
