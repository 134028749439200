import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";

const styles = () => ({
  readOnly: {
    marginTop: "22px",
    display: "inline-block",
    fontFamily: "Roboto",
    fontSize: "14px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
});

const MaterialField = ({
  input,
  label,
  inputComponent,
  inputProps,
  placeholder,
  disabled,
  className,
  inputClassName,
  labelClassName,
  meta,
  multiline,
  rowsMax,
  readOnly,
  classes,
  endAdornment,
}) => {
  return (
    <FormControl error={meta.touched && meta.invalid} className={className}>
      <InputLabel
        className={labelClassName}
        htmlFor={`${input.name}_input`}
        shrink={true}
      >
        {label}
      </InputLabel>
      {readOnly ? (
        <div
          id={`${input.name}_input`}
          title={input.value}
          className={classes.readOnly}
        >
          {input.value}
        </div>
      ) : (
        <Input
          id={`${input.name}_input`}
          name={input.name}
          value={input.value}
          onChange={input.onChange}
          onBlur={input.onBlur}
          inputComponent={inputComponent}
          placeholder={placeholder}
          inputProps={inputProps}
          className={inputClassName}
          disabled={disabled}
          multiline={multiline}
          rowsMax={rowsMax}
          endAdornment={endAdornment}
        />
      )}
      {meta.touched && meta.error ? (
        <FormHelperText error id={`${input.name}_helperText`}>
          {meta.error}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
};

MaterialField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  inputComponent: PropTypes.node,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  multiline: PropTypes.bool,
  rowsMax: PropTypes.string,
  classes: PropTypes.object,
  readOnly: PropTypes.bool,
  endAdornment: PropTypes.node,
};

export default withStyles(styles)(MaterialField);
