import React from "react";
import PropTypes from "prop-types";
import Text from "components/shared/ui/Typography/Text";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

const styles = theme => ({
  title: {
    fontSize: "20px",
    fontWeight: 500,
    color: "white",
    paddingTop: "12px",
  },
  titleContent: {
    paddingLeft: "8px",
    paddingTop: "4px",
  },
  root: {
    display: "flex",
    boxSizing: "border-box",
    height: "80px",
    marginLeft: "-8px",
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: 400,
    margin: "8px 0 0 0",
  },
  buttonLabel: {
    position: "absolute",
    top: "8px",
  },
  icon: {
    fontSize: "40px",
    fontWeight: 200,
    color: "white",
  },
  backButton: {
    "&:hover": {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "rgba(255, 255, 255, .05)",
        borderRadius: 0,
      },
    },
  },
});

const ManageUsersMobileHeaderContent = ({
  onNavigate,
  pageContext,
  classes,
}) => {
  return (
    <div className={classes.root}>
      <Button
        className={classes.backButton}
        classes={{ label: classes.buttonLabel }}
        onClick={() => onNavigate("/dashboard/users")}
      >
        <ChevronLeftIcon className={classes.icon} />
      </Button>
      <div className={classes.titleContent}>
        <Typography variant="title" className={classes.title}>
          {!pageContext.error ? pageContext.pageTitle : "Error"}
        </Typography>
        <Text size="14" className={classes.subTitle}>
          {pageContext.pageSubTitle}
        </Text>
      </div>
    </div>
  );
};

ManageUsersMobileHeaderContent.propTypes = {
  onNavigate: PropTypes.func,
  classes: PropTypes.object,
  pageContext: PropTypes.object,
};

export default withStyles(styles)(ManageUsersMobileHeaderContent);
