import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    height: "50px",
    width: "1px",
    backgroundColor: "rgba(224, 224, 224, 1)",
    display: "inline",
  },
});

const ContentHeaderVerticalDivider = ({ classes }) => {
  return <div className={classes.root} />;
};

ContentHeaderVerticalDivider.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ContentHeaderVerticalDivider);
