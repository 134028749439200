import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import numberFormatter from "lib/numberFormatter";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import MessagePanel from "components/shared/ui/Panel/MessagePanel";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import Skeleton from "react-loading-skeleton";
import { periodToString } from "lib/stringUtils";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    minHeight: "260px",
    maxHeight: "190px",
  },
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    display: "inline-block",
    marginRight: "8px",
    fontStyle: "normal",
  },
  panelContent: {
    boxSizing: "border-box",
    padding: "16px",
  },
  paramContent: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  field: {
    minWidth: "156px",
    marginTop: "16px",
    boxSizing: "border-box",
    paddingRight: "16px",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "28px",
    color: "rgba(52, 73, 94, 0.87)",
    width: "50%",
  },
  fieldText: {
    fontSize: "36px !important",
    lineHeight: "28px !important",
  },
  loadingPanel: {
    position: "relative",
  },
  messagePanel: {
    height: "190px",
    padding: "25px 0 0 0",
    margin: 0,
    boxSizing: "border-box",
  },
  totalsContent: {
    marginBottom: "16px",
  },
  averagesContent: {},
});

const UtilizationLayout = ({
  classes,
  calcData = {},
  resourceMetadata,
  site,
}) => {
  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  if (resourceMetadata && resourceMetadata.fetchError) {
    return (
      <div className={classes.root}>
        <ErrorPanel className={classes.messagePanel} />
      </div>
    );
  } else if (!loading && calcData.therapistsWithPatientsNumber === 0) {
    return (
      <div className={classes.root}>
        <MessagePanel
          iconName="speaker_notes"
          headerText="No Utilization Recorded"
          className={classes.messagePanel}
        />
      </div>
    );
  }

  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : "allTime";

  const utilizationData = (
    <div className={classNames(classes.paramContent, classes.averagesContent)}>
      <LargeParamField
        classes={{ root: classes.field, value: classes.fieldText }}
        data={calcData.averages ? calcData.averages : {}}
        label="Sessions"
        name="sessionLength"
        formatValue={value =>
          numberFormatter.numberWithCommas(Math.round(value))
        }
        loading={loading}
      />
      <LargeParamField
        classes={{ root: classes.field, value: classes.fieldText }}
        data={calcData.averages ? calcData.averages : {}}
        label="Steps"
        name="sessionLengthTotalSteps"
        formatValue={value =>
          numberFormatter.numberWithCommas(Math.round(value))
        }
        loading={loading}
      />
      <LargeParamField
        classes={{ root: classes.field, value: classes.fieldText }}
        data={calcData.averages ? calcData.averages : {}}
        label="Up time"
        name="sessionLengthUpTime"
        formatValue={numberFormatter.secondsToRoundedTime}
        loading={loading}
      />
      <LargeParamField
        classes={{ root: classes.field, value: classes.fieldText }}
        data={calcData.averages ? calcData.averages : {}}
        label="Walk Time"
        name="sessionLengthWalkTime"
        formatValue={numberFormatter.secondsToRoundedTime}
        loading={loading}
      />
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.panelContent} data-test="utilization">
        <Typography className={classes.header} variant="subheading">
          {loading ? (
            <Skeleton width={80} />
          ) : site ? (
            "Site Utilization ("+`${periodToString(period)}`+")"
          ) : (
            "Network Utilization ("+`${periodToString(period)}`+")"
          )}
        </Typography>
        {loading ? <LoadingPanel /> : utilizationData}
      </div>
    </div>
  );
};

UtilizationLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  site: PropTypes.object,
};

export default withStyles(styles)(UtilizationLayout);
