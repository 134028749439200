import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import WalkingStatParamLayout from "./WalkingStatParamLayout";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    paddingTop: "16px",
  },
  detailsPanel: {
    padding: "12px 16px 16px 16px",
  },
  headerContent: {
    display: "flex",
    padding: "0 16px 16px 16px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    flex: 1,
  },
  headerDesc: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(52,73,94,0.87)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
    },
  },
  sessionHeader: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "16px",
  },
  graphPanel: {
    paddingLeft: "4px",
    paddingRight: "16px",
  },
  date: {
    marginTop: "4px",
    color: "rgba(0,0,0,0.87)",
    display: "block",
    opacity: 0.5,
    fontSize: "12px",
    fontWeight: 500,
  },
});

//const MAX_SESSIONS_SHOWN = 9;
const WalkingStatsLayout = ({ insessEvent, resourceMetadata, classes }) => {
  const showParams =
    resourceMetadata &&
    !resourceMetadata.isFindingItem &&
    resourceMetadata.isFindItemSuccess;
  const showError = resourceMetadata && resourceMetadata.findError;
  const showLoading = !resourceMetadata || resourceMetadata.isFindingItem;

  return (
    <div className={classes.root}>
      {showLoading ? <LoadingPanel /> : null}

      {showError ? <ErrorPanel error={resourceMetadata.findError} /> : null}

      {showParams && insessEvent ? (
        <WalkingStatParamLayout insessEvent={insessEvent} show={true} />
      ) : null}
    </div>
  );
};

WalkingStatsLayout.propTypes = {
  insessEvent: PropTypes.object,
  resourceMetadata: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(WalkingStatsLayout);
