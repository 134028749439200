import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DoughnutChartBase from "components/shared/ui/Graph/DoughnutChartBase";
import TimeLineGraphPager from "components/shared/ui/Graph/TimeLineGraphPager";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    paddingTop: "0px",
  },
  chart: {
    marginTop: "20px",
    position: "relative",
    margin: "auto",
  },
});

const DemographicsDonutChart = ({
  classes,
  data,
  period,
  page,
  onPagerClick,
}) => {
  let chartData = data
    ? data.map(indication => {
        return indication.count;
      })
    : [];

  let labels = data
    ? data.map(indication => {
        return indication.name;
      })
    : [];

  let backgroundColor = [
    "#00a38f",
    "#a85fff",
    "#f57c00",
    "#64b5f6",
    "#e94948",
    "#388e3c",
    "#65fff2",
    "#2600c6",
    "#ffb74d",
    "#1976d2",
    "#81c784",
    "#790000",
  ];

  return (
    <div className={classes.root} data-test="demographicsChart">
      {page ? (
        <TimeLineGraphPager
          period={period}
          page={page}
          onPagerClick={onPagerClick}
        />
      ) : (
        ""
      )}
      <DoughnutChartBase
        className={classes.chart}
        data={chartData}
        backgroundColor={backgroundColor}
        labels={labels}
      />
    </div>
  );
};

DemographicsDonutChart.propTypes = {
  classes: PropTypes.object,
  range: PropTypes.string,
  data: PropTypes.object,
  period: PropTypes.string,
  page: PropTypes.number,
  onPagerClick: PropTypes.func,
};

export default withStyles(styles)(DemographicsDonutChart);
