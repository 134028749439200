import * as validationUtils from "validation/validationUtils";
import * as InSessDataTypes from "constants/InSessDataTypes";
import moment from "moment";

const HHMMSS_REGEX = /^\d{1,2}:\d{2}:\d{2}$/;
const METERS_REGEX = /^(\d+)?([.]?\d{0,1})?$/;

export default function validate(values) {
  const errors = {};
  validationUtils.validateRequired(
    ["insessEventResult", "insessSpeedMode"],
    values,
    errors
  );

  switch (values.type) {
    case InSessDataTypes.TEN_METER_WALK:
      if (!HHMMSS_REGEX.test(values.insessEventResult)) {
        errors.insessEventResult =
          "Must be a valid time duration with format HH:MM:SS";
      } else if (moment.duration(values.insessEventResult).asSeconds() > 300) {
        errors.insessEventResult = "Result should not be greater than 5 min";
      }
      break;
    case InSessDataTypes.TWO_MIN_WALK:
      if (!METERS_REGEX.test(values.insessEventResult)) {
        errors.insessEventResult =
          "Must be a valid number of meters with a maximum of 1 decimal precision";
      } else if (parseFloat(values.insessEventResult) > 180) {
        errors.insessEventResult = "Result should not be greater than 180m";
      }
      break;
    case InSessDataTypes.SIX_MIN_WALK:
      if (!METERS_REGEX.test(values.insessEventResult)) {
        errors.insessEventResult =
          "Must be a valid number of meters with a maximum of 1 decimal precision";
      } else if (parseFloat(values.insessEventResult) > 540) {
        errors.insessEventResult = "Result should not be greater than 540m";
      }
      break;
    default:
    // do nothing
  }

  return errors;
}
