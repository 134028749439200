import * as types from "constants/PageContextTypes";
import { RESET } from "constants/ClearTypes";

const initialState = {
  pageTitle: "",
  path: "",
  onFilter: () => {},
  isFilterOpen: false,
  onSearch: () => {},
  isSearchOpen: false,
  mobileToolbarHeight: null,
  data: null,
  tab: null,
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.SET_PAGE_CONTEXT: {
      return {
        ...state,
        mobileToolbarHeight: null,
        data: null,
        tab: null,
        ...action.pageContext,
      };
    }

    case types.TOGGLE_FILTER: {
      return {
        ...state,
        isFilterOpen: !state.isFilterOpen,
      };
    }

    case types.TOGGLE_SEARCH: {
      return {
        ...state,
        isSearchOpen: !state.isSearchOpen,
      };
    }

    case types.CHANGE_TAB: {
      return {
        ...state,
        tab: action.tab,
      };
    }

    case RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
