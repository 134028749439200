import React from "react";
import PropTypes from "prop-types";
import { calculateSuggestedMaxYFromData } from "lib/graphUtils";
import LineGraphBase from "./LineGraphBase";

const MAX_TICKS = 15;
const MIN_TICKS = 5;

const SessionGraphBase = ({
  id,
  data,
  emptyMax,
  unitLabel,
  tooltipLabelCallback,
  lineColor,
  pointColor,
  fill,
  fillColor,
  pointRadius,
  maxY,
}) => {
  let suggestedMax = maxY;
  if (!maxY) {
    suggestedMax = calculateSuggestedMaxYFromData(data, emptyMax);
  }

  let length = data.length;
  // Array of arrays: merge to get suggesedtMaxY
  if (data && data.length && Array.isArray(data[0])) {
    length = data[0].length;
  }

  let stepSize = 1;
  // Fallback on graph internal tick algorithm when length exceeds MAX_TICKS
  if (length > MAX_TICKS) {
    stepSize = null;
  }

  let max;
  if (length < MIN_TICKS) {
    max = 5;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItems, data) => {
          const datasetIndex = tooltipItems[0].datasetIndex;
          const dataIndex = tooltipItems[0].index;
          const xLabel = tooltipItems[0].xLabel;
          const pointData = data.datasets[datasetIndex].data[dataIndex];
          return ["Session " + xLabel, pointData.date];
        },
        label: tooltipLabelCallback
          ? tooltipLabelCallback
          : tooltipItem => tooltipItem.yLabel + ` ${unitLabel}`,
      },
    },
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Session",
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 10,
            fontFamily: "Roboto",
            padding: 0,
          },
          bounds: "ticks",
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            min: 1,
            max,
            stepSize,
            source: "auto",
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 8,
            fontFamily: "Roboto",
            padding: 5,
            maxTicksLimit: 15,
          },
          type: "linear",
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: false,
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 10,
            fontFamily: "Roboto",
            padding: 0,
          },
          gridLines: {
            drawTicks: false,
          },
          ticks: {
            precision: 0,
            min: 0,
            max: maxY,
            maxTicksLimit: 8,
            suggestedMax,
            fontColor: "rgba(0,0,0,0.50)",
            fontSize: 8,
            fontFamily: "Roboto",
            padding: 5,
          },
        },
      ],
    },
  };

  return (
    <div id={`${id}_session_graph`}>
      <LineGraphBase
        data={data}
        labels={[]}
        options={options}
        lineColor={lineColor}
        pointColor={pointColor}
        fill={fill}
        fillColor={fillColor}
        pointRadius={pointRadius}
        height={"179px"}
        margin={"8px 0 0 0"}
      />
    </div>
  );
};

SessionGraphBase.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.array,
  period: PropTypes.string,
  unitLabel: PropTypes.string,
  emptyMax: PropTypes.number,
  tooltipLabelCallback: PropTypes.func,
  pointRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  lineColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pointColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  fill: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  maxY: PropTypes.number,
};

export default SessionGraphBase;
