import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Hidden } from "@material-ui/core";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import OverviewMobileTabs from "../OverviewMobileTabs";
import AdminUtilizationLayout from "./AdminUtilizationLayout";
import RecentSessions from "../RecentSessions";

const AdminContentLayout = ({ resourceMetadata, calcData }) => {
  const utilization = (
    <AdminUtilizationLayout
      resourceMetadata={resourceMetadata}
      calcData={calcData}
    />
  );
  const recentSessions = <RecentSessions />;

  return (
    <Fragment>
      <Hidden smDown>
        <Grid item xs={12} md={6}>
          <PaperPanel>{recentSessions}</PaperPanel>
        </Grid>
        <Grid item xs={12} md={6}>
          <PaperPanel>{utilization}</PaperPanel>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <OverviewMobileTabs
          recentSessionsTable={recentSessions}
          utilization={utilization}
          utilizationTabLabel="Utilization"
        />
      </Hidden>
    </Fragment>
  );
};

AdminContentLayout.defaultProps = {};

AdminContentLayout.propTypes = {
  resourceMetadata: PropTypes.object,
  calcData: PropTypes.object,
};

export default AdminContentLayout;
