import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// eslint-disable-next-line
const styles = theme => ({
  icon: {
    color: "#E74C3C",
    float: "right",
    fontSize: "14px",
  },
  iconHidden: {
    display: "none",
  },
});

const UnreadDotIcon = ({ classes, read, id }) => {
  return (
    <FiberManualRecord
      className={classNames(classes.icon, {
        [classes.iconHidden]: read,
      })}
      id={id}
    />
  );
};

UnreadDotIcon.propTypes = {
  read: PropTypes.bool,
  classes: PropTypes.object,
  id: PropTypes.string,
};

export default withStyles(styles)(UnreadDotIcon);
