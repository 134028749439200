let rules = {};

export function configPolicyRules(newRules) {
  rules = newRules;
}

export default function policyRulesEvaluator(
  action,
  object,
  currentUser,
  context = {}
) {
  let objectEvaluator;

  objectEvaluator = rules[object];

  if (!objectEvaluator || !objectEvaluator[action]) {
    return false;
  }

  return objectEvaluator[action](currentUser, context);
}
