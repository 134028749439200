import { combineReducers } from "redux";
import reduxAdapter from "@blue-chip/redux-adapter";
import { reducer as formReducer } from "redux-form";
import auth from "./auth";
import localization from "reducers/localization";
import resetPassword from "./resetPassword";
import toast from "./toast";
import pageContext from "./pageContext";
import jsonApiResources from "./jsonApiResources";
import modal from "./modal";
import menu from "./menu";
import notification from "./notification";
import globalFilter from "./globalFilter";
import videoPlayback from "./videoPlayback";
import network from "./network";

const rootReducer = combineReducers({
  resources: reduxAdapter.resourcesReducer,
  auth,
  localization,
  resetPassword,
  toast,
  pageContext,
  jsonApiResources,
  form: formReducer,
  modal,
  menu,
  notification,
  globalFilter,
  videoPlayback,
  network,
});

export default rootReducer;
