import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const styles = theme => ({
  root: {
    position: "relative",
  },
  category: {
    color: "#9B9B9B",
    fontFamily: "Roboto",
    fontSize: "10px",
    lineHeight: "11px",
    fontWeight: 700,
    textTransform: "uppercase",
  },
  question: {
    paddingBottom: "16px",
    marginTop: "8px",
    color: "#4A4A4A",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    paddingRight: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  open: {
    paddingBottom: "6px",
  },
  shadowBlocker: {
    position: "absolute",
    height: "10px",
    width: "calc(100% + 46px)",
    bottom: "-2px",
    backgroundColor: "white",
    marginLeft: "-16px",
  },
  icon: {
    color: "#B4B4B4",
    position: "absolute",
    top: "12px",
    right: "0px",
    [theme.breakpoints.down("sm")]: {
      top: "20px",
    },
  },
});

const FaqHeader = ({ classes, faq }) => {
  return (
    <div className={classes.root}>
      <Typography className={classes.category}>
        {faq.faqCategory ? faq.faqCategory.title : "No Category"}
      </Typography>
      <Typography
        className={classNames(classes.question, {
          [classes.open]: faq.isActive,
        })}
      >
        {faq.question}
      </Typography>
      {faq.isActive ? (
        <ExpandLess className={classes.icon} />
      ) : (
        <ExpandMore className={classes.icon} />
      )}
      <div className={classes.shadowBlocker} />
    </div>
  );
};

FaqHeader.propTypes = {
  faq: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(FaqHeader);
