import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import CreatableField from "components/shared/ui/Field/CreatableField";

const validate = values => {
  const errors = [];

  if (!values.pilotNum) {
    errors.pilotNum = "Required";
  }
  return errors;
};

const onKeyPress = event => {
  if (event.key === "Enter") {
    event.preventDefault(); //<===== This stops the form from being submitted
  }
};

const EditSessionReduxForm = ({ onSubmit, session, patients }) => {
  return (
    <form onSubmit={onSubmit} onKeyPress={onKeyPress}>
      <Field
        name="pilotNum"
        label="Patient ID"
        component={CreatableField}
        validation={{
          type: "number",
          values: { from: 0, to: 1999, completeZeros: true, digits: 4 },
        }}
        inputProps={{
          "data-test": "pilotNum",
          value: session.pilotNum || "",
          options: patients.map(item => ({
            label: item,
            value: item,
          })),
        }}
      />
    </form>
  );
};

EditSessionReduxForm.propTypes = {
  onSubmit: PropTypes.func,
  classes: PropTypes.object,
  fieldData: PropTypes.object,
  disabled: PropTypes.bool,
  session: PropTypes.object,
  patients: PropTypes.array,
};

export default reduxForm({
  form: "editSessionReduxForm",
  validate,
})(EditSessionReduxForm);
