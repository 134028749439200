import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import * as networkActionCreators from "actions/networkActions";
import NetworkCompareModalDialog from "./NetworkCompareModalDialog";
import { NETWORK_COMPARE_MODAL_ID } from "constants/ModalIds";
import { translateDatePeriodFilter } from "lib/filterUtils";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { STATS_SITES_UTILIZATION_GRAPH } from "constants/NetworkTypes";

class NetworkCompareModalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compare: null,
      page: props.globalPage || 1,
      clientFilter: {
        period: props.globalPeriod || "allTime",
      },
    };
  }

  componentDidUpdate(prevProps) {
    let { compare } = this.state;
    let { modalData, globalPeriod, globalPage } = this.props;

    if (modalData !== prevProps.modalData) {
      compare = {
        ...compare,
        title: modalData.title,
      };
      this.setState({ compare });
    }

    if (globalPeriod !== prevProps.globalPeriod) {
      this.setState({clientFilter: { period: globalPeriod }});
    }

    if (globalPage !== prevProps.globalPage) {
      this.setState({ page: globalPage });
    }
  }

  handleClose = () => {
    this.props.modalActions.closeModal(NETWORK_COMPARE_MODAL_ID);
    this.props.siteAction.drawSites([]);
    this.setState({
      compare: null,
      page: this.props.globalPage,
      clientFilter: { period: this.props.globalPeriod },
    });
  };

  showCompare = show => {
    let {
      modalData: { title },
    } = this.props;
    let compare = {
      title,
      show: show,
    };
    this.props.networkActions.compareShow(compare);
    this.setState({ compare });
  };

  quickFilter = quickFilter => {
    let { site_id } = this.props;
    let filter = {
      ...translateDatePeriodFilter(quickFilter.period),
      period: quickFilter.period,
      modal: true,
      site_id
    };

    this.props.getUtilizationGraph.get({ filter });
    this.setState({ clientFilter: quickFilter, page: 1 });
  };

  onPagerClick = dateFilter => {
    let { site_id } = this.props;
    let filter = {
      ...translateDatePeriodFilter(dateFilter.period),
      ...dateFilter,
      modal: true,
      site_id
    };
    this.props.getUtilizationGraph.get({ filter });
    this.setState({ clientFilter: filter, page: dateFilter.page });
  };

  render() {
    let { open, modalData, menuItems, sites, site_id } = this.props;
    let { compare, page, clientFilter } = this.state;
    return (
      <NetworkCompareModalDialog
        onClose={this.handleClose}
        open={open}
        modalData={modalData}
        showCompare={this.showCompare}
        compare={compare}
        menuItems={menuItems}
        quickFilter={this.quickFilter}
        period={clientFilter.period}
        onPagerClick={this.onPagerClick}
        page={page}
        sites={sites}
        site_id={site_id}
      />
    );
  }
}

const mapStateToProps = state => ({
  open: state.modal[NETWORK_COMPARE_MODAL_ID]
    ? state.modal[NETWORK_COMPARE_MODAL_ID].open
    : false,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  networkActions: bindActionCreators(networkActionCreators, dispatch),
  getUtilizationGraph: jsonAPIReduxActions(
    dispatch,
    STATS_SITES_UTILIZATION_GRAPH
  ),
  siteAction: bindActionCreators(networkActionCreators, dispatch)
});

NetworkCompareModalContainer.propTypes = {
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  networkActions: PropTypes.object,
  modalData: PropTypes.object,
  getUtilizationGraph: PropTypes.object,
  network: PropTypes.object,
  menuItems: PropTypes.array,
  sites: PropTypes.array,
  site_id: PropTypes.number,
  globalPeriod: PropTypes.string,
  globalPage: PropTypes.number,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkCompareModalContainer);
