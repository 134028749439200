import React from "react";
import PropTypes from "prop-types";
import Text from "../Typography/Text";
import { withStyles } from "@material-ui/core/styles";
import _ from "lodash";

const styles = theme => ({
  label: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#1D2426",
    opacity: 0.5,
    marginRight: theme.spacing.unit * 3,
    margin: 0,
    lineHeight: "21px",
    textAlign: "left",
  },
  value: {
    fontSize: "12px",
    color: "#1D2426",
    fontWeight: 700,
    margin: 0,
    lineHeight: "21px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
    },
  },
  labelCol: {
    verticalAlign: "top",
    whiteSpace: "nowrap",
  },
  valueCol: {},
});

const ParamTableRow = ({
  data,
  name,
  label,
  formatValue,
  classes,
  uppercase,
  paddingBottom,
  children,
}) => {
  let value = "";
  let formattedLabel = label ? label : "";
  if (data && formatValue) {
    value = formatValue(data[name], data, name);
  } else if (data && data[name] !== undefined && data[name] !== null) {
    value = `${data[name]}`;
  }

  if (uppercase) {
    formattedLabel = formattedLabel.toUpperCase();
  }

  if (
    _.isString(value) &&
    (value.includes("undefined") || value.includes("null"))
  ) {
    value = "-";
  }

  return (
    <tr className={classes.root} data-test={`${name}_row`}>
      <th className={classes.labelCol} style={{ paddingBottom }}>
        <Text className={classes.label} fontSize={12} fontFamily="Noto Sans">
          {formattedLabel}
        </Text>
      </th>
      <td className={classes.valueCol} style={{ paddingBottom }}>
        {children ? (
          children
        ) : (
          <Text className={classes.value} fontSize={12} fontFamily="Noto Sans">
            {value ? value : "-"}
          </Text>
        )}
      </td>
    </tr>
  );
};

ParamTableRow.defaultProps = {
  uppercase: true,
  paddingBottom: 0,
};

ParamTableRow.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  formatValue: PropTypes.func,
  uppercase: PropTypes.bool,
  paddingBottom: PropTypes.number,
  children: PropTypes.node,
};

export default withStyles(styles)(ParamTableRow);
