import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import DetailsPageStatusContent from "components/shared/ui/Page/DetailsPageStatusContent";
import UserDetailPanel from "./UserDetailPanel";
import HeaderContainer from "./HeaderContainer";
import UserModal from "../UserModal";

import UserLog from "./UserLog";
import { TransitionGroup, CSSTransition } from "react-transition-group";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
  },
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
  grid: {
    flexDirection: "row-reverse",
  },
  tablePanel: {
    height: "100%",
  },
  tablePanelMessage: {
    maxHeight: "275px",
  },
});

const UserDetailsLayout = ({ classes, user, id, usersResourceMetadata }) => {
  const loading = !usersResourceMetadata || usersResourceMetadata.isFindingItem;
  const error = usersResourceMetadata && usersResourceMetadata.findError;
  let statusContent = (
    <DetailsPageStatusContent loading={loading} error={error} />
  );

  return (
    <div className={classes.root}>
      <HeaderContainer
        user={user}
        error={!!usersResourceMetadata && !!usersResourceMetadata.findError}
      />
      <div className={classes.main}>
        {statusContent}
        <TransitionGroup appear className="userDetail-content">
          {!loading && !error ? (
            <CSSTransition
              key={id}
              timeout={600}
              exit={false}
              classNames="fade"
            >
              <Grid className={classes.grid} container spacing={8}>
                <Grid item xs={12} md={5} lg={4}>
                  <UserDetailPanel user={user ? user : {}} />
                </Grid>
                <Grid item xs={12} md={7} lg={8}>
                  <PaperPanel>
                    <UserLog requiredFilter={{ user_id: id }} />
                  </PaperPanel>
                </Grid>
              </Grid>
            </CSSTransition>
          ) : null}
        </TransitionGroup>
      </div>
      <UserModal />
    </div>
  );
};

UserDetailsLayout.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  usersResourceMetadata: PropTypes.object,
  id: PropTypes.string,
};

export default withStyles(styles)(UserDetailsLayout);
