import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
//import * as toastActionCreators from "actions/toastActions";
import PatientModalDialog from "./PatientModalDialog";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { PATIENT_MODAL_ID } from "constants/ModalIds";
import {
  submit as submitActionCreator,
  getFormSyncErrors,
  isPristine,
  stopSubmit as stopSubmitActionCreator,
  initialize as initializeActionCreator,
} from "redux-form";
import _ from "lodash";

import FieldData from "models/bluechip/FieldData";
import Patient from "models/bluechip/Patient";

const FORM_NAME = "patientReduxForm";

function createInitialValues(patient) {
  if (!patient) {
    return {};
  }

  const initialValues = {
    indication: patient.indication ? patient.indication.id : "",
    settingOfUse: patient.settingOfUse ? patient.settingOfUse.id : "",
    assistiveDevices: patient.assistiveDevices
      ? patient.assistiveDevices.map(assistiveDevice => assistiveDevice.id)
      : null,
    myPatient: !!patient.myPatient,
  };

  return initialValues;
}

class PatientModalContainer extends Component {
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    const wasUpdating =
      prevProps.patientsResourceMetadata &&
      prevProps.patientsResourceMetadata.isUpdatingItem;
    const wasOpen = prevProps.open;
    const { stopSubmit, initializeForm, patient } = this.props;
    const {
      isUpdateItemSuccess,
      updateError,
    } = this.props.patientsResourceMetadata;

    // After update close the dialog, or show field errors if any
    if (wasUpdating && isUpdateItemSuccess) {
      this.handleClose();
    } else if (wasUpdating && !!updateError) {
      if (updateError.validationMessages) {
        // Throw redux-form submission error
        stopSubmit(FORM_NAME, updateError.validationMessages);
      }
    }

    // Retrieve necessary data to render when dialog is open
    if (!wasOpen && this.props.open) {
      const {
        patientsActions,
        patientsFieldData,
        fieldDataActions,
        patientObjectId,
      } = this.props;

      if (
        !this.props.patientsResourceMetadata.foundIds.includes(patientObjectId)
      ) {
        patientsActions.find(patientObjectId);
      }

      if (_.isEmpty(patientsFieldData)) {
        fieldDataActions.find("patients", {
          errorToast: "Patient field data retrieval failed",
        });
      }
    }

    if (!_.isEqual(patient, prevProps.patient)) {
      initializeForm(FORM_NAME, createInitialValues(patient));
    }
  }

  handleSubmit = form => {
    if (this.props.isSubmitDisabled) {
      return;
    }

    const values = {
      relationships: {
        indication: form.indication,
        setting_of_use: form.settingOfUse,
        assistive_devices:
          form.assistiveDevices && form.assistiveDevices.length
            ? form.assistiveDevices
            : [],
      },
      my_patient: form.myPatient,
      id: this.props.patient.id,
    };

    this.props.patientsActions.update(values, {
      successToast: "Patient Updated",
      errorToast: "Patient Update Failed",
    });
  };

  handleClose = () => {
    this.props.modalActions.closeModal(PATIENT_MODAL_ID);
  };

  handlePrimaryAction = () => {
    this.props.submit(FORM_NAME);
  };

  render() {
    const { patientsResourceMetadata, fieldDataResourceMetadata } = this.props;
    if (!patientsResourceMetadata) {
      return null;
    }

    const loading =
      patientsResourceMetadata.isFindingItem ||
      fieldDataResourceMetadata.isFindingItem;
    const disabled =
      loading ||
      !!patientsResourceMetadata.findError ||
      !!fieldDataResourceMetadata.findError;
    return (
      <PatientModalDialog
        onSubmit={this.handleSubmit}
        onPrimaryAction={this.handlePrimaryAction}
        primaryActionLabel="Save"
        isSubmitDisabled={
          disabled || this.props.isSubmitDisabled || this.props.isPristine
        }
        loading={loading}
        disabled={disabled}
        onClose={this.handleClose}
        open={this.props.open}
        submitting={
          patientsResourceMetadata && patientsResourceMetadata.isUpdatingItem
        }
        patient={this.props.patient ? this.props.patient : {}}
        initialValues={createInitialValues(this.props.patient)}
        fieldData={this.props.patientsFieldData}
      />
    );
  }
}

const mapStateToProps = state => {
  const patientObjectId = state.modal[PATIENT_MODAL_ID]
    ? state.modal[PATIENT_MODAL_ID].data.id
    : null;
  return {
    open: state.modal[PATIENT_MODAL_ID]
      ? state.modal[PATIENT_MODAL_ID].open
      : false,
    patientObjectId,
    patient:
      state.resources.patients && patientObjectId
        ? Patient.query(state.resources)
            .where({ id: patientObjectId })
            .includes(["indication", "settingOfUse", "assistiveDevices"])
            .all()
            .toObjects()[0]
        : null,
    patientsFieldData: state.resources.fieldData
      ? FieldData.query(state.resources)
          .where({ id: "patients" })
          .all()
          .toObjects()[0]
      : {},
    patientsResourceMetadata: state.jsonApiResources.patients,
    fieldDataResourceMetadata: state.jsonApiResources.field_data
      ? state.jsonApiResources.field_data
      : {},
    isSubmitDisabled: !_.isEmpty(getFormSyncErrors(FORM_NAME)(state)),
    isPristine: isPristine(FORM_NAME)(state),
  };
};

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  patientsActions: jsonAPIReduxActions(dispatch, "patients"),
  fieldDataActions: jsonAPIReduxActions(dispatch, "field_data"),
  submit: bindActionCreators(submitActionCreator, dispatch),
  stopSubmit: bindActionCreators(stopSubmitActionCreator, dispatch),
  initializeForm: bindActionCreators(initializeActionCreator, dispatch),
});

PatientModalContainer.propTypes = {
  currentUser: PropTypes.object,
  patient: PropTypes.object,
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  patientsActions: PropTypes.object,
  submit: PropTypes.func,
  toastActions: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  stopSubmit: PropTypes.func,
  initializeForm: PropTypes.func,
  patientsResourceMetadata: PropTypes.object,
  patientsFieldData: PropTypes.object,
  fieldDataActions: PropTypes.object,
  patientObjectId: PropTypes.string,
  fieldDataResourceMetadata: PropTypes.object,
  isPristine: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientModalContainer);
