import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";

// #lizard forgives
function EksoGraphContainerWrapper(
  WrappedEksoGraphComponent,
  BlueChipResourceModel,
  options
) {
  class EksoGraphContainer extends Component {
    componentDidMount() {
      if (!options.skipMountClear && this.props.resourceExists) {
        this.props.resourceActions.removeResource(
          this.props.id,
          options.resourceTypeName
        );
      }

      const dataLoaded = !_.isEmpty(this.props.calcData);
      if (!dataLoaded || !options.skipMountClear) {
        this.props.resourceActions.init({
          filter: options.startFilter ? options.startFilter : {},
          firstLoad: true,
        });
      }
    }

    componentDidUpdate() {
      const noDataLoaded = _.isEmpty(this.props.calcData);
      if (
        !!this.props.resourceMetadata &&
        noDataLoaded &&
        !this.props.resourceMetadata.isFetchingItems &&
        this.props.resourceMetadata.firstLoad
      ) {
        this.load();
      }
    }

    _requiredFilter = (filter, site_id, period, page) => {
      if (site_id && !!filter.modal){
        filter = {
          ...filter,
          site_id,
        }
      }

      if (!!period){
        filter = {
          ...filter,
          period: period
        }
      }

      if (!!page){
        filter = {
          ...filter,
          page: page
        }
      }

      return filter;
    };

    load = () => {
      let { site_id, resourceMetadata: { filter }, requiredFilter, period, page } = this.props;
      filter = this._requiredFilter(filter, site_id, period, page);
      this.props.resourceActions.get({
        filter: _.extend(
          {},
          requiredFilter,
          filter
        ),
      });
    };

    onQuickFilter = quickFilter => {
      let filter = _.extend(
        {},
        this.props.resourceMetadata.filter,
        quickFilter
      );
      filter = _.extend({}, this.props.requiredFilter, filter);

      this.props.resourceActions.get({ filter });

      if (this.props.siblingResourceActions) {
        this.props.siblingResourceActions.forEach(actions => {
          actions.get({ filter });
        });
      }
    };

    render() {
      return (
        <WrappedEksoGraphComponent
          {...this.props}
          {...this.state}
          calcData={this.props.calcData}
          resourceMetadata={this.props.resourceMetadata}
          resourceActions={this.props.resourceActions}
          load={this.load}
          onQuickFilter={this.onQuickFilter}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      id: ownProps.id ? ownProps.id : options.id,
      resourceExists: !!state.resources[options.resourceTypeName],
      calcData: state.resources[options.resourceTypeName]
        ? BlueChipResourceModel.query(state.resources)
            .where({ id: ownProps.id ? ownProps.id : options.id })
            .all()
            .toObjects()[0]
        : {},
      resourceMetadata: state.jsonApiResources[options.resourceName],
      requiredFilter: ownProps.requiredFilter ? ownProps.requiredFilter : {},
    };
  };

  const mapDispatchToProps = (dispatch, ownProps) => ({
    resourceActions: jsonAPIReduxActions(dispatch, options.resourceName),
    siblingResourceActions: ownProps.siblingResourceNames
      ? ownProps.siblingResourceNames.reduce((results, resourceName) => {
          if (resourceName !== options.resourceName) {
            results.push(jsonAPIReduxActions(dispatch, resourceName));
          }

          return results;
        }, [])
      : null,
  });

  EksoGraphContainer.propTypes = {
    id: PropTypes.string,
    resourceExists: PropTypes.bool,
    resourceMetadata: PropTypes.object,
    calcData: PropTypes.object,
    resourceActions: PropTypes.object,
    requiredFilter: PropTypes.object,
    siblingResourceNames: PropTypes.array,
    siblingResourceActions: PropTypes.array,
    site_id: PropTypes.number,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(EksoGraphContainer);
}

export default EksoGraphContainerWrapper;
