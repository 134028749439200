import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import IndicationCheckboxSelect from "./IndicationCheckboxSelectComponent";
import FieldData from "models/bluechip/FieldData";
import _ from "lodash";

class IndicationCheckboxSelectContainer extends Component {
  componentDidMount() {
    // Retrieve and populate indication data
    if (_.isEmpty(this.props.patientsFieldData)) {
      this.props.fieldDataActions.find("patients", {
        errorToast: "Patient field data retrieval failed",
      });
    }
  }

  render() {
    const { value, patientsFieldData, onQuickFilter } = this.props;
    let indications =
      patientsFieldData && patientsFieldData.indications
        ? patientsFieldData.indications
        : [];
    let checkboxValues = [];
    if (value) {
      checkboxValues = value;
    } else if (indications) {
      checkboxValues = indications.map(item => `${item.id}`);
    }

    return (
      <IndicationCheckboxSelect
        indications={indications}
        value={checkboxValues}
        onQuickFilter={onQuickFilter}
      />
    );
  }
}

const mapStateToProps = state => ({
  patientsFieldData: state.resources.fieldData
    ? FieldData.query(state.resources)
        .where({ id: "patients" })
        .all()
        .toObjects()[0]
    : {},
});

const mapDispatchToProps = dispatch => ({
  fieldDataActions: jsonAPIReduxActions(dispatch, "field_data"),
});

IndicationCheckboxSelectContainer.propTypes = {
  patientsFieldData: PropTypes.object,
  fieldDataActions: PropTypes.object,
  value: PropTypes.array,
  onQuickFilter: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndicationCheckboxSelectContainer);
