import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import LearningCenterItemLayout from "./LearningCenterItemLayout";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    height: "100%",
  },
  button: {
    color: "rgba(74,74,74,1)",
    display: "block",
    marginTop: "8px",
    cursor: "pointer",
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },
  buttonText: {
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "24px",
  },
  category: {
    color: "#9B9B9B",
    fontFamily: "Roboto",
    fontSize: "10px",
    lineHeight: "11px",
    textTransform: "uppercase",
    fontWeight: 700,
  },
});

const LearningCenterFaqCategoryItem = ({ classes, item, onNavigate }) => {
  const category = item.faqCategory ? item.faqCategory.id : "NOT_SET";

  return (
    <LearningCenterItemLayout classes={{ root: classes.root }} id={category}>
      <Typography className={classes.category} data-test="category">
        {item.faqCategory ? item.faqCategory.title : "No Category"}
      </Typography>
      {item.questions.map(questionData => (
        <a
          className={classes.button}
          key={questionData.id}
          id={`question-${questionData.id}`}
          onClick={() =>
            onNavigate(
              `/dashboard/learning/faq?filter[category]=${category}&filter[id]=${
                questionData.id
              }`
            )
          }
        >
          <span className={classes.buttonText} data-test="question">
            {questionData.question}
          </span>
        </a>
      ))}
    </LearningCenterItemLayout>
  );
};

LearningCenterFaqCategoryItem.propTypes = {
  classes: PropTypes.object,
  onNavigate: PropTypes.func,
  item: PropTypes.object,
};

export default withStyles(styles)(LearningCenterFaqCategoryItem);
