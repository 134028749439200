import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { setContext } from "actions/pageContextActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import * as modalActionCreators from "actions/modalActions";
import LearningCenterChildPage from "../childPage/LearningCenterChildPage";
import LearningResourceVideo from "models/bluechip/LearningResourceVideo";
import { VIDEO_MODAL_ID } from "constants/ModalIds";

class LearningCenterContainer extends Component {
  componentDidMount() {
    this.props.setContext({
      pageTitle: "Training Videos",
      isFilterOpen: false,
      path: "/dashboard/learning/videos",
      isSearchOpen: false,
    });

    this.props.resourceActions.clear(["learningResourceVideos"]);
    this.props.resourceActions.init({
      firstLoad: true,
    });
  }

  componentDidUpdate() {
    // Learning resource initial request
    if (
      !this.props.resourceMetadata.isFetchingItems &&
      this.props.resourceMetadata.firstLoad
    ) {
      this.props.resourceActions.get({
        type: "LearningResourceVideo",
        locale: this.props.locale,
      });
    }
  }

  onPlayVideo = videoData => {
    this.props.modalActions.openModalWithData(VIDEO_MODAL_ID, videoData);
  };

  render() {
    const { push, trainingVideoItems, resourceMetadata } = this.props;
    return (
      <LearningCenterChildPage
        onNavigate={push}
        title="Training Videos"
        id="trainingVideoPageContent"
        items={trainingVideoItems}
        loading={resourceMetadata.isFetchingItems}
        error={resourceMetadata.fetchError}
        onPlayVideo={this.onPlayVideo}
      />
    );
  }
}

const mapStateToProps = state => ({
  lang: state.localization.lang,
  resourceMetadata: state.jsonApiResources.learning_resources
    ? state.jsonApiResources.learning_resources
    : {},
  trainingVideoItems: state.resources.learningResourceVideos
    ? LearningResourceVideo.query(state.resources)
        .all()
        .toObjects()
    : [],
});

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  resourceActions: jsonAPIReduxActions(dispatch, "learning_resources"),
  setContext: bindActionCreators(setContext, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
});

LearningCenterContainer.propTypes = {
  push: PropTypes.func,
  resourceActions: PropTypes.object,
  resourceMetadata: PropTypes.object,
  trainingVideoItems: PropTypes.array,
  setContext: PropTypes.func,
  modalActions: PropTypes.object,
  locale: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LearningCenterContainer);
