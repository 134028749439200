import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import VideoModalDialog from "./VideoModalDialog";
import { VIDEO_MODAL_ID } from "constants/ModalIds";

class VideoModalContainer extends Component {
  handleClose = () => {
    this.props.modalActions.closeModal(VIDEO_MODAL_ID);
  };

  render() {
    return (
      <VideoModalDialog
        onClose={this.handleClose}
        open={this.props.open}
        videoData={this.props.videoData}
      />
    );
  }
}

const mapStateToProps = state => ({
  open: state.modal[VIDEO_MODAL_ID] ? state.modal[VIDEO_MODAL_ID].open : false,
  videoData: state.modal[VIDEO_MODAL_ID]
    ? state.modal[VIDEO_MODAL_ID].data
    : null,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
});

VideoModalContainer.propTypes = {
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  videoData: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoModalContainer);
