import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import Policy from "components/Policy";

import ManageUsersContainer from "./ManageUsersContainer";
import UserDetails from "./UserDetails";

class ManageUsersRoutes extends Component {
  render() {
    const { match } = this.props;
    return (
      <Policy action="view" object="users" forceRedirect>
        <Switch>
          <Route
            exact
            path={`${match.url}/`}
            render={() => {
              // Main Index page
              return <ManageUsersContainer />;
            }}
          />
          <Route
            exact
            path={`${match.url}/:id`}
            render={props => {
              // View Details Page
              return <UserDetails id={props.match.params.id} />;
            }}
          />
        </Switch>
      </Policy>
    );
  }
}

ManageUsersRoutes.propTypes = {
  match: PropTypes.object,
};

export default ManageUsersRoutes;
