import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalActionCreators from "actions/modalActions";
import EditPatientIDModalDialog from "./EditSessionModalDialog";
import { EDIT_SESSION_MODAL_ID } from "constants/ModalIds";
import FieldData from "models/bluechip/FieldData";
import policyRules from "lib/policyRules";
import {
  submit as submitActionCreator,
  getFormSyncErrors,
  isPristine,
} from "redux-form";
import _ from "lodash";

import { jsonAPIReduxActions } from "lib/jsonAPIRedux";

const FORM_NAME = "editSessionReduxForm";
class EditSessionModalContainer extends Component {
  componentDidUpdate(prevProps) {
    const { isEksoUser, session, filterFormsActions } = this.props;
    if (isEksoUser && prevProps.session.id !== session.id) {
      filterFormsActions.find("pilot_numbers", {
        findParams: { customer_num: [session.customerNum] },
      });
    }
  }

  handleClose = () => {
    this.props.modalActions.closeModal(EDIT_SESSION_MODAL_ID);
  };

  handlePrimaryAction = () => {
    this.props.submit(FORM_NAME);
  };

  handleSubmit = form => {
    const values = {
      id: this.props.session.id,
      pilot_num: form.pilotNum,
    };

    this.props.sessionActions.update(values, {
      successToast: "Session Updated",
      errorToast: "Session Update Failed",
    });

    this.handleClose();
  };

  getFilterData = (filterFieldData, fieldName) => {
    return filterFieldData && filterFieldData[fieldName]
      ? filterFieldData[fieldName]
      : [];
  };

  render() {
    const {
      open,
      pilotNumbersFieldData,
      isSubmitDisabled,
      isPristine,
    } = this.props;
    return (
      <EditPatientIDModalDialog
        onClose={this.handleClose}
        onPrimaryAction={this.handlePrimaryAction}
        open={open}
        primaryActionLabel="Update"
        isSubmitDisabled={isPristine || isSubmitDisabled}
        submitting={false}
        onSubmit={this.handleSubmit}
        session={this.props.session}
        patients={this.getFilterData(pilotNumbersFieldData, "pilotNumbers")}
      />
    );
  }
}

EditSessionModalContainer.propTypes = {
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  submit: PropTypes.func,
  session: PropTypes.object,
  pilotNumbersFieldData: PropTypes.object,
  sessionActions: PropTypes.object,
  filterFormsActions: PropTypes.object,
  isEksoUser: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  isPristine: PropTypes.bool,
};

const mapStateToProps = state => ({
  open: state.modal[EDIT_SESSION_MODAL_ID]
    ? state.modal[EDIT_SESSION_MODAL_ID].open
    : false,
  session: state.modal[EDIT_SESSION_MODAL_ID]
    ? state.modal[EDIT_SESSION_MODAL_ID].data
    : {},
  pilotNumbersFieldData: state.resources.fieldData
    ? FieldData.query(state.resources)
        .where({ id: "pilot_numbers" })
        .all()
        .toObjects()[0]
    : {},
  isEksoUser: policyRules.filter.viewCustomerNum(state.auth.currentUser),
  isSubmitDisabled: !_.isEmpty(getFormSyncErrors(FORM_NAME)(state)),
  isPristine: isPristine(FORM_NAME)(state),
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  submit: bindActionCreators(submitActionCreator, dispatch),
  sessionActions: jsonAPIReduxActions(dispatch, "pulse_sessions"),
  filterFormsActions: jsonAPIReduxActions(dispatch, "field_data"),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSessionModalContainer);
