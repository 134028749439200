import React, { Component } from "react";
import { connect } from "react-redux";
import AuthLayout from "components/shared/layouts/AuthLayout";
import { Button } from "@material-ui/core";
import policyRulesEvaluator from "lib/policyRulesEvaluator";

class NotFoundPage extends Component {
  render() {
    const { user } = this.props;
    const is_overviewPage = policyRulesEvaluator("view", "showOverview", user);
    return (
      <AuthLayout
        header="404 Not Found"
        description="Oops!, this page doesn’t exist."
        renderFooter={false}
      >
        <Button
          data-test="goBackButton"
          variant="contained"
          color="primary"
          style={{ marginTop: "16px" }}
          onClick={() => (window.location = `/dashboard/${is_overviewPage ? 'overview' : 'network'}`)}
        >
          Go back to Dashboard
        </Button>
      </AuthLayout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.currentUser,
});

export default connect(
  mapStateToProps,
  null
)(NotFoundPage);
