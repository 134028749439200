import React from "react";
import PropTypes from "prop-types";
import NetworkUtilizationGraphBase from "components/shared/ui/Graph/NetworkUtilizationGraphBase";

const AverageSessionsGraph = ({
  period,
  convertedData,
  unitLabel,
  page,
  onGraphClick,
}) => {
  return (
    <NetworkUtilizationGraphBase
      id="AverageSessions"
      data={[convertedData]}
      emptyMax={1}
      period={period}
      page={page}
      unitLabel={unitLabel ? unitLabel : "sessions"}
      tooltipLabelCallback={tooltipItem => {
        let point;
        if (Array.isArray(convertedData)) {
          point = convertedData[tooltipItem.index];
        } else {
          point = convertedData.data[tooltipItem.index];
        }
        if (point.label) {
          return point.label;
        }

        return `${tooltipItem.yLabel} ${unitLabel}`;
      }}
      onGraphClick={onGraphClick}
    />
  );
};

AverageSessionsGraph.propTypes = {
  period: PropTypes.string,
  convertedData: PropTypes.array,
  unitLabel: PropTypes.string,
  page: PropTypes.number,
  onGraphClick: PropTypes.func,
};

export default AverageSessionsGraph;
