import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import PdfIcon from "@material-ui/icons/PictureAsPdf";

import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  icon: {
    color: "white",
  },
});

const ExportDetailsMobileButton = ({ classes, onClick, className }) => {
  return (
    <IconButton onClick={onClick} className={className}>
      <PdfIcon className={classes.icon} />
    </IconButton>
  );
};

ExportDetailsMobileButton.defaultProps = {
  isFilterOpen: false,
};

ExportDetailsMobileButton.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(ExportDetailsMobileButton);
