import React, { Component } from "react";
import PropTypes from "prop-types";
import { navigate } from "actions/navigateActions";
import FieldData from "models/bluechip/FieldData";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DemographicsTable from "./DemographicsTable";

class DemographicsTableLayout extends Component {
  state = {
    dateCurrentValue: null,
    customerCurrentValue: null,
  };

  render() {
    let { rows } = this.props;
    return (
      <DemographicsTable
        clientFilter={this.props.clientFilter}
        rows={rows ? rows.data : []}
        resourceMetadata={this.props.resourceMetadata}
        customerCurrentValue={this.state.customerCurrentValue}
        dateCurrentValue={this.state.dateCurrentValue}
        onDateChange={value => this.setState({ dateCurrentValue: value })}
        onCustomerChange={value =>
          this.setState({ customerCurrentValue: value })
        }
        loadMore={this.props.loadMore}
        onQuickFilter={this.props.onQuickFilter}
        onSortChange={this.props.onSortChange}
      />
    );
  }
}

const mapStateToProps = state => ({
  filterFieldData: state.resources.fieldData
    ? FieldData.query(state.resources)
        .where({ id: "network_demographics_table" })
        .all()
        .toObjects()[0]
    : {},
});

const mapDispatchToProps = dispatch => ({
  navigate: bindActionCreators(navigate, dispatch),
});

DemographicsTableLayout.propTypes = {
  resourceMetadata: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  filterFieldData: PropTypes.object,
  filterFormsActions: PropTypes.object,
  autoHeightMax: PropTypes.string,
  clientFilter: PropTypes.object,
  site: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemographicsTableLayout);
