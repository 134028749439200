import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AuthLayoutComponent from "./AuthLayoutComponent";

class AuthLayoutContainer extends Component {
  render() {
    return <AuthLayoutComponent {...this.props} lang={this.props.lang} />;
  }
}

const mapStateToProps = state => ({
  lang: state.localization.lang,
});

AuthLayoutContainer.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  description: PropTypes.node,
  classes: PropTypes.object,
  subContent: PropTypes.node,
  renderFooter: PropTypes.bool,
  lang: PropTypes.string,
};

export default connect(mapStateToProps)(AuthLayoutContainer);
