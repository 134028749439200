import React, { Component } from "react";
import PropTypes from "prop-types";
import UpdatePasswordForm from "./UpdatePasswordForm";
import AuthLayout from "components/shared/layouts/AuthLayout";

class UpdatePasswordPage extends Component {
  render() {
    const { disabled, isSubmitting, onCancel, onUpdatePassword } = this.props;
    let header = "Update Your Password";
    let description = "Please enter your new password.";

    return (
      <AuthLayout header={header} description={description}>
        <UpdatePasswordForm
          onSubmit={onUpdatePassword}
          onCancel={onCancel}
          disabled={disabled}
          isSubmitting={isSubmitting}
        />
      </AuthLayout>
    );
  }
}

UpdatePasswordPage.propTypes = {
  onCancel: PropTypes.func,
  onUpdatePassword: PropTypes.func,
  hasErrors: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default UpdatePasswordPage;
