import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import SessionsTable from "./SessionsTable";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import ContentHeaderVerticalDivider from "components/shared/ui/Page/ContentHeaderVerticalDivider";
import DateQuickFilter from "components/shared/ui/Page/DateQuickFilter";
import Text from "components/shared/ui/Typography/Text";
import { deriveTitle } from "lib/stringUtils";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    margin: "-8px",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 64px)",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
  },
  toolbarContent: {
    display: "flex",
    alignItems: "center",
  },
  quickFilter: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    display: "flex",
  },
});

class SessionsContentLayout extends Component {
  render() {
    const {
      sessionsResourceMetadata,
      sessions,
      classes,
      loadMore,
      onShow,
      onSortChange,
      clientFilter,
      menusOpen,
    } = this.props;

    const title = (
      <Text fontSize={16} className={classes.title}>
        {deriveTitle(sessionsResourceMetadata, "Session")}
      </Text>
    );

    const periodValue = clientFilter.period ? clientFilter.period : "allTime";

    const toolbar = (
      <div className={classes.toolbarContent}>
        <ContentHeaderVerticalDivider />
        <DateQuickFilter
          onQuickFilter={this.props.onQuickFilter}
          className={classes.quickFilter}
          value={periodValue}
        />
      </div>
    );

    return (
      <div className={classes.root} data-test="sessionsContentLayout">
        <ContentHeader title={title} toolbar={toolbar} />
        <SessionsTable
          sessions={sessions}
          sessionsResourceMetadata={sessionsResourceMetadata}
          loadMore={loadMore}
          onShow={onShow}
          onSortChange={onSortChange}
          menusOpen={menusOpen}
        />
      </div>
    );
  }
}

SessionsContentLayout.propTypes = {
  sessionsResourceMetadata: PropTypes.object,
  sessions: PropTypes.array,
  classes: PropTypes.object,
  loadMore: PropTypes.func,
  onShow: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  clientFilter: PropTypes.object,
  menusOpen: PropTypes.bool,
};

export default withStyles(styles)(SessionsContentLayout);
