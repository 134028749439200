import React from "react";
import PropTypes from "prop-types";
import SessionGraphBase from "components/shared/ui/Graph/SessionGraphBase";
import TimeLineGraphBase from "components/shared/ui/Graph/TimeLineGraphBase";
import _ from "lodash";
import moment from "moment";

const _convertData = (data, propName, period) => {
  if (!data || !data[propName] || !data[propName].data) {
    return [];
  }

  return _.map(data[propName].data, (item, index) => ({
    x: period == "last" ? index + 1 : item.period,
    y: item.avg ? item.avg : NaN,
    date: period == "last" ? moment(item.period).format("MM-DD-YYYY") : null,
  }));
};

const _fillInMissingDataPoints = (arrayToIterate, arrayToFill) => {
  let reSort = false;
  arrayToIterate.forEach(item => {
    // Fill in empty value if one data set has it and the other doesn't
    if (_.findIndex(arrayToFill, { x: item.x }) === -1) {
      arrayToFill.push({ x: item.x, y: NaN });
      reSort = true;
    }
  });

  if (reSort) {
    arrayToFill = _.sortBy(arrayToFill, item => parseInt(item.x));
  }

  return arrayToFill;
};

const tooltipLabelCallback = (tooltipItem, leftLeg, rightLeg, unit) => {
  if (leftLeg[tooltipItem.index].y === rightLeg[tooltipItem.index].y) {
    return tooltipItem.yLabel + " Left & Right " + unit;
  }

  if (tooltipItem.datasetIndex === 0) {
    return tooltipItem.yLabel + " Left " + unit;
  }

  return tooltipItem.yLabel + " Right " + unit;
};

const AssistanceGraph = ({ id, period, data, page, onPagerClick, unit }) => {
  let leftLeg = _convertData(data, "leftLeg", period);
  let rightLeg = _convertData(data, "rightLeg", period);

  rightLeg = _fillInMissingDataPoints(leftLeg, rightLeg);
  leftLeg = _fillInMissingDataPoints(rightLeg, leftLeg);

  if (period == "last") {
    return (
      <SessionGraphBase
        id={id}
        data={[leftLeg, rightLeg]}
        emptyMax={100}
        maxY={100}
        fillColor={["rgba(240,112,0,0.1)", "rgba(1,154,232,0.1)"]}
        lineColor={["#E67E22", "#4A90E2"]}
        pointColor={["#E67E22", "#4A90E2"]}
        fill={[false, false]}
        tooltipLabelCallback={tooltipItem =>
          tooltipLabelCallback(tooltipItem, leftLeg, rightLeg, unit)
        }
      />
    );
  } else {
    return (
      <TimeLineGraphBase
        id={id}
        data={[leftLeg, rightLeg]}
        period={period}
        page={page}
        onPagerClick={onPagerClick}
        emptyMax={100}
        maxY={100}
        fillColor={["rgba(240,112,0,0.1)", "rgba(1,154,232,0.1)"]}
        lineColor={["#E67E22", "#4A90E2"]}
        pointColor={["#E67E22", "#4A90E2"]}
        fill={[false, false]}
        tooltipLabelCallback={tooltipItem =>
          tooltipLabelCallback(tooltipItem, leftLeg, rightLeg, unit)
        }
      />
    );
  }
};

AssistanceGraph.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object,
  period: PropTypes.string,
  data: PropTypes.object,
  page: PropTypes.number,
  onPagerClick: PropTypes.func,
  unit: PropTypes.string,
};

export default AssistanceGraph;
