/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DataTableRow from "components/shared/ui/Table/DataTableRow";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import WalkingStats from "./WalkingStats";
import SessionDetailsContainer from "./SessionDetailsContainer";
import * as InSessDataTypes from "constants/InSessDataTypes";

const styles = theme => ({
  root: {},
  closed: {
    "&::before": {
      content: "close-quote",
      height: "0px",
      display: "block",
      transition: "all 200ms",
    },
    "&::after": {
      content: "close-quote",
      height: "0px",
      display: "block",
      transition: "all 200ms",
    },
  },
  open: {
    "&:before": {
      content: "close-quote",
      display: "block",
      height: "16px",
      transition: "all 200ms",
    },
    "&:after": {
      content: "close-quote",
      display: "block",
      height: "16px",
      transition: "all 200ms",
    },
  },
  detailsDiv: {
    position: "relative",
    minHeight: "100px",
    paddingBottom: "14px",
  },
  // Note use of div is to hide the shadow of the details row to allow a consistent shadow border
  shadowBlocker: {
    position: "absolute",
    height: "24px",
    width: "calc(100% + 46px)",
    top: "-12px",
    backgroundColor: "white",
    marginLeft: "-16px",
  },
  row: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
  },
  rowOpen: {
    boxShadow: "0 1px 10px 0 rgba(0,0,0,0.32)",
  },
});

class SessionItem extends Component {
  state = {
    loaded: false,
  };

  componentWillReceiveProps(newProps) {
    this.componentWillReceiveNewSessionAttributes(
      newProps.session,
      this.props.session
    );
  }

  componentWillReceiveNewSessionAttributes(newAttributes, oldAttributes) {
    let newAttributeLength = Object.keys(newAttributes).length;
    let oldAttributeLength = Object.keys(oldAttributes).length;
    if (newAttributeLength > oldAttributeLength) {
      this.setState({
        loaded: true,
      });
    }
  }

  handleClick = () => {
    this.props.onRowClick(this.props.session);
  };

  render() {
    const { classes, session, columns, isActive } = this.props;

    let bodyClass = `${classes.root} ${classes.closed}`;
    let rowClass = classes.row;
    if (isActive) {
      bodyClass = `${classes.root} ${classes.open}`;
      rowClass = `${classes.row} ${classes.rowOpen}`;
    }

    return (
      <TableBody className={bodyClass}>
        <DataTableRow
          onClick={this.handleClick}
          className={rowClass}
          columns={columns}
          rowData={session}
        />

        {isActive ? (
          <TableRow className={rowClass} data-test="tableRowActive">
            <TableCell colSpan={columns.length}>
              <div className={classes.detailsDiv}>
                <div className={classes.shadowBlocker} />
                <WalkingStats
                  session={session}
                  id={`walking_stats_${session.id}`}
                  requiredFilter={{
                    type: InSessDataTypes.WALK_STATS,
                    session_id: session.id,
                  }}
                  toggleRow={this.handleClick}
                />
                <SessionDetailsContainer
                  session={session}
                  show={isActive && this.state.loaded}
                />
              </div>
            </TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    );
  }
}

export default withStyles(styles)(SessionItem);

SessionItem.propTypes = {
  session: PropTypes.object.isRequired,
  columns: PropTypes.array,
  onShow: PropTypes.func,
  isActive: PropTypes.bool,
};
