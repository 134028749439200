import _ from "lodash";

export function calculateSuggestedMaxYFromData(origData, emptyMax = 60) {
  if (!origData || !origData.length) {
    return emptyMax;
  }

  let data = origData;
  // Array of arrays: merge to get suggesedtMaxY
  if (Array.isArray(origData[0])) {
    data = [].concat.apply([], origData);
  }

  if (!data || !data.length) {
    return emptyMax;
  }

  return calculateSuggestedMax(data, "y", emptyMax);
}

export function calculateSuggestedMax(data, propName, emptyMax) {
  const maxDataPoint = _.maxBy(data, propName);
  const mean = _.meanBy(data, propName);
  if (!maxDataPoint || !maxDataPoint.y) {
    return emptyMax;
  }
  let suggestedMax = maxDataPoint.y;

  if (suggestedMax > 1000 && mean > 1000) {
    suggestedMax = Math.round(suggestedMax / 1000) * 1000 + 1000;
  } else if (suggestedMax > 100 && mean > 100) {
    suggestedMax = Math.round(suggestedMax / 100) * 100 + 200;
  } else if (suggestedMax > 10 && mean > 10) {
    suggestedMax = Math.round(suggestedMax / 10) * 10 + 20;
  } else {
    suggestedMax = suggestedMax + 2;
  }

  return suggestedMax;
}
