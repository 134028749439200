import React, { Component } from "react";
import PropTypes from "prop-types";
import { navigate } from "actions/navigateActions";
import FieldData from "models/bluechip/FieldData";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UtilizationTable from "./UtilizationTable";

class UtilizationTableLayout extends Component {
  state = {
    dateCurrentValue: null,
    customerCurrentValue: null,
  };

  render() {
    let {
      rows,
      onExportClick,
      loadMore,
      onQuickFilter,
      onSortChange,
      resourceMetadata,
      clientFilter,
      site,
    } = this.props;
    const { customerCurrentValue, dateCurrentValue } = this.state;
    return (
      <UtilizationTable
        clientFilter={clientFilter}
        rows={rows ? rows.data : []}
        resourceMetadata={resourceMetadata}
        customerCurrentValue={customerCurrentValue}
        dateCurrentValue={dateCurrentValue}
        onDateChange={value => this.setState({ dateCurrentValue: value })}
        onCustomerChange={value =>
          this.setState({ customerCurrentValue: value })
        }
        loadMore={loadMore}
        onQuickFilter={onQuickFilter}
        onSortChange={onSortChange}
        onExportClick={onExportClick}
        site={site}
      />
    );
  }
}

const mapStateToProps = state => ({
  filterFieldData: state.resources.fieldData
    ? FieldData.query(state.resources)
        .where({ id: "network_utilization_table" })
        .all()
        .toObjects()[0]
    : {},
});

const mapDispatchToProps = dispatch => ({
  navigate: bindActionCreators(navigate, dispatch),
});

UtilizationTableLayout.propTypes = {
  resourceMetadata: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  filterFieldData: PropTypes.object,
  filterFormsActions: PropTypes.object,
  autoHeightMax: PropTypes.string,
  clientFilter: PropTypes.object,
  site: PropTypes.object,
  onExportClick: PropTypes.func,
  onQuickFilter: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UtilizationTableLayout);
