import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MobileContentTitle from "components/shared/layouts/Dashboard/MobileContentHeader/MobileContentTitle";

const AccountMobileHeaderContent = ({ onDrawerToggle }) => {
  return (
    <Fragment>
      <MobileContentTitle title="My Account" onDrawerToggle={onDrawerToggle} />
    </Fragment>
  );
};

AccountMobileHeaderContent.propTypes = {
  onDrawerToggle: PropTypes.func,
};

export default AccountMobileHeaderContent;
