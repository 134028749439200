import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MobileContentTitle from "components/shared/layouts/Dashboard/MobileContentHeader/MobileContentTitle";
import SearchReduxForm from "components/shared/ui/Form/SearchReduxForm";
import CreateUserMobileButton from "./CreateUserMobileButton";
import Policy from "components/Policy";
import { USER_MODAL_ID } from "constants/ModalIds";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "24px",
    marginRight: "8px",
  },
  searchOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.standard,
    }),
    width: "100%",
  },
});

const ManageUsersMobileHeaderContent = ({
  onDrawerToggle,
  pageContext,
  classes,
  modalActions,
}) => {
  return (
    <Fragment>
      {pageContext.isSearchOpen ? null : (
        <MobileContentTitle
          title={pageContext.pageTitle}
          onDrawerToggle={onDrawerToggle}
        />
      )}
      <SearchReduxForm
        formName="manageUsersSearch"
        className={classNames(classes.form, {
          [classes.searchOpen]: pageContext.isSearchOpen,
        })}
      />
      {pageContext.isSearchOpen ? null : (
        <Policy action="create" object="user">
          <CreateUserMobileButton
            onClick={() => modalActions.openModal([USER_MODAL_ID])}
          />
        </Policy>
      )}
    </Fragment>
  );
};

ManageUsersMobileHeaderContent.propTypes = {
  onDrawerToggle: PropTypes.func,
  pageContext: PropTypes.object,
  classes: PropTypes.object,
  modalActions: PropTypes.object,
};

export default withStyles(styles)(ManageUsersMobileHeaderContent);
