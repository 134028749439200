import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import MaterialField from "components/shared/ui/Field/MaterialField";
import validate from "validation/ManageUsers/createUserValidation";
import ActionButton from "components/shared/ui/Button/ActionButton";
import LanguagePicker from "components/shared/ui/Localization/LanguagePicker";
import FormHeader from "components/shared/ui/Page/FormHeader";

const styles = theme => ({
  submitButton: {},
  materialField: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "block",
  },
  input: {
    width: "100%",
  },
  buttonPanel: {
    textAlign: "right",
  },
});

const AccountDetailsForm = ({
  handleSubmit,
  isSaving,
  classes,
  invalid,
  pristine,
  showEksoSerialNumbers,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <FormHeader title="Personal Details" />
      <Field
        name="email"
        component={MaterialField}
        label="Email"
        readOnly={true}
        className={classes.materialField}
        inputClassName={classes.input}
      />
      {showEksoSerialNumbers ? (
        <Field
          name="eksoSerialNumbers"
          component={MaterialField}
          label="Assigned Ekso Serial Numbers"
          readOnly={true}
          className={classes.materialField}
          inputClassName={classes.input}
        />
      ) : null}
      <Field
        name="userFirstName"
        component={MaterialField}
        label="First Name"
        className={classes.materialField}
        inputClassName={classes.input}
      />
      <Field
        name="userLastName"
        component={MaterialField}
        label="Last Name"
        className={classes.materialField}
        inputClassName={classes.input}
      />
      <Field
        name="language"
        component={LanguagePicker}
        label="Preferred Language"
        className={classes.materialField}
        inputClassName={classes.input}
      />
      <div className={classes.buttonPanel}>
        <ActionButton
          className={classes.submitButton}
          variant="contained"
          color="primary"
          loading={isSaving}
          onClick={handleSubmit}
          disabled={pristine || invalid}
          data-test="updateButton"
        >
          Update
        </ActionButton>
      </div>
    </form>
  );
};

AccountDetailsForm.propTypes = {
  isSaving: PropTypes.bool,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  showEksoSerialNumbers: PropTypes.bool,
};

export default reduxForm({
  form: "accountDetailsForm",
  validate,
})(withStyles(styles)(AccountDetailsForm));
