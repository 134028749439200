import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";

import Login from "components/pages/Login";
import Dashboard from "components/shared/layouts/Dashboard";
import ResetPassword from "components/pages/ResetPassword";
import UpdatePassword from "components/pages/UpdatePassword";
import NotFoundPage from "components/pages/NotFoundPage";
import UnauthorizedPage from "components/pages/UnauthorizedPage";

const CaptureInvalidRoutes = withRouter(({ children, location }) => {
  if (location && location.state && location.state.notFoundError) {
    return <NotFoundPage />;
  } else if (location && location.state && location.state.unauthorizedError) {
    return <UnauthorizedPage />;
  }

  return children;
});

const Routes = () => {
  return (
    <CaptureInvalidRoutes>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/resetPassword" component={ResetPassword} />
        <Route exact path="/update-password" component={UpdatePassword} />
        <Route path="/dashboard" component={Dashboard} />
        <Route component={NotFoundPage} />
      </Switch>
    </CaptureInvalidRoutes>
  );
};

export default Routes;
