import { jsonAPIReduxTypes } from "lib/jsonAPIRedux";
import qs from "qs";
import * as types from "constants/JsonApiTypes";
import { RESET } from "constants/ClearTypes";
import _ from "lodash";

let requestTypes = jsonAPIReduxTypes();

const initialState = {};

const initialResourceState = {
  meta: null,
  nextPage: 1,
  isFetchingItems: false,
  isFetchItemsSuccess: null,
  fetchError: null,
  isFindingItem: false,
  isFindItemSuccess: null,
  findError: null,
  isCreatingItem: false,
  isCreateItemSuccess: null,
  createError: null,
  isUpdatingItem: false,
  isUpdateItemSuccess: null,
  updateError: null,
  isDeletingItem: false,
  isDeleteItemSuccess: null,
  deleteError: null,
  statusText: null,
  silence: false,
  filter: {},
  order: {},
  currentPage: 0,
  firstLoad: true,
  initiated: false,
  foundIds: [],
};

function getNextPage(response) {
  if (!response.links || !response.links.next) {
    return null;
  }
  let links = response.links;

  let nextLink = links.next;
  let queryParamsStr = nextLink.split("?")[1];
  let next = qs.parse(queryParamsStr).page.number;
  next = parseInt(next);

  if (links.last) {
    let lastLink = links.last;
    let lastQueryParamsStr = lastLink.split("?")[1];
    let last = qs.parse(lastQueryParamsStr).page.number;
    last = parseInt(next);

    if (next > last) {
      return null;
    }
  }

  return next;
}

function getCurrentPage(response) {
  if (!response.links || !response.links.self) {
    return null;
  }
  let links = response.links;
  let currentLink = links.self;
  let queryParamsStr = currentLink.split("?")[1];
  const current = qs.parse(queryParamsStr).page.number;
  return parseInt(current);
}

export default function(state = initialState, action) {
  let nextPage;
  let currentPage;
  const { type, payload, resourceName } = action;
  switch (type) {
    case types.INIT: {
      const { params } = action;
      return {
        ...state,
        [resourceName]: {
          ...initialResourceState,
          ...params,
          initiated: true,
        },
      };
    }

    case types.UPDATE_RESOURCE_PARAMS: {
      const { params, params: { filter } } = action;
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          ...params,
          meta: _.isEqual(filter, state[resourceName] ? state[resourceName].filter : {})
            ? state[resourceName].meta
            : null,
        },
      };
    }

    case requestTypes.GET_REQUEST: {
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          firstLoad: false,
          isFetchingItems: true,
          isFetchItemsSuccess: null,
          statusText: null,
          fetchError: null,
          getTimestamp: action.requestTimestamp,
        },
      };
    }

    case requestTypes.GET_FAILURE: {
      if (
        state[resourceName].getTimestamp &&
        action.requestTimestamp < state[resourceName].getTimestamp
      ) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          firstLoad: false,
          isFetchingItems: false,
          isFetchItemsSuccess: false,
          fetchError: payload.error,
        },
      };
    }

    case requestTypes.GET_SUCCESS: {
      if (
        state[resourceName].getTimestamp &&
        action.requestTimestamp < state[resourceName].getTimestamp
      ) {
        return state;
      }

      nextPage = getNextPage(payload.response);
      currentPage = getCurrentPage(payload.response);
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          meta: payload.response.meta || state[resourceName].meta,
          currentPage,
          nextPage,
          isFetchingItems: false,
          isFetchItemsSuccess: true,
          fetchError: null,
          firstLoad: false,
        },
      };
    }

    case requestTypes.FIND_REQUEST: {
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isFindingItem: true,
          isFindItemSuccess: null,
          statusText: null,
          findError: null,
          findTimestamp: action.requestTimestamp,
        },
      };
    }

    case requestTypes.FIND_FAILURE: {
      if (action.requestTimestamp < state[resourceName].findTimestamp) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isFindingItem: false,
          isFindItemSuccess: false,
          findError: payload.error,
        },
      };
    }

    case requestTypes.FIND_SUCCESS: {
      if (action.requestTimestamp < state[resourceName].findTimestamp) {
        return state;
      }

      const foundId =
        action.payload.response && action.payload.response.data
          ? [action.payload.response.data.id]
          : [];
      const foundIds = state[resourceName].foundIds
        ? state[resourceName].foundIds.concat(foundId)
        : foundId;
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isFindingItem: false,
          isFindItemSuccess: true,
          findError: null,
          foundIds,
        },
      };
    }

    case requestTypes.CREATE_REQUEST: {
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isCreatingItem: true,
          isCreateItemSuccess: null,
          statusText: null,
          createError: null,
          createTimestamp: action.requestTimestamp,
        },
      };
    }

    case requestTypes.CREATE_FAILURE: {
      if (action.requestTimestamp < state[resourceName].createTimestamp) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isCreatingItem: false,
          isCreateItemSuccess: false,
          createError: payload.error,
        },
      };
    }

    case requestTypes.CREATE_SUCCESS: {
      if (action.requestTimestamp < state[resourceName].createTimestamp) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isCreatingItem: false,
          isCreateItemSuccess: true,
          createError: null,
        },
      };
    }

    case requestTypes.UPDATE_REQUEST: {
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isUpdatingItem: true,
          isUpdateItemSuccess: null,
          statusText: null,
          updateError: null,
          updateTimestamp: action.requestTimestamp,
        },
      };
    }

    case requestTypes.UPDATE_FAILURE: {
      if (action.requestTimestamp < state[resourceName].updateTimestamp) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isUpdatingItem: false,
          isUpdateItemSuccess: false,
          updateError: payload.error,
        },
      };
    }

    case requestTypes.UPDATE_SUCCESS: {
      if (action.requestTimestamp < state[resourceName].updateTimestamp) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isUpdatingItem: false,
          isUpdateItemSuccess: true,
          updateError: null,
        },
      };
    }

    case requestTypes.DELETE_REQUEST: {
      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isDeletingItem: true,
          isDeleteItemSuccess: null,
          statusText: null,
          deleteError: null,
          deleteTimestamp: action.requestTimestamp,
        },
      };
    }

    case requestTypes.DELETE_FAILURE: {
      if (action.requestTimestamp < state[resourceName].deleteTimestamp) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isDeletingItem: false,
          isDeleteItemSuccess: false,
          deleteError: payload.error,
        },
      };
    }

    case requestTypes.DELETE_SUCCESS: {
      if (action.requestTimestamp < state[resourceName].deleteTimestamp) {
        return state;
      }

      return {
        ...state,
        [resourceName]: {
          ...state[resourceName],
          isDeletingItem: false,
          isDeleteItemSuccess: true,
          deleteError: null,
        },
      };
    }

    case RESET: {
      return initialState;
    }

    default:
      return state;
  }
}
