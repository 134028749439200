import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import MaterialField from "components/shared/ui/Field/MaterialField";
import ActionButton from "components/shared/ui/Button/ActionButton";
import validate from "validation/Login/loginValidation";

const styles = theme => ({
  loginButton: {
    float: "right",
  },
  forgotButton: {
    paddingLeft: 0,
  },
  buttonBar: {
    marginTop: theme.spacing.unit,
  },
  materialField: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "inline-block",
  },
  input: {
    width: "100%",
  },
});

class LoginForm extends Component {
  render() {
    const { onForgotPassword, classes, disabled, handleSubmit } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          component={MaterialField}
          label="Email"
          className={classes.materialField}
          inputClassName={classes.input}
          inputProps={{
            autoCapitalize: "none",
          }}
        />
        <Field
          name="password"
          component={MaterialField}
          label="Password"
          className={classes.materialField}
          inputClassName={classes.input}
          inputProps={{
            type: "password",
            autoComplete: "new-password",
          }}
        />
        <div className={classes.buttonBar}>
          <Button
            color="primary"
            onClick={onForgotPassword}
            className={classes.forgotButton}
            data-test="resetPasswordButton"
          >
            Forgot Password
          </Button>
          <ActionButton
            variant="contained"
            disabled={disabled}
            loading={this.props.isSubmitting}
            onClick={handleSubmit}
            color="primary"
            type="submit"
            className={classes.loginButton}
            data-test="sendButton"
          >
            Log in
          </ActionButton>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  onForgotPassword: PropTypes.func,
  isSubmitting: PropTypes.bool,
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: "loginForm",
  validate,
})(withStyles(styles)(LoginForm));
