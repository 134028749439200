import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    color: "#4A4A4A",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    marginBottom: "24px",
    marginTop: 0,
    padding: 0,
  },
});

const FormHeader = ({ classes, title }) => {
  return (
    <Typography className={classes.root} variant="subheading">
      {title}
    </Typography>
  );
};

FormHeader.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.node,
};

export default withStyles(styles)(FormHeader);
