import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import MaterialField from "components/shared/ui/Field/MaterialField";
import FormHeader from "components/shared/ui/Page/FormHeader";

const styles = theme => ({
  materialField: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "block",
  },
  readOnly: {
    whiteSpace: "normal",
  },
});

const EksoDetails = ({ classes, eksoSerialNumbers }) => {
  return (
    <div>
      <FormHeader title="Ekso Details" />
      <MaterialField
        className={classes.materialField}
        readOnly={true}
        label="Ekso Serial Number(s)"
        input={{
          value: eksoSerialNumbers.join(", "),
        }}
        meta={{}}
        classes={{
          readOnly: classes.readOnly,
        }}
      />
    </div>
  );
};

EksoDetails.propTypes = {
  classes: PropTypes.object,
  eksoSerialNumbers: PropTypes.array,
};

export default withStyles(styles)(EksoDetails);
