import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import * as toastActionCreators from "actions/toastActions";
import InsessModalDialog from "./InsessModalDialog";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { INSESS_MODAL_ID } from "constants/ModalIds";
import {
  submit as submitActionCreator,
  stopSubmit as stopSubmitActionCreator,
  getFormSyncErrors,
} from "redux-form";
import _ from "lodash";
import moment from "moment";
import * as InSessDataTypes from "constants/InSessDataTypes";

const FORM_NAME = "insessReduxForm";
class InsessModalContainer extends Component {
  componentDidMount() {
    const { insessEventsResourceMetadata } = this.props;
    if (!insessEventsResourceMetadata) {
      this.props.insessEventsActions.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.insessEventsResourceMetadata) {
      return;
    }

    const wasUpdating =
      prevProps.insessEventsResourceMetadata &&
      prevProps.insessEventsResourceMetadata.isUpdatingItem;
    const {
      stopSubmit,
      patient,
      insessStatsActions,
      insessStatsResourceMetadata,
    } = this.props;
    const {
      isUpdateItemSuccess,
      updateError,
    } = this.props.insessEventsResourceMetadata;

    const error = updateError;

    // After update close the dialog, or show field errors if any
    if (wasUpdating && isUpdateItemSuccess) {
      this.handleClose();

      // If we are currently looking at a patient, refresh their Ekso Outcomes
      // graph after the update
      if (patient && patient.id) {
        insessStatsActions.get({
          filter: _.extend(
            { patient_id: patient.id },
            insessStatsResourceMetadata.filter
          ),
        });
      }
    } else if (wasUpdating && !!error) {
      if (error && error.validationMessages) {
        this.props.toastActions.showToast(
          "Validation failed.  Please correct form errors and try again."
        );
        // Throw redux-form submission error
        stopSubmit(FORM_NAME, error.validationMessages);
      } else if (error && error.message) {
        this.props.toastActions.showToast(
          `${error.status ? error.status + " " : ""}${error.message}`
        );
      } else {
        this.props.toastActions.showToast("An Unknown Error Occurred");
      }
    }
  }

  handleSubmit = form => {
    const { insessEvent, isSubmitDisabled, insessEventsActions } = this.props;

    if (isSubmitDisabled) {
      return;
    }

    const values = {
      id: insessEvent.id,
      insess_event_result:
        `${insessEvent.insessDataType}` === InSessDataTypes.TEN_METER_WALK
          ? moment.duration(form.insessEventResult).asSeconds() * 10
          : parseInt(parseFloat(form.insessEventResult) * 10),
      insess_speed_mode: form.insessSpeedMode,
    };

    insessEventsActions.update(values, {
      successToast: "Ekso Outcome Updated",
    });
  };

  handleClose = () => {
    this.props.modalActions.closeModal(INSESS_MODAL_ID);
  };

  handlePrimaryAction = () => this.props.submit(FORM_NAME);

  render() {
    const {
      insessEventsResourceMetadata,
      insessEvent,
      isSubmitDisabled,
      open,
    } = this.props;
    return (
      <InsessModalDialog
        onSubmit={this.handleSubmit}
        onPrimaryAction={this.handlePrimaryAction}
        primaryActionLabel="Save"
        type={insessEvent ? `${insessEvent.insessDataType}` : null}
        isSubmitDisabled={isSubmitDisabled}
        onClose={this.handleClose}
        open={open}
        submitting={
          insessEventsResourceMetadata &&
          insessEventsResourceMetadata.isUpdatingItem
        }
        insessEvent={insessEvent}
      />
    );
  }
}

const mapStateToProps = state => ({
  insessEventsResourceMetadata: state.jsonApiResources["insess_events"],
  insessStatsResourceMetadata: state.jsonApiResources["stats/insess_event"],
  open: state.modal[INSESS_MODAL_ID]
    ? state.modal[INSESS_MODAL_ID].open
    : false,
  insessEvent: state.modal[INSESS_MODAL_ID]
    ? state.modal[INSESS_MODAL_ID].data
    : {},
  isSubmitDisabled: !_.isEmpty(getFormSyncErrors(FORM_NAME)(state)),
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  insessEventsActions: jsonAPIReduxActions(dispatch, "insess_events"),
  insessStatsActions: jsonAPIReduxActions(dispatch, "stats/insess_event"),
  toastActions: bindActionCreators(toastActionCreators, dispatch),
  submit: bindActionCreators(submitActionCreator, dispatch),
  stopSubmit: bindActionCreators(stopSubmitActionCreator, dispatch),
});

InsessModalContainer.propTypes = {
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  insessEventsActions: PropTypes.object,
  submit: PropTypes.func,
  toastActions: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  insessEventsResourceMetadata: PropTypes.object,
  stopSubmit: PropTypes.func,
  insessEvent: PropTypes.object,
  patient: PropTypes.object,
  insessStatsActions: PropTypes.object,
  insessStatsResourceMetadata: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsessModalContainer);
