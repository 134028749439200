import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Collapse } from "@material-ui/core";
import SessionParameters from "./SessionParameters";
import GaitPerformance from "./GaitPerformance";
import PregaitPerformance from "./PregaitPerformance";
import GaitStats from "./GaitStats";

class SessionDetailsLayout extends Component {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const { show, session, hideSubParams } = this.props;

    return (
      <Collapse in={show && this.state.isMounted} timeout="auto">
        <Grid
          data-test="sessionDetails"
          container
          spacing={16}
          alignItems="stretch"
          alignContent="stretch"
        >
          <Grid item xs={12}>
            <SessionParameters
              session={session}
              hideSubParams={hideSubParams}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <GaitPerformance session={session} />
          </Grid>
          <Grid item xs={12} md={6}>
            <PregaitPerformance session={session} />
            <GaitStats session={session} />
          </Grid>
        </Grid>
      </Collapse>
    );
  }
}

SessionDetailsLayout.defaultProps = {
  hideSubParams: false,
};

SessionDetailsLayout.propTypes = {
  session: PropTypes.object.isRequired,
  show: PropTypes.bool,
  hideSubParams: PropTypes.bool,
};

export default SessionDetailsLayout;
