import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import MaterialField from "components/shared/ui/Field/MaterialField";
import validate from "validation/ManageUsers/createUserValidation";
import MultiSelectField from "components/shared/ui/Field/MultiSelectField";

const styles = theme => ({
  submitButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  clearButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  materialField: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "block",
  },
  disabledClearButton: {
    color: "inherit !important",
    backgroundColor: "white !important",
  },
  dateInput: {
    width: "100%",
  },
  dateIcon: {
    paddingBottom: "3px", // IE hack :(
  },
  input: {
    width: "100%",
  },
});

const UserReduxForm = ({ handleSubmit, classes, eksoSerialNumbers }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="email"
        component={MaterialField}
        label="Email"
        className={classes.materialField}
        inputClassName={classes.input}
        inputProps={{
          autoFocus: true,
        }}
      />
      <Field
        name="userFirstName"
        component={MaterialField}
        label="First Name"
        className={classes.materialField}
        inputClassName={classes.input}
      />
      <Field
        name="userLastName"
        component={MaterialField}
        label="Last Name"
        className={classes.materialField}
        inputClassName={classes.input}
      />
      <Field
        name="eksoSerialNumbers"
        component={MultiSelectField}
        label="Ekso Serial Numbers (optional)"
        placeholder="Search"
        className={classes.materialField}
        suggestions={eksoSerialNumbers.map(item => {
          return {
            label: `${item}`,
            value: `${item}`,
          };
        })}
      />
    </form>
  );
};

UserReduxForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  eksoSerialNumbers: PropTypes.array,
};

export default reduxForm({
  form: "userReduxForm",
  validate,
})(withStyles(styles)(UserReduxForm));
