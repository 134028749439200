import React from "react";
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    paddingLeft: "8px",
    paddingBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingBottom: 0,
    },
  },
  title: {
    fontSize: "22px",
    lineHeight: "32px",
    fontWeight: 300,
    paddingTop: "12px",
    color: "rgba(0,0,0,0.87)",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  description: {
    marginTop: "12px",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    opacity: 0.5,
    color: "rgba(0,0,0,0.87)",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  needsTranslation: {
    fontWeight: 500,
  },
  button: {
    marginTop: "16px",
  },
  descriptionText: {},
});

const MessageContent = ({ classes, messageData, toggleContent }) => {
  return (
    <div className={classes.root}>
      <Typography
        variant="subheading"
        className={classes.title}
        onClick={toggleContent}
        data-test="messageContentHeader"
      >
        {messageData.title}
      </Typography>
      <Typography variant="body2" className={classes.description}>
        {!messageData.translationAvailable && messageData.description ? (
          <div className={classes.needsTranslation}>
            We are working on your translation, only English translations are
            currently available:
          </div>
        ) : null}
        <div
          data-test="description"
          className={classes.descriptionText}
          dangerouslySetInnerHTML={{ __html: messageData.description }}
        />
      </Typography>
      {messageData.fileUrl ? (
        <Button
          className={classes.button}
          target="_blank"
          variant="contained"
          color="primary"
          href={messageData.fileUrl}
          data-test="takeMeThereButton"
        >
          Take Me There
        </Button>
      ) : null}
    </div>
  );
};

MessageContent.propTypes = {
  classes: PropTypes.object,
  messageData: PropTypes.object,
  toggleContent: PropTypes.func,
};

export default withStyles(styles)(MessageContent);
