import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import numberFormatter from "lib/numberFormatter";
import policyRules from "lib/policyRules";

// eslint-disable-next-line
const styles = theme => ({
  root: {},
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    display: "inline-block",
    marginRight: "8px",
    marginBottom: 0,
  },
  panel: {
    padding: "16px",
  },
  field: {
    marginTop: "16px",
    marginRight: "24px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "16px",
    },
  },
  fieldValue: {
    fontSize: "14px !important",
    fontWeight: 400,
    color: "rgba(0,0,0,0.87)",
  },
  utilPanel: {
    borderLeft: "1px solid rgba(216, 216, 216, 0.6)",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      paddingLeft: 0,
      marginLeft: "16px",
      borderTop: "1px solid rgba(216, 216, 216, 0.6)",
    },
  },
  details: {
    cursor: "pointer",
  },
  noValue: {
    opacity: 0.5,
  },
  noDetailsPanel: {
    margin: "40px auto 28px auto",
  },
});

const PatientParamsPanel = ({
  classes,
  patient,
  onAddDetails,
  currentUser,
  loading,
}) => {
  const fieldClasses = {
    root: classes.field,
    value: classes.fieldValue,
  };

  const canAddDetails = policyRules.patient.edit(currentUser);

  return (
    <Grid item xs={12}>
      <PaperPanel>
        <Grid className={classes.grid} container spacing={0}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className={classNames(classes.panel, {
              [classes.details]: canAddDetails,
            })}
            onClick={canAddDetails ? onAddDetails : null}
          >
            <Typography className={classes.header} variant="subheading">
              Details
            </Typography>
            <Grid className={classes.grid} container spacing={0}>
              <Grid item xs={12}>
                <LargeParamField
                  classes={fieldClasses}
                  data={patient}
                  label="Ekso Serial Numbers"
                  name="eksoSerialNumbers"
                  formatValue={value =>
                    value && value.length ? value.join(", ") : ""
                  }
                  loading={loading}
                />
                <LargeParamField
                  classes={fieldClasses}
                  data={patient}
                  label="Therapists"
                  name="therapists"
                  formatValue={value =>
                    value ? (
                      value
                    ) : (
                      <span className={classes.noValue}>Not Selected</span>
                    )
                  }
                  loading={loading}
                />
              </Grid>
            </Grid>
            <Grid className={classes.grid} container spacing={0}>
              <Grid item xs={12}>
                <LargeParamField
                  classes={fieldClasses}
                  data={patient.indication ? patient.indication : {}}
                  label="Indication"
                  id="indication"
                  name="name"
                  formatValue={value =>
                    value ? (
                      value
                    ) : (
                      <span className={classes.noValue}>Not Selected</span>
                    )
                  }
                  loading={loading}
                />
                <LargeParamField
                  classes={fieldClasses}
                  data={patient.settingOfUse ? patient.settingOfUse : {}}
                  label="Setting of Use"
                  id="settingOfUse"
                  name="name"
                  formatValue={value =>
                    value ? (
                      value
                    ) : (
                      <span className={classes.noValue}>Not Selected</span>
                    )
                  }
                  loading={loading}
                />
                <LargeParamField
                  classes={fieldClasses}
                  data={patient}
                  label="Assistive Device"
                  name="assistiveDevices"
                  formatValue={assistiveDevices =>
                    assistiveDevices && assistiveDevices.length ? (
                      assistiveDevices
                        .map(assistiveDevice => assistiveDevice.name)
                        .join(", ")
                    ) : (
                      <span className={classes.noValue}>Not Selected</span>
                    )
                  }
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className={classNames(classes.panel, classes.utilPanel)}
          >
            <Typography className={classes.header} variant="subheading">
              Total Utilization
            </Typography>
            <div>
              <LargeParamField
                classes={fieldClasses}
                data={patient}
                label="Sessions"
                name="sessionsCount"
                formatValue={numberFormatter.numberWithCommas}
                loading={loading}
              />
              <LargeParamField
                classes={fieldClasses}
                data={patient}
                label="Steps"
                name="steps"
                formatValue={numberFormatter.numberWithCommas}
                loading={loading}
              />
              <LargeParamField
                classes={fieldClasses}
                data={patient}
                label="Up-Time"
                name="upTime"
                formatValue={numberFormatter.secondsToRoundedTime}
                loading={loading}
              />
            </div>
          </Grid>
        </Grid>
      </PaperPanel>
    </Grid>
  );
};

PatientParamsPanel.propTypes = {
  classes: PropTypes.object,
  patient: PropTypes.object,
  onAddDetails: PropTypes.func,
  currentUser: PropTypes.object,
  loading: PropTypes.bool,
};

export default withStyles(styles)(PatientParamsPanel);
