import React, { Component } from "react";
import PropTypes from "prop-types";
import { TableBody } from "@material-ui/core";
import DataTable from "components/shared/ui/Table";
import DataTableRow from "components/shared/ui/Table/DataTableRow";
import {
  convertCamelCaseToUnderscoreCase,
  convertUnderscoreCaseToCamelCase,
} from "lib/stringUtils";
import { TransitionGroup, CSSTransition } from "react-transition-group";

class EksoDataTable extends Component {
  convertSortPropName = columnConfig => {
    // blue-chip auto camelCases property names, must undo for api call
    return convertCamelCaseToUnderscoreCase(
      columnConfig.sortProp ? columnConfig.sortProp : columnConfig.prop
    );
  };

  onSort = columnConfig => {
    // Must convert to underscoreCase for api call
    const column = this.convertSortPropName(columnConfig);
    const sort = this.props.sort || this.props.resourceMetadata.order;

    let order = "ASC";
    if (sort.column === column) {
      order = sort.order === "DESC" ? "ASC" : "DESC";
    }

    const sortObj = {
      column,
      order,
    };
    this.props.onSortChange(sortObj);
  };

  render() {
    const {
      id,
      rows,
      resourceMetadata,
      columns,
      classes,
      scrollbarsSettings,
      emptyContent,
      tableContentProps,
      onRowClick,
      sort,
    } = this.props;

    let tableSort = sort;
    if (!tableSort) {
      tableSort =
        resourceMetadata && resourceMetadata.order
          ? resourceMetadata.order
          : {};
    }

    return (
      <DataTable
        classes={classes}
        columns={columns}
        loading={resourceMetadata.isFetchingItems}
        sort={{
          column: convertUnderscoreCaseToCamelCase(tableSort.column),
          order: tableSort.order,
        }}
        onSort={this.onSort}
        error={resourceMetadata.fetchError}
        isInfiniteScroll={true}
        loadMore={this.props.loadMore}
        hasMore={
          !!resourceMetadata.nextPage && !resourceMetadata.isFetchingItems
        }
        scrollbarsSettings={scrollbarsSettings}
        emptyContent={emptyContent}
        tableContentProps={tableContentProps}
      >
        {!rows || !rows.length ? null : (
          <TableBody>
            <TransitionGroup
              appear={false}
              className={`${id}_eksoTable`}
              component={null}
            >
              {rows.map(row => {
                return (
                  <CSSTransition
                    key={`${id}-${row.id}_cssT`}
                    timeout={600}
                    classNames="fade"
                  >
                    <DataTableRow
                      key={`${id}-${row.id}_row`}
                      columns={columns}
                      rowData={row}
                      onClick={onRowClick}
                    />
                  </CSSTransition>
                );
              })}
            </TransitionGroup>
          </TableBody>
        )}
      </DataTable>
    );
  }
}

EksoDataTable.propTypes = {
  id: PropTypes.string.isRequired,
  classes: PropTypes.object,
  rows: PropTypes.array,
  resourceMetadata: PropTypes.object,
  onSortChange: PropTypes.func,
  loadMore: PropTypes.func,
  columns: PropTypes.array.isRequired,
  scrollbarsSettings: PropTypes.object,
  emptyContent: PropTypes.node,
  tableContentProps: PropTypes.object,
  onRowClick: PropTypes.func,
  sort: PropTypes.object,
};

export { EksoDataTable as EksoDataTableBase };
export default EksoDataTable;
