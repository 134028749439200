import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Policy from "components/Policy";
import moment from "moment";
import EksoIconButton from "components/shared/ui/Button/EksoIconButton";
import Skeleton from "react-loading-skeleton";

import PatientTabs from "./PatientTabs";

const styles = theme => ({
  root: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
    paddingTop: "8px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  title: {
    color: "#4A4A4A",
    fontWeight: 500,
    fontSize: "16px",
  },
  titleBlock: {
    display: "inline-block",
    marginTop: "4px",
  },
  subTitle: {
    display: "block",
    fontSize: "12px",
    color: "rgba(0,0,0,0.435)",
  },
  toolbar: {
    alignItems: "flex-start",
    paddingLeft: "0px",
    paddingRight: "12px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "8px",
    },
  },
  iconButton: {
    margin: "0px 16px 0px 8px",
    width: "32px",
    height: "32px",
    verticalAlign: "top",
  },
  grow: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      flexGrow: 0,
      display: "none",
    },
  },
  invisible: {
    display: "none",
  },
  invisibleOnMobile: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class Header extends Component {
  render() {
    const {
      classes,
      patient,
      onAddDetails,
      onExportSummary,
      onNavigate,
      onTabChange,
      error,
      tab,
    } = this.props;
    let title = null;
    if (!error) {
      title = (
        <div className={classes.titleBlock}>
          <span data-test="headerTitle" className={classes.title}>
            {!patient ? <Skeleton width={60} /> : `#${patient.pilotNum}`}
          </span>
          <span className={classes.subTitle}>
            {!patient ? (
              <Skeleton width={180} />
            ) : (
              `Created on ${moment(patient.createdAt).format(
                "MM/DD/YY, h:mma"
              )}`
            )}
          </span>
        </div>
      );
    } else if (error) {
      title = <span className={classes.title}>Error</span>;
    }

    return (
      <AppBar
        position="static"
        className={classNames(classes.root, {
          [classes.invisibleOnMobile]: error,
        })}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography variant="title" color="inherit" className={classes.grow}>
            <IconButton
              color="primary"
              className={classes.iconButton}
              data-test="backButton"
              onClick={() => onNavigate("/dashboard/patients")}
            >
              <ChevronLeftIcon />
            </IconButton>
            {title}
          </Typography>
          <div className={classes.grow} />
          <div
            className={classNames({
              [classes.invisible]: error,
            })}
          >
            <Policy action="edit" object="patient">
              <EksoIconButton
                onClick={onAddDetails}
                label="Add Details"
                iconName="add_circle_outline"
                dataTest="addDetailsButton"
              />
            </Policy>
            <EksoIconButton
              onClick={onExportSummary}
              label="Export Summary"
              iconName="picture_as_pdf"
              dataTest="exportDetailsButton"
            />
          </div>
        </Toolbar>
        <PatientTabs onTabChange={onTabChange} value={tab} patient={patient} />
      </AppBar>
    );
  }
}

Header.propTypes = {
  onNavigate: PropTypes.func,
  onTabChange: PropTypes.func,
  patient: PropTypes.object,
  onAddDetails: PropTypes.func,
  onExportSummary: PropTypes.func,
  classes: PropTypes.object.isRequired,
  error: PropTypes.bool,
  tab: PropTypes.string,
};

export default withStyles(styles)(Header);
