import DashboardContainer from "./DashboardContainer";
import AuthComponent from "utils/requireAuth";
import React from "react";
import IdleTimerContainer from "components/shared/containers/IdleTimerContainer";

const AuthWrappedDashboardContainer = props => {
  return (
    <IdleTimerContainer>
      <AuthComponent {...props}>
        <DashboardContainer {...props} />
      </AuthComponent>
    </IdleTimerContainer>
  );
};

export default AuthWrappedDashboardContainer;
