import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import EksoDataTable from "components/shared/ui/Table/EksoDataTable";
import { withStyles } from "@material-ui/core/styles";
import { formatDate } from "lib/dateUtils";
import PatientActionMenu from "./PatientActionMenu";
import policyRules from "lib/policyRules";
import _ from "lodash";
import MessagePanel from "components/shared/ui/Panel/MessagePanel";

const styles = theme => ({
  eksoSerialNum: {
    [theme.breakpoints.up("md")]: {
      width: "25%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.up("sm")]: {
      textOverflow: "ellipsis",
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  therapists: {
    [theme.breakpoints.up("md")]: {
      width: "15%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20%",
    },
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  leftColumns: {
    width: "56px",
    [theme.breakpoints.up("md")]: {
      width: "80px",
    },
  },
  rightColumns: {
    width: "76px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left !important",
    },
  },
  hideCellXsDown: {
    [theme.breakpoints.down("xs")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  hideCellSmDown: {
    [theme.breakpoints.down("sm")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  hideCellMdDown: {
    [theme.breakpoints.down("md")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  actionCol: {
    width: "50px",
    paddingLeft: "0px",
    paddingRight: "1px !important",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
    },
  },
  noIndication: {
    opacity: 0.5,
  },
  noCustomerPanel: {},
  filterLink: {
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class PatientsTable extends Component {
  columns = [
    {
      label: "PATIENT ID",
      prop: "pilotNum",
      className: this.props.classes.leftColumns,
    },
    {
      label: "CUSTOMER #",
      prop: "customer",
      sortProp: "customerId",
      className: classNames(
        this.props.classes.leftColumns,
        this.props.classes.hideCellSmDown
      ),
    },
    {
      label: "SERIAL #",
      prop: "eksoSerialNumbers",
      unsortable: true,
      className: this.props.classes.eksoSerialNum,
      format: value => {
        const serialList = value && value.length ? value.join(", ") : "";
        return <span title={serialList}>{serialList}</span>;
      },
    },
    {
      label: "THERAPISTS",
      prop: "therapists",
      unsortable: true,
      className: classNames(
        this.props.classes.therapists,
        this.props.classes.hideCellMdDown
      ),
    },
    {
      label: "CREATED ON",
      prop: "createdAt",
      isNumeric: true,
      className: classNames(
        this.props.classes.rightColumns,
        this.props.classes.hideCellSmDown
      ),
      format: value => formatDate(value),
    },
    {
      label: "INDICATION",
      prop: "indication",
      isNumeric: true,
      className: this.props.classes.rightColumns,
      sortProp: "indicationId",
      format: value =>
        value ? (
          value.name
        ) : (
          <span className={this.props.classes.noIndication}>Not Selected</span>
        ),
    },
    {
      label: "LAST SESSION",
      prop: "lastSessionDate",
      className: classNames(
        this.props.classes.rightColumns,
        this.props.classes.hideCellXsDown
      ),
      isNumeric: true,
      format: value => formatDate(value),
    },
    {
      label: "",
      prop: "",
      unsortable: true,
      className: this.props.classes.actionCol,
      format: (value, data) => {
        return <PatientActionMenu patient={data} />;
      },
    },
  ];

  render() {
    const {
      patients,
      patientsResourceMetadata,
      loadMore,
      onSortChange,
      onNavigate,
      menusOpen,
      currentUser,
      noCustomerIdSelected,
      toggleFilter,
      classes,
    } = this.props;

    let tableColumns = this.columns;
    let canViewCustomerNum = policyRules.filter.viewCustomerNum(currentUser);
    if (!canViewCustomerNum) {
      tableColumns = _.reject(this.columns, ["prop", "customer"]);
    }

    let emptyContentOverride;
    if (canViewCustomerNum && noCustomerIdSelected) {
      emptyContentOverride = (
        <MessagePanel
          className={classes.noCustomerPanel}
          headerText="Select a Customer to view Patients"
          description={
            <span>
              Plase add a{" "}
              <a className={classes.filterLink} onClick={toggleFilter}>
                Customer # filter
              </a>
            </span>
          }
        />
      );
    }

    return (
      <EksoDataTable
        id="patients"
        columns={tableColumns}
        rows={patients}
        resourceMetadata={patientsResourceMetadata}
        loadMore={loadMore}
        onSortChange={onSortChange}
        emptyContent={emptyContentOverride}
        onRowClick={rowData =>
          menusOpen
            ? null
            : onNavigate(`/dashboard/patients/${rowData.id}/summary`)
        }
      />
    );
  }
}

PatientsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  patients: PropTypes.array,
  patientsResourceMetadata: PropTypes.object,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  loadMore: PropTypes.func,
  onNavigate: PropTypes.func,
  menusOpen: PropTypes.bool,
  currentUser: PropTypes.object,
  noCustomerIdSelected: PropTypes.bool,
  toggleFilter: PropTypes.func,
};

export { PatientsTable as PatientsTableBase };
export default withStyles(styles)(PatientsTable);
