/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import UnreadDotIcon from "./UnreadDotIcon";
import { withStyles } from "@material-ui/core/styles";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { formatDate } from "lib/dateUtils";
import DataTable from "components/shared/ui/Table";
import MobileMessageHeader from "./MobileMessageHeader";
import NotificationItem from "./NotificationItem";
import {
  convertCamelCaseToUnderscoreCase,
  convertUnderscoreCaseToCamelCase,
} from "lib/stringUtils";

const styles = theme => ({
  headerDiv: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  message: {
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "80%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "85%",
    },
  },
  iconCell: {
    width: "34px",
    paddingRight: "16px !important",
  },
  hideCellSmDown: {
    [theme.breakpoints.down("sm")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
});

class NotificationsTable extends Component {
  state = {
    openNotificationId: null,
  };

  columns = [
    {
      label: "DATE",
      prop: "createdAt",
      format: value => formatDate(value),
      className: this.props.classes.hideCellSmDown,
    },
    {
      label: "MESSAGE",
      prop: "title",
      className: this.props.classes.message,
      format: (value, data) =>
        isWidthUp("md", this.props.width) ? (
          <span>{value}</span>
        ) : (
          <MobileMessageHeader messageData={data} />
        ),
    },
    {
      label: "",
      prop: "read",
      className: this.props.classes.iconCell,
      unsortable: true,
      format: (read, data) => (
        <UnreadDotIcon id={`unread-${data.id}`} read={read} />
      ),
    },
  ];

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.notificationsResourceMetadata.isFetchingItems) {
      return { openNotificationId: null };
    }

    return null;
  }

  handleRowClick = rowData => {
    const rowNotOpen = this.state.openNotificationId !== rowData.id;
    this.setState({
      openNotificationId: rowNotOpen ? rowData.id : null,
    });

    if (rowNotOpen) {
      this.props.onRowOpen(rowData);
    }
  };

  convertSortPropName = columnConfig => {
    // blue-chip auto camelCases property names, must undo for api call
    return convertCamelCaseToUnderscoreCase(
      columnConfig.sortProp ? columnConfig.sortProp : columnConfig.prop
    );
  };

  onSort = columnConfig => {
    const { notificationsResourceMetadata } = this.props;
    const sort =
      notificationsResourceMetadata && notificationsResourceMetadata.order
        ? notificationsResourceMetadata.order
        : {};

    // Must convert to underscoreCase for api call
    const column = this.convertSortPropName(columnConfig);

    let order = "ASC";
    if (sort.column === column) {
      order = sort.order === "DESC" ? "ASC" : "DESC";
    }

    const sortObj = {
      column,
      order,
    };
    this.props.onSortChange(sortObj);
  };

  render() {
    const {
      notifications,
      onShow,
      notificationsResourceMetadata,
      classes,
    } = this.props;

    const sort =
      notificationsResourceMetadata && notificationsResourceMetadata.order
        ? notificationsResourceMetadata.order
        : {};

    return (
      <DataTable
        columns={this.columns}
        loading={notificationsResourceMetadata.isFetchingItems}
        sort={{
          column: convertUnderscoreCaseToCamelCase(sort.column),
          order: sort.order,
        }}
        error={notificationsResourceMetadata.fetchError}
        onSort={this.onSort}
        isInfiniteScroll={true}
        loadMore={this.props.loadMore}
        classes={{
          headerDiv: classes.headerDiv,
        }}
        hasMore={
          !!notificationsResourceMetadata.nextPage &&
          !notificationsResourceMetadata.isFetchingItems
        }
      >
        {notifications.map(notification => {
          return (
            <NotificationItem
              key={notification.id}
              columns={this.columns}
              notification={notification}
              onRowClick={this.handleRowClick}
              isActive={this.state.openNotificationId === notification.id}
            />
          );
        })}
      </DataTable>
    );
  }
}

NotificationsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.array,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  onRowOpen: PropTypes.func,
};

export { NotificationsTable as NotificationsTableBase };
export default withWidth()(withStyles(styles)(NotificationsTable));
