import React, { Component } from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    height: "47px",
    paddingLeft: "16px",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "20px",
    },
    "&:hover": {
      [theme.breakpoints.up("md")]: {
        backgroundColor: "rgba(255, 255, 255, .05)",
      },
    },
  },
  active: {
    [theme.breakpoints.up("md")]: {
      borderRight: "5px solid #50E3C2",
    },
  },
  link: {
    color: "inherit",
    fontSize: "14px",
    fontWeight: 400,
    [theme.breakpoints.up("md")]: {
      fontSize: "12px",
      fontWeight: 500,
    },
  },
});

class Link extends Component {
  isActive = () => {
    if (
      this.props.currentPath &&
      this.props.currentPath.startsWith(this.props.path)
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { icon, text, classes, onClick, dataTest } = this.props;
    let listItemClassName = classes.root;
    if (this.isActive()) {
      listItemClassName = `${classes.root} ${classes.active}`;
    }
    return (
      <ListItem
        button
        className={listItemClassName}
        onClick={onClick}
        data-test={dataTest}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            className: classes.link,
          }}
        />
      </ListItem>
    );
  }
}

Link.propTypes = {
  onClick: PropTypes.func,
  match: PropTypes.object,
  path: PropTypes.string,
  icon: PropTypes.node,
  text: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  currentPath: PropTypes.string,
  dataTest: PropTypes.string,
};

export default withStyles(styles)(Link);
