import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    margin: "16px -16px",
  },
});

const InnerPaperPanelDivider = ({ classes }) => {
  return <Divider className={classes.root} />;
};

InnerPaperPanelDivider.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(InnerPaperPanelDivider);
