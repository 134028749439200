/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { bindActionCreators } from "redux";
import * as LoginActions from "actions/loginActions";

class AuthComponent extends Component {
  componentDidMount() {
    this.checkAuth(this.props.isAuthenticated);
  }

  componentDidUpdate() {
    this.checkAuth(this.props.isAuthenticated);
  }

  checkAuth(isAuthenticated) {
    if (!isAuthenticated) {
      let redirectAfterLogin = encodeURIComponent(
        this.props.location.pathname + this.props.location.search
      );
      this.props.actions.redirectToRoute(`/?next=${redirectAfterLogin}`);
    }
  }

  render() {
    if (!this.props.isAuthenticated) {
      return null;
    }

    return this.props.children;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(LoginActions, dispatch),
  };
}

AuthComponent.propTypes = {
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
  actions: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthComponent);
