import React from "react";
import PropTypes from "prop-types";
import { get, omit } from "lodash";
import { connect } from "react-redux";

const LocalizedLink = props => {
  let href = props.href;
  if (props.lang) {
    href = get(props, "href", "").concat("?lang=", props.lang);
  }

  return (
    <a href={href} {...omit(props, ["children", "href", "dispatch"])}>
      {props.children}
    </a>
  );
};

LocalizedLink.propTypes = {
  children: PropTypes.node,
  lang: PropTypes.string,
  href: PropTypes.string,
};

const mapStateToProps = state => ({
  lang: state.localization.lang,
});

export { LocalizedLink as LocalizedLinkBase };
export default connect(mapStateToProps)(LocalizedLink);
