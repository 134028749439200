import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import DemographicsModalDialog from "./DemographicsModalDialog";
import { DEMOGRAPHICS_MODAL_ID } from "constants/ModalIds";

class DemographicsModalContainer extends Component {
  handleClose = () => {
    this.props.modalActions.closeModal(DEMOGRAPHICS_MODAL_ID);
  };

  render() {
    const { open } = this.props;
    return (
      <DemographicsModalDialog
        onClose={this.handleClose}
        open={open}
      />
    );
  }
}

const mapStateToProps = state => ({
  open: state.modal[DEMOGRAPHICS_MODAL_ID]
    ? state.modal[DEMOGRAPHICS_MODAL_ID].open
    : false,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
});

DemographicsModalContainer.propTypes = {
  modalActions: PropTypes.object,
  open: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemographicsModalContainer);
