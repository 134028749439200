import * as types from "constants/ResetPasswordTypes";
import { RESET } from "constants/ClearTypes";

const initialState = {
  isRequesting: false,
  isResetPasswordResetSuccess: null,
  isUpdatingPassword: false,
  isUpdatePasswordSuccess: null,
  statusText: null,
  error: null,
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case types.REQUEST_PASSWORD_RESET_REQUEST: {
      return {
        ...state,
        isRequestingResetPassword: true,
        isResetPasswordResetSuccess: null,
        statusText: null,
        error: null,
      };
    }

    case types.REQUEST_PASSWORD_RESET_REQUEST_SUCCESS: {
      return {
        ...state,
        isRequestingResetPassword: false,
        isResetPasswordResetSuccess: true,
        statusText: "Password reset email sent.",
        error: null,
      };
    }

    case types.REQUEST_PASSWORD_RESET_REQUEST_FAILURE: {
      return {
        ...state,
        isRequestingResetPassword: false,
        isResetPasswordResetSuccess: false,
        statusText: payload.statusText,
        error: payload.error,
      };
    }

    case types.PASSWORD_RESET_REQUEST: {
      return {
        ...state,
        isUpdatingPassword: true,
        isUpdatePasswordSuccess: null,
        statusText: null,
        error: null,
      };
    }

    case types.PASSWORD_RESET_REQUEST_SUCCESS: {
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatePasswordSuccess: true,
        statusText: "Password updated.",
        error: null,
      };
    }

    case types.PASSWORD_RESET_REQUEST_FAILURE: {
      return {
        ...state,
        isUpdatingPassword: false,
        isUpdatePasswordSuccess: false,
        statusText: payload.statusText,
        error: payload.error,
      };
    }

    case RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
}
