import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import {
  FormControl,
  Input,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  input: {
    color: "#4A4A4A",
    fontSize: "14px",
    width: "80px",
    [theme.breakpoints.up("md")]: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.standard,
      }),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "8px",
      fontSize: "20px",
      color: "white",
      width: "24px",
    },
  },
  inputFocused: {
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  baseInput: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8px",
      paddingLeft: "8px",
      fontWeight: 500,
    },
    "&::placeholder": {
      color: "#4A4A4A !important",
      [theme.breakpoints.down("sm")]: {
        color: "white !important",
      },
      opacity: 1,
    },
    "&::-webkit-input-placeholder": {
      color: "#4A4A4A !important",
      [theme.breakpoints.down("sm")]: {
        color: "white !important",
      },
      opacity: 1,
    },
  },
  searchIcon: {
    opacity: 0.4,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      opacity: 1,
      color: "white",
    },
  },
  clearButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  clearIcon: {
    color: "#4A4A4A",
    [theme.breakpoints.down("sm")]: {
      color: "white",
    },
  },
  startAdornment: {
    minWidth: "24px",
  },
  endAdornment: {
    minWidth: "24px",
  },
});

class SearchField extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = _.debounce(this.handleSearch, 300);
  }

  handleClearClick = () => {
    this.props.input.onChange("");
    this.props.toggleSearch();
    this.handleSearch();
  };

  handleFocus = event => {
    this.props.input.onFocus(event);
    if (!this.props.isSearchOpen) {
      this.props.toggleSearch();
    }
  };

  handleBlur = event => {
    this.props.input.onBlur(event);
    if (!this.props.input.value && this.props.isSearchOpen) {
      this.props.toggleSearch();
    }
  };

  handleSearch = () => {
    // this is being debounced, see constructor
    this.props.onSearch();
  };

  handleChange = event => {
    this.props.input.onChange(event);
    this.handleSearch();
  };

  render() {
    const { input, disabled, classes, meta, isSearchOpen } = this.props;

    return (
      <FormControl error={meta.invalid} className={classes.root}>
        <Input
          inputRef={node => {
            this.inputRef = node;
          }}
          placeholder="Search"
          disabled={disabled}
          className={classNames(classes.input, {
            [classes.inputFocused]: isSearchOpen,
          })}
          startAdornment={
            <InputAdornment
              onClick={() => this.inputRef.focus()}
              position="start"
              className={classes.startAdornment}
            >
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          }
          endAdornment={
            isSearchOpen ? (
              <InputAdornment position="end" className={classes.endAdornment}>
                <IconButton
                  className={classes.clearButton}
                  onClick={this.handleClearClick}
                  data-test="searchClearButton"
                >
                  <ClearIcon className={classes.clearIcon} />
                </IconButton>
              </InputAdornment>
            ) : null
          }
          disableUnderline
          classes={{
            input: classes.baseInput,
          }}
          autoComplete="off"
          id={`${input.name}_input`}
          name={input.name}
          value={input.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
      </FormControl>
    );
  }
}

SearchField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  inputComponent: PropTypes.node,
  classes: PropTypes.object,
  toggleSearch: PropTypes.func,
  isSearchOpen: PropTypes.bool,
  onSearch: PropTypes.func,
};

export default withStyles(styles)(SearchField);
