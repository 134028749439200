import React, { Component } from "react";
import PropTypes from "prop-types";
import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NotificationsTable from "./NotificationsTable";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import ContentHeaderVerticalDivider from "components/shared/ui/Page/ContentHeaderVerticalDivider";
import SortFilter from "./SortFilter";
import Text from "components/shared/ui/Typography/Text";
import { deriveTitle } from "lib/stringUtils";

import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 64px)",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
  },
  toolbarContent: {
    display: "flex",
    alignItems: "center",
  },
  quickFilter: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    display: "flex",
  },
  toolbar: {
    paddingLeft: "16px",
  },
});

class NotificationsPage extends Component {
  render() {
    const {
      notificationsResourceMetadata,
      notifications,
      classes,
      loadMore,
      onShow,
      onSortChange,
      onRowOpen,
      unreadNotificationTotal,
      width,
    } = this.props;

    let titleText = "All Read";
    if (unreadNotificationTotal > 0) {
      if (isWidthDown("sm", width)) {
        titleText = (
          <span>
            <span className="totalNum">{unreadNotificationTotal}</span> Unread
          </span>
        );
      } else {
        titleText = deriveTitle(
          { meta: { total_unread: unreadNotificationTotal } },
          "Unread Notification",
          null,
          "total_unread"
        );
      }
    }

    const title = (
      <Text fontSize={16} className={classes.title}>
        {titleText}
      </Text>
    );

    const sortValue =
      notificationsResourceMetadata.order &&
      notificationsResourceMetadata.order.column &&
      notificationsResourceMetadata.order.column === "created_at"
        ? notificationsResourceMetadata.order.order
        : "DESC";

    const toolbar = (
      <div className={classes.toolbarContent}>
        <Hidden mdUp>
          <ContentHeaderVerticalDivider />
          <SortFilter
            column="createdAt"
            onSortChange={onSortChange}
            className={classes.quickFilter}
            value={sortValue}
          />
        </Hidden>
      </div>
    );

    return (
      <div className={classes.root} data-test="notificationsPage">
        <ContentHeader
          title={title}
          toolbar={toolbar}
          classes={{ toolbar: classes.toolbar }}
        />
        <NotificationsTable
          notifications={notifications}
          notificationsResourceMetadata={notificationsResourceMetadata}
          loadMore={loadMore}
          onShow={onShow}
          onSortChange={onSortChange}
          onRowOpen={onRowOpen}
        />
      </div>
    );
  }
}

NotificationsPage.propTypes = {
  notificationsResourceMetadata: PropTypes.object,
  notifications: PropTypes.array,
  classes: PropTypes.object,
  loadMore: PropTypes.func,
  onShow: PropTypes.func,
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  hasCustomFilter: PropTypes.bool,
  onQuickFilter: PropTypes.func,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  onRowOpen: PropTypes.func,
  unreadNotificationTotal: PropTypes.number,
  currentLocale: PropTypes.string,
  onChangeLocale: PropTypes.func,
  width: PropTypes.string,
};

export default withWidth()(withStyles(styles)(NotificationsPage));
