import AverageStepsLayout from "./AverageStepsLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";

export default EksoGraphContainerWrapper(AverageStepsLayout, Stats, {
  resourceTypeName: "stats",
  resourceName: "stats/steps_count",
  id: "steps_count",
  startFilter: {
    period: "year",
    page: 1,
  },
});
