import {
  SET_PAGE_CONTEXT,
  TOGGLE_FILTER,
  TOGGLE_SEARCH,
  CHANGE_TAB,
} from "constants/PageContextTypes";

export function setContext(pageContext) {
  return {
    type: SET_PAGE_CONTEXT,
    pageContext,
  };
}

export function toggleFilter() {
  return {
    type: TOGGLE_FILTER,
  };
}

export function toggleSearch() {
  return {
    type: TOGGLE_SEARCH,
  };
}

export function changeTab(tab) {
  return {
    type: CHANGE_TAB,
    tab,
  };
}
