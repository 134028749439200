import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import { navigate } from "actions/navigateActions";
import { logoutAndRedirect } from "actions/loginActions";
import { retrieveUnreadNotificationTotal } from "actions/notificationActions";
import Header from "./Header";
import Footer from "./Footer";
import UserMenu from "./UserMenu";
import NavigationLinkList from "./NavigationLinkList";
import * as modalIds from "constants/ModalIds";

class NavigationDrawerContainer extends Component {
  componentDidMount() {
    this.props.retrieveUnreadNotificationTotal();
  }

  handleNavigate = path => {
    this.props.navigate(path);
    if (this.props.mobileOpen) {
      this.props.onDrawerToggle();
    }
  };

  handleLogout = event => {
    event.preventDefault();
    this.props.logoutAndRedirect();
  };

  handleContactSupportClick = () => {
    this.props.modalActions.openModal(modalIds.CONTACT_SUPPORT_MODAL_ID);
  };

  render() {
    const { user, location, className } = this.props;
    return (
      <div data-test="navigation" className={className}>
        <Header />
        <UserMenu
          user={user}
          onNavigate={this.handleNavigate}
          onLogout={this.handleLogout}
        />
        <NavigationLinkList
          currentPath={location.pathname}
          onNavigate={this.handleNavigate}
          onContactSupportClick={this.handleContactSupportClick}
          hasNotifications={this.props.unreadNotificationTotal > 0}
          user={user}
        />
        <Footer
          onNavigate={this.handleNavigate}
          onContactSupportClick={this.handleContactSupportClick}
          hasNotifications={this.props.unreadNotificationTotal > 0}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.currentUser,
  location: state.router.location,
  unreadNotificationTotal: state.notification.unreadTotal,
  lang: state.localization.lang,
});

const mapDispatchToProps = dispatch => ({
  navigate: bindActionCreators(navigate, dispatch),
  logoutAndRedirect: bindActionCreators(logoutAndRedirect, dispatch),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  retrieveUnreadNotificationTotal: bindActionCreators(
    retrieveUnreadNotificationTotal,
    dispatch
  ),
});

NavigationDrawerContainer.propTypes = {
  user: PropTypes.object,
  location: PropTypes.object,
  navigate: PropTypes.func,
  logoutAndRedirect: PropTypes.func,
  modalActions: PropTypes.object,
  retrieveUnreadNotificationTotal: PropTypes.object,
  unreadNotificationTotal: PropTypes.number,
  lang: PropTypes.string,
  mobileOpen: PropTypes.bool,
  onDrawerToggle: PropTypes.func,
  className: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationDrawerContainer);
