export const CONFIRM_MODAL_ID = "confirmModal";
export const USER_MODAL_ID = "userModal";
export const PATIENT_MODAL_ID = "patientModal";
export const CONTACT_SUPPORT_MODAL_ID = "contactSupportModal";
export const VIDEO_MODAL_ID = "videoModal";
export const INSESS_MODAL_ID = "insessModal";
export const NETWORK_MODAL_ID = "networkModal";
export const NETWORK_COMPARE_MODAL_ID = "networkCompareModal";
export const DEMOGRAPHICS_MODAL_ID = "demographicsModal";
export const EDIT_SESSION_MODAL_ID = "editSessionModal";
