import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import ActionButton from "components/shared/ui/Button/ActionButton";
import MultiSelectField from "components/shared/ui/Field/MultiSelectField";
import FilterHeader from "components/shared/ui/Page/FilterHeader";
import validate from "validation/Activity/filterValidation";
import Policy from "components/Policy";
import { Scrollbars } from "react-custom-scrollbars";

const styles = theme => ({
  formContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  submitButton: {
    width: "100%",
  },
  clearButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  materialField: {
    marginBottom: theme.spacing.unit * 2,
    width: "100%",
    display: "block",
  },
  disabledClearButton: {
    color: "inherit !important",
    backgroundColor: "white !important",
  },
  fieldDiv: {
    flexGrow: 1,
    padding: 0,
    paddingRight: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 2,
  },
  dateInput: {
    width: "100%",
  },
  dateIcon: {
    paddingBottom: "3px", // IE hack :(
  },
  buttonPanel: {
    margin: theme.spacing.unit * 2,
  },
  form: {
    height: "100%",
  },
});

const PatientFilterForm = ({
  handleSubmit,
  onClear,
  submitting,
  classes,
  customers,
  patients,
  eksoSerialNumbers,
  customerNumSelected,
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      name="patientFilterForm"
      className={classes.form}
    >
      <Scrollbars>
        <div className={classes.formContent}>
          <FilterHeader title="Patients Filter" />

          <div className={classes.fieldDiv} data-test="patientFieldsContainer">
            <Policy action="viewCustomerNum" object="filter">
              <Field
                name="customerNum"
                component={MultiSelectField}
                label="Customer #"
                placeholder="Search"
                className={classes.materialField}
                suggestions={customers.map(item => {
                  return {
                    label: `${item.customerNum} - ${item.customerDisplayName}`,
                    value: item.customerNum,
                  };
                })}
              />
            </Policy>
            <Field
              name="pilotNum"
              component={MultiSelectField}
              label="Patient ID"
              placeholder="Search"
              disabled={!customerNumSelected}
              className={classes.materialField}
              inputProps={{
                "data-test": "pilotNum",
              }}
              suggestions={patients.map(item => {
                return {
                  label: item,
                  value: item,
                };
              })}
            />
            <Field
              name="eksoSerialNumber"
              component={MultiSelectField}
              label="Ekso Serial Number"
              placeholder="Search"
              disabled={!customerNumSelected}
              className={classes.materialField}
              suggestions={eksoSerialNumbers.map(item => {
                return {
                  label: `${item}`,
                  value: `${item}`,
                };
              })}
            />
          </div>

          <div className={classes.buttonPanel}>
            <ActionButton
              className={classes.submitButton}
              variant="contained"
              color="primary"
              disabled={submitting}
              onClick={handleSubmit}
              data-test="applyFiltersButton"
            >
              Apply
            </ActionButton>
            <Button
              className={classes.clearButton}
              color="primary"
              classes={{
                disabled: classes.disabledClearButton,
              }}
              variant="text"
              disabled={submitting}
              onClick={onClear}
              data-test="clearFiltersButton"
            >
              Clear Fields
            </Button>
          </div>
        </div>
      </Scrollbars>
    </form>
  );
};

PatientFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  classes: PropTypes.object,
  customers: PropTypes.array.isRequired,
  patients: PropTypes.array.isRequired,
  eksoSerialNumbers: PropTypes.array.isRequired,
  onClear: PropTypes.func,
  customerNumSelected: PropTypes.bool,
};

export default reduxForm({
  form: "patientFilterForm",
  destroyOnUnmount: false,
  validate,
  forceUnregisterOnUnmount: true,
})(withStyles(styles)(PatientFilterForm));
