import "isomorphic-fetch";
import qs from "qs";
import { currentUserToken } from "utils/login";
import _ from "lodash";
import moment from "moment";

const sessionExpiredCallbacks = [];

function buildError(jsonAPIError, httpStatus) {
  let title;
  let error;
  let validationMessages;

  if (jsonAPIError.title) {
    title = jsonAPIError.title;
  } else if (jsonAPIError.error) {
    title = jsonAPIError.error;
  } else {
    validationMessages = {};
    title = jsonAPIError.errors.map(error => {
      let attrName = error.source.pointer.split("/").pop();
      if (attrName != "base") {
        validationMessages[attrName] = validationMessages[attrName]
          ? `${validationMessages[attrName]}, ${error.detail}`
          : error.detail;
        return attrName.concat(":", error.detail);
      }
      return error.detail;
    });
  }

  error = new Error(title);
  error.title = title;
  error.status = httpStatus;
  error.errors = jsonAPIError.errors;
  // Convert keys to camelCase for ui
  if (_.isPlainObject(validationMessages)) {
    error.validationMessages = _.mapKeys(validationMessages, (value, key) =>
      _.camelCase(key)
    );
  } else {
    error.validationMessages = validationMessages;
  }

  return error;
}

export async function api(path, method, params = {}, useToken = true) {
  let response;
  let json;
  let baseRequestConfig;
  let host = process.env.API_URL || "http://localhost:3000/api/v2";
  let url = `${host}/${path}.json`;
  let token = currentUserToken();

  baseRequestConfig = {
    method: method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Timezone: moment.tz ? moment.tz.guess() : null,
    },
  };

  if (token && useToken) {
    baseRequestConfig.headers["Authorization"] = `Bearer ${token}`;
  }

  if (method === "get") {
    url = url.concat(
      "?",
      qs.stringify(params.data, { arrayFormat: "brackets" })
    );
  } else {
    baseRequestConfig.body = JSON.stringify(params.data);
  }
  try {
    response = await fetch(url, baseRequestConfig);
  } catch (e) {
    throw Error("An Unknown Error Occurred");
  }

  if (response.status === 204) {
    return {};
  }

  json = await response.json();
  // Special case: Handle unauthorized explicitly on a login post call as an error
  if (response.status === 401 && path === "sessions" && method === "post") {
    throw buildError(json, response.status);
  }

  if (response.status >= 200 && response.status < 300) {
    return json;
  } else if (response.status == 401) {
    sessionExpiredCallbacks.forEach(cb => cb());
  } else {
    throw buildError(json, response.status);
  }
}

export function onSessionExpired(callback) {
  sessionExpiredCallbacks.push(callback);
}

export function getResources(type, params) {
  return api(type, "get", {
    type: type,
    data: params,
  });
}

export function getResource(type, id, params) {
  return api(`${type}/${id}`, "get", {
    type: type,
    data: params,
  });
}

export function _createJsonApiRequestData(attributes) {
  let result = {
    data: {
      attributes: attributes,
    },
  };

  if (attributes.relationships) {
    result.data.relationships = {};
    for (var key in attributes.relationships) {
      let value = attributes.relationships[key];
      if (_.isPlainObject(value)) {
        // Use object value as is
        result.data.relationships[key] = value;
      } else if (Array.isArray(value)) {
        // Convert to object array
        result.data.relationships[key] = {
          data: value.map(item => {
            if (_.isObject(item)) {
              return item;
            } else {
              return { type: key, id: item };
            }
          }),
        };
      } else {
        // Use value as id in relationship
        result.data.relationships[key] = {
          data: { type: `${key}s`, id: value },
        };
      }
    }

    // Remove from attributes
    delete result.data.attributes.relationships;
  }

  return result;
}

export function createResource(type, attributes, useToken = true) {
  let data;

  // TODO: update sessions api so it supports json api request structure
  if (type === "sessions") {
    data = {
      user: attributes,
    };
  } else {
    data = _createJsonApiRequestData(attributes);
  }

  return api(
    type,
    "post",
    {
      type: type,
      data: data,
    },
    useToken
  );
}

export function updateResource(type, attributes) {
  let path;

  path = attributes.id ? `${type}/${attributes.id}` : type;

  return api(path, "put", {
    type: type,
    data: _createJsonApiRequestData(attributes),
  });
}

export function deleteResource(type, id) {
  let path;

  path = id ? `${type}/${id}` : type;

  return api(path, "delete", {
    type: type,
  });
}

export function patchResource(type, id, attributes, subPath = "") {
  return api(`${type}/${id}${subPath}`, "PATCH", {
    type: type,
    data: _createJsonApiRequestData(attributes),
  });
}
