import React, { Component } from "react";
import DemographicsLayout from "./DemographicsLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";
import PropTypes from "prop-types";
import _ from "lodash";

class DemographicsContainer extends Component {
  onQuickFilter = quickFilter => {
    if (quickFilter && !quickFilter.period) {
      // Only filter self when "all" (blank period)
      let filter = _.extend(
        {},
        this.props.resourceMetadata.filter,
        quickFilter
      );
      filter = _.extend({}, this.props.requiredFilter, filter);

      this.props.resourceActions.get({ filter });
    } else {
      // Allow HOC to filter sibling graphs
      this.props.onQuickFilter(quickFilter);
    }
  };

  render() {
    return (
      <DemographicsLayout {...this.props} onQuickFilter={this.onQuickFilter} />
    );
  }
}

DemographicsContainer.propTypes = {
  resourceMetadata: PropTypes.object,
  requiredFilter: PropTypes.object,
  resourceActions: PropTypes.object,
  onQuickFilter: PropTypes.func,
};

export default EksoGraphContainerWrapper(DemographicsContainer, Stats, {
  resourceTypeName: "stats",
  resourceName: "stats/demographics",
  id: "demographics",
  startFilter: {
    period: "year",
    page: 1,
  },
});
