/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import { Scrollbars } from "react-custom-scrollbars";
import CancelIcon from "@material-ui/icons/Cancel";

export function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      No matches..
    </Typography>
  );
}

export function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

export function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      name={props.selectProps.name}
      disabled={props.selectProps.isDisabled}
      error={props.selectProps.meta.invalid}
      {...props.selectProps.textFieldProps}
    />
  );
}

export function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
      data-value={props.value}
    >
      {props.children}
    </MenuItem>
  );
}

export function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

export function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

export function MultiValue(props) {
  return (
    <div title={props.children} style={{ maxWidth: "100%", width: "100%" }}>
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
          [props.selectProps.classes.chipDisabled]:
            props.isDisabled || props.isLoading,
        })}
        classes={{
          label: props.selectProps.classes.chipLabel,
        }}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    </div>
  );
}

export function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

export function MenuList(props) {
  return (
    <Scrollbars
      onScroll={event =>
        onScroll(event, props.selectProps.onMenuScrollToBottom)
      }
      autoHeight
    >
      {props.children}
    </Scrollbars>
  );
}

export function onScroll(event, onMenuScrollToBottom) {
  if (
    event.target.offsetHeight + event.target.scrollTop >=
    event.target.scrollHeight
  ) {
    onMenuScrollToBottom();
  }
}
