import { Actions } from "@blue-chip/core";
import reduxAdapter from "@blue-chip/redux-adapter";
import { RESET } from "constants/ClearTypes";

let resourceActions;

export function clearStore() {
  return dispatch => {
    _clearBluechipResources(dispatch);
    dispatch({ type: RESET });
  };
}

function _clearBluechipResources(dispatch) {
  if (!resourceActions) {
    resourceActions = Actions.config({
      adapter: reduxAdapter,
      mutator: dispatch,
    });
  }

  resourceActions.clearResources([
    "sessions",
    "users",
    "patients",
    "indications",
    "settingOfUses",
    "assistiveDevices",
    "fieldData",
    "stats",
    "insessEvents",
    "learningResourceVideos",
    "learningResourceDocuments",
    "faqs",
    "faqCategory",
    "notifications",
    "userLogs",
    "customers",
    "devices",
  ]);
}
