import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";

const dialogTheme = theme => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInput: {
      input: {
        fontSize: "14px",
        padding: "9px 0 7px",
      },
    },
    MuiDialog: {
      paper: {
        margin: "24px 12px",
        borderRadius: "0px",
        maxWidth: "800px !important",
        width: "100%",
        height: "679px",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "0 24px 0",
        overflowY: "unset",
      },
    },
  },
});

// eslint-disable-next-line
const styles = theme => ({
  title: {
    position: "relative",
    fontSize: "16px",
    fontWeight: 700,
    padding: "24px 24px 24px",
  },
  titleText: {
    fontSize: "16px",
    color: "#4A4A4A",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
    color: "#444444",
  },
  actions: {
    margin: "8px 20px 24px 20px",
  },
  loadingIndicator: {
    marginLeft: "8px",
    position: "absolute",
  },
});

const NetworkModalDialog = ({
  open,
  onClose,
  classes,
  buttons,
  title,
  closeable,
  dialogOptions,
  children,
  loading,
}) => {
  const closeButton = closeable ? (
    <IconButton
      onClick={onClose}
      className={classes.closeButton}
      data-test="closeModal"
    >
      <ClearIcon />
    </IconButton>
  ) : null;

  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        open={open}
        onClose={closeable ? onClose : null}
        aria-labelledby="dialog-title"
        {...dialogOptions}
      >
        <DialogTitle
          id="dialog-title"
          disableTypography
          className={classes.title}
          data-test="modalTitle"
        >
          <Typography variant="title" className={classes.titleText}>
            {title}
            {loading ? (
              <CircularProgress
                data-test="modalLoader"
                size={16}
                thickness={5}
                className={classes.loadingIndicator}
              />
            ) : null}
          </Typography>

          {closeButton}
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions className={classes.actions}>{buttons}</DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
};

NetworkModalDialog.defaultProps = {
  closeable: true,
};

NetworkModalDialog.propTypes = {
  onClose: PropTypes.func,
  onCreateUser: PropTypes.func,
  open: PropTypes.bool,
  buttons: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  closeable: PropTypes.bool,
  classes: PropTypes.object,
  dialogOptions: PropTypes.object,
  loading: PropTypes.bool,
};

export default withStyles(styles)(NetworkModalDialog);
