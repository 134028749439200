import React from "react";
import PropTypes from "prop-types";
import NetworkModalDialog from "components/shared/ui/Modal/NetworkModalDialog";
import AverageSessions from "./AverageModalSessions";

const NetworkCompareModalDialog = ({
  open,
  onClose,
  modalData,
  showCompare,
  compare,
  menuItems,
  quickFilter,
  period,
  onPagerClick,
  page,
  sites,
  site_id
}) => {
  return (
    <NetworkModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-graph"
      title={compare ? compare.show ? compare.title : modalData.title : "Avg. Sessions"}
    >
      <AverageSessions
        modalData={modalData}
        showCompare={showCompare}
        menuItems={menuItems}
        quickFilter={quickFilter}
        period={period}
        onPagerClick={onPagerClick}
        page={page}
        sites={sites}
        compare={compare}
        site_id={site_id}
      />
    </NetworkModalDialog>
  );
};

NetworkCompareModalDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  compare: PropTypes.object,
};

export default NetworkCompareModalDialog;
