// eslint-disable-next-line
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import policyRulesEvaluator from "lib/policyRulesEvaluator";

class Policy extends Component {
  static propTypes = {
    currentUser: PropTypes.object,
    isAuthenticated: PropTypes.bool,
    action: PropTypes.string.isRequired,
    object: PropTypes.string.isRequired,
    context: PropTypes.object,
    children: PropTypes.node.isRequired,
    forceRedirect: PropTypes.bool,
  };

  render() {
    let {
      isAuthenticated,
      action,
      object,
      currentUser,
      context,
      children,
      forceRedirect,
    } = this.props;
    const authorized = policyRulesEvaluator(
      action,
      object,
      currentUser,
      context
    );

    if (isAuthenticated && authorized) {
      return children;
    } else if (isAuthenticated && !authorized && forceRedirect) {
      return <Redirect to={{ state: { unauthorizedError: true } }} />;
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser,
    isAuthenticated: state.auth.isAuthenticated,
  };
}

export { Policy as PolicyBase };
export default connect(mapStateToProps)(Policy);
