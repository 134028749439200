import React, { Component } from "react";
import PropTypes from "prop-types";
import { Scatter } from "react-chartjs-2";
import { withStyles } from "@material-ui/core/styles";
// Required import for zoom and pan
// eslint-disable-next-line
import * as zoom from "components/shared/ui/Graph/plugins/customChart.zoom";

const styles = () => ({
  legend: {
    textAlign: "center",
  },
  legendItem: {
    fontSize: "11px",
    color: "rgba(0,0,0,0.57)",
    paddingRight: "8px",
    display: "inline",
  },
  circleLegendPoint: {
    fontSize: "30px",
    verticalAlign: "middle",
    lineHeight: "12px",
    marginRight: "-2px",
  },
  diamondLegendPoint: {
    fontSize: "12px",
  },
});

const createDatasets = (
  data,
  { pointColor, pointRadius, pointStyle, legendLabel }
) => {
  let dataArrays = data;
  if (data && data.length && !Array.isArray(data[0])) {
    dataArrays = [data];
  }

  return dataArrays.map((dataArray, index) => ({
    label: Array.isArray(legendLabel)
      ? legendLabel[index]
      : legendLabel
        ? legendLabel
        : "" + index,
    data: dataArray,
    pointHoverRadius: Array.isArray(pointRadius)
      ? pointRadius[index] + 2
      : pointRadius + 2,
    pointRadius: Array.isArray(pointRadius) ? pointRadius[index] : pointRadius,
    pointHitRadius: 6,
    borderWidth: 2,
    backgroundColor: Array.isArray(pointColor) ? pointColor[index] : pointColor,
    borderColor: Array.isArray(pointColor) ? pointColor[index] : pointColor,
    pointBackgroundColor: Array.isArray(pointColor)
      ? pointColor[index]
      : pointColor,
    pointBorderColor: Array.isArray(pointColor)
      ? pointColor[index]
      : pointColor,
    pointStyle: Array.isArray(pointColor) ? pointStyle[index] : pointStyle,
  }));
};

class ScatterGraphBase extends Component {
  render() {
    const {
      id,
      data,
      pointColor,
      pointRadius,
      pointStyle,
      labels,
      height,
      width,
      className,
      options,
      legendLabel,
      classes,
    } = this.props;

    const chartjsData = {
      labels,
      options,
      datasets: createDatasets(data, {
        pointColor,
        pointRadius,
        pointStyle,
        legendLabel,
      }),
    };

    return (
      <div>
        <div className={classes.legend}>
          {legendLabel.map((label, index) => {
            return (
              <div key={label} className={classes.legendItem}>
                <span
                  style={{ color: pointColor[index] }}
                  className={
                    pointStyle[index] === "circle"
                      ? classes.circleLegendPoint
                      : classes.diamondLegendPoint
                  }
                >
                  {pointStyle[index] === "circle" ? "\u2022" : "\u2B25"}
                </span>{" "}
                {label}
              </div>
            );
          })}
        </div>
        <div
          id={id}
          className={className}
          style={{
            position: "relative",
            height,
            width,
          }}
        >
          <Scatter data={chartjsData} options={options} />
        </div>
      </div>
    );
  }
}

ScatterGraphBase.defaultProps = {
  className: "chart-container",
  height: "233px",
  width: "100%",
  pointColor: "#4A90E2",
  pointRadius: 2,
};

ScatterGraphBase.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array,
  height: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  pointRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  pointColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  pointStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  legendLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  options: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(ScatterGraphBase);
