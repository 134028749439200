import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Grid, Typography, Button } from "@material-ui/core";
import LearningCenterItem from "./LearningCenterItem";
import { withStyles } from "@material-ui/core/styles";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  header: {
    height: "52px",
    borderBottom: "1px solid #E8E8E8",
    padding: "16px",
    boxSizing: "border-box",
  },
  footer: {
    height: "52px",
    borderTop: "1px solid #E8E8E8",
    padding: "8px",
    boxSizing: "border-box",
  },
  item: {
    [theme.breakpoints.down("sm")]: {
      borderRight: 0,
    },
  },
  last4: {
    [theme.breakpoints.up("md")]: {
      borderBottom: 0,
    },
  },
  items: {
    [theme.breakpoints.down("sm")]: {
      "& > div:last-child > div": {
        borderBottom: 0,
        "& .borderBlocker": {
          display: "none",
        },
      },
    },
  },
  last: {
    borderRight: 0,
  },
  title: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "19px",
  },
  headerGrid: {},
  contentGrid: {},
  footerGrid: {},
});

const LearningCenterCategoryLayout = ({
  title,
  items,
  ItemComponent,
  classes,
  onNavigate,
  id,
  error,
  loading,
  onPlayVideo,
  viewAllPath,
}) => {
  let content;
  if (loading) {
    content = <LoadingPanel />;
  } else if (error) {
    content = <ErrorPanel error={error} />;
  } else if (items && items.length) {
    // Calculate last row (each row is 4 items) starting index
    const last4StartIndex = Math.floor((items.length - 1) / 4) * 4;
    content = items.map((item, index) => (
      <Grid key={`${item.type}-${item.id}`} item md={3} xs={12}>
        <ItemComponent
          item={item}
          classes={{
            root: classNames(classes.item, {
              [classes.last]: index % 4 === 3,
              [classes.last4]: index >= last4StartIndex,
            }),
          }}
          onPlayVideo={onPlayVideo}
          onNavigate={onNavigate}
        />
      </Grid>
    ));
  }

  return (
    <PaperPanel id={id}>
      <Grid container spacing={0}>
        <Grid item xs={12} className={classes.headerGrid}>
          <div className={classes.header}>
            <Typography className={classes.title}>{title}</Typography>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.contentGrid}>
          <Grid
            container
            spacing={0}
            className={classes.items}
            alignItems="stretch"
          >
            {content}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.footerGrid}>
          <div className={classes.footer}>
            <Button
              onClick={() => onNavigate(viewAllPath)}
              color="primary"
              data-test={`${id}_viewAll`}
            >
              View All
            </Button>
          </div>
        </Grid>
      </Grid>
    </PaperPanel>
  );
};

LearningCenterCategoryLayout.defaultProps = {
  ItemComponent: LearningCenterItem,
  hasViewAll: true,
};

LearningCenterCategoryLayout.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
  classes: PropTypes.object,
  ItemComponent: PropTypes.func,
  onNavigate: PropTypes.func,
  id: PropTypes.string,
  error: PropTypes.object,
  loading: PropTypes.bool,
  onPlayVideo: PropTypes.func,
  viewAllPath: PropTypes.string,
};

export default withStyles(styles)(LearningCenterCategoryLayout);
