import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, Collapse } from "@material-ui/core";
import WalkingStatGeneralParams from "./WalkingStatGeneralParams";
import WalkingStatGaitPerformance from "./WalkingStatGaitPerformance";
import WalkingStatGaitStats from "./WalkingStatGaitStats";

class WalkingStatParams extends Component {
  state = {
    isMounted: false,
  };

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  render() {
    const { show, insessEvent } = this.props;

    return (
      <Collapse in={show && this.state.isMounted} timeout="auto">
        <Grid
          data-test="insessWalkDetails"
          container
          spacing={16}
          alignItems="stretch"
          alignContent="stretch"
        >
          <Grid item xs={12}>
            <WalkingStatGeneralParams insessEvent={insessEvent} />
          </Grid>
          <Grid item xs={12} md={6}>
            <WalkingStatGaitPerformance insessEvent={insessEvent} />
          </Grid>
          <Grid item xs={12} md={6}>
            <WalkingStatGaitStats insessEvent={insessEvent} />
          </Grid>
        </Grid>
      </Collapse>
    );
  }
}

WalkingStatParams.propTypes = {
  insessEvent: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default WalkingStatParams;
