import AverageSessionLengthLayout from "components/shared/ui/Graph/AverageSessionLength/AverageSessionLengthLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";

export default EksoGraphContainerWrapper(AverageSessionLengthLayout, Stats, {
  resourceTypeName: "stats",
  resourceName: "stats/sessions_length",
  id: "sessions_length",
  startFilter: {
    period: "last",
  },
});
