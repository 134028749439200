const rules = {
  filter: {
    viewCustomerNum: function(currentUser) {
      return currentUser.isA(["ekso_superuser", "ekso_admin", "ekso_user"]);
    },
  },
  adminMenu: {
    view: function(currentUser) {
      return currentUser.isA(["ekso_superuser", "ekso_admin"]);
    },
  },
  overview: {
    viewMyUtilization: function(currentUser) {
      return currentUser.isA(["customer_user", "network_customer_user"]);
    },
    viewAdminUtilization: function(currentUser) {
      return !currentUser.isA(["customer_user", "network_customer_user"]);
    },
  },
  settings: {
    view: function(currentUser) {
      return currentUser.isA(["customer_admin"]);
    },
  },
  users: {
    view: function(currentUser) {
      return currentUser.isA([
        "ekso_admin",
        "customer_admin",
        "ekso_superuser",
      ]);
    },
  },
  patients: {
    view: function(currentUser) {
      return currentUser.isA([
        "ekso_admin",
        "customer_admin",
        "ekso_user",
        "ekso_superuser",
        "customer_user",
        "network_customer_user",
      ]);
    },
  },
  activities: {
    view: function(currentUser) {
      return currentUser.isA([
        "ekso_admin",
        "customer_admin",
        "ekso_user",
        "ekso_superuser",
        "customer_user",
        "network_customer_user",
      ]);
    },
  },
  showOverview: {
    view: function(currentUser) {
      return currentUser.isA([
        "ekso_admin",
        "customer_admin",
        "ekso_user",
        "ekso_superuser",
        "customer_user",
        "network_customer_user",
      ]);
    },
  },
  all: {
    manage: function(currentUser) {
      return currentUser.isA(["ekso_superuser", "ekso_admin"]);
    },
    read: function(currentUser) {
      return currentUser.isA(["ekso_superuser", "ekso_admin", "ekso_user"]);
    },
  },
  adminExport: {
    view: function(currentUser) {
      return currentUser.isA([
        "ekso_superuser",
        "ekso_admin",
        "ekso_user",
        "distributor",
      ]);
    },
  },
  export: {
    view: function(currentUser) {
      return currentUser.isA([
        "customer_user",
        "customer_admin",
        "network_customer_user",
      ]);
    },
  },
  patient: {
    add: function(currentUser) {
      return currentUser.isA([
        "customer_user",
        "network_customer_user",
        "customer_admin",
      ]);
    },
    edit: function(currentUser) {
      return currentUser.isA([
        "customer_admin",
        "customer_user",
        "network_customer_user",
      ]);
    },
  },
  session: {
    edit: function(currentUser) {
      return currentUser.isA([
        "customer_admin",
        "customer_user",
        "ekso_superuser",
        "network_customer_user",
      ]);
    },
  },
  user: {
    create: function(currentUser) {
      return currentUser.isA(["customer_admin"]);
    },

    edit: function(currentUser, context) {
      if (!context.user) {
        return false;
      }

      const isAdmin = !!context.user.role.match(
        /^(ekso_admin|customer_admin|ekso_superuser)$/
      );

      // Can't edit admins through this ui
      if (isAdmin) return false;

      return currentUser.isA(["customer_admin"]);
    },

    delete: function(currentUser, context) {
      const isAdmin = !!context.user.role.match(
        /^(ekso_admin|customer_admin|ekso_superuser)$/
      );

      // Can't delete or deactivate admins including yourself
      if (isAdmin) {
        return false;
      }

      return true;
    },
  },
  network: {
    view: function(currentUser) {
      return currentUser.isA([
        "network_admin",
        "network_customer_user",
        "ekso_sales",
      ]);
    },
  },
};

export default rules;
