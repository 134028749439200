import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import * as toastActionCreators from "actions/toastActions";
import UserModalDialog from "./UserModalDialog";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { USER_MODAL_ID } from "constants/ModalIds";
import {
  submit as submitActionCreator,
  stopSubmit as stopSubmitActionCreator,
  getFormSyncErrors,
} from "redux-form";
import _ from "lodash";
import FieldData from "models/bluechip/FieldData";

const FORM_NAME = "userReduxForm";
class UserModalContainer extends Component {
  componentDidMount() {
    // Retrieve and populate suggestion data
    this.props.filterFormsActions.find("ekso_serial_numbers");
  }

  componentDidUpdate(prevProps) {
    const wasUpdating =
      prevProps.usersResourceMetadata &&
      prevProps.usersResourceMetadata.isUpdatingItem;
    const wasCreating =
      prevProps.usersResourceMetadata &&
      prevProps.usersResourceMetadata.isCreatingItem;
    const { stopSubmit } = this.props;
    const {
      isUpdateItemSuccess,
      isCreateItemSuccess,
      updateError,
      createError,
    } = this.props.usersResourceMetadata;

    const error = updateError || createError;

    // After update close the dialog, or show field errors if any
    if (
      (wasUpdating && isUpdateItemSuccess) ||
      (wasCreating && isCreateItemSuccess)
    ) {
      this.handleClose();
    } else if ((wasUpdating || wasCreating) && !!error) {
      if (error && error.validationMessages) {
        this.props.toastActions.showToast(
          "Validation failed.  Please correct form errors and try again."
        );
        // Throw redux-form submission error
        stopSubmit(FORM_NAME, error.validationMessages);
      } else if (error && error.message) {
        this.props.toastActions.showToast(
          `${error.status ? error.status + " " : ""}${error.message}`
        );
      } else {
        this.props.toastActions.showToast("An Unknown Error Occurred");
      }
    }
  }

  handleSubmit = form => {
    if (this.props.isSubmitDisabled) {
      return;
    }

    const values = {
      email: form.email,
      user_first_name: form.userFirstName,
      user_last_name: form.userLastName,
      role: form.role,
      id: form.id,
      relationships: {
        devices:
          form.eksoSerialNumbers && form.eksoSerialNumbers.length
            ? form.eksoSerialNumbers.map(
                item => (item.value ? item.value : item)
              )
            : [],
      },
    };

    let userApiCall = this.props.usersActions.create;
    let successMessage = "User Invitation Sent";
    if (this.props.user) {
      userApiCall = this.props.usersActions.update;
      successMessage = "User Updated";
    }

    userApiCall(values, {
      successToast: successMessage,
    });
  };

  handleClose = () => {
    this.props.modalActions.closeModal(USER_MODAL_ID);
  };

  handlePrimaryAction = () => this.props.submit(FORM_NAME);

  render() {
    const { usersResourceMetadata, filterFieldData } = this.props;
    return (
      <UserModalDialog
        onSubmit={this.handleSubmit}
        onPrimaryAction={this.handlePrimaryAction}
        primaryActionLabel={this.props.user ? "Save" : "Send Invitation"}
        isSubmitDisabled={this.props.isSubmitDisabled}
        onClose={this.handleClose}
        open={this.props.open}
        submitting={
          usersResourceMetadata &&
          (usersResourceMetadata.isUpdatingItem ||
            usersResourceMetadata.isCreatingItem)
        }
        user={this.props.user}
        eksoSerialNumbers={
          filterFieldData && filterFieldData.eksoSerialNumbers
            ? filterFieldData.eksoSerialNumbers
            : []
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  usersResourceMetadata: state.jsonApiResources.users,
  open: state.modal[USER_MODAL_ID] ? state.modal[USER_MODAL_ID].open : false,
  user: state.modal[USER_MODAL_ID] ? state.modal[USER_MODAL_ID].data : null,
  isSubmitDisabled: !_.isEmpty(getFormSyncErrors(FORM_NAME)(state)),
  filterFieldData: state.resources.fieldData
    ? FieldData.query(state.resources)
        .where({ id: "ekso_serial_numbers" })
        .all()
        .toObjects()[0]
    : {},
});

const mapDispatchToProps = dispatch => ({
  filterFormsActions: jsonAPIReduxActions(dispatch, "field_data"),
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  usersActions: jsonAPIReduxActions(dispatch, "users"),
  toastActions: bindActionCreators(toastActionCreators, dispatch),
  submit: bindActionCreators(submitActionCreator, dispatch),
  stopSubmit: bindActionCreators(stopSubmitActionCreator, dispatch),
});

UserModalContainer.propTypes = {
  currentUser: PropTypes.object,
  user: PropTypes.object,
  modalActions: PropTypes.object,
  open: PropTypes.bool,
  usersActions: PropTypes.object,
  submit: PropTypes.func,
  toastActions: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  usersResourceMetadata: PropTypes.object,
  stopSubmit: PropTypes.func,
  filterFieldData: PropTypes.object,
  filterFormsActions: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserModalContainer);
