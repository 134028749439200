import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Text from "components/shared/ui/Typography/Text";

const styles = theme => ({
  footer: {
    backgroundColor: "white",
    position: "fixed",
    width: "100%",
    left: 0,
    bottom: 0,
  },
  copyright: {
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
      padding: 0,
      margin: "0 0 16px 0",
    },
    [theme.breakpoints.up("sm")]: {
      float: "left",
      marginTop: "22px",
      marginLeft: "20px",
    },
    color: "#4A4A4A",
    fontSize: "10px !important",
  },
  footerButtons: {
    [theme.breakpoints.up("xs")]: {
      textAlign: "center",
    },
    [theme.breakpoints.up("sm")]: {
      float: "right",
      marginRight: "28px",
      height: "48px",
    },
    paddingTop: "8px",
  },
  spacer: {
    color: "#D8D8D8",
    verticalAlign: "middle",
    width: "1px",
    height: "16px",
    display: "inline-block",
    backgroundColor: "#D8D8D8",
  },
});

class AuthLayoutFooter extends Component {
  render() {
    const { classes, lang } = this.props;
    return (
      <div className={classes.footer}>
        <div className={classes.footerButtons}>
          <Button
            color="primary"
            href={`http://www.eksobionics.com${lang ? "/?lang=" + lang : ""}`}
          >
            EKSOBIONICS.COM
          </Button>
          <span className={classes.spacer} />
          <Button
            color="primary"
            href="mailto:CustomerRelations@eksobionics.com"
          >
            contact support
          </Button>
        </div>
        <Text className={classes.copyright}>© Ekso GT, 2018</Text>
      </div>
    );
  }
}

AuthLayoutFooter.propTypes = {
  classes: PropTypes.object,
  lang: PropTypes.string,
};

export default withStyles(styles)(AuthLayoutFooter);
