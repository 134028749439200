import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import PaperPanel from "../Panel/PaperPanel";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    height: "73px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    margin: "0px 0px 0px 0px",
    position: "relative",
    minHeight: "50px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.115744)",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  toolbar: {
    margin: "33px 24px 16px 0px",
  },
  title: {
    paddingLeft: "16px",
    flex: 1,
    fontWeight: 400,
    color: "rgba(0,0,0,0.87)",
  },
});

const NetworkContentHeader = ({ classes, title, toolbar }) => {
  return (
    <PaperPanel className={classes.root}>
      <div className={classes.title}>{title}</div>
      <div className={classes.toolbar}>{toolbar}</div>
    </PaperPanel>
  );
};

NetworkContentHeader.propTypes = {
  title: PropTypes.node,
  toolbar: PropTypes.node,
  classes: PropTypes.object,
};

export default withStyles(styles)(NetworkContentHeader);
