import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    verticalAlign: "bottom",
  },
});

const videoFormats = [
  "MP4",
  "WEBM",
  "FLV",
  "MOV",
  "OGV",
  "3GP",
  "3G2",
  "WMV",
  "MPEG",
  "FLV",
  "MKV",
  "AVI",
];
const imageFormats = ["JPG", "JPEG", "GIF", "PNG", "TIFF"];
const xlsFormats = ["XLS", "XLSX", "XLSM", "XLTX", "XLTM"];

const FallbackThumbnail = ({ classes, fileFormat, onLoad }) => {
  let image = "thumb_unk.svg";
  if (!fileFormat) {
    // Default - unknown, do nothing
  } else if (videoFormats.contains(fileFormat)) {
    image = "thumb_mov.svg";
  } else if (imageFormats.contains(fileFormat)) {
    image = "thumb_img.svg";
  } else if (xlsFormats.contains(fileFormat)) {
    image = "thumb_xls.svg";
  } else if ("CSV" === fileFormat) {
    image = "thumb_csv.svg";
  } else if ("PDF" === fileFormat) {
    image = "thumb_pdf.svg";
  }

  return (
    <img className={classes.root} onLoad={onLoad} src={`/images/${image}`} />
  );
};

FallbackThumbnail.propTypes = {
  classes: PropTypes.object,
  fileFormat: PropTypes.string,
  onLoad: PropTypes.func,
};

export default withStyles(styles)(FallbackThumbnail);
