import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { formatDateFull } from "lib/dateUtils";
import EksoDataTable from "components/shared/ui/Table/EksoDataTable";

const styles = theme => ({
  root: {
    padding: "0px",
    borderRadius: "4px",
  },
  headerDiv: {
    padding: "0px",
  },
  tableDiv: {
    padding: "0px",
  },
  dateCell: {
    [theme.breakpoints.up("md")]: {
      width: "35%",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%",
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20%",
      whiteSpace: "nowrap",
    },
  },
});

class UserLogsTable extends Component {
  columns = [
    {
      label: "DATE / TIME",
      prop: "createdAt",
      format: value => formatDateFull(value),
      className: this.props.classes.dateCell,
    },
    {
      label: "MESSAGE",
      prop: "message",
    },
  ];

  render() {
    const {
      rows,
      resourceMetadata,
      loadMore,
      onSortChange,
      classes,
    } = this.props;

    if (
      !this.props.resourceMetadata ||
      !this.props.resourceMetadata.initiated
    ) {
      return null;
    }

    return (
      <EksoDataTable
        id="userLogs"
        columns={this.columns}
        rows={rows}
        resourceMetadata={resourceMetadata}
        loadMore={loadMore}
        onSortChange={onSortChange}
        scrollbarsSettings={{
          autoHeight: true,
          autoHeightMax: "calc(100vh - 108px)",
        }}
        classes={{
          root: classes.root,
          headerDiv: classes.headerDiv,
          tableDiv: classes.tableDiv,
        }}
      />
    );
  }
}

UserLogsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array,
  resourceMetadata: PropTypes.object,
  onSortChange: PropTypes.func,
  loadMore: PropTypes.func,
};

export { UserLogsTable as UserLogsTableBase };
export default withStyles(styles)(UserLogsTable);
