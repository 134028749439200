import React from "react";
import AssistanceLayout from "./Assistance/AssistanceLayout";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";

export default EksoGraphContainerWrapper(
  props => (
    <AssistanceLayout
      id="minimumAssistance"
      title={"Minimum Assistance"}
      unit="Min Assist"
      {...props}
    />
  ),
  Stats,
  {
    resourceTypeName: "stats",
    resourceName: "stats/minimum_assistance",
    id: "minimum_assistance",
    startFilter: {
      period: "last",
      page: 1,
    },
  }
);
