import React from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";

const InfiniteScrollWrapper = ({ loadMore, hasMore, children }) => {
  return (
    <InfiniteScroll
      initialLoad={false}
      loadMore={loadMore}
      hasMore={hasMore}
      useWindow={false}
      threshold={150}
    >
      {children}
    </InfiniteScroll>
  );
};

InfiniteScrollWrapper.propTypes = {
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  children: PropTypes.node,
};

export default InfiniteScrollWrapper;
