class Base {
  constructor(apiResponse) {
    this.apiAttrs = apiResponse.data || apiResponse;
    this.included = apiResponse.included || [];
  }

  get attributes() {
    return {
      id: this.id,
      ...this.apiAttrs.attributes,
    };
  }

  get id() {
    return this.apiAttrs["id"];
  }
}

export default Base;
