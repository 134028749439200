import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import GraphPanelLayout from "components/shared/ui/Graph/GraphPanelLayout";
import DemographicsDonutChart from "./DemographicsDonutChart";
import GraphPeriodFilter from "components/shared/ui/Graph/GraphPeriodFilter";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";

// eslint-disable-next-line
const styles = theme => ({
  parentPanelContent: {
    overflowX: "auto",
  },
  panelContent: {
    display: "flex",
  },
  labelValue: {
    margin: "0",
  },
  titleLabel: {
    whiteSpace: "nowrap",
    marginBottom: "5px",
  },
  demographicsItem: {
    flexGrow: "1",
    textAlign: "center",
    marginRight: "5px",
    padding: "0 5px",
    "&:lastChild": {
      marginRight: "0",
    },
  },
  demographicsItem1: {
    color: "#00a38f",
  },
  demographicsItem2: {
    color: "#a85fff",
  },
  demographicsItem3: {
    color: "#f57c00",
  },
  demographicsItem4: {
    color: "#64b5f6",
  },
  demographicsItem5: {
    color: "#e94948",
  },
  demographicsItem6: {
    color: "#388e3c",
  },
  demographicsItem7: {
    color: "#65fff2",
  },
  demographicsItem8: {
    color: "#2600c6",
  },
  demographicsItem9: {
    color: "#ffb74d",
  },
  demographicsItem10: {
    color: "#1976d2",
  },
  demographicsItem11: {
    color: "#81c784",
  },
  demographicsItem12: {
    color: "#790000",
  },
});

const DemographicsLayout = ({
  classes,
  onQuickFilter,
  calcData = {},
  resourceMetadata,
}) => {
  const period =
    resourceMetadata &&
    resourceMetadata.filter &&
    resourceMetadata.filter.period
      ? resourceMetadata.filter.period
      : "all";

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  return (
    <GraphPanelLayout
      title="Demographics"
      loading={loading}
      error={resourceMetadata ? resourceMetadata.fetchError : null}
      headerTools={
        <GraphPeriodFilter
          name="demographicsPeriodFilter"
          onQuickFilter={onQuickFilter}
          defaultValue={period}
          menuItems={[
            { value: "all", label: "All Time" },
            { value: "year", label: "Enrolled Past Year" },
            { value: "month", label: "Enrolled Past Month" },
            { value: "week", label: "Enrolled Past Week" },
          ]}
        />
      }
      graphContent={
        <DemographicsDonutChart
          data={calcData.stats}
          period={period}
          onPagerClick={onQuickFilter}
          page={
            resourceMetadata && resourceMetadata.filter
              ? resourceMetadata.filter.page
              : 1
          }
        />
      }
      paramContent={
        <div className={classes.parentPanelContent}>
          <div className={classes.panelContent}>
            {calcData && calcData.stats
              ? calcData.stats.map((data, index) => {
                  return (
                    <LargeParamField
                      key={data.name}
                      id={
                        typeof data === "string"
                          ? data
                          : data.name.toLowerCase()
                      }
                      data={data}
                      label={data.name}
                      name={data.name}
                      loading={loading}
                      classes={{
                        value: classes.demographicsItem,
                        labelValue: classes.labelValue,
                        titleLabel: classes.titleLabel,
                        colorLabel: classes[`demographicsItem${index + 1}`],
                      }}
                    />
                  );
                })
              : ""}
          </div>
        </div>
      }
    />
  );
};

DemographicsLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  onQuickFilter: PropTypes.func,
  resourceMetadata: PropTypes.object,
};

export default withStyles(styles)(DemographicsLayout);
