import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Text from "components/shared/ui/Typography/Text";
import LocalizedText from "components/localization/LocalizedText";

const styles = theme => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      margin: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "24px 48px",
    },
  },
});

function PrivacyPolicy({ classes }) {
  return (
    <Text className={classes.root}>
      <LocalizedText
        stringPath="landing.policy"
        context={{ policyUrl: "https://eksobionics.com/privacy-policy" }}
      />
    </Text>
  );
}

PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicy);
