import { Actions } from "@blue-chip/core";
import reduxAdapter from "@blue-chip/redux-adapter";
import * as api from "./api";
import * as types from "constants/JsonApiTypes";
import * as toastActions from "actions/toastActions";
import _ from "lodash";

let resourceActions;
const actions = ["GET", "FIND", "CREATE", "UPDATE", "DELETE"];

function constantsNameFor(constantsObj, action) {
  let request;
  let success;
  let failure;

  request = constantNameFor(action, "request");
  success = constantNameFor(action, "success");
  failure = constantNameFor(action, "failure");
  constantsObj[request] = request;
  constantsObj[success] = success;
  constantsObj[failure] = failure;
}

function constantNameFor(action, status) {
  return `${action.toUpperCase()}_${status.toUpperCase()}`;
}

async function makeRequest(action, resourceName, params, options) {
  switch (action) {
    case "get": {
      return await api.getResources(resourceName, params);
    }
    case "find": {
      return await api.getResource(resourceName, params, options.findParams);
    }
    case "update": {
      return await api.updateResource(resourceName, params);
    }
    case "create": {
      return await api.createResource(resourceName, params);
    }
    case "delete": {
      const response = await api.deleteResource(resourceName, params.id);
      if (_.isNil(response) || _.isEmpty(response)) {
        return { data: params };
      }

      return response;
    }
    default: {
      return null;
    }
  }
}

function getMostRecentRequestTimestamp(state, resourceName, action) {
  // Special field_data case, allow multiple requests to this api in flight
  if (resourceName == "field_data") {
    return 1;
  }

  if (
    resourceName.includes("pulse_sessions") &&
    state.jsonApiResources["pulse_sessions"]
  ) {
    return Math.max(
      state.jsonApiResources[resourceName][`${action}Timestamp`],
      state.jsonApiResources["pulse_sessions"][`${action}Timestamp`]
    );
  }

  return state.jsonApiResources[resourceName][`${action}Timestamp`];
}

// Redux thunk success action
export function success({
  action,
  options,
  resourceName,
  resourceActions,
  response,
  requestTimestamp,
}) {
  return (dispatch, getState) => {
    if (
      getMostRecentRequestTimestamp(getState(), resourceName, action) >
      requestTimestamp
    ) {
      return;
    }

    if (action !== "delete" || options.forceUpdate) {
      // Store as resource via blue-chip lib
      resourceActions.updateResources(response);
    }

    if (options.removeResource) {
      resourceActions.removeResource(response.data);
    }

    dispatch({
      type: `${action.toUpperCase()}_SUCCESS`,
      resourceName,
      payload: {
        response: response,
      },
      requestTimestamp,
    });

    if (options.successToast) {
      dispatch(toastActions.showToast(options.successToast));
    }
  };
}

// Redux thunk failure action
export function failure({
  action,
  options,
  resourceName,
  error,
  requestTimestamp,
}) {
  return (dispatch, getState) => {
    if (
      getState().jsonApiResources[resourceName][`${action}Timestamp`] >
      requestTimestamp
    ) {
      return;
    }

    dispatch({
      type: `${action.toUpperCase()}_FAILURE`,
      resourceName,
      payload: {
        error: error,
      },
      requestTimestamp,
    });

    if (options.errorToast) {
      dispatch(toastActions.showToast(options.errorToast));
    }
  };
}

async function reduxRequest(
  dispatch,
  action,
  resourceName,
  params,
  options = {}
) {
  // #lizard forgives
  let response;
  const requestTimestamp = Date.now();

  try {
    dispatch({
      type: `${action.toUpperCase()}_REQUEST`,
      resourceName,
      params,
      requestTimestamp,
    });

    if (options.clearResourceTypes) {
      resourceActions.clearResources(options.clearResourceTypes);
    }

    response = await makeRequest(action, resourceName, params, options);

    return dispatch(
      success({
        action,
        options,
        resourceName,
        resourceActions,
        response,
        requestTimestamp,
      })
    );
  } catch (error) {
    return dispatch(
      failure({
        action,
        options,
        resourceName,
        resourceActions,
        error,
        requestTimestamp,
      })
    );
  }
}

export function jsonAPIReduxTypes() {
  let constants = {};

  actions.forEach(action => {
    constantsNameFor(constants, action);
  });
  return constants;
}

export function jsonAPIReduxActions(dispatch, resourceName) {
  // Initialize blue-chip
  if (!resourceActions) {
    resourceActions = Actions.config({
      adapter: reduxAdapter,
      mutator: dispatch,
    });
  }

  return {
    init: function(params) {
      return dispatch({
        type: types.INIT,
        resourceName: resourceName,
        params,
      });
    },
    get: async function(params, options) {
      dispatch({
        type: types.UPDATE_RESOURCE_PARAMS,
        resourceName: resourceName,
        params: {
          filter: params.filter ? params.filter : {},
          order: params.order ? params.order : {},
          currentPage:
            params.page && params.page.number ? params.page.number : 0,
        },
      });
      if (options && options.translateFilterForApi) {
        params.filter = options.translateFilterForApi(params.filter);
      }
      return await reduxRequest(dispatch, "get", resourceName, params, options);
    },
    find: async function(id, options) {
      return await reduxRequest(dispatch, "find", resourceName, id, options);
    },
    update: async function(params, options) {
      return await reduxRequest(
        dispatch,
        "update",
        resourceName,
        params,
        options
      );
    },
    create: async function(params, options) {
      return await reduxRequest(
        dispatch,
        "create",
        resourceName,
        params,
        options
      );
    },
    delete: async function(item, options) {
      return await reduxRequest(
        dispatch,
        "delete",
        resourceName,
        item,
        options
      );
    },
    clear: function(clearResourceTypes) {
      if (clearResourceTypes) {
        resourceActions.clearResources(clearResourceTypes);
      }
    },
    removeResource: function(id, type) {
      if (id && type) {
        resourceActions.removeResource({ id, type });
      }
    },
  };
}
