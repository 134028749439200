import React from "react";
import PropTypes from "prop-types";
import { Grid, CircularProgress } from "@material-ui/core";

const LoadingPanel = ({ className }) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <div className={className} data-test="loadingPanel">
          <CircularProgress
            style={{
              display: "block",
              margin: "24px auto",
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

LoadingPanel.propTypes = {
  className: PropTypes.string,
};

export default LoadingPanel;
