import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button, Badge, Icon } from "@material-ui/core";
import Divider from "./NavigationDivider";
import Text from "components/shared/ui/Typography/Text";
import moment from "moment";

const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: "94px",
    },
  },
  copyright: {
    textAlign: "center",
    fontWeight: 400,
    color: "inherit",
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  button: {
    color: "inherit",
    width: "99px",
    height: "46px",
    borderRadius: 0,
    "&:hover": {
      [theme.breakpoints.up("md")]: {
        backgroundColor: "rgba(255, 255, 255, .05)",
      },
    },
  },
  alertButton: {
    borderRight: "1px solid rgba(255, 255, 255, .16);",
  },
  badge: {
    top: 0,
    right: 0,
    height: "6px",
    width: "6px",
    backgroundColor: "#E74C3C",
  },
  icon: {
    fontSize: "16px",
  },
  buttons: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

class Footer extends Component {
  render() {
    const {
      classes,
      onNavigate,
      hasNotifications,
      onContactSupportClick,
    } = this.props;

    let alertIcon = (
      <Icon className={`${classes.icon} icon-icn-notification`} />
    );
    if (hasNotifications) {
      alertIcon = (
        <Badge
          className={classes.margin}
          badgeContent={""}
          classes={{ badge: classes.badge }}
        >
          {alertIcon}
        </Badge>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.buttons}>
          <Divider />
          <Button
            className={`${classes.button} ${classes.alertButton}`}
            aria-label="Notifications"
            onClick={() => onNavigate("/dashboard/notifications")}
          >
            {alertIcon}
          </Button>
          <Button
            className={`${classes.button}`}
            aria-label="Contact Support"
            onClick={onContactSupportClick}
            data-test="contactSupportButton"
          >
            <Icon className={`${classes.icon} icon-icn-help`} />
          </Button>
          <Divider />
        </div>
        <Text className={classes.copyright} fontSize={12}>
          © 2011–{moment().year()} Ekso Bionics
        </Text>
      </div>
    );
  }
}

Footer.propTypes = {
  hasNotifications: PropTypes.bool,
  onNavigate: PropTypes.func,
  classes: PropTypes.object,
  onContactSupportClick: PropTypes.func,
};

export default withStyles(styles)(Footer);
