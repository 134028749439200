import { PLAYBACK_START, PLAYBACK_STOP } from "constants/VideoPlaybackTypes";

export function playbackStart() {
  return {
    type: PLAYBACK_START,
  };
}

export function playbackStop() {
  return {
    type: PLAYBACK_STOP,
  };
}
