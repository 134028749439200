import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { ArrowDropDown, ExpandMore } from "@material-ui/icons";
import _ from "lodash";
// #lizard forgives
// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  rootLeftLabel: {
    verticalAlign: "middle",
    display: "inline-block",
  },
  linkStyle: {
    color: "#3498DB !important",
    fontSize: "14px",
    fontWeight: 400,
  },
  miniStyle: {
    color: "rgba(0,0,0,0.87)",
    opacity: 0.5,
    fontSize: "10px",
    fontWeight: 500,
  },
  leftLabel: {
    fontWeight: 300,
    fontSize: "14px",
    marginTop: "auto",
    marginBottom: "auto",
    lineHeight: 2.2, // IE11 hack
  },
  icon: {},
  linkIcon: {
    opacity: 0.5,
    color: "#3498DB",
  },
  miniIcon: {
    fontSize: "16px",
    color: "black",
    top: "calc(50% - 8px)",
  },
  altSelect: {
    paddingTop: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit * 3,
    fontWeight: 700,
  },
  select: {},
  formControl: {
    width: "100%",
  },
});

function createRenderValueFunc(
  placeholder,
  renderPlaceholderMenuItem,
  menuItems
) {
  let renderValue;
  let selectedItems;
  if (placeholder && !renderPlaceholderMenuItem) {
    renderValue = selected => {
      if (
        !selected ||
        selected.length === 0 ||
        !menuItems ||
        menuItems.length === 0
      ) {
        return placeholder;
      }

      selectedItems = selected;
      if (!_.isArray(selectedItems)) {
        selectedItems = [selectedItems];
      }

      selectedItems = _.filter(
        menuItems,
        item => item.value !== "" && selectedItems.includes(item.value)
      );

      selectedItems = selectedItems.map(item => item.label);
      return selectedItems.length > 1
        ? selectedItems.join(", ")
        : selectedItems[0];
    };
  }

  return renderValue;
}

const SelectField = ({
  input,
  label,
  disabled,
  className,
  meta,
  inputProps = {},
  placeholder,
  renderPlaceholderMenuItem,
  style,
  labelLeft,
  isLinkStyle,
  isMiniStyle,
  menuItems,
  menuProps,
  classes,
  children,
}) => {
  // #lizard forgives
  let placeholderOption;
  if (!children && placeholder && renderPlaceholderMenuItem) {
    placeholderOption = (
      <MenuItem key="_placeholder" value="" disabled>
        {placeholder}
      </MenuItem>
    );
  }

  let options;
  if (!children) {
    options = menuItems.map(item => (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    ));
  }

  let defaultValue = "";
  if (inputProps && inputProps.multiple) {
    defaultValue = [];
  }

  return (
    <div
      className={classNames(
        {
          [classes.rootLeftLabel]: labelLeft,
        },
        className
      )}
      style={style}
    >
      {label && labelLeft ? (
        <InputLabel
          className={classes.leftLabel}
          htmlFor={`${input.name}_input`}
        >
          {label}
        </InputLabel>
      ) : null}
      <FormControl
        error={meta.touched && meta.invalid}
        className={classes.formControl}
        disabled={disabled}
      >
        {label && !labelLeft ? (
          <InputLabel htmlFor={`${input.name}_input`} shrink={true}>
            {label}
          </InputLabel>
        ) : null}
        <Select
          className={classNames({
            [classes.linkStyle]: isLinkStyle,
            [classes.miniStyle]: isMiniStyle,
          })}
          displayEmpty={!!placeholder}
          disableUnderline={isLinkStyle || isMiniStyle}
          classes={{
            icon: isMiniStyle
              ? classes.miniIcon
              : isLinkStyle
                ? classes.linkIcon
                : classes.icon,
            select:
              isMiniStyle || isLinkStyle ? classes.altSelect : classes.select,
          }}
          SelectDisplayProps={{
            "data-test": `${input.name}_select`,
          }}
          MenuProps={menuProps}
          renderValue={createRenderValueFunc(
            placeholder,
            renderPlaceholderMenuItem,
            menuItems
          )}
          {...inputProps}
          id={`${input.name}_input`}
          name={input.name}
          value={input.value ? input.value : defaultValue}
          onChange={input.onChange}
          onBlur={input.onBlur}
          IconComponent={isMiniStyle ? ExpandMore : ArrowDropDown}
          data-disabled={disabled}
          data-select={input.name}
        >
          {children ? children : null}
          {placeholderOption}
          {options}
        </Select>
        {meta.touched && meta.error ? (
          <FormHelperText error id={`${input.name}_helperText`}>
            {meta.error}
          </FormHelperText>
        ) : null}
      </FormControl>
    </div>
  );
};

SelectField.defaultProps = {
  renderPlaceholderMenuItem: true,
  menuProps: {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  },
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  inputComponent: PropTypes.node,
  style: PropTypes.object,
  isLinkStyle: PropTypes.bool,
  labelLeft: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
  classes: PropTypes.object,
  renderItems: PropTypes.func,
  children: PropTypes.node,
  isMiniStyle: PropTypes.bool,
  menuProps: PropTypes.object,
  renderPlaceholderMenuItem: PropTypes.bool,
};

export default withStyles(styles)(SelectField);
