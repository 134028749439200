import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { navigate } from "actions/navigateActions";
import qs from "qs";
import NotFoundPage from "components/pages/NotFoundPage";

import * as toastActionCreators from "actions/toastActions";
import UpdatePasswordPage from "./UpdatePasswordPage";
import * as actionCreators from "actions/resetPasswordActions";

class UpdatePasswordContainer extends Component {
  onUpdatePassword = ({ password, password_confirmation }) => {
    let query = {};

    // Parse the "search" (query) params in location
    if (this.props.location && this.props.location.search) {
      query = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    }

    const values = {
      password,
      password_confirmation,
      ...query,
    };

    if (!values.reset_password_token && !values.invitation_token) {
      this.props.toastActions.showToast("Missing password reset token on url");
      return false;
    }

    return this.props.actions.requestPasswordUpdate(values);
  };

  onCancel = () => {
    this.props.navigate("/");
  };

  render() {
    const { disabled, isUpdatingPassword } = this.props;
    const query = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (!query.reset_password_token && !query.invitation_token) {
      return <NotFoundPage />;
    }

    return (
      <UpdatePasswordPage
        disabled={disabled}
        isSubmitting={isUpdatingPassword}
        onUpdatePassword={this.onUpdatePassword}
        onCancel={this.onCancel}
      />
    );
  }
}

const selector = formValueSelector("updatePasswordForm");
const mapStateToProps = state => {
  let disabled = true;
  if (state.form.updatePasswordForm) {
    const { password, password_confirmation } = selector(
      state,
      "password",
      "password_confirmation"
    );
    disabled = !password || !password_confirmation;
  }

  return {
    disabled,
    isUpdatingPassword: state.resetPassword.isUpdatingPassword,
    location: state.router.location,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actionCreators, dispatch),
  toastActions: bindActionCreators(toastActionCreators, dispatch),
  navigate: bindActionCreators(navigate, dispatch),
});

UpdatePasswordContainer.propTypes = {
  actions: PropTypes.object,
  navigate: PropTypes.func,
  error: PropTypes.object,
  isUpdatingPassword: PropTypes.bool,
  location: PropTypes.object,
  disabled: PropTypes.bool,
  toastActions: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePasswordContainer);
