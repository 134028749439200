import React from "react";
import PropTypes from "prop-types";
import { Icon, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  button: {
    minWidth: "0px",
    minHeight: "0px",
  },
  icon: {
    opacity: 0.5,
  },
});

const RightIconButton = ({ classes, onClick, iconName, label, dataTest }) => {
  return (
    <Button
      className={classes.button}
      onClick={onClick}
      data-test={dataTest}
      color="primary"
    >
      {label}
      <Icon className={classes.icon}>{iconName}</Icon>
    </Button>
  );
};

RightIconButton.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  iconName: PropTypes.string,
  label: PropTypes.node,
  dataTest: PropTypes.string,
};

export default withStyles(styles)(RightIconButton);
