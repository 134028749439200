import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import EksoDataNetworkTableContainerWrapper from "components/shared/ui/Table/EksoDataNetworkTableContainerWrapper";
import Stats from "models/bluechip/Stats";
import { api } from "lib/api";
import * as toastActionCreators from "actions/toastActions";
import UtilizationTableLayout from "./UtilizationTableLayout";
import { STATS_UTILIZATION_TABLE } from "constants/NetworkTypes";

class UtilizationTableContainer extends React.Component {
  handleExportClick = async (rows, dateRange = {}) => {
    const { toastActions, site } = this.props;
    let newWindow = window.open("/generatingExport.html", "_blank");
    const { startDate, endDate } = dateRange;
    try {
      toastActions.showToast("Generating summary report...");

      let response = await api(
        "stats/network_demographics_table_export",
        "post",
        {
          data: {
            data: {
              attributes: {
                rows,
                start_date: startDate,
                end_date: endDate,
                site_id: site && site.id,
              },
            },
          },
        }
      );

      toastActions.dismissToast();
      newWindow.location.href = response.url;
    } catch (error) {
      newWindow.close();
      toastActions.showToast(
        `Error while generating summary report${
          error.message ? ": " + error.message : ""
        }`
      );
    }
  };

  render() {
    const {
      resourceMetadata,
      rows,
      loadMore,
      onSortChange,
      filterFieldData,
      filterFormsActions,
      autoHeightMax,
      clientFilter,
      site,
    } = this.props;

    return (
      <UtilizationTableLayout
        resourceMetadata={resourceMetadata}
        rows={rows}
        loadMore={loadMore}
        onSortChange={onSortChange}
        filterFieldData={filterFieldData}
        filterFormsActions={filterFormsActions}
        autoHeightMax={autoHeightMax}
        clientFilter={clientFilter}
        site={site}
        onExportClick={this.handleExportClick}
      />
    );
  }
}

UtilizationTableContainer.propTypes = {
  resourceMetadata: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  filterFieldData: PropTypes.object,
  filterFormsActions: PropTypes.object,
  autoHeightMax: PropTypes.string,
  clientFilter: PropTypes.object,
  site: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    toastActions: bindActionCreators(toastActionCreators, dispatch),
  };
}

const ConnectedContainer = connect(
  null,
  mapDispatchToProps
)(UtilizationTableContainer);
export default EksoDataNetworkTableContainerWrapper(ConnectedContainer, Stats, {
  resourceTypeName: "devices",
  resourceName: STATS_UTILIZATION_TABLE,
  startSort: { column: "site_name", order: "DESC" },
  id: "network_utilization_table",
  startFilter: {
    period: "allTime",
  },
});
