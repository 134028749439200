import React from "react";
import PropTypes from "prop-types";
import DropdownLinkField from "components/shared/ui/Field/SelectField";

class UserTypeQuickFilter extends React.Component {
  state = {
    value: "allTypes",
  };

  onChange = event => {
    const value = event.target.value;
    this.setState({ value });
    this.props.onQuickFilter({
      role: value === "allTypes" ? "" : value,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.filter && nextProps.filter.role !== prevState.value) {
      return {
        value: nextProps.filter.role ? nextProps.filter.role : "allTypes",
      };
    }

    return null;
  }

  render() {
    const { className } = this.props;
    return (
      <DropdownLinkField
        className={className}
        menuItems={[
          { value: "allTypes", label: "All Types" },
          { value: "customer_user", label: "Therapist" },
          { value: "customer_admin", label: "Admin" },
        ]}
        input={{
          name: "userTypeQuickFilter",
          onChange: this.onChange,
          value: this.state.value,
        }}
        meta={{}}
        isLinkStyle
        labelLeft
      />
    );
  }
}

UserTypeQuickFilter.propTypes = {
  onQuickFilter: PropTypes.func,
  filter: PropTypes.object,
  className: PropTypes.string,
};

export default UserTypeQuickFilter;
