import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActionCreators from "actions/modalActions";
import NetworkModalDialog from "./NetworkModalDialog";
import { NETWORK_MODAL_ID } from "constants/ModalIds";

class NetworkModalContainer extends Component {
  handleClose = () => {
    this.props.modalActions.closeModal(NETWORK_MODAL_ID);
  };

  render() {
    const { open } = this.props;
    return (
      <NetworkModalDialog
        onClose={this.handleClose}
        open={open}
      />
    );
  }
}

const mapStateToProps = state => ({
  open: state.modal[NETWORK_MODAL_ID]
    ? state.modal[NETWORK_MODAL_ID].open
    : false,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
});

NetworkModalContainer.propTypes = {
  modalActions: PropTypes.object,
  open: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkModalContainer);
