import { push, replace as replaceAction } from "connected-react-router";

export function navigate(path) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.localization && state.localization.lang !== "en") {
      let separator = "?";
      if (path && path.includes("?")) {
        separator = "&";
      }

      dispatch(push(`${path}${separator}lang=${state.localization.lang}`));
    } else {
      dispatch(push(path));
    }
  };
}

export function replace(path) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.localization && state.localization.lang !== "en") {
      let separator = "?";
      if (path && path.includes("?")) {
        separator = "&";
      }

      dispatch(
        replaceAction(`${path}${separator}lang=${state.localization.lang}`)
      );
    } else {
      dispatch(replaceAction(path));
    }
  };
}
