import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import BasicPageHeader from "components/shared/ui/Page/BasicPageHeader";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import LocationForm from "./LocationForm";
import EksoDetails from "./EksoDetails";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
  },
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
  mainPanel: {
    [theme.breakpoints.down("sm")]: {
      border: 0,
      boxShadow: "none",
      borderRadius: 0,
      backgroundColor: "transparent",
    },
  },
  innerPanel: {},
  detailsPanel: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "8px",
    },
  },
  formPanel: {
    padding: "24px",
    [theme.breakpoints.up("md")]: {
      border: 0,
      boxShadow: "none",
      borderRadius: 0,
    },
  },
});

class AccountPage extends Component {
  render() {
    const {
      classes,
      eksoSerialNumbers,
      customer,
      isSaving,
      loading,
      onSubmitLocation,
    } = this.props;

    if (!customer || loading) {
      return (
        <div className={classes.root}>
          <BasicPageHeader title="Settings" />
          <div className={classes.main}>
            <Grid item xs={12}>
              <PaperPanel>
                <LoadingPanel />
              </PaperPanel>
            </Grid>
          </div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <BasicPageHeader title="Settings" />
        <div className={classes.main}>
          <Grid container spacing={8}>
            <Grid item xs={12} className={classes.gridItem}>
              <PaperPanel className={classes.mainPanel}>
                <Grid
                  className={classes.innerPanel}
                  container
                  spacing={0}
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item md={4} lg={3} xs={12}>
                    <PaperPanel
                      className={classNames(
                        classes.detailsPanel,
                        classes.formPanel
                      )}
                    >
                      <LocationForm
                        isSaving={isSaving}
                        onSubmit={onSubmitLocation}
                        initialValues={{
                          timeZone: customer.timeZone,
                          language: customer.language
                            ? customer.language
                            : "en",
                        }}
                      />
                    </PaperPanel>
                  </Grid>
                  <Grid item md={4} lg={3} xs={12}>
                    <PaperPanel className={classes.formPanel}>
                      <EksoDetails eksoSerialNumbers={eksoSerialNumbers} />
                    </PaperPanel>
                  </Grid>
                </Grid>
              </PaperPanel>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

AccountPage.propTypes = {
  classes: PropTypes.object,
  onSubmitDetails: PropTypes.func,
  onSubmitPassword: PropTypes.func,
  customer: PropTypes.object,
  isSaving: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmitLocation: PropTypes.func,
  eksoSerialNumbers: PropTypes.array,
};

export default withStyles(styles)(AccountPage);
