import React from "react";
import PropTypes from "prop-types";

import BaseText from "./BaseText";

const PanelHeader = ({ children, style, className, testId }) => {
  return (
    <BaseText
      testId={testId}
      text={children}
      fontFamily={"Roboto"}
      fontSize={24}
      lineHeight={"28px"}
      fontWeight={200}
      style={style}
      className={className}
    />
  );
};

PanelHeader.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default PanelHeader;
