import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Patient from "models/bluechip/Patient";
import { Redirect } from "react-router-dom";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { setContext } from "actions/pageContextActions";
import { bindActionCreators } from "redux";
import PatientDetailsLayout from "./PatientDetailsLayout";
import moment from "moment";

class PatientDetailsContainer extends Component {
  state = {
    initialMount: true,
  };

  componentDidMount() {
    const { id, patientsResourceMetadata } = this.props;
    this.props.setContext({
      pageTitle: "",
      pageSubTitle: "",
      isFilterOpen: false,
      mobileToolbarHeight: "120px",
      loaded: false,
    });
    if (
      !patientsResourceMetadata ||
      !patientsResourceMetadata.foundIds.includes(id)
    ) {
      this.props.patientsActions.find(this.props.id);
    }
    this.setState({ initialMount: false });
  }

  componentDidUpdate() {
    const { patient, patientsResourceMetadata, pageContext } = this.props;
    if (patient && !pageContext.loaded) {
      this.props.setContext({
        pageTitle: `#${patient.pilotNum}`,
        pageSubTitle: `Created on ${moment(patient.createdAt).format(
          "MM/DD/YY, h:mma"
        )}`,
        isFilterOpen: false,
        mobileToolbarHeight: "120px",
        path: `/dashboard/patients/${patient.id}`,
        loaded: true,
        data: patient,
      });
    } else if (
      patientsResourceMetadata &&
      patientsResourceMetadata.findError &&
      !pageContext.loaded
    ) {
      this.props.setContext({
        mobileToolbarHeight: "80px",
        error: true,
        loaded: true,
      });
    }
  }

  render() {
    const { patientsResourceMetadata, patient, tab } = this.props;
    // Redirect on 404 error for base user data
    if (
      patientsResourceMetadata &&
      patientsResourceMetadata.findError &&
      patientsResourceMetadata.findError.status === 404
    ) {
      return <Redirect to={{ state: { notFoundError: true } }} />;
    }

    return (
      <PatientDetailsLayout
        patientsResourceMetadata={patientsResourceMetadata}
        patient={patient}
        initialMount={this.state.initialMount}
        tab={tab}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  patient: state.resources.patients
    ? Patient.query(state.resources)
        .where({ id: ownProps.id })
        .includes(["indication", "settingOfUse", "assistiveDevices"])
        .all()
        .toObjects()[0]
    : null,
  pageContext: state.pageContext,
  patientsResourceMetadata: state.jsonApiResources["patients"],
});

const mapDispatchToProps = dispatch => ({
  patientsActions: jsonAPIReduxActions(dispatch, "patients"),
  setContext: bindActionCreators(setContext, dispatch),
});

PatientDetailsContainer.propTypes = {
  id: PropTypes.string,
  patientsActions: PropTypes.object,
  patient: PropTypes.object,
  pageContext: PropTypes.object,
  setContext: PropTypes.func,
  patientsResourceMetadata: PropTypes.object,
  tab: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientDetailsContainer);
