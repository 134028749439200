import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { get, isNil } from "lodash";
import Policy from "components/Policy";
import SessionsTable from "./SessionsTable";
import ContentHeader from "components/shared/ui/Page/ContentHeader";
import ContentHeaderVerticalDivider from "components/shared/ui/Page/ContentHeaderVerticalDivider";
import FilterButton from "components/shared/ui/Page/FilterButton";
import DateQuickFilter from "components/shared/ui/Page/DateQuickFilter";
import Text from "components/shared/ui/Typography/Text";
import { deriveTitle } from "lib/stringUtils";
import InsessModal from "components/pages/Activity/SessionDetails/InsessModal";
import MyPatientsQuickFilter from "components/pages/Patients/MyPatientsQuickFilter";
import EksoIconButton from "components/shared/ui/Button/EksoIconButton";
import { convertStringArray } from "lib/commonUtils";
import EditSessionModal from "components/pages/Activity/EditSessionModal";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    maxHeight: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "calc(100% - 64px)",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
  },
  titleText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleTextDivider: {
    margin: "0 5px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  titleFilterText: {
    fontSize: "14px",
    opacity: 0.5,
    fontWeight: 500,
  },
  titleSeparator: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolbarContent: {
    display: "flex",
    alignItems: "center",
  },
  quickFilter: {
    marginRight: theme.spacing.unit,
    marginLeft: theme.spacing.unit,
    display: "flex",
  },
  checkboxQuickFilter: {
    marginRight: 0,
    marginLeft: theme.spacing.unit * 2,
    display: "flex",
  },
});

class ActivityPage extends Component {
  render() {
    const {
      sessionsResourceMetadata,
      sessions,
      classes,
      loadMore,
      onShow,
      toggleFilter,
      isFilterOpen,
      onSortChange,
      onExportSummarySubmit,
      clientFilter,
      menusOpen,
      onQuickFilter,
      currentUser,
    } = this.props;
    const hasStepsTotal = !isNil(
      get(sessionsResourceMetadata, "meta.total_steps")
    );
    const noCustomerIdSelected =
      !sessionsResourceMetadata ||
      !sessionsResourceMetadata.filter ||
      !sessionsResourceMetadata.filter.customer_num ||
      !sessionsResourceMetadata.filter.customer_num.length;

    const title = (
      <Text fontSize={16} className={classes.title}>
        <span className={classes.titleText}>
          {deriveTitle(sessionsResourceMetadata, "Session")}
        </span>
        {hasStepsTotal && (
          <Fragment>
            <span className={classes.titleTextDivider}>|</span>
            <span className={classes.titleText}>
              {deriveTitle(
                sessionsResourceMetadata,
                "Step",
                null,
                "total_steps"
              )}
            </span>
          </Fragment>
        )}

        <span className={classes.titleFilterText}>
          {noCustomerIdSelected ? null : (
            <span className={classes.titleSeparator}>{" - "}</span>
          )}
          {noCustomerIdSelected
            ? ""
            : `Customer #${convertStringArray(
                sessionsResourceMetadata.filter.customer_num
              ).join(", #")}`}
        </span>
      </Text>
    );

    const periodValue = clientFilter.period ? clientFilter.period : "allTime";

    const toolbar = (
      <div className={classes.toolbarContent}>
        <Policy action="add" object="patient">
          <ContentHeaderVerticalDivider />
          <MyPatientsQuickFilter
            className={classes.checkboxQuickFilter}
            onQuickFilter={onQuickFilter}
            value={
              sessionsResourceMetadata &&
              sessionsResourceMetadata.filter &&
              sessionsResourceMetadata.filter.my_patients
            }
          />
        </Policy>

        <EksoIconButton
          onClick={() => onExportSummarySubmit(false)}
          label="Export"
          iconName="insert_drive_file"
          dataTest="exportDetailsButton"
        />

        <Policy action="view" object="adminExport">
          <EksoIconButton
            onClick={() => onExportSummarySubmit(true)}
            label="Admin Export"
            iconName="insert_drive_file"
            dataTest="exportDetailsButton"
          />
        </Policy>
        <ContentHeaderVerticalDivider />
        <DateQuickFilter
          hasCustomFilter={this.props.hasCustomFilter}
          onQuickFilter={onQuickFilter}
          className={classes.quickFilter}
          value={periodValue}
        />
        <Hidden smDown>
          <ContentHeaderVerticalDivider />
          <FilterButton
            toggleFilter={toggleFilter}
            isFilterOpen={isFilterOpen}
          />
        </Hidden>
      </div>
    );

    return (
      <div className={classes.root} data-test="activityPage">
        <ContentHeader title={title} toolbar={toolbar} />
        <SessionsTable
          sessions={sessions}
          sessionsResourceMetadata={sessionsResourceMetadata}
          loadMore={loadMore}
          onShow={onShow}
          onSortChange={onSortChange}
          menusOpen={menusOpen}
          currentUser={currentUser}
        />
        <InsessModal />
        <EditSessionModal />
      </div>
    );
  }
}

ActivityPage.propTypes = {
  sessionsResourceMetadata: PropTypes.object,
  sessions: PropTypes.array,
  classes: PropTypes.object,
  loadMore: PropTypes.func,
  onShow: PropTypes.func,
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  hasCustomFilter: PropTypes.bool,
  onQuickFilter: PropTypes.func,
  onSortChange: PropTypes.func,
  onExportSummarySubmit: PropTypes.func,
  sort: PropTypes.object,
  clientFilter: PropTypes.object,
  menusOpen: PropTypes.bool,
  currentUser: PropTypes.object,
};

export default withStyles(styles)(ActivityPage);
