import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { FullscreenToggle, VolumeMenuButton } from "video-react";

// eslint-disable-next-line
const styles = theme => ({
  controls: {
    fontSize: "15px",
    marginTop: "-10px",
    height: "45px",
    "& .video-react-control:before": {
      textAlign: "right",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
      marginTop: "-6px",
    },
  },
  title: {
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: 400,
    flexGrow: 1,
    marginLeft: "8px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  hideTitle: {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0s 500ms, opacity 500ms linear",
  },
  titleDiv: {
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "72px",
    width: "100%",
    boxSizing: "border-box",
    padding: "24px 16px",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    zIndex: 1,
    "& .video-react-volume-bar.video-react-slider-horizontal": {
      width: "7em",
    },
    "& .video-react-fullscreen-control.video-react-control:before": {
      textAlign: "center !important",
    },
    [theme.breakpoints.down("xs")]: {
      height: "48px",
      padding: "12px 0px",
    },
  },
  iconButton: {
    color: "white",
    flexGrow: 0,
    marginTop: "-12px",
  },
});

const VideoHeader = ({
  classes,
  playerRef,
  playerState,
  videoData,
  onClose,
}) => {
  return (
    <div
      className={classNames(classes.titleDiv, {
        [classes.hideTitle]: !playerState.userActivity && !playerState.paused,
      })}
    >
      <IconButton
        color="primary"
        className={classes.iconButton}
        onClick={onClose}
        data-test="videoClose"
      >
        <ChevronLeftIcon />
      </IconButton>
      <div
        className={classes.title}
        title={videoData.title}
        data-test="videoTitle"
      >
        {videoData.title}
      </div>
      <div className={classes.controls}>
        <VolumeMenuButton
          className={classes.volume}
          player={playerState}
          actions={playerRef.actions}
          alwaysShowVolume
        />
      </div>
      <div className={classes.controls}>
        <FullscreenToggle player={playerState} actions={playerRef.actions} />
      </div>
    </div>
  );
};

VideoHeader.propTypes = {
  classes: PropTypes.object,
  playerRef: PropTypes.object,
  playerState: PropTypes.object,
  videoData: PropTypes.object,
  onClose: PropTypes.func,
};

export default withStyles(styles)(VideoHeader);
