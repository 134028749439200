import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import EksoDataTable from "components/shared/ui/Table/EksoDataTable";
import MessagePanel from "components/shared/ui/Panel/MessagePanel";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";

const styles = theme => ({
  table: {
    padding: "0px",
    borderRadius: "4px",
  },
  headerDiv: {
    padding: "0px",
  },
  tableDiv: {
    padding: "0px",
  },
  dateCol: {
    width: "350px",
    [theme.breakpoints.down("xs")]: {
      width: "72px",
    },
  },
  title: {
    margin: 0,
    fontSize: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentHeader: {
    width: "100%",
    padding: "0",
    margin: "0",
    boxShadow: "none",
    height: "52px",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
  },
  contentHeaderToolbar: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dateField: {
    marginRight: "4px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "48%",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-8px",
      maxWidth: "inherit",
      marginTop: "2px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "30%",
    },
  },
  activityButton: {
    marginLeft: "8px",
    marginRight: "8px",
    padding: "8px",
    [theme.breakpoints.down("sm")]: {
      float: "right",
    },
  },
  hideCellMdDown: {
    width: "0 !important",
    height: "0 !important",
    padding: "0 !important",
    margin: "0 !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "transparent",
  },
  hideCellXs: {
    [theme.breakpoints.down("xs")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  alignLeftSm: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      flexDirection: "row",
      paddingLeft: "8px",
    },
  },
  patientLink: {
    fontWeight: 500,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class DemographicsTable extends Component {
  state = {
    dateLabel: "This Month",
    sortObj: { column: "site_name", order: "DESC" },
  };

  handleSortChange = sortObj => {
    this.setState({ sortObj });
  };

  _sortedRows() {
    const { sortObj } = this.state;
    const { rows, resourceMetadata } = this.props;
    if (resourceMetadata && resourceMetadata.isFetchingItems) return [];
    return _.orderBy(
      rows.data || [],
      [_.camelCase(sortObj.column)],
      [sortObj.order.toLowerCase()]
    );
  }

  _camalize(str) {
    return str
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
  }

  render() {
    const { resourceMetadata, loadMore, classes, rows } = this.props;

    const { sortObj } = this.state;

    const siteNameColumn = [
      {
        label: "SITE NAME",
        prop: "siteName",
        className: this.props.classes.alignLeftSm,
      },
    ];

    const indicationColumns =
      rows && rows.indications
        ? rows.indications.map(indication => {
            return {
              label: indication.name,
              prop: this._camalize(indication.name),
              isNumeric: true,
              className: this.props.classes.alignLeftSm,
            };
          })
        : [];

    const unassignedColumn = [
      {
        label: "UNASSIGNED",
        prop: "totalUnassigned",
        className: this.props.classes.alignLeftSm,
      },
    ];

    const columns = [
      ...siteNameColumn,
      ...indicationColumns,
      ...unassignedColumn,
    ];

    if (!resourceMetadata || !resourceMetadata.initiated) {
      return null;
    }

    const emptyContent = (
      <MessagePanel
        className={classes.emptyTablePanel}
        headerText="No Information Recorded"
        description="Come back soon."
      />
    );

    let autoHeightMax = "calc(100vh - 416px)";
    if (isWidthDown("sm", this.props.width)) {
      autoHeightMax = "488px";
    }

    return (
      <div className={classes.root}>
        <EksoDataTable
          id="DemographicsTable"
          columns={columns}
          sort={sortObj}
          rows={this._sortedRows()}
          resourceMetadata={resourceMetadata}
          loadMore={loadMore}
          onSortChange={this.handleSortChange}
          scrollbarsSettings={{
            autoHeight: true,
            autoHeightMax,
          }}
          classes={{
            root: classes.table,
            headerDiv: classes.headerDiv,
            tableDiv: classes.tableDiv,
          }}
          emptyContent={emptyContent}
        />
      </div>
    );
  }
}

DemographicsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array,
  resourceMetadata: PropTypes.object,
  onSortChange: PropTypes.func,
  loadMore: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onNavigate: PropTypes.func,
  customers: PropTypes.array,
  autoHeightMax: PropTypes.string,
  width: PropTypes.string,
  onDateChange: PropTypes.func,
  onCustomerChange: PropTypes.func,
  dateCurrentValue: PropTypes.string,
  customerCurrentValue: PropTypes.array,
  clientFilter: PropTypes.object,
  site: PropTypes.object,
};

export { DemographicsTable as DemographicsTableBase };
export default withWidth()(withStyles(styles)(DemographicsTable));
