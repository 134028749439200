import moment from "moment";
import { formatDateForApi } from "./dateUtils";

export function translateDatePeriodFilter(periodValue) {
  const filter = {};
  const today = moment();
  switch (periodValue) {
    case "allTime":
      filter.start_date = null;
      filter.end_date = null;
      break;
    case "thisYear":
      filter.start_date = formatDateForApi(moment().subtract(1, "year"));
      filter.end_date = formatDateForApi(today);
      break;
    case "thisQuarter":
      filter.start_date = formatDateForApi(
        moment()
          .quarter(moment().quarter())
          .startOf("quarter")
      );
      filter.end_date = formatDateForApi(
        moment()
          .quarter(moment().quarter())
          .endOf("quarter")
      );
      break;
    case "thisMonth":
      filter.start_date = formatDateForApi(moment().subtract(1, "month"));
      filter.end_date = formatDateForApi(today);
      break;
    case "thisWeek":
      filter.start_date = formatDateForApi(moment().subtract(7, "days"));
      filter.end_date = formatDateForApi(today);
      break;
    case "today":
      filter.start_date = formatDateForApi(today);
      filter.end_date = formatDateForApi(today);
      break;
    default:
      // Do nothing
      break;
  }
  return filter;
}

export function translateDatePeriodFilterToCalendar(periodValue) {
  const filter = {};
  const today = moment();
  switch (periodValue) {
    case "allTime":
      filter.start_date = null;
      filter.end_date = null;
      break;
    case "thisYear":
      filter.start_date = formatDateForApi(moment().startOf("year"));
      filter.end_date = formatDateForApi(today);
      break;
    case "thisQuarter":
      filter.start_date = formatDateForApi(moment().startOf("quarter"));
      filter.end_date = formatDateForApi(moment().endOf("quarter"));
      break;
    case "thisMonth":
      filter.start_date = formatDateForApi(moment().startOf("month"));
      filter.end_date = formatDateForApi(today);
      break;
    case "thisWeek":
      filter.start_date = formatDateForApi(moment().startOf("week"));
      filter.end_date = formatDateForApi(today);
      break;
    case "today":
      filter.start_date = formatDateForApi(today);
      filter.end_date = formatDateForApi(today);
      break;
    default:
      // Do nothing
      break;
  }
  return filter;
}
