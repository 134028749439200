import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import WalkingStatsGraph from "./WalkingStatsGraph";
import { Typography, Divider } from "@material-ui/core";
import LoadingPanel from "components/shared/ui/Panel/LoadingPanel";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import WalkingStatDetails from "./WalkingStatDetails";
import { BrowserView, MobileView } from "react-device-detect";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    paddingTop: "16px",
  },
  detailsPanel: {
    paddingTop: "16px",
  },
  headerContent: {
    display: "flex",
    padding: "0 16px 16px 0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    flex: 1,
  },
  headerDesc: {
    textAlign: "right",
    fontFamily: "Roboto",
    fontSize: "12px",
    lineHeight: "16px",
    fontWeight: 500,
    color: "rgba(0,0,0,0.435)",
    [theme.breakpoints.down("sm")]: {
      marginTop: "8px",
      textAlign: "left",
    },
  },
  selectionTitle: {
    color: "rgba(0,0,0,0.87)",
    fontFamily: "Roboto",
    fontSize: "16px",
    lineHeight: "19px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "16px",
    },
  },
  graphPanel: {
    paddingLeft: "4px",
    paddingRight: "16px",
    width: "100%",
  },
  topDivider: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginBottom: "16px",
      marginLeft: "-16px",
      marginRight: "-32px",
    },
  },
  bottomDivider: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      marginTop: "16px",
      marginLeft: "-16px",
      marginRight: "-32px",
    },
  },
});

const WalkingStatsLayout = ({
  classes,
  graphData,
  onStepChange,
  resourceMetadata,
  selectedStepData,
  isMissingFeature,
}) => {
  const showGraph =
    resourceMetadata &&
    !resourceMetadata.isFetchingItems &&
    resourceMetadata.isFetchItemsSuccess;
  const showError = resourceMetadata && resourceMetadata.fetchError;
  const showLoading = !resourceMetadata || resourceMetadata.isFetchingItems;
  const stepSelected =
    resourceMetadata &&
    !resourceMetadata.isFetchingItems &&
    selectedStepData &&
    selectedStepData.id;
  const showGraphDesc =
    showGraph && graphData && graphData.left && graphData.left.length;

  return (
    <div className={classes.root}>
      <div className={classes.headerContent}>
        <Typography className={classes.header} variant="subheading">
          Walking Statistics
        </Typography>
        {showGraphDesc ? (
          <div className={classes.headerDesc}>
            Select a Step to view the details below.
            <BrowserView>
              <div>
                Use drag to pan the graph left or right, and mouse wheel to zoom
                in and out.
              </div>
            </BrowserView>
            <MobileView>
              <div>
                Drag to pan the graph left or right, and pinch to zoom in and
                out.
              </div>
            </MobileView>
          </div>
        ) : null}
      </div>

      <Divider className={classes.topDivider} />

      {showGraph ? (
        <div className={classes.graphPanel}>
          <WalkingStatsGraph
            graphData={graphData ? graphData : { left: [], right: [] }}
            onStepChange={onStepChange}
            selectedStepData={selectedStepData}
            isMissingFeature={isMissingFeature}
          />
        </div>
      ) : null}

      {showLoading ? <LoadingPanel /> : null}

      {showError ? <ErrorPanel error={resourceMetadata.findError} /> : null}

      {stepSelected ? <Divider className={classes.bottomDivider} /> : null}

      {stepSelected ? (
        <div className={classes.detailsPanel}>
          <Typography
            data-test="stepTitle"
            className={classes.selectionTitle}
            variant="subheading"
          >
            Step {selectedStepData.x} Statistics
          </Typography>
          <WalkingStatDetails id={String(selectedStepData.id)} />
        </div>
      ) : null}
    </div>
  );
};

WalkingStatsLayout.propTypes = {
  graphData: PropTypes.object,
  onStepChange: PropTypes.func,
  resourceMetadata: PropTypes.object,
  selectedStepData: PropTypes.object,
  classes: PropTypes.object,
  isMissingFeature: PropTypes.bool,
};

export default withStyles(styles)(WalkingStatsLayout);
