import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import GraphPager from "./GraphPager";

const _calculatePagerProps = (period, page, onPagerClick) => {
  const result = {};
  result.rightDisabled = page === 1;

  let startLabel;
  let endLabel;

  switch (period) {
    case "year": {
      const start = moment()
        .subtract(page, "years")
        .add(1, "month");
      const end = moment().subtract(page - 1, "years");

      startLabel = start.format("MMM YYYY");
      endLabel = end.format("MMM YYYY");

      result.leftDisabled = page === 10;
      result.label = `${startLabel} - ${endLabel}`;
      result.labelWidth = "112px";
      break;
    }
    case "month": {
      const start = moment().subtract(page, "month");
      const end = moment().subtract(page - 1, "month");

      startLabel = start.format("MMM DD, YYYY");
      endLabel = end.format("MMM DD, YYYY");

      result.leftDisabled = page === 12 * 10;
      result.label = `${startLabel} - ${endLabel}`;
      result.labelWidth = "150px";
      break;
    }
    case "week": {
      const start = moment()
        .subtract(page, "weeks")
        .add(1, "day");
      const end = moment().subtract(page - 1, "weeks");

      startLabel = start.format("MMM DD, YYYY");
      endLabel = end.format("MMM DD, YYYY");

      result.leftDisabled = page === 52 * 10;
      result.label = `${startLabel} - ${endLabel}`;
      result.labelWidth = "150px";
      break;
    }
    case "all": {
      result.hideButtons = true;
      result.label = "All Time";
    }
  }

  result.onLeftClick = () => onPagerClick({ period, page: page + 1 });
  result.onRightClick = () => onPagerClick({ period, page: page - 1 });

  return result;
};

const TimeLineGraphPager = ({ onPagerClick, page, period }) => {
  const pagerProps = _calculatePagerProps(period, page, onPagerClick);
  return <GraphPager {...pagerProps} />;
};

TimeLineGraphPager.propTypes = {
  onPagerClick: PropTypes.func,
  page: PropTypes.number,
  period: PropTypes.string,
};

export default TimeLineGraphPager;
