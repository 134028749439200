/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import DataTableRow from "components/shared/ui/Table/DataTableRow";
import { TableBody, TableRow, TableCell, Typography } from "@material-ui/core";
import _ from "lodash";

const styles = theme => ({
  root: {},
  closed: {
    "&::before": {
      content: "close-quote",
      height: "0px",
      display: "block",
      transition: "all 200ms",
    },
    "&::after": {
      content: "close-quote",
      height: "0px",
      display: "block",
      transition: "all 200ms",
    },
  },
  open: {
    "&:before": {
      content: "close-quote",
      display: "block",
      height: "16px",
      transition: "all 200ms",
    },
    "&:after": {
      content: "close-quote",
      display: "block",
      height: "16px",
      transition: "all 200ms",
    },
  },
  detailsDiv: {
    position: "relative",
    marginTop: "-8px",
  },
  // Note use of div is to hide the shadow of the details row to allow a consistent shadow border
  shadowBlocker: {
    position: "absolute",
    height: "10px",
    width: "calc(100% + 46px)",
    top: "-10px",
    backgroundColor: "white",
    marginLeft: "-16px",
    cursor: "pointer",
  },
  row: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
  },
  rowOpen: {
    boxShadow: "0 1px 10px 0 rgba(0,0,0,0.32)",
  },
  answer: {
    color: "#9B9B9B",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    paddingRight: "32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  detailsCell: {
    paddingTop: 0,
  },
});

class FaqItem extends Component {
  handleClick = () => {
    this.props.onRowClick(this.props.faq);
  };

  render() {
    const { classes, faq, columns, isActive } = this.props;

    let bodyClass = `${classes.root} ${classes.closed}`;
    let rowClass = classes.row;

    if (isActive) {
      bodyClass = `${classes.root} ${classes.open}`;
      rowClass = `${classes.row} ${classes.rowOpen}`;
    }

    return (
      <TableBody className={bodyClass}>
        <DataTableRow
          onClick={this.handleClick}
          className={rowClass}
          columns={columns}
          rowData={_.extend({}, faq, { isActive })}
        />

        {isActive ? (
          <TableRow className={rowClass} data-test="tableRowActive">
            <TableCell colSpan={columns.length} className={classes.detailsCell}>
              <div className={classes.detailsDiv}>
                <div className={classes.shadowBlocker} />
                <p
                  data-test="answer"
                  className={classes.answer}
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </div>
            </TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    );
  }
}

export default withStyles(styles)(FaqItem);

FaqItem.propTypes = {
  faq: PropTypes.object.isRequired,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  isActive: PropTypes.bool,
};
