import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import TimeLineGraphBase from "components/shared/ui/Graph/TimeLineGraphBase";

const AverageStepsGraph = ({ period, data, page, onPagerClick }) => {
  let convertedData = [];
  if (data && data.length) {
    convertedData = _.map(data, item => ({
      x: item.period,
      y: item.avg ? item.avg : NaN,
    }));
  }

  return (
    <TimeLineGraphBase
      id="AverageSteps"
      data={[convertedData]}
      period={period}
      page={page}
      onPagerClick={onPagerClick}
      emptyMax={100}
      unitLabel={"steps"}
      tooltipLabelCallback={tooltipItem => {
        if (`${tooltipItem.yLabel}` !== "1") {
          return tooltipItem.yLabel + " steps";
        } else {
          return tooltipItem.yLabel + " step";
        }
      }}
    />
  );
};

AverageStepsGraph.propTypes = {
  classes: PropTypes.object,
  period: PropTypes.string,
  data: PropTypes.array,
  page: PropTypes.number,
  onPagerClick: PropTypes.func,
};

export default AverageStepsGraph;
