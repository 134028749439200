import React from "react";
import PropTypes from "prop-types";
import GraphPanelLayout from "components/shared/ui/Graph/GraphPanelLayout";
import GraphPeriodFilter from "components/shared/ui/Graph/GraphPeriodFilter";
import AssistanceGraph from "./AssistanceGraph";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  leftParamField: {
    marginRight: "26px",
  },
  leftParamValue: {
    color: "#E67E22",
  },
  rightParamValue: {
    color: "#4A90E2",
  },
});

const AssistanceLayout = ({
  onQuickFilter,
  calcData = {},
  resourceMetadata,
  classes,
  title,
  id,
  unit,
}) => {
  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : "last";

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  return (
    <GraphPanelLayout
      title={title}
      headerTools={
        <GraphPeriodFilter
          name={`${id}_PeriodFilter`}
          onQuickFilter={onQuickFilter}
          defaultValue={period}
          menuItems={[
            { value: "last", label: "Last 40" },
            { value: "year", label: "Year" },
            { value: "month", label: "Month" },
            { value: "week", label: "Week" },
          ]}
        />
      }
      loading={loading}
      error={resourceMetadata ? resourceMetadata.fetchError : null}
      graphContent={
        <AssistanceGraph
          id={id}
          data={calcData}
          period={period}
          unit={unit}
          onPagerClick={onQuickFilter}
          page={
            resourceMetadata && resourceMetadata.filter
              ? resourceMetadata.filter.page
              : 1
          }
        />
      }
      paramContent={
        <div>
          <LargeParamField
            id={`${id}_LeftLeg`}
            classes={{
              root: classes.leftParamField,
              value: classes.leftParamValue,
            }}
            data={calcData.leftLeg}
            label="Left Leg"
            name="totalAvg"
            loading={loading}
          />
          <LargeParamField
            id={`${id}_RightLeg`}
            classes={{
              value: classes.rightParamValue,
            }}
            data={calcData.rightLeg}
            label="Right Leg"
            name="totalAvg"
            loading={loading}
          />
        </div>
      }
    />
  );
};

AssistanceLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  onQuickFilter: PropTypes.func,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
};

export default withStyles(styles)(AssistanceLayout);
