import React, { Component } from "react";
import PropTypes from "prop-types";

import LoginForm from "./LoginForm";
import AuthLayout from "components/shared/layouts/AuthLayout";
import LocalizedText from "components/localization/LocalizedText";
import PrivacyPolicy from "components/shared/layouts/AuthLayout/PrivacyPolicy";

class LoginPage extends Component {
  render() {
    const {
      hasErrors,
      onLogin,
      onForgotPassword,
      isSubmitting,
      disabled,
    } = this.props;
    let header = <LocalizedText stringPath="landing.title" />;
    let description = <LocalizedText stringPath="landing.description" />;
    const subContent = <PrivacyPolicy />;

    // Error occurred
    if (hasErrors) {
      header = <span>Wrong Email or Password</span>;
      description = null;
    }

    return (
      <AuthLayout
        header={header}
        description={description}
        subContent={subContent}
      >
        <LoginForm
          onSubmit={onLogin}
          onForgotPassword={onForgotPassword}
          hasErrors={hasErrors}
          isSubmitting={isSubmitting}
          disabled={disabled}
        />
      </AuthLayout>
    );
  }
}

LoginPage.propTypes = {
  onLogin: PropTypes.func,
  onForgotPassword: PropTypes.func,
  hasErrors: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default LoginPage;
