import React from "react";
import _ from "lodash";
import numberFormatter from "lib/numberFormatter";

export function convertCamelCaseToUnderscoreCase(value) {
  if (!value) {
    return "";
  }

  return value
    .split(/(?=[A-Z])/)
    .join("_")
    .toLowerCase();
}

export function convertUnderscoreCaseToCamelCase(value) {
  if (!value) {
    return "";
  }

  return _.camelCase(value);
}

export function deriveTitle(
  resourceMetadata,
  unitName,
  search,
  totalName = "total"
) {
  const total =
    resourceMetadata && resourceMetadata.meta
      ? `${resourceMetadata.meta[totalName]}`
      : "";
  const pluralSuffix = total.toString() === "1" ? "" : "s";

  let titleText = ` ${unitName}${pluralSuffix}`;
  if (search) {
    titleText = (
      <span data-test="searchResultsTitle">{` Result${pluralSuffix} for "${search}"`}</span>
    );
  }

  return (
    <span>
      <span className="totalNum">
        {resourceMetadata && resourceMetadata.meta
          ? numberFormatter.numberWithCommas(resourceMetadata.meta[totalName])
          : ""}
      </span>
      {titleText}
    </span>
  );
}

export function periodToString(periodValue, isGraph=false) {
  let period = isGraph ? "Year" : "All Time";
  switch (periodValue) {
    case "thisYear":
      period = isGraph ? "Month" : "Year";
      break;
    case "thisQuarter":
      period = isGraph ? "Month" : "Quarter";
      break;
    case "thisMonth":
      period = isGraph ? "Day" : "Month";
      break;
    case "thisWeek":
      period = isGraph ? "Day" : "Week";
      break;
    case "today":
      period = isGraph ? "Day" : "Today";
      break;
    default:
      // Do nothing
      break;
  }
  return period;
}
