import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import IdleTimer from "react-idle-timer";
import * as loginActionCreators from "actions/loginActions";

class IdleTimerContainer extends Component {
  onIdle = () => {
    const { isAuthenticated, isVideoPlaying, loginActions } = this.props;

    if (!isAuthenticated) return;
    if (isVideoPlaying) return;

    loginActions.logoutInactivity();
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.isVideoPlaying !== this.props.isVideoPlaying &&
      !this.props.isVideoPlaying
    ) {
      this.idletimer.reset();
    }
  }

  render() {
    return (
      <IdleTimer
        element={document}
        onIdle={this.onIdle}
        timeout={this.props.timeout}
        ref={c => {
          this.idletimer = c;
        }}
      >
        {this.props.children}
      </IdleTimer>
    );
  }
}

IdleTimerContainer.propTypes = {
  children: PropTypes.element.isRequired,
  loginActions: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
  isVideoPlaying: PropTypes.bool,
  timeout: PropTypes.number,
};

IdleTimerContainer.defaultProps = {
  timeout: process.env.TIMEOUT_MS ? process.env.TIMEOUT_MS : 600000,
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVideoPlaying: state.videoPlayback.isPlaying,
});

const mapDispatchToProps = dispatch => ({
  loginActions: bindActionCreators(loginActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdleTimerContainer);
