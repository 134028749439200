import React from "react";
import PropTypes from "prop-types";
import { Grid, Hidden } from "@material-ui/core";
import moment from "moment";
import PatientParamPanel from "./PatientParamPanel";
import GTParamPanel from "./GTParamPanel";
import PanelSubHeader from "components/shared/ui/Panel/PanelSubHeader";
import ParamField from "components/shared/ui/Parameters/ParamField";
import numberFormatter from "lib/numberFormatter";

const SessionParametersLayout = ({ session, hideSubParams }) => {
  return (
    <div style={{ flexGrow: 1 }}>
      {hideSubParams ? null : (
        <Hidden mdUp>
          <div>
            <ParamField
              data={session}
              name="date"
              label="START TIME"
              formatValue={value => moment(value).format("h:mm A")}
            />
            <ParamField
              data={session}
              name="sessTotUprightTimeSec"
              label="UPRIGHT TIME"
              formatValue={numberFormatter.secondsToMinForTable}
            />
            <ParamField
              data={session}
              name="sessTotWalkTimeSec"
              label="WALKING TIME"
              formatValue={numberFormatter.secondsToMinForTable}
            />
            <ParamField data={session} name="sessTotStepCount" label="STEPS" />
          </div>
        </Hidden>
      )}
      <PanelSubHeader title="END OF SESSION PARAMETERS" />
      <Grid container spacing={16}>
        <Grid item xs={12} md={6} style={{ display: "flex" }}>
          <PatientParamPanel session={session} />
        </Grid>
        <Grid item xs={12} md={6}>
          <GTParamPanel session={session} />
        </Grid>
        {/* TODO restore for future feature
          <Grid item xs={12}>
            <AssistGraphsPanel session={session} />
          </Grid>
        */}
      </Grid>
    </div>
  );
};

SessionParametersLayout.propTypes = {
  session: PropTypes.object.isRequired,
  hideSubParams: PropTypes.bool,
};

export default SessionParametersLayout;
