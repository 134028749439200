import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as modalActionCreators from "actions/modalActions";
import DemographicsLayout from "./DemographicsLayout";
import { DEMOGRAPHICS_MODAL_ID, NETWORK_MODAL_ID } from "constants/ModalIds";

class DemographicsContentLayout extends Component {
  handleDemographicsOpenModal = () => {
    this.props.modalActions.openModal(DEMOGRAPHICS_MODAL_ID);
  };

  handleSitesClick = () => {
    this.props.modalActions.openModal(NETWORK_MODAL_ID);
  };

  render() {
    const { resourceMetadata, calcData, site, graph } = this.props;

    return (
      <DemographicsLayout
        resourceMetadata={resourceMetadata}
        calcData={calcData}
        site={site}
        handleDemographicsOpenModal={this.handleDemographicsOpenModal}
        onSitesClick={this.handleSitesClick}
        graph={graph}
      />
    );
  }
}

DemographicsContentLayout.defaultProps = {};

DemographicsContentLayout.propTypes = {
  resourceMetadata: PropTypes.object,
  calcData: PropTypes.object,
  modalActions: PropTypes.object,
  site: PropTypes.object,
  graph: PropTypes.bool,
};

const mapStateToProps = state => ({
  site: state.network.site,
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemographicsContentLayout);
