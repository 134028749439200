import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import BasicPageHeader from "components/shared/ui/Page/BasicPageHeader";
import BottomContent from "./BottomContent";
import { Scrollbars } from "react-custom-scrollbars";
import AverageSessionLength from "components/shared/ui/Graph/AverageSessionLength";
import AverageSteps from "./AverageSteps";
import AverageSessions from "./AverageSessions";
import Demographics from "./Demographics";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
  },
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
  grid: {},
  graphPanel: {
    height: "296px",
  },
  graphContent: {
    paddingBottom: "0px !important",
    paddingRight: "0px !important",
  },
  graphGrid: {
    width: "296px",
  },
  graphContainer: {
    minWidth: "1242px",
    maxWidth: "calc(100% + 4px)",
  },
  scrollDiv: {
    height: "300px !important",
    width: "100%",
  },
});

const graphSiblingResourceNames = [
  "stats/sessions_count",
  "stats/steps_count",
  "stats/sessions_length",
  "stats/demographics",
];

const OverviewPage = ({ classes }) => {
  return (
    <div className={classes.root} data-test="overviewPage">
      <BasicPageHeader title="Device Usage Summary" />
      <div className={classes.main}>
        <Grid className={classes.grid} container spacing={8}>
          <Grid item xs={12} className={classes.graphContent}>
            <Scrollbars
              className={classes.scrollDiv}
              renderTrackVertical={() => <div />}
              renderThumbVertical={() => <div />}
              hideTracksWhenNotNeeded={true}
            >
              <Grid container spacing={8} className={classes.graphContainer}>
                <Grid item xs={3}>
                  <AverageSessionLength
                    key="avgSessionLength"
                    siblingResourceNames={graphSiblingResourceNames}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AverageSteps
                    key="avgSteps"
                    siblingResourceNames={graphSiblingResourceNames}
                  />
                </Grid>
                <Grid item xs={3}>
                  <AverageSessions
                    key="avgSessions"
                    siblingResourceNames={graphSiblingResourceNames}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Demographics
                    key="demographics"
                    siblingResourceNames={graphSiblingResourceNames}
                  />
                </Grid>
              </Grid>
            </Scrollbars>
          </Grid>
          <BottomContent />
        </Grid>
      </div>
    </div>
  );
};

OverviewPage.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object,
  usersResourceMetadata: PropTypes.object,
  id: PropTypes.string,
};

export default withStyles(styles)(OverviewPage);
