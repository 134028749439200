import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Player,
  ControlBar,
  RemainingTimeDisplay,
  PlayToggle,
  ProgressControl,
  BigPlayButton,
  LoadingSpinner,
} from "video-react";
import { withStyles } from "@material-ui/core/styles";
import VideoHeader from "./VideoHeader";
import * as videoPlaybackActions from "actions/videoPlaybackActions";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    position: "relative",
  },

  player: {
    "& .video-react-control-bar": {
      backgroundColor: "rgba(0,0,0,0.5)",
      height: "72px",
      [theme.breakpoints.down("xs")]: {
        height: "48px",
      },
    },
    "& .video-react-control:before": {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        textAlign: "center",
      },
    },
  },

  controlBar: {
    fontSize: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
    "& .video-react-mouse-display:after": {
      fontSize: "14px !important",
      fontFamily: "Roboto",
    },
    "& .video-react-play-progress:after": {
      fontSize: "14px !important",
      fontFamily: "Roboto",
      display: "none !important",
    },
  },

  play: {
    marginLeft: "-12px !important",
    "&.video-react-play-control.video-react-control:before": {
      paddingTop: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-1px !important",
      "&.video-react-play-control.video-react-control:before": {
        paddingTop: "6px",
      },
    },
  },
  remaining: {
    paddingTop: "14px !important",
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: "14px !important",
    marginRight: "12px !important",
    marginLeft: "-12px !important",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto !important",
      marginRight: "6px !important",
      paddingTop: "6px !important",
    },
  },
  bigPlayButton: {
    display: "none !important",
  },
  loadingSpinner: {
    display: "block !important",
  },
});

class VideoPlayer extends Component {
  state = {
    player: {},
  };

  componentDidMount() {
    // subscribe state change
    this.playerRef.subscribeToStateChange(this.handleStateChange.bind(this));

    // Disbale video context menu
    const videoTagProxyCompnent = this.playerRef.video;
    if (videoTagProxyCompnent) {
      videoTagProxyCompnent.video.setAttribute(
        "oncontextmenu",
        "return false;"
      );
    }
  }

  componentWillUnmount() {
    const { videoPlaybackActions } = this.props;
    videoPlaybackActions.playbackStop();
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });
  }

  handleVideoPlay = () => {
    const { videoPlaybackActions } = this.props;
    videoPlaybackActions.playbackStart();
  };

  handleVideoPause = () => {
    const { videoPlaybackActions } = this.props;
    videoPlaybackActions.playbackStop();
  };

  handleVideoEnded = () => {
    const { videoPlaybackActions } = this.props;
    videoPlaybackActions.playbackStop();
  };

  render() {
    const { classes, videoData, onClose } = this.props;
    const playerRef = this.playerRef ? this.playerRef : {};
    return (
      <div className={classes.root} data-test="videoPlayer">
        <Player
          ref={player => {
            this.playerRef = player;
          }}
          className={classes.player}
          autoPlay
          aspectRatio="16:9"
          poster={videoData.fileThumbnail}
          src={videoData.fileUrl}
          onPlay={this.handleVideoPlay}
          onPause={this.handleVideoPause}
          onEnded={this.handleVideoEnded}
        >
          <LoadingSpinner
            className={classNames({
              [classes.loadingSpinner]:
                !this.state.player.isActive &&
                (this.state.player.waiting || this.state.player.readyState < 4),
            })}
          />
          <BigPlayButton position="center" className={classes.bigPlayButton} />
          <ControlBar
            autoHide={true}
            disableDefaultControls={true}
            className={classes.controlBar}
          >
            <PlayToggle order={1} className={classes.play} />
            <ProgressControl order={2} />
            <RemainingTimeDisplay order={3} className={classes.remaining} />
          </ControlBar>
          <VideoHeader
            playerRef={playerRef}
            playerState={this.state.player}
            onClose={onClose}
            videoData={videoData}
          />
        </Player>
      </div>
    );
  }
}

VideoPlayer.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  videoData: PropTypes.object,
  videoPlaybackActions: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  videoPlaybackActions: bindActionCreators(videoPlaybackActions, dispatch),
});

export const DisconnectedVideoPlayer = withStyles(styles)(VideoPlayer);

export default connect(
  null,
  mapDispatchToProps
)(DisconnectedVideoPlayer);
