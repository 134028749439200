import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MobileContentTitle from "components/shared/layouts/Dashboard/MobileContentHeader/MobileContentTitle";
import FilterButton from "components/shared/ui/Page/FilterButton";

const ActivityMobileHeaderContent = ({
  pageContext,
  onDrawerToggle,
  toggleFilter,
}) => {
  return (
    <Fragment>
      <MobileContentTitle
        title={pageContext.pageTitle}
        secondTitle={pageContext.secondTitle}
        onDrawerToggle={onDrawerToggle}
      />
      <FilterButton toggleFilter={toggleFilter} />
    </Fragment>
  );
};

ActivityMobileHeaderContent.propTypes = {
  onDrawerToggle: PropTypes.func,
  toggleFilter: PropTypes.func,
  pageContext: PropTypes.object,
};

export default ActivityMobileHeaderContent;
