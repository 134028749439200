import React, { Component } from "react";
import PropTypes from "prop-types";
import { Creatable } from "react-select";
import { withStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";
import * as CreatableFieldInnerComponents from "./CreatableFieldInnerComponents";

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 2,
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing.unit,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

const components = {
  DropdownIndicator: null,
  ...CreatableFieldInnerComponents,
};

class CreatableField extends Component {
  state = {
    selected:
      {
        value: this.props.inputProps.value,
        label: this.props.inputProps.value,
      } || {},
  };

  onChange = item => {
    const value = item.value || "";
    let newValue = value;
    let newItem = {};
    if (value !== "" && this.existValidation()) {
      const { validation } = this.props;
      if (validation.type === "number") {
        const { completeZeros, digits } = validation.values;
        if (completeZeros) {
          newValue = value.toString().padStart(digits, "0");
        }
      }
    }
    newItem = { value: newValue, label: newValue };
    this.props.input.onChange(newValue);
    this.setState({ selected: newItem });
  };

  existValidation = () => {
    return Object.keys(this.props.validation).length > 0;
  };

  isValidNewOption = item => {
    if (!this.existValidation()) return true;

    const { validation } = this.props;
    if (validation.type === "number") {
      const number = +item;
      const { from, to } = validation.values;
      const size = item.toString().length;
      return (
        size <= validation.values.digits && (number >= from && number <= to)
      );
    }
  };

  render() {
    const { classes, theme, inputProps, label, input } = this.props;

    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
      }),
      menuPortal: base => ({ ...base, zIndex: 9999 }),
    };

    return (
      <Creatable
        classes={classes}
        className={classes.materialField}
        isSearchable
        name={input.name}
        options={inputProps.options}
        menuPortalTarget={document.body}
        styles={selectStyles}
        components={components}
        value={this.state.selected}
        onChange={this.onChange}
        isValidNewOption={this.isValidNewOption}
        textFieldProps={{
          label: label,
          InputLabelProps: {
            shrink: true,
          },
        }}
      />
    );
  }
}

CreatableField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  input: PropTypes.string,
  value: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  validation: PropTypes.shape({
    type: PropTypes.string,
    values: PropTypes.object,
  }),
};

CreatableField.defaultProps = {
  validation: {},
};

export default withStyles(styles, { withTheme: true })(CreatableField);
