import React, { Component } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import Indication from "models/bluechip/Indication";
import PatientsContainer from "./PatientsContainer";
import PatientDetails from "./PatientDetails";
import _ from "lodash";
import policyRules from "lib/policyRules";

const applyResourceFilter = (bluechipQuery, filter) => {
  if (_.isEmpty(filter)) {
    return bluechipQuery;
  }

  if (
    filter.my_patients &&
    String(filter.my_patients).toLowerCase() === "true"
  ) {
    bluechipQuery = bluechipQuery.where({ myPatient: true });
  }

  if (filter.indication_ids) {
    bluechipQuery = bluechipQuery.whereRelated(Indication, {
      id: filter.indication_ids.map(id => `${id}`),
    });
  }

  return bluechipQuery;
};

class PatientRoutes extends Component {
  render() {
    const { match, startFilter, user } = this.props;
    return (
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={() => {
            // Main Index page
            return (
              <PatientsContainer
                applyResourceFilter={applyResourceFilter}
                startFilter={startFilter}
                mustExistToSearchFilterNames={
                  policyRules.filter.viewCustomerNum(user)
                    ? ["customer_num"]
                    : null
                }
              />
            );
          }}
        />
        <Route
          exact
          path={`${match.url}/:id/:tab`}
          render={props => {
            // View Details Page
            return (
              <PatientDetails
                id={props.match.params.id}
                tab={props.match.params.tab}
              />
            );
          }}
        />
      </Switch>
    );
  }
}

PatientRoutes.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  startFilter: PropTypes.object,
};

export default PatientRoutes;
