import React, { Fragment } from "react";
import PropTypes from "prop-types";
import MobileContentTitle from "components/shared/layouts/Dashboard/MobileContentHeader/MobileContentTitle";

const LearningCenterMobileHeaderContent = ({ onDrawerToggle }) => {
  return (
    <Fragment>
      <MobileContentTitle
        title="Learning Center"
        onDrawerToggle={onDrawerToggle}
      />
    </Fragment>
  );
};

LearningCenterMobileHeaderContent.propTypes = {
  onDrawerToggle: PropTypes.func,
  toggleFilter: PropTypes.func,
  pageContext: PropTypes.object,
};

export default LearningCenterMobileHeaderContent;
