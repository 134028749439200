import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import BasicPageHeader from "components/shared/ui/Page/BasicPageHeader";
import LearningCenterCategoryLayout from "../LearningCenterCategoryLayout";
import VideoModal from "../VideoModal";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
  },
  main: {
    padding: "8px",
    width: "100%",
    boxSizing: "border-box",
  },
  gridItem: {
    //marginBottom: "-1px",
  },
  headerGrid: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contentGrid: {
    "& div > div:last-child > div": {
      //borderBottom: 0,
    },
  },
  footerGrid: {
    display: "none",
  },
});

class LearningCenterChildPage extends Component {
  render() {
    const {
      classes,
      items,
      title,
      id,
      onNavigate,
      error,
      loading,
      onPlayVideo,
    } = this.props;

    return (
      <div className={classes.root}>
        <BasicPageHeader
          onNavigate={onNavigate}
          backPath="/dashboard/learning"
          title="Learning Center"
        />
        <div className={classes.main}>
          <Grid container spacing={8}>
            <Grid item xs={12} className={classes.gridItem}>
              <LearningCenterCategoryLayout
                title={title}
                items={items}
                id={id}
                error={error}
                loading={loading}
                onPlayVideo={onPlayVideo}
                classes={{
                  headerGrid: classes.headerGrid,
                  contentGrid: classes.contentGrid,
                  footerGrid: classes.footerGrid,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <VideoModal />
      </div>
    );
  }
}

LearningCenterChildPage.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array,
  title: PropTypes.string,
  id: PropTypes.string,
  onNavigate: PropTypes.func,
  error: PropTypes.object,
  loading: PropTypes.bool,
  onPlayVideo: PropTypes.func,
};

export default withStyles(styles)(LearningCenterChildPage);
