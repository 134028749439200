import React, { Component } from "react";
import PropTypes from "prop-types";
import ResetPasswordForm from "./ResetPasswordForm";
import AuthLayout from "components/shared/layouts/AuthLayout";

class ResetPasswordPage extends Component {
  render() {
    const { disabled, isSubmitting, onRequestReset, onCancel } = this.props;
    let header = "Forgot your password?";
    let description = "Enter your email address to reset your password.";

    return (
      <AuthLayout header={header} description={description}>
        <ResetPasswordForm
          onSubmit={onRequestReset}
          onCancel={onCancel}
          disabled={disabled}
          isSubmitting={isSubmitting}
        />
      </AuthLayout>
    );
  }
}

ResetPasswordPage.propTypes = {
  onRequestReset: PropTypes.func,
  onCancel: PropTypes.func,
  hasErrors: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ResetPasswordPage;
