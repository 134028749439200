import React from "react";
import PropTypes from "prop-types";
import GraphPanelLayout from "components/shared/ui/Graph/GraphPanelLayout";
import GraphPeriodFilter from "components/shared/ui/Graph/GraphPeriodFilter";
import AverageSessionLengthGraph from "./AverageSessionLengthGraph";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";

const AverageSessionLengthLayout = ({
  onQuickFilter,
  calcData = {},
  resourceMetadata,
  last40Option,
}) => {
  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : last40Option
        ? "last"
        : "year";

  let menuItems = [
    { value: "year", label: "Year" },
    { value: "month", label: "Month" },
    { value: "week", label: "Week" },
  ];
  if (last40Option) {
    menuItems = [
      { value: "last", label: "Last 40" },
      { value: "year", label: "Year" },
      { value: "month", label: "Month" },
      { value: "week", label: "Week" },
    ];
  }

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  return (
    <GraphPanelLayout
      title="Walk Time"
      headerTools={
        <GraphPeriodFilter
          name="averageSessionLengthPeriodFilter"
          onQuickFilter={onQuickFilter}
          defaultValue={period}
          menuItems={menuItems}
        />
      }
      loading={loading}
      error={resourceMetadata ? resourceMetadata.fetchError : null}
      graphContent={
        <AverageSessionLengthGraph
          data={calcData.data}
          period={period}
          onPagerClick={onQuickFilter}
          page={
            resourceMetadata && resourceMetadata.filter
              ? resourceMetadata.filter.page
              : 1
          }
        />
      }
      paramContent={
        <div>
          <LargeParamField
            id="AverageSessionLength"
            data={calcData}
            label="Average"
            name="totalAvg"
            loading={loading}
            formatValue={value => `${value ? Math.floor(value / 60) : 0} min`}
          />
        </div>
      }
    />
  );
};

AverageSessionLengthLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  onQuickFilter: PropTypes.func,
  last40Option: PropTypes.bool,
};

export default AverageSessionLengthLayout;
