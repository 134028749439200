import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  root: {
    backgroundColor: "#f3f3f3",
    [theme.breakpoints.up("md")]: {
      maxWidth: "200px",
      minWidth: "200px",
    },
  },
  drawerPaper: {
    height: "100%",
    backgroundColor: "white",
    overflow: "hidden",
    maxWidth: "200px",
    right: 0,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  },
  desktopDrawerWrapper: {
    height: "100%",
    maxWidth: "200px",
    minWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "&> div": {
      height: "100%",
    },
  },
  mobileDrawer: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
});

class FilterDrawerLayout extends Component {
  render() {
    const { isFilterOpen, toggleFilter, classes } = this.props;
    const content = this.props.children;
    return (
      <div className={classes.root}>
        <Drawer
          variant="temporary"
          anchor="right"
          open={isFilterOpen}
          className={classes.mobileDrawer}
          onClose={toggleFilter}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {content}
        </Drawer>
        <div className={classes.desktopDrawerWrapper}>
          <Drawer
            variant="persistent"
            open={isFilterOpen}
            onClose={toggleFilter}
            anchor="right"
            classes={{
              paper: classes.drawerPaper,
            }}
            SlideProps={{ unmountOnExit: true }}
          >
            {content}
          </Drawer>
        </div>
      </div>
    );
  }
}

FilterDrawerLayout.propTypes = {
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  onFilter: PropTypes.func,
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(FilterDrawerLayout);
