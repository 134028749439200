import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import GraphPager from "./GraphPager";

const _calculatePagerProps = (page, onPagerClick) => {
  const result = {};
  result.rightDisabled = page === 1;

  let startLabel;
  let endLabel;

  const start = moment()
    .subtract(page - 1, "years")
    .startOf("year");
  const end = moment()
    .subtract(page - 1, "years")
    .endOf("year");

  startLabel = start.format("MMM YYYY");
  endLabel = end.format("MMM YYYY");

  result.leftDisabled = false;
  result.label = `${startLabel} - ${endLabel}`;
  result.labelWidth = "112px";

  result.onLeftClick = () => onPagerClick({ period: "year", page: page + 1 });
  result.onRightClick = () => onPagerClick({ period: "year", page: page - 1 });

  return result;
};

const CalendarYearTimeLineGraphPager = ({ onPagerClick, page }) => {
  const pagerProps = _calculatePagerProps(page, onPagerClick);
  return <GraphPager {...pagerProps} />;
};

CalendarYearTimeLineGraphPager.propTypes = {
  onPagerClick: PropTypes.func,
  page: PropTypes.number,
};

export default CalendarYearTimeLineGraphPager;
