import UtilizationGraphContent from "./UtilizationGraphContent";
import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";
import { STATS_UTILIZATION_GRAPH } from "constants/NetworkTypes";

export default EksoGraphContainerWrapper(UtilizationGraphContent, Stats, {
  resourceTypeName: "stats",
  resourceName: STATS_UTILIZATION_GRAPH,
  id: "network_utilization_graph",
  startFilter: {
    period: "allTime",
    page: 1,
  },
});
