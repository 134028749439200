import { OPEN, CLOSE, OPEN_CONFIRM } from "constants/ModalTypes";
import * as modalIds from "constants/ModalIds";

export function openModal(id) {
  return {
    type: OPEN,
    id,
    data: null,
  };
}

export function openModalWithData(id, data) {
  return {
    type: OPEN,
    id,
    data,
  };
}

export function closeModal(id) {
  return {
    type: CLOSE,
    id,
  };
}

export function openConfirmModal(options) {
  return {
    type: OPEN_CONFIRM,
    id: modalIds.CONFIRM_MODAL_ID,
    options,
  };
}

export function closeConfirmModal() {
  return closeModal(modalIds.CONFIRM_MODAL_ID);
}
