import changeCase from "change-case";
import Base from "./Base";

class User extends Base {
  fullName() {
    return changeCase.titleCase(
      `${this.apiAttrs.attributes.user_first_name} ${
        this.apiAttrs.attributes.user_last_name
      }`
    );
  }

  roles() {
    return this.apiAttrs.relationships.roles.data;
  }

  customer() {
    return this.included.find(incl => incl.type == "customers");
  }

  roleNames() {
    return this.roles().map(role => role.name);
  }

  isA(role) {
    if (Array.isArray(role)) {
      return !!this.roleNames().find(function(roleName) {
        return role.indexOf(roleName) >= 0;
      });
    } else {
      if (this.roleNames().indexOf(role) >= 0) return true;
      return false;
    }
  }
}

export default User;
