import React from "react";
import PropTypes from "prop-types";

import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line
const styles = theme => ({
  icon: {
    color: "white",
  },
});

const AddDetailsMobileButton = ({ classes, onClick, className }) => {
  return (
    <IconButton onClick={onClick} className={className}>
      <EditIcon className={classes.icon} />
    </IconButton>
  );
};

AddDetailsMobileButton.defaultProps = {
  isFilterOpen: false,
};

AddDetailsMobileButton.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
};

export default withStyles(styles)(AddDetailsMobileButton);
