import * as types from "constants/ToastTypes";

const initialState = {
  open: false,
  queue: [],
  currentMessage: null,
  currentToastId: null,
  hideDuration: null,
};

export default function(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case types.SHOW_TOAST: {
      const { message, hideDuration } = action;
      const toastId = new Date().getTime().toString();

      if (state.open) {
        // If a toast is already open, queue it for later instead
        const queue = state.queue.slice();
        queue.push({
          message,
          toastId,
          hideDuration,
        });

        // Close the current toast
        return {
          ...state,
          open: false,
          queue,
        };
      } else {
        return {
          ...state,
          open: true,
          currentMessage: message,
          currentToastId: toastId,
          hideDuration,
        };
      }
    }

    case types.DISMISS_TOAST: {
      return {
        ...state,
        open: false,
        hideDuration: null,
      };
    }

    case types.SHOW_QUEUED_TOAST: {
      let currentMessage = null;
      let currentToastId = null;
      let hideDuration = null;
      let open = false;
      const queue = state.queue.slice();

      if (state.queue.length > 0) {
        open = true;

        // Grab the queued message to open
        const messageInfo = queue.shift();
        currentMessage = messageInfo.message;
        currentToastId = messageInfo.toastId;
        hideDuration = messageInfo.hideDuration;
      }

      return {
        open,
        currentMessage,
        currentToastId,
        queue,
        hideDuration,
      };
    }

    default:
      return state;
  }
}
