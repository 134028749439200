import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from "@material-ui/core";
import { NETWORK_MODAL_ID } from "constants/ModalIds";
import * as modalActionCreators from "actions/modalActions";
import * as networkActionCreators from "actions/networkActions";

const styles = () => ({
  listText: {
    margin: 0,
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  listItem: {
    paddingLeft: "0px",
  },
  titleText: {
    fontSize: "12px",
    opacity: 0.5,
    mixBlendMode: "normal",
    paddingBottom: "21px",
    paddingTop: "24px",
    lineHeight: "14px",
    color: "rgba(0, 0, 0, 0.87)",
  },
});

class NetworkList extends Component {
  handleNetworkClick = network => {
    this.props.modalActions.closeModal(NETWORK_MODAL_ID);
    this.props.networkActions.changeNetwork(network);
  };

  handleSiteClick = site => {
    this.props.modalActions.closeModal(NETWORK_MODAL_ID);
    this.props.networkActions.changeSite(site);
  };

  render() {
    const {
      classes,
      network: [network],
      sites,
      currentUser,
    } = this.props;

    const siteTitle = (
      <Typography fontSize={16} className={classes.titleText}>
        Sites
      </Typography>
    );

    return (
      <List>
        {currentUser.isA(["ekso_sales"]) ? (
          ""
        ) : (
          <div>
            <Typography fontSize={16} className={classes.titleText}>
              Network
            </Typography>
            <ListItem
              key="network-item"
              button
              className={classes.listItem}
              onClick={() => this.handleNetworkClick(network)}
            >
              <ListItemText
                id="checkbox-list-secondary-label-network"
                primary={network.name ? network.name : null}
                className={classes.listText}
              />
              <ListItemSecondaryAction key="network-action">
                <ArrowForwardIosIcon
                  key="network-icon"
                  style={{ fontSize: 14 }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </div>
        )}
        <Divider variant="inset" component="li" key="network-divider" />
        {sites.length ? siteTitle : null}
        {sites.length
          ? sites.map(site => {
              const labelId = `checkbox-list-secondary-label-${site.id}`;
              return (
                <div key={`div-${site.id}`}>
                  <ListItem
                    data-value={`${site.name}`}
                    key={site.id}
                    button
                    className={classes.listItem}
                    onClick={() => this.handleSiteClick(site)}
                  >
                    <ListItemText
                      id={labelId}
                      key={`list-item-${site.id}`}
                      primary={site.name}
                      className={classes.listText}
                    />
                    <ListItemSecondaryAction key={site.id}>
                      <ArrowForwardIosIcon
                        key={`icon-item-${site.id}`}
                        style={{ fontSize: 14 }}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider
                    variant="inset"
                    component="li"
                    key={`divider-${site.id}`}
                  />
                </div>
              );
            })
          : null}
      </List>
    );
  }
}

NetworkList.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
  modalActions: PropTypes.object,
  networkActions: PropTypes.object,
  site: PropTypes.object,
  network: PropTypes.array,
  sites: PropTypes.array,
  currentUser: PropTypes.object,
};

const mapStateToProps = state => {
  let sites = state.auth.currentUser.included.reduce(
    (sites, { type, id, attributes: { name } }) =>
      type === "sites" ? [...sites, { id, name }] : sites,
    []
  );
  return {
    open: state.modal[NETWORK_MODAL_ID]
      ? state.modal[NETWORK_MODAL_ID].open
      : false,
    network: state.auth.network,
    sites: sites,
    currentUser: state.auth.currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(modalActionCreators, dispatch),
  networkActions: bindActionCreators(networkActionCreators, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NetworkList));
