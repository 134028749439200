import { BaseModel } from "@blue-chip/core";
import Patient from "./Patient";

export default class SettingOfUse extends BaseModel {
  static singular = "settingOfUse";
  static plural = "settingOfUses";

  static get hasMany() {
    return [Patient];
  }
}
