const numberFormatter = {
  convertSeconds: function(secs) {
    var secNum = parseInt(secs, 10);
    var seconds = Math.floor((secNum % 3600) % 60);
    var hours = Math.floor(secNum / 3600);
    var minutes = Math.floor((secNum - hours * 3600) / 60);

    return { seconds, hours, minutes };
  },
  secondsToHM: function(secs) {
    if (!secs) {
      return "00:00";
    }
    let { hours, minutes } = numberFormatter.convertSeconds(secs);

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    return hours + ":" + minutes;
  },
  secondsToHMS: function(secs, withUnit = true, keepZeroHours = false) {
    if (!secs) {
      return "00:00";
    }
    let { hours, minutes, seconds } = numberFormatter.convertSeconds(secs);

    seconds = ("0" + seconds).slice(-2);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (!keepZeroHours && hours === "00") {
      return minutes + ":" + seconds + (withUnit ? " min" : "");
    }
    return hours + ":" + minutes + ":" + seconds + (withUnit ? " h" : "");
  },
  secondsToHMSPlain: function(secs) {
    return numberFormatter.secondsToHMS(secs, false, true);
  },
  secondsToRoundedTime: function(secs) {
    if (!secs) {
      return 0 + "min";
    }

    let { hours, minutes, seconds } = numberFormatter.convertSeconds(secs);

    if (hours) {
      return Math.round(hours + minutes / 60) + "h";
    } else if (minutes) {
      return Math.round(minutes + seconds / 60) + "min";
    } else {
      return seconds + "s";
    }
  },
  secondsToRoundedTimeGraph: function(secs) {
    if (!secs) {
      return 0;
    }

    let { hours, minutes, seconds } = numberFormatter.convertSeconds(secs);

    if (hours) {
      return Math.round(hours + minutes / 60);
    } else if (minutes) {
      return Math.round(minutes + seconds / 60);
    } else {
      return seconds;
    }
  },
  secondsToLabel: function(secs) {
    let { hours, minutes, seconds } = numberFormatter.convertSeconds(secs);
    if (hours) {
      return `${Math.round(hours + minutes / 60)} h`;
    } else if (minutes) {
      return `${Math.round(minutes + seconds / 60)} min`;
    } else {
      return `${Math.round(seconds)} s`;
    }
  },

  secondsToRoundedTimeGraphLabel: function(secs) {
    if (!secs) {
      return "min";
    }

    let { hours, minutes } = numberFormatter.convertSeconds(secs);

    if (hours) {
      return "h";
    } else if (minutes) {
      return "min";
    } else {
      return "s";
    }
  },
  numberWithCommas: function(num) {
    if (!num) {
      return 0;
    }
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  secondsToMinForTable: function(secs) {
    if (!secs) {
      return "0:00min";
    }
    var secNum = parseInt(secs, 10);
    var seconds = Math.floor((secNum % 3600) % 60);
    var minutes = Math.floor(secNum / 60);

    seconds = ("0" + seconds).slice(-2);

    return minutes + ":" + seconds + "min";
  },
  divideNum: function(value, divisor, decimals = 1) {
    if (!value) {
      return "0";
    }

    return (parseFloat(value) / divisor).toFixed(decimals);
  },
  stepsPerMin: function(data, stepsPropName, walkTimeSecPropName) {
    if (
      !data[stepsPropName] ||
      !data[walkTimeSecPropName] ||
      parseFloat(data[stepsPropName]) == 0 ||
      parseFloat(data[walkTimeSecPropName]) == 0
    ) {
      return "0";
    }

    const steps = parseFloat(data[stepsPropName]);
    const walkTime = parseFloat(data[walkTimeSecPropName]);
    return (steps / (walkTime / 60)).toFixed(1);
  },
  convertAutoStepWShift(data, divisor = 1) {
    let shift = data;
    if (!shift && shift !== 0) {
      return null;
    }
    if (shift > 5000) {
      shift = shift - 65536;
    }
    return (shift / divisor).toFixed(0).toString();
  },
  convertGaitScore(data) {
    if (data === 65535) {
      return null;
    }

    return data;
  },
};

export default numberFormatter;
