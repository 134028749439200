import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TherapistContent from "./TherapistContent";
import AdminContent from "./AdminContent";
import Policy from "components/Policy";

const BottomContent = () => {
  return (
    <Fragment>
      <Policy action="viewMyUtilization" object="overview">
        <TherapistContent />
      </Policy>
      <Policy action="viewAdminUtilization" object="overview">
        <AdminContent />
      </Policy>
    </Fragment>
  );
};

BottomContent.defaultProps = {};

BottomContent.propTypes = {
  classes: PropTypes.object,
  recentSessionsTable: PropTypes.node,
  myUtilization: PropTypes.node,
};

export default BottomContent;
