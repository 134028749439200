import { BaseModel } from "@blue-chip/core";
import Faq from "./Faq";

export default class FaqCategory extends BaseModel {
  static singular = "faqCategory";
  static plural = "faqCategory";

  static get hasMany() {
    return [Faq];
  }
}
