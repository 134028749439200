import numberFormatter from "lib/numberFormatter";

function convertSecondsToUnit(secs, unit) {
  let divider = 1;
  if (unit === "h") {
    divider = 3600;
  } else if (unit === "min") {
    divider = 60;
  }

  return secs / divider;
}

export function getDataWithOptimalUnit(data, dataAttr) {
  let bestUnit = "sec";

  const parsedPoints = data.map(point => {
    const pointValueInSeconds = point[dataAttr];
    const { hours, minutes, seconds } = numberFormatter.convertSeconds(
      pointValueInSeconds
    );
    let label;

    if (hours) {
      bestUnit = "h";
      label = `${Math.round(hours + minutes / 60)} h`;
    } else if (!hours && minutes) {
      if (bestUnit !== "h") {
        bestUnit = "min";
      }
      label = `${Math.round(minutes + seconds / 60)} h`;
    }

    return {
      x: point.period,
      y: pointValueInSeconds,
      label,
    };
  });

  const pointsWithBestUnitData = parsedPoints.map(point => {
    return {
      ...point,
      y: convertSecondsToUnit(point.y, bestUnit),
      label: numberFormatter.secondsToLabel(point.y),
    };
  });

  return {
    points: pointsWithBestUnitData,
    bestUnit,
  };
}

export function convertDataToUnit(data, dataAttr, unit) {
  return data.map(point => {
    return {
      x: point.period,
      y: convertSecondsToUnit(point[dataAttr], unit),
      label: numberFormatter.secondsToLabel(point[dataAttr]),
    };
  });
}
