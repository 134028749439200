/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import DataTableRow from "components/shared/ui/Table/DataTableRow";
import { TableBody, TableRow, TableCell } from "@material-ui/core";
import MessageContent from "./MessageContent";
import _ from "lodash";

const styles = theme => ({
  root: {},
  closed: {
    "&::before": {
      content: "close-quote",
      height: "0px",
      display: "block",
      transition: "all 200ms",
    },
    "&::after": {
      content: "close-quote",
      height: "0px",
      display: "block",
      transition: "all 200ms",
    },
  },
  open: {
    "&:before": {
      content: "close-quote",
      display: "block",
      height: "16px",
      transition: "all 200ms",
    },
    "&:after": {
      content: "close-quote",
      display: "block",
      height: "16px",
      transition: "all 200ms",
    },
  },
  detailsDiv: {
    position: "relative",
    paddingBottom: "14px",
  },
  // Note use of div is to hide the shadow of the details row to allow a consistent shadow border
  shadowBlocker: {
    position: "absolute",
    height: "10px",
    width: "calc(100% + 46px)",
    top: "-12px",
    backgroundColor: "white",
    marginLeft: "-16px",
    cursor: "pointer",
  },
  row: {
    backgroundColor: "white",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.12)",
  },
  rowOpen: {
    boxShadow: "0 1px 10px 0 rgba(0,0,0,0.32)",
  },
  activeDateCell: {
    color: "rgba(52,73,94,0.87)",
    opacity: 0.5,
    fontSize: "12px",
    fontWeight: 500,
    paddingLeft: "24px",
    paddingTop: "24px",
  },
  activeTitleCell: {
    [theme.breakpoints.up("md")]: {
      "&>span": {
        display: "none",
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 0,
      "&>div": {
        paddingBottom: "8px",
      },
    },
  },
  activeContentCell: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: "26px",
    },
  },
});

class NotificationItem extends Component {
  handleClick = () => {
    this.props.onRowClick(this.props.notification);
  };

  render() {
    const { classes, notification, columns, isActive } = this.props;

    let bodyClass = `${classes.root} ${classes.closed}`;
    let rowClass = classes.row;
    let modifiedColumns = columns;

    if (isActive) {
      bodyClass = `${classes.root} ${classes.open}`;
      rowClass = `${classes.row} ${classes.rowOpen}`;

      modifiedColumns = columns.map(oldColumn => {
        const column = _.clone(oldColumn);
        if (column.prop === "createdAt") {
          column.className = classNames(
            column.className,
            classes.activeDateCell
          );
        } else if (column.prop === "title") {
          column.className = classNames(
            column.className,
            classes.activeTitleCell
          );
        }

        return column;
      });
    }

    return (
      <TableBody className={bodyClass}>
        <DataTableRow
          onClick={this.handleClick}
          className={rowClass}
          columns={modifiedColumns}
          rowData={notification}
        />

        {isActive ? (
          <TableRow className={rowClass} data-test="tableRowActive">
            <TableCell
              colSpan={modifiedColumns.length}
              className={classes.activeContentCell}
            >
              <div className={classes.detailsDiv}>
                <div
                  className={classes.shadowBlocker}
                  onClick={this.handleClick}
                />
                <MessageContent
                  messageData={notification}
                  toggleContent={this.handleClick}
                />
              </div>
            </TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    );
  }
}

export default withStyles(styles)(NotificationItem);

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  isActive: PropTypes.bool,
};
