import React, { Component } from "react";
import PropTypes from "prop-types";
import SelectField from "./SelectField";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { Divider, MenuItem, Checkbox, ListItemText } from "@material-ui/core";
import { Scrollbars } from "react-custom-scrollbars";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  allOption: {},
  checkboxItem: {
    padding: "12px 0px",
    marginRight: "12px",
  },
  selectedOption: {
    backgroundColor: "transparent !important",
  },
  optionTextItem: {
    padding: "0 4px 0 0",
  },
  optionText: {
    fontSize: "14px !important",
    lineHeight: "1em",
  },
  noFocus: {
    "&:focus": {
      outline: "none",
    },
  },
  scrollbars: {
    "&:focus": {
      outline: "none",
    },
    marginBottom: "10px",
  },
});

class CheckboxSelectField extends Component {
  state = {
    open: false,
    allOptions: this.props.menuItems.map(item => item.value),
    allChecked: true,
  };

  static getDerivedStateFromProps(props, state) {
    const newOptions = props.menuItems.map(item => item.value);
    // Menu options updated so reset
    if (!_.isEqual(newOptions, state.allOptions)) {
      return {
        allOptions: newOptions,
      };
    }

    if (
      props.input.value &&
      props.input.value.length === props.menuItems.length &&
      state.all === false
    ) {
      return {
        all: true,
      };
    } else if (
      props.input.value &&
      props.input.value.length !== props.menuItems.length &&
      state.all === true
    ) {
      return {
        all: false,
      };
    }

    return null;
  }

  handleAllClick = () => {
    const allChecked = !this.state.allChecked;
    const options = allChecked ? this.state.allOptions : [];
    this.props.input.onChange(options);
    this.setState({ allChecked });
  };

  handleCheckboxClick = value => {
    let newValues;
    if (this.props.input.value.indexOf(value) > -1) {
      newValues = _.without(this.props.input.value, value);
    } else {
      newValues = this.props.input.value.slice();
      newValues.push(value);
    }
    this.props.input.onChange(newValues);
    this.setState({
      allChecked: newValues.length === this.state.allOptions.length,
    });
  };

  _renderSelectedValuesLabel(selectedValues) {
    const { menuItems } = this.props;
    const remaining = selectedValues.length - 1;
    const label = _.find(menuItems, { value: selectedValues[0] }).label;
    const remainingText = remaining ? `, ${remaining} more` : "";
    return `${label}${remainingText}`;
  }

  renderValue = selectedValues => {
    const { menuItems, allItemsLabel, input } = this.props;
    if (!_.get(menuItems, "length", 0)) {
      return "Loading...";
    }

    if (_.get(input, "value.length", 0) == this.state.allOptions.length) {
      return allItemsLabel;
    } else if (_.get(selectedValues, "length", 0)) {
      return this._renderSelectedValuesLabel(selectedValues);
    } else {
      return "None Selected";
    }
  };

  _renderAllOptionsItem() {
    const { classes, allItemsLabel } = this.props;
    const { allChecked } = this.state;

    return (
      <div className={classes.noFocus}>
        <MenuItem
          classes={{
            root: classes.checkboxItem,
            selected: classes.selectedOption,
          }}
          key="all_items"
          value="all_items"
          onClick={() => this.handleAllClick()}
        >
          <Checkbox color="primary" checked={allChecked} />
          <ListItemText
            primary={allItemsLabel}
            className={classes.optionTextItem}
            primaryTypographyProps={{
              className: classes.optionText,
            }}
          />
        </MenuItem>
      </div>
    );
  }

  _renderMenuItems() {
    const { classes, menuItems } = this.props;
    return menuItems.map(item => (
      <MenuItem
        key={item.value}
        onClick={() => this.handleCheckboxClick(item.value)}
        value={item.value}
        classes={{
          root: classes.checkboxItem,
          selected: classes.selectedOption,
        }}
      >
        <Checkbox
          color="primary"
          checked={this.props.input.value.indexOf(item.value) > -1}
        />
        <ListItemText
          primary={item.label}
          className={classes.optionTextItem}
          primaryTypographyProps={{
            className: classes.optionText,
          }}
          title={item.label}
        />
      </MenuItem>
    ));
  }

  render() {
    const { classes, menuItems } = this.props;
    const modifiedInput = _.extend({}, this.props.input, {
      onChange: () => {},
    });
    return (
      <SelectField
        {...this.props}
        classes={_.omit(classes, [
          "allOption",
          "checkboxItem",
          "selectedOption",
          "optionTextItem",
          "optionText",
          "noFocus",
          "scrollbars",
        ])}
        input={modifiedInput}
        disabled={menuItems.length === 0}
        inputProps={{
          name: this.props.input.name,
          renderValue: this.renderValue,
          displayEmpty: true,
          multiple: true,
          autoWidth: true,
          SelectDisplayProps: {
            "data-name": this.props.input.name,
          },
          MenuProps: {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          },
        }}
      >
        {this._renderAllOptionsItem()}
        <Divider className={classes.noFocus} />
        <Scrollbars
          autoHeight
          style={{ width: this.props.menuWidth }}
          autoHeightMax="144px"
          className={classes.scrollbars}
        >
          {this._renderMenuItems()}
        </Scrollbars>
      </SelectField>
    );
  }
}

CheckboxSelectField.defaultProps = {
  menuWidth: "150px",
};

CheckboxSelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  inputProps: PropTypes.object,
  inputComponent: PropTypes.node,
  style: PropTypes.object,
  isLinkStyle: PropTypes.bool,
  labelLeft: PropTypes.bool,
  menuItems: PropTypes.array.isRequired,
  classes: PropTypes.object,
  allItemsLabel: PropTypes.string,
  menuWidth: PropTypes.string,
};

export default withStyles(styles)(CheckboxSelectField);
