import React from "react";
import PropTypes from "prop-types";

import { Button, IconButton } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";

import MenuList from "@material-ui/core/MenuList";
import { withStyles } from "@material-ui/core/styles";

// eslint-disable-next-line no-unused-vars
const styles = theme => ({
  menuList: {
    minWidth: "130px",
    padding: 0,
  },
});

class Menu extends React.Component {
  determineAnchorEl = () => {
    if (this.props.displayMenuOver) {
      return this.topDiv;
    }

    return this.buttonRef;
  };

  determinePopperPlacement = () => {
    if (this.props.displayMenuOver) {
      return "bottom-start";
    }

    return "bottom";
  };

  determineGrowPlacement = placement => {
    if (this.props.growPlacement) {
      return this.props.growPlacement;
    }

    if (this.props.displayMenuOver) {
      return "left top";
    }

    return placement === "bottom" ? "center top" : "center bottom";
  };

  render() {
    const {
      classes,
      label,
      onClickAway,
      onToggle,
      open,
      isIconButton,
      dataTest,
    } = this.props;

    const buttonProps = {
      buttonRef: node => {
        this.buttonRef = node;
      },
      "aria-owns": open ? "menu-list-grow" : null,
      "aria-haspopup": "true",
      className: this.props.buttonClassName,
      onClick: onToggle,
      children: label,
      "data-test": dataTest,
    };

    const button = React.createElement(
      isIconButton ? IconButton : Button,
      buttonProps
    );

    return (
      <div>
        <div
          ref={node => {
            this.topDiv = node;
          }}
        />
        <div>
          {button}
          <Popper
            open={!!open}
            anchorEl={this.determineAnchorEl()}
            transition
            style={{ zIndex: 2000 }}
            placement={this.determinePopperPlacement()}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin: this.determineGrowPlacement(placement),
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={e => onClickAway(e, this.buttonRef)}
                    mouseEvent="onClick"
                  >
                    <MenuList className={classes.menuList}>
                      {this.props.children}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  displayMenuOver: PropTypes.bool, // set to true to display menu directly over button
  classes: PropTypes.object,
  buttonClassName: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.node.isRequired,
  onClickAway: PropTypes.func,
  onToggle: PropTypes.func,
  open: PropTypes.bool,
  isIconButton: PropTypes.bool,
  growPlacement: PropTypes.string,
  dataTest: PropTypes.string,
};

export default withStyles(styles)(Menu);
