import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import SelectField from "components/shared/ui/Field/SelectField";
import CheckboxField from "components/shared/ui/Field/CheckboxField";
//import validate from "validation/Patients/addPatientDetailsValidation";

const styles = theme => ({
  submitButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  clearButton: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
  },
  materialField: {
    marginBottom: theme.spacing.unit * 1.5,
    display: "block",
  },
  selectField: {
    width: "110px",
    display: "inline-block",
    marginRight: "16px",
    marginBottom: theme.spacing.unit * 3,
  },
  disabledClearButton: {
    color: "inherit !important",
    backgroundColor: "white !important",
  },
});

const ITEM_HEIGHT = 36;
const selectMenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5,
    },
  },
};

function mapMenuItems(fieldData, name, hasBlank) {
  if (!fieldData || !fieldData[name]) {
    return [];
  }

  const menuItems = fieldData[name].map(item => ({
    label: item.name,
    value: `${item.id}`,
  }));

  if (hasBlank) {
    menuItems.unshift({ label: "None", value: "" });
  }

  return menuItems;
}

const PatientReduxForm = ({ handleSubmit, classes, fieldData, disabled }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="indication"
        component={SelectField}
        label="Indication"
        className={classNames(classes.materialField, classes.selectField)}
        inputClassName={classes.input}
        placeholder="Select"
        renderPlaceholderMenuItem={false}
        disabled={disabled}
        menuProps={selectMenuProps}
        menuItems={mapMenuItems(fieldData, "indications", true)}
        inputProps={{
          autoFocus: true,
        }}
      />
      <Field
        name="settingOfUse"
        component={SelectField}
        label="Setting of Use"
        className={classNames(classes.materialField, classes.selectField)}
        inputClassName={classes.input}
        placeholder="Select"
        renderPlaceholderMenuItem={false}
        disabled={disabled}
        menuProps={selectMenuProps}
        menuItems={mapMenuItems(fieldData, "settingOfUses", true)}
      />
      <Field
        name="assistiveDevices"
        component={SelectField}
        label="Assistive Device"
        className={classes.materialField}
        inputClassName={classes.input}
        placeholder="Select"
        renderPlaceholderMenuItem={false}
        disabled={disabled}
        menuProps={selectMenuProps}
        menuItems={mapMenuItems(fieldData, "assistiveDevices")}
        inputProps={{
          multiple: true,
        }}
      />
    </form>
  );
};

PatientReduxForm.propTypes = {
  handleSubmit: PropTypes.func,
  classes: PropTypes.object,
  fieldData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default reduxForm({
  form: "patientReduxForm",
  //validate,
})(withStyles(styles)(PatientReduxForm));
