import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Grid, Hidden } from "@material-ui/core";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import NetworkOverviewMobileTabs from "./NetworkOverviewMobileTabs";
import Utilization from "./Utilization";
import Demographics from "./Demographics";

const PanelContent = () => {
  const demographics = <Demographics />;
  const demographicsGraph = <Demographics graph={true} />;
  const utilization = <Utilization />;
  return (
    <Fragment>
      <Hidden smDown>
        <Grid container spacing={8}>
          <Grid item xs={6} md={3}>
            <PaperPanel>{demographics}</PaperPanel>
          </Grid>
          <Grid item xs={6} md={3}>
            <PaperPanel>{demographicsGraph}</PaperPanel>
          </Grid>
          <Grid item xs={12} md={6}>
            <PaperPanel>{utilization}</PaperPanel>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <NetworkOverviewMobileTabs
          demographics={demographics}
          demographicsTabLabel="Demographics"
          utilization={utilization}
          utilizationTabLabel="Utilization"
        />
      </Hidden>
    </Fragment>
  );
};

PanelContent.defaultProps = {};

PanelContent.propTypes = {
  classes: PropTypes.object,
  demographics: PropTypes.node,
  utilization: PropTypes.node,
};

export default PanelContent;
