import React from "react";
import PropTypes from "prop-types";
import NetworkGraphPanelLayout from "components/shared/ui/Graph/NetworkGraphPanelLayout";
import AverageSessionsGraph from "./AverageSessionsGraph";
import LargeParamField from "components/shared/ui/Parameters/LargeParamField";
import { withStyles } from "@material-ui/core/styles";
import { periodToString } from "lib/stringUtils";

// eslint-disable-next-line
const styles = theme => ({
  label: {
    paddingTop: "8px",
    marginBottom: "8px",
  },
  topContent: {
    marginBottom: "0px",
  },
  root: {
    boxShadow: "none !important",
  },
});

const AverageSessionsLayout = ({
  calcData = {},
  resourceMetadata,
  title,
  fieldValue,
  formatValue,
  label,
  convertedData,
  classes,
  unitLabel,
  page,
  key,
  compare,
  showCompare,
  sites,
  sitesConvertedData,
  site_id,
}) => {
  const period =
    resourceMetadata && resourceMetadata.filter
      ? resourceMetadata.filter.period
      : "allTime";

  const loading = !resourceMetadata || resourceMetadata.isFetchingItems;
  let periodUnit = periodToString(period, true).toLowerCase();
  const LargeParamValue = (
    <LargeParamField
      id={fieldValue}
      data={calcData.totalAvg ? calcData.totalAvg : {}}
      label={label ? label : "Average"}
      name={fieldValue}
      formatValue={formatValue}
      loading={loading}
      classes={{ label: classes.label }}
      period={periodUnit}
    />
  );

  const _getSitesFromResource = (site, sites, sitesConvertedData) => {
    let newSites = sites;
    if (site) {
      newSites = [];
      for (site in sitesConvertedData) {
        newSites.push({ id: site, name: `Device ${site}` });
      }
    }
    return newSites;
  };

  return (
    <NetworkGraphPanelLayout
      key={key}
      title={title}
      loading={loading}
      error={resourceMetadata ? resourceMetadata.fetchError : null}
      graphContent={
        <AverageSessionsGraph
          convertedData={convertedData}
          period={period}
          unitLabel={unitLabel}
          page={page}
          compare={compare}
          showCompare={showCompare}
          sites={_getSitesFromResource(site_id, sites, sitesConvertedData)}
          sitesConvertedData={sitesConvertedData}
          loading={loading}
          site_id={site_id}
        />
      }
      classes={{ topContent: classes.topContent, root: classes.root }}
      paramContent={<div>{periodUnit != "year" ? LargeParamValue : ""}</div>}
    />
  );
};

AverageSessionsLayout.propTypes = {
  classes: PropTypes.object,
  calcData: PropTypes.object,
  resourceMetadata: PropTypes.object,
  title: PropTypes.string,
  fieldValue: PropTypes.string,
  label: PropTypes.string,
  formatValue: PropTypes.func,
  convertedData: PropTypes.array,
  unitLabel: PropTypes.string,
  page: PropTypes.number,
  site_id: PropTypes.number,
  key: PropTypes.number,
  compare: PropTypes.object,
  showCompare: PropTypes.func,
  sites: PropTypes.array,
  sitesConvertedData: PropTypes.object,
};

export default withStyles(styles)(AverageSessionsLayout);
