import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { bindActionCreators } from "redux";
import { toggleSearch as toggleSearchActionCreator } from "actions/pageContextActions";
import SearchField from "components/shared/ui/Field/SearchField";

const SearchFormContent = ({
  className,
  isSearchOpen,
  toggleSearch,
  handleSubmit,
}) => {
  return (
    <form onSubmit={handleSubmit} className={className}>
      <Field
        name="search"
        component={SearchField}
        toggleSearch={toggleSearch}
        isSearchOpen={isSearchOpen}
        onSearch={handleSubmit}
      />
    </form>
  );
};

SearchFormContent.propTypes = {
  handleSubmit: PropTypes.func,
  toggleSearch: PropTypes.func,
  isSearchOpen: PropTypes.bool,
  className: PropTypes.string,
};

const SearchForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SearchFormContent);

const mapStateToProps = (state, { formName }) => ({
  form: formName || "search",
  isSearchOpen: state.pageContext.isSearchOpen,
  onSubmit: state.pageContext.onSearch,
});

const mapDispatchToProps = dispatch => ({
  toggleSearch: bindActionCreators(toggleSearchActionCreator, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchForm);
