import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";

const styles = theme => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      padding: "24px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "48px",
    },
  },
});

function AuthLayoutPanel({ children, classes }) {
  return <PaperPanel className={classes.root}>{children}</PaperPanel>;
}

AuthLayoutPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(AuthLayoutPanel);
