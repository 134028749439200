const locales = ["en", "de-DE", "pl", "es-ES", "it-IT"];

export function translateLocaleToLang(locale) {
  return locale.substring(0, 2);
}

export function translateLangToLocale(lang) {
  var locale = locales.filter(function(locale) {
    return locale.startsWith(lang);
  });

  if (locale.length) {
    return locale[0];
  }
}
