import React, { Component } from "react";
import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import PropTypes from "prop-types";
import Session from "models/bluechip/Session";
import _ from "lodash";
import SessionStatsLayout from "./SessionStatsLayout";

class SessionStatsContainer extends Component {
  state = {
    currentSessionId: null,
    sessionNum: 0,
  };

  componentDidUpdate(prevProps) {
    const { rows, resourceMetadata } = this.props;
    const wasLoading =
      prevProps.resourceMetadata && prevProps.resourceMetadata.isFetchingItems;
    const newState = {};

    if (
      this.state.currentSessionId === null &&
      wasLoading &&
      resourceMetadata.isFetchItemsSuccess &&
      rows &&
      rows.length
    ) {
      newState.currentSessionId = rows[0].id;
      newState.sessionNum = rows.length;
      this.setState(newState);
    }
  }

  onSessionChange = (session, sessionNum) => {
    this.setState({
      currentSessionId: session.id,
      sessionNum,
    });
  };

  render() {
    return (
      <SessionStatsLayout
        patient={this.props.patient}
        sessions={this.props.rows}
        resourceMetadata={this.props.resourceMetadata}
        onSessionChange={this.onSessionChange}
        currentSessionId={this.state.currentSessionId}
        currentSession={_.find(this.props.rows, {
          id: this.state.currentSessionId,
        })}
        sessionNum={this.state.sessionNum}
      />
    );
  }
}

SessionStatsContainer.propTypes = {
  resourceMetadata: PropTypes.object,
  rows: PropTypes.array,
  onFind: PropTypes.func,
  patient: PropTypes.object,
};

export default EksoDataTableContainerWrapper(SessionStatsContainer, Session, {
  resourceTypeName: "sessions",
  includes: ["insessEvents"],
  startSort: { column: "date", order: "DESC" },
});
