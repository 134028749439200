/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { formatDate, formatTime } from "lib/dateUtils";
import DataTable from "components/shared/ui/Table";
import numberFormatter from "lib/numberFormatter";
import SessionItem from "./SessionItem";
import {
  convertCamelCaseToUnderscoreCase,
  convertUnderscoreCaseToCamelCase,
} from "lib/stringUtils";
import SessionActionMenu from "../SessionActionMenu";

const styles = theme => ({
  serialCell: {
    [theme.breakpoints.up("lg")]: {
      width: "35%",
    },
  },
  hideCellSmDown: {
    [theme.breakpoints.down("sm")]: {
      width: "0 !important",
      height: "0 !important",
      padding: "0 !important",
      margin: "0 !important",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "transparent",
    },
  },
  scrollDiv: {
    height: "calc(100vh - 224px) !important",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 232px) !important",
    },
  },
  actionCol: {
    width: "50px",
    paddingLeft: "0px",
    paddingRight: "1px !important",
    [theme.breakpoints.down("sm")]: {
      width: "40px",
    },
  },
});

class SessionsTable extends Component {
  state = {
    openSessionId: null,
  };

  columns = [
    {
      label: "DATE",
      prop: "date",
      format: value => formatDate(value),
    },
    {
      label: "START TIME",
      prop: "date",
      sortProp: "startTime",
      key: "startTime",
      format: formatTime,
      className: this.props.classes.hideCellSmDown,
    },
    {
      label: "EKSO SERIAL",
      prop: "eksoSerialNum",
      className: this.props.classes.serialCell,
    },
    {
      label: "UPRIGHT TIME",
      prop: "sessTotUprightTimeSec",
      format: numberFormatter.secondsToMinForTable,
      isNumeric: true,
      className: this.props.classes.hideCellSmDown,
    },
    {
      label: "WALKING TIME",
      prop: "sessTotWalkTimeSec",
      format: numberFormatter.secondsToMinForTable,
      isNumeric: true,
      className: this.props.classes.hideCellSmDown,
    },
    {
      label: "STEPS",
      prop: "sessTotStepCount",
      isNumeric: true,
      className: this.props.classes.hideCellSmDown,
      format: numberFormatter.numberWithCommas,
    },
    {
      label: "",
      prop: "",
      unsortable: true,
      className: this.props.classes.actionCol,
      format: (value, data) => {
        return <SessionActionMenu session={data} />;
      },
    },
  ];

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.sessionsResourceMetadata.isFetchingItems) {
      return { openSessionId: null };
    }

    return null;
  }

  handleRowClick = rowData => {
    this.setState({
      openSessionId:
        this.state.openSessionId !== rowData.id ? rowData.id : null,
    });
  };

  convertSortPropName = columnConfig => {
    // blue-chip auto camelCases property names, must undo for api call
    return convertCamelCaseToUnderscoreCase(
      columnConfig.sortProp ? columnConfig.sortProp : columnConfig.prop
    );
  };

  onSort = columnConfig => {
    const { sessionsResourceMetadata } = this.props;
    const sort =
      sessionsResourceMetadata && sessionsResourceMetadata.order
        ? sessionsResourceMetadata.order
        : {};

    // Must convert to underscoreCase for api call
    const column = this.convertSortPropName(columnConfig);

    let order = "ASC";
    if (sort.column === column) {
      order = sort.order === "DESC" ? "ASC" : "DESC";
    }

    const sortObj = {
      column,
      order,
    };
    this.props.onSortChange(sortObj);
  };

  render() {
    const {
      sessions,
      onShow,
      sessionsResourceMetadata,
      classes,
      menusOpen,
    } = this.props;
    const sort =
      sessionsResourceMetadata && sessionsResourceMetadata.order
        ? sessionsResourceMetadata.order
        : {};

    return (
      <DataTable
        columns={this.columns}
        loading={sessionsResourceMetadata.isFetchingItems}
        sort={{
          column: convertUnderscoreCaseToCamelCase(sort.column),
          order: sort.order,
        }}
        error={sessionsResourceMetadata.fetchError}
        onSort={this.onSort}
        isInfiniteScroll={true}
        loadMore={this.props.loadMore}
        hasMore={
          !!sessionsResourceMetadata.nextPage &&
          !sessionsResourceMetadata.isFetchingItems
        }
        classes={{
          scrollDiv: classes.scrollDiv,
          root: classes.table,
        }}
      >
        {sessions.map(session => {
          return (
            <SessionItem
              key={session.id}
              columns={this.columns}
              session={session}
              onShow={onShow}
              onRowClick={rowData =>
                menusOpen ? null : this.handleRowClick(rowData)
              }
              isActive={this.state.openSessionId === session.id}
            />
          );
        })}
      </DataTable>
    );
  }
}

SessionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  sessions: PropTypes.array,
  onSortChange: PropTypes.func,
  sort: PropTypes.object,
  menusOpen: PropTypes.bool,
};

export { SessionsTable as SessionsTableBase };
export default withStyles(styles)(SessionsTable);
