import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

const styles = theme => ({
  root: {
    backgroundColor: "black",
    opacity: 0.12,
    [theme.breakpoints.up("md")]: {
      backgroundColor: "white",
      opacity: 0.16,
    },
  },
  inset: {
    marginLeft: "16px",
    [theme.breakpoints.up("md")]: {
      marginLeft: "20px",
    },
  },
});

class NavigationDivider extends Component {
  render() {
    const { classes } = this.props;
    let className = classes.root;
    if (this.props.inset) {
      className = `${classes.root} ${classes.inset}`;
    }
    return <Divider className={className} />;
  }
}

NavigationDivider.propTypes = {
  classes: PropTypes.object,
  inset: PropTypes.bool,
};

export default withStyles(styles)(NavigationDivider);
