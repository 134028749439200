import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent } from "@material-ui/core";
import VideoPlayer from "./VideoPlayer";

import { MuiThemeProvider, withStyles } from "@material-ui/core/styles";

const dialogTheme = theme => ({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiDialog: {
      paper: {
        margin: 0,
        padding: 0,
        borderRadius: "0px",
        maxWidth: "816px !important",
        width: "100%",
      },
    },
    MuiDialogContent: {
      root: {
        padding: "0 !important",
      },
    },
  },
});

const styles = () => ({
  macOSExpandFix: {
    willChange: "unset !important",
    maxHeight: "unset",
    "& > div": {
      overflowY: "visible",
    },
  },
});

const VideoModalDialog = ({
  open,
  onClose,
  videoData,
  dialogOptions,
  classes,
}) => {
  return (
    <MuiThemeProvider theme={dialogTheme}>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="dialog-title"
        classes={{
          paper: classes.macOSExpandFix,
        }}
        {...dialogOptions}
      >
        <DialogContent>
          <VideoPlayer videoData={videoData} onClose={onClose} />
        </DialogContent>
      </Dialog>
    </MuiThemeProvider>
  );
};

VideoModalDialog.propTypes = {
  onClose: PropTypes.func,
  onCreateUser: PropTypes.func,
  open: PropTypes.bool,
  buttons: PropTypes.node,
  children: PropTypes.node,
  title: PropTypes.string,
  closeable: PropTypes.bool,
  classes: PropTypes.object,
  dialogOptions: PropTypes.object,
  loading: PropTypes.bool,
  videoData: PropTypes.object,
};

export default withStyles(styles)(VideoModalDialog);
