import * as validationUtils from "validation/validationUtils";

export default function validate(values) {
  const errors = {};
  validationUtils.validateRequired(
    ["currentPassword", "password", "passwordConfirm"],
    values,
    errors
  );

  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = "Confirmation must match New Password";
  }

  if (values.currentPassword == values.password) {
    errors.password = "New Password must be different from Current Password";
  }

  return errors;
}
