import EksoGraphContainerWrapper from "components/shared/ui/Graph/EksoGraphContainerWrapper";
import Stats from "models/bluechip/Stats";
import UtilizationContentLayout from "./UtilizationContentLayout";
import { STATS_UTILIZATION } from "constants/NetworkTypes";

export default EksoGraphContainerWrapper(UtilizationContentLayout, Stats, {
  resourceTypeName: "stats",
  resourceName: STATS_UTILIZATION,
  id: "network_utilization",
  startFilter: {
    period: "allTime",
  },
});
