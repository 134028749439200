import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Typography } from "@material-ui/core";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import ErrorPanel from "components/shared/ui/Panel/ErrorPanel";
import Skeleton from "react-loading-skeleton";
import GraphPanelOverlay from "./GraphPanelOverlay";

// eslint-disable-next-line
const styles = theme => ({
  root: {
    height: "296px",
  },
  topContent: {
    height: "100px",
    padding: "16px",
    boxSizing: "border-box",
    paddingBottom: "8px",
  },
  topContentEmptyPanel: {
    height: "auto",
  },
  noParams: {
    height: "34px",
  },
  header: {
    color: "rgba(52,73,94,0.87)",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "16px",
    flex: 1,
  },
  headerTools: {
    float: "right",
  },
  headerContent: {
    display: "flex",
    marginBottom: "8px",
  },
  paramContent: {},
  graphContent: {
    padding: "4px 8px 0px 4px",
  },
  loadingPanel: {
    position: "relative",
    top: "60%",
  },
  skeletonGraph: {
    height: "185px",
    paddingLeft: "4px",
  },
  contentDiv: {
    position: "relative",
  },
});

const GraphPanelLayout = ({
  classes,
  title,
  headerTools,
  paramContent,
  graphContent,
  loading,
  error,
  showOverlay,
  overlayIconName,
  overlayTitle,
  overlayDescription,
}) => {
  let content = (
    <div className={classes.graphContent}>
      {loading ? (
        <div className={classes.skeletonGraph}>
          <Skeleton height={paramContent ? 185 : 249} />
        </div>
      ) : (
        graphContent
      )}
    </div>
  );

  if (error) {
    content = <ErrorPanel error={error} />;
  }

  return (
    <PaperPanel className={classes.root}>
      <div
        className={classNames(classes.topContent, {
          [classes.topContentEmptyPanel]: loading || !!error,
          [classes.noParams]: !paramContent,
        })}
      >
        <div className={classes.headerContent}>
          <Typography className={classes.header} variant="subheading">
            {loading ? <Skeleton width={120} /> : title}
          </Typography>
          {loading ? null : (
            <div className={classes.headerTools}>{headerTools}</div>
          )}
        </div>
        {error || !paramContent ? null : (
          <div className={classes.paramContent}>{paramContent}</div>
        )}
      </div>
      {error || !paramContent ? null : <Divider />}
      <div className={classes.contentDiv}>
        {content}
        {showOverlay ? (
          <GraphPanelOverlay
            iconName={overlayIconName}
            headerText={overlayTitle}
            description={overlayDescription}
          />
        ) : null}
      </div>
    </PaperPanel>
  );
};

GraphPanelLayout.propTypes = {
  headerTools: PropTypes.node,
  paramContent: PropTypes.node,
  graphContent: PropTypes.node,
  classes: PropTypes.object,
  title: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.object,
  showOverlay: PropTypes.bool,
  overlayIconName: PropTypes.string,
  overlayTitle: PropTypes.string,
  overlayDescription: PropTypes.string,
};

export default withStyles(styles)(GraphPanelLayout);
