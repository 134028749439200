import React from "react";
import PropTypes from "prop-types";
import PaperPanel from "components/shared/ui/Panel/PaperPanel";
import ParamTable from "components/shared/ui/Parameters/ParamTable";
import UserDetailRow from "./UserDetailRow";
import { withStyles } from "@material-ui/core/styles";
import { formatDateFull } from "lib/dateUtils";

const styles = theme => ({
  root: {
    padding: "24px 24px 16px 24px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 8px 0px 8px",
    },
  },
  paramTable: {
    width: "100%",
    margin: 0,
  },
});

const formatIp = value => {
  if (!value || value === "::1") {
    return "Unknown";
  }

  return value;
};

const UserDetailPanel = ({ user, classes }) => {
  return (
    <PaperPanel className={classes.root} dataTest="userDetailPanel">
      <ParamTable classes={{ root: classes.paramTable }}>
        <UserDetailRow
          data={user}
          name="userFirstName"
          label="User first name"
        />
        <UserDetailRow data={user} name="userLastName" label="User last name" />
        <UserDetailRow data={user} name="email" label="Email" />
        <UserDetailRow data={user} name="signInCount" label="Sign in count" />
        <UserDetailRow
          data={user}
          name="currentSignInAt"
          formatValue={value => formatDateFull(value)}
          label="Current sign in at"
        />
        <UserDetailRow
          data={user}
          name="lastSignInAt"
          formatValue={value => formatDateFull(value)}
          label="Last sign in at"
        />
        <UserDetailRow
          data={user}
          name="currentSignInIp"
          label="Current sign in ip"
          formatValue={formatIp}
        />
        <UserDetailRow
          data={user}
          name="lastSignInIp"
          label="Last sign in ip"
          formatValue={formatIp}
        />
        <UserDetailRow
          data={user}
          name="invitationCreatedAt"
          formatValue={value => formatDateFull(value)}
          label="Invitation created at"
        />
        <UserDetailRow
          data={user}
          name="invitationSentAt"
          formatValue={value => formatDateFull(value)}
          label="Invitation sent at"
        />
        <UserDetailRow
          data={user}
          name="invitationAcceptedAt"
          formatValue={value => formatDateFull(value)}
          label="Invitation accepted at"
        />
        <UserDetailRow
          data={user}
          name="failedAttempts"
          label="Failed attempts"
        />
        <UserDetailRow
          data={user}
          name="role"
          formatValue={value =>
            value === "customer_admin" ? "Admin" : "Therapist"
          }
          label="Role"
        />
      </ParamTable>
    </PaperPanel>
  );
};

UserDetailPanel.propTypes = {
  user: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(UserDetailPanel);
