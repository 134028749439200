import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { navigate, replace } from "actions/navigateActions";
import * as menuActionCreators from "actions/menuActions";
import { jsonAPIReduxActions } from "lib/jsonAPIRedux";
import { api } from "lib/api";
import * as toastActionCreators from "actions/toastActions";

// #lizard forgives
function SessionActionWrapper(WrappedActionComponent) {
  class BaseSessionActionContainer extends Component {
    handleExportSessionSummary = async () => {
      const { session, toastActions } = this.props;
      let newWindow = window.open("/generatingPdf.html", "_blank");
      try {
        toastActions.showToast("Generating summary report...");

        let response = await api(
          `pulse_sessions/${session.id}/summary_export`,
          "get"
        );

        toastActions.dismissToast();
        newWindow.location.href = response.url;
      } catch (error) {
        newWindow.close();
        toastActions.showToast(
          `Error while generating summary report${
            error.message ? ": " + error.message : ""
          }`
        );
      }
    };

    render() {
      return (
        <WrappedActionComponent
          {...this.state}
          {...this.props}
          onExportSessionSummary={this.handleExportSessionSummary}
          session={this.props.session}
          menuOpen={this.props.menuOpen}
          menuActions={this.props.menuActions}
          tab={this.props.tab}
          currentUser={this.props.currentUser}
        />
      );
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    patientsResourceMetadata: state.jsonApiResources.patients,
    menuOpen: ownProps.session
      ? state.menu[`${ownProps.session.id}_menu`]
      : false,
    currentUser: state.auth.currentUser,
  });

  const mapDispatchToProps = dispatch => ({
    menuActions: bindActionCreators(menuActionCreators, dispatch),
    patientActions: jsonAPIReduxActions(dispatch, "therapist_patients"),
    navigate: bindActionCreators(navigate, dispatch),
    replace: bindActionCreators(replace, dispatch),
    toastActions: bindActionCreators(toastActionCreators, dispatch),
  });

  BaseSessionActionContainer.propTypes = {
    session: PropTypes.object,
    navigate: PropTypes.func,
    replace: PropTypes.func,
    modalActions: PropTypes.object,
    patientActions: PropTypes.object,
    patientsResourceMetadata: PropTypes.object,
    menuActions: PropTypes.object,
    menuOpen: PropTypes.bool,
    tab: PropTypes.string,
    currentUser: PropTypes.object,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(BaseSessionActionContainer);
}

export default SessionActionWrapper;
