import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SearchReduxForm from "components/shared/ui/Form/SearchReduxForm";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "24px",
    marginRight: "8px",
    marginTop: "8px",
    marginLeft: "8px",
  },
  searchOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.standard,
    }),
    width: "100%",
  },
  buttonLabel: {
    position: "absolute",
    top: "8px",
  },
  title: {
    fontSize: "20px",
    fontWeight: 500,
    color: "white",
    paddingTop: "12px",
  },
  titleContent: {
    paddingLeft: "8px",
    paddingTop: "4px",
  },
  root: {
    display: "flex",
    boxSizing: "border-box",
    height: "56px",
    marginLeft: "-8px",
    width: "100%",
  },
  icon: {
    fontSize: "40px",
    fontWeight: 200,
    color: "white",
  },
  backButton: {
    "&:hover": {
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "rgba(255, 255, 255, .05)",
        borderRadius: 0,
      },
    },
  },
  titleDiv: {
    display: "flex",
    width: "100%",
  },
});

const LearningCenterChildPageMobileHeaderContent = ({
  onNavigate,
  pageContext,
  classes,
  hasSearch,
}) => {
  return (
    <div className={classes.root}>
      {hasSearch && pageContext.isSearchOpen ? null : (
        <div className={classes.titleDiv}>
          <Button
            className={classes.backButton}
            classes={{ label: classes.buttonLabel }}
            onClick={() => onNavigate("/dashboard/learning")}
          >
            <ChevronLeftIcon className={classes.icon} />
          </Button>
          <div className={classes.titleContent}>
            <Typography variant="title" className={classes.title}>
              {pageContext.pageTitle}
            </Typography>
          </div>
        </div>
      )}
      {hasSearch ? (
        <SearchReduxForm
          formName="faqSearch"
          className={classNames(classes.form, {
            [classes.searchOpen]: pageContext.isSearchOpen,
          })}
        />
      ) : null}
    </div>
  );
};

LearningCenterChildPageMobileHeaderContent.propTypes = {
  onNavigate: PropTypes.func,
  pageContext: PropTypes.object,
  classes: PropTypes.object,
  hasSearch: PropTypes.bool,
};

export default withStyles(styles)(LearningCenterChildPageMobileHeaderContent);
