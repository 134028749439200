import React from "react";
import PropTypes from "prop-types";
import ActionButton from "components/shared/ui/Button/ActionButton";
import ModalDialog from "components/shared/ui/Modal/ModalDialog";
import UserReduxForm from "./UserReduxForm";

const UserModalDialog = ({
  open,
  onClose,
  onPrimaryAction,
  onSubmit,
  isSubmitDisabled,
  submitting,
  primaryActionLabel,
  user,
  eksoSerialNumbers,
}) => {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      title={user ? `${user.userFirstName} ${user.userLastName}` : "New User"}
      buttons={[
        <ActionButton
          key="createUserButton"
          variant="contained"
          color="primary"
          onClick={onPrimaryAction}
          disabled={isSubmitDisabled}
          loading={submitting}
          data-test="saveUserButton"
        >
          {primaryActionLabel}
        </ActionButton>,
      ]}
    >
      <UserReduxForm
        initialValues={user}
        onSubmit={onSubmit}
        eksoSerialNumbers={eksoSerialNumbers}
      />
    </ModalDialog>
  );
};

UserModalDialog.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onPrimaryAction: PropTypes.func,
  primaryActionLabel: PropTypes.string,
  open: PropTypes.bool,
  classes: PropTypes.object,
  isSubmitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  user: PropTypes.object,
  eksoSerialNumbers: PropTypes.array,
};

export default UserModalDialog;
