import React from "react";
import PropTypes from "prop-types";
import ModalDialog from "components/shared/ui/Modal/ModalDialog";
import ActionButton from "components/shared/ui/Button/ActionButton";
import EditSessionReduxForm from "./EditSessionReduxForm";

const EditSessionModalDialog = ({ 
  open, 
  onClose,
  onPrimaryAction,
  isSubmitDisabled,
  submitting,
  primaryActionLabel,
  onSubmit,
  session,
  patients
}) => {
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      title="Edit Session"
      buttons={[
        <ActionButton
          key="savePatientButton"
          variant="contained"
          color="primary"
          onClick={onPrimaryAction}
          disabled={isSubmitDisabled}
          loading={submitting}
          data-test="savePatientButton"
        >
          {primaryActionLabel}
        </ActionButton>,
      ]}
    >
      <EditSessionReduxForm 
        onSubmit={onSubmit}
        session={session}
        patients={patients}
      />
    </ModalDialog>
  );
};

EditSessionModalDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onPrimaryAction: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  submitting: PropTypes.bool,
  primaryActionLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  session: PropTypes.object,
  patients: PropTypes.array,
};

export default EditSessionModalDialog;
