import React, { Component } from "react";
import PropTypes from "prop-types";
import Patient from "models/bluechip/Patient";
import { navigate } from "actions/navigateActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setContext, toggleFilter } from "actions/pageContextActions";
import EksoDataTableContainerWrapper from "components/shared/ui/Table/EksoDataTableContainerWrapper";
import { destroy } from "redux-form";
import PatientsPage from "./PatientsPage";
import { deriveTitle } from "lib/stringUtils";
import { updateGlobalFilter } from "actions/globalFilterActions";
import _ from "lodash";
import qs from "qs";

class PatientsContainer extends Component {
  state = {
    hasCustomFilter: false,
  };

  componentDidMount() {
    this.props.setContext({
      pageTitle: "Patients",
      onFilter: this.onSubmitFilters,
      filterName: "patients",
      isFilterOpen: false,
      path: "/dashboard/patients",
    });

    const params = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (params.filter) {
      this._updateGlobalFilter(params.filter);
    }
  }

  _updateGlobalFilter = filter => {
    const globalFilter = {};
    if ("my_patients" in filter) {
      globalFilter.my_patients =
        new String(filter.my_patients).toLowerCase() === "true";
    }

    globalFilter.customer_num = filter.customer_num;

    this.props.updateGlobalFilter(globalFilter);
  };

  componentDidUpdate(prevProps) {
    const { resourceMetadata } = this.props;
    if (
      resourceMetadata &&
      (!prevProps.resourceMetadata ||
        !_.isEqual(prevProps.resourceMetadata.meta, resourceMetadata.meta))
    ) {
      this.props.setContext({
        pageTitle: deriveTitle(resourceMetadata, "Patient"),
      });
    }
  }

  componentWillUnmount() {
    this.props.destroyForm("patientFilterForm");
  }

  onQuickFilter = quickFilter => {
    this.props.onQuickFilter(quickFilter);
    this._updateGlobalFilter(quickFilter);

    this.setState({ hasCustomFilter: false });
  };

  onSubmitFilters = filter => {
    this.props.onSubmitFilters(filter, true);
    this._updateGlobalFilter(filter);

    this.setState({ hasCustomFilter: true });
  };

  onNavigate = path => {
    this.props.navigate(path);
  };

  render() {
    if (!this.props.resourceMetadata) {
      return null;
    }

    return (
      <PatientsPage
        patients={this.props.rows}
        patientsResourceMetadata={this.props.resourceMetadata}
        loadMore={this.props.loadMore}
        onShow={this.props.onFind}
        toggleFilter={this.props.toggleFilter}
        isFilterOpen={this.props.isFilterOpen}
        onQuickFilter={this.onQuickFilter}
        hasCustomFilter={this.state.hasCustomFilter}
        onSortChange={this.props.onSortChange}
        onNavigate={this.onNavigate}
        menusOpen={this.props.menusOpen}
        currentUser={this.props.currentUser}
      />
    );
  }
}

const mapStateToProps = state => ({
  isFilterOpen: state.pageContext.isFilterOpen,
  menusOpen: state.menu.menusOpen,
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => ({
  setContext: bindActionCreators(setContext, dispatch),
  toggleFilter: bindActionCreators(toggleFilter, dispatch),
  destroyForm: bindActionCreators(destroy, dispatch),
  navigate: bindActionCreators(navigate, dispatch),
  updateGlobalFilter: bindActionCreators(updateGlobalFilter, dispatch),
});

PatientsContainer.propTypes = {
  setContext: PropTypes.func,
  toggleFilter: PropTypes.func,
  isFilterOpen: PropTypes.bool,
  resourceMetadata: PropTypes.object,
  resourceActions: PropTypes.object,
  rows: PropTypes.array,
  loadMore: PropTypes.func,
  onSortChange: PropTypes.func,
  onQuickFilter: PropTypes.func,
  onSubmitFilters: PropTypes.func,
  onFind: PropTypes.func,
  destroyForm: PropTypes.func,
  navigate: PropTypes.func,
  menusOpen: PropTypes.bool,
  currentUser: PropTypes.object,
  location: PropTypes.object,
  updateGlobalFilter: PropTypes.func,
};

const PatientsContainerConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientsContainer);

export default EksoDataTableContainerWrapper(
  PatientsContainerConnected,
  Patient,
  {
    resourceTypeName: "patients",
    resourceName: "patients",
    includes: ["indication"],
  }
);
